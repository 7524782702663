import axios from "axios";
import { createUrlWithParams } from "@/components/utils/utils";

export async function getApplicationList(paramsURL = null) {
  if (paramsURL) {
    const {
      ACAD_YR,
      EXERCISE,
      ADM_NO,
      REC_AWARD_TYPE,
      REC_AWARD_CODE,
      ORDER_BY_FIELD,
    } = paramsURL;

    let params = {};
    if (ACAD_YR !== null && ACAD_YR !== undefined) {
      params = {
        ...params,
        ACAD_YR: ACAD_YR,
      };
    }
    if (EXERCISE !== null && EXERCISE !== undefined) {
      params = {
        ...params,
        EXERCISE: EXERCISE,
      };
    }
    if (ADM_NO !== null && ADM_NO !== undefined) {
      params = {
        ...params,
        ADM_NO: ADM_NO,
      };
    }
    if (REC_AWARD_TYPE !== null && REC_AWARD_TYPE !== undefined) {
      params = {
        ...params,
        REC_AWARD_TYPE: REC_AWARD_TYPE,
      };
    }
    if (REC_AWARD_CODE !== null && REC_AWARD_CODE !== undefined) {
      params = {
        ...params,
        REC_AWARD_CODE: REC_AWARD_CODE,
      };
    }

    if (ORDER_BY_FIELD !== null && ORDER_BY_FIELD !== undefined) {
      params = {
        ...params,
        ORDER_BY_FIELD: ORDER_BY_FIELD,
      };
    }
    let baseUrl = process.env.REACT_APP_API_URL + `/api/v1/applications`;
    const url = createUrlWithParams(baseUrl, params);

    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err?.message);
        return err;
      });
  } else {
    return axios
      .get(process.env.REACT_APP_API_URL + "/api/v1/applications")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err?.message);
        return err;
      });
  }
}

export function getApplicationDetail(acadyear, exercise, admno) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/details?ACAD_YR=${acadyear}&EXERCISE=${exercise}&ADM_NO=${admno}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export function submitApplicationDetail(params) {
  return axios
    .post(process.env.REACT_APP_API_URL + "/api/v1/applications", params)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export function editApplicationDetail(params) {
  return axios
    .patch(process.env.REACT_APP_API_URL + "/api/v1/applications", params)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export function transferApplicationExercise(params) {
  return axios
    .post(
      process.env.REACT_APP_API_URL +
        "/api/v1/applications/transfers/anothers/exercises",
      params
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export function getDetailApplicationLog(ACAD_YR, EXERCISE, ADM_NO) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/details/logs?ACAD_YR=${ACAD_YR}&EXERCISE=${EXERCISE}&ADM_NO=${ADM_NO}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function getApplicationParentLog(ACAD_YR, EXERCISE, ADM_NO) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/parents/logs?ACAD_YR=${ACAD_YR}&EXERCISE=${EXERCISE}&ADM_NO=${ADM_NO}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function getApplicationSiblingLog(ACAD_YR, EXERCISE, ADM_NO) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/siblings/logs?ACAD_YR=${ACAD_YR}&EXERCISE=${EXERCISE}&ADM_NO=${ADM_NO}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function getScholarship(ACAD_YR, EXERCISE, ADM_NO) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/scholarships?ACAD_YR=${ACAD_YR}&EXERCISE=${EXERCISE}&ADM_NO=${ADM_NO}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function upsertScholarship(data) {
  return axios
    .post(
      process.env.REACT_APP_API_URL + "/api/v1/applications/scholarships",
      data
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export function deleteScholarship(id) {
  return axios
    .delete(
      process.env.REACT_APP_API_URL + "/api/v1/applications/scholarships/" + id
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export function getDSpecialDetails(data) {
  return axios
    .post(
      process.env.REACT_APP_API_URL + "/api/v1/applications/specials/statuses",
      data
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}
