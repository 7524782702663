import axios from "axios";
// {
//     "INSTITUTE_ID": "N1",
//     "SCHEME_ID": "NYP",
//     "INSTITUTION": "NYP SG",
//     "YEAR_OFFER": 2024,
//     "MONTH_OFFER": 12,
//     "SERIAL_NO": "np"
// }

export async function getSchemeList(params = {}) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/schemes?limit=0`,
      params
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function postScheme(serialData) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/schemes`,
      serialData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function putScheme(serialID, serialData) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/schemes/${serialID}`,
      serialData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteScheme(selectedId) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/schemes/${selectedId}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
