import {Button, Modal} from "@govtechsg/sgds-react";
import {useDropzone} from "react-dropzone";
import {Link} from "react-router-dom";
import { downloadTemplate } from '@/components/utils/utils'

export default function UploadModal({
                                        title = "Upload Constituency Secretariat Code",
                                        description = "",
                                        formatDescription = "",
                                        show,
                                        handleClose,
                                        onFileSelect,
                                        actionAppend,
                                        actionOverwrite,
                                        templateURL,
                                        headers = [],
                                        fileName = "import"
                                    }) {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            onFileSelect(acceptedFiles[0])
        }
    });


    return (
        <Modal show={show} onHide={handleClose} centered={true} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="bi bi-exclamation-triangle me-3"></i>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mx-8 my-3">
                    <div {...getRootProps({className: "drop"})}>
                        <input {...getInputProps()} />
                        <i
                            className={`bi bi-cloud-upload`}
                            style={{fontSize: "30px"}}
                        ></i>
                        <div>Drag and drop or choose a file to upload</div>

                        <div
                            className={`btn btn-primary mt-3 d-flex gap-2 align-items-center`}
                        >
                            <i className={`bi bi-upload`} style={{fontSize: "20px"}}></i>
                            Choose a File
                        </div>
                    </div>

                    {formatDescription ? (
                        <div className={`text-muted fst-italic mt-3`}>
                            {formatDescription}
                        </div>
                    ) : null}

                    {acceptedFiles.length > 0 && (
                        <div className="d-flex gap-2 align-items-center">
                            <i className={`bi bi-check-lg text-green-500`}></i>
                            {acceptedFiles[0].path}
                            <button className={`btn-clear`}>
                                <i className={`bi bi-x-circle text-red-500`}></i>
                            </button>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className={`justify-content-between`}>
                {
                    templateURL ? (
                            <Link variant="outline-primary" to={templateURL} style={{justifyContent: "start"}}
                                  target={"_blank"}>
                                Download Template
                            </Link>
                        )
                        : downloadTemplate(headers, fileName)
                }

                <div className="d-flex gap-3 align-items-center">
                    <Link variant="outline-dark" onClick={handleClose}>
                        Cancel
                    </Link>
                    <Button variant="outline-dark" type={"button"} onClick={actionAppend}>
                        Append
                    </Button>
                    <Button variant="primary" type={"button"} onClick={actionOverwrite}>
                        Overwrite
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
