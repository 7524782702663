import axios from "axios";

// NYP

// {
//     "CODE": "10",
//     "REMARK": "need confirmations"
// }

export async function getRemarkNYP(limit,page) {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/nyp?limit=${limit}&page=${page}`)
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}

export function postRemarkNYP(remarkData) {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/nyp`, remarkData)
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}

export function putRemarkNYP(remarkID, remarkData) {
    return axios.put(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/nyp/${remarkID}`, remarkData)
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}

export function deleteRemarkNYP(selectedId) {
    return axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/nyp/${selectedId}`)
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}

export function postRemarkNYPOverwrite(jsonData) {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/nyp/overwrite`, {
        DATA: jsonData
    })
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}

export function postRemarkNYPAppend(jsonData) {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/nyp/append`, {
        DATA: jsonData
    })
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}

// PA

// {
//     "STS_REASON": "withdraw"
// }

export async function getRemarkPA(limit,page) {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/pa?limit=${limit}&page=${page}`)
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}

export function postRemarkPA(remarkData) {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/pa`, remarkData)
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}

export function putRemarkPA(remarkID, remarkData) {
    return axios.put(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/pa/${remarkID}`, remarkData)
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}

export function deleteRemarkPA(selectedId) {
    return axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/pa/${selectedId}`)
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}

export function postRemarkPAOverwrite(jsonData) {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/pa/overwrite`, {
        DATA: jsonData
    })
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}

export function postRemarkPAAppend(jsonData) {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/applications/remarks/pa/append`, {
        DATA: jsonData
    })
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error
        })
}
