import React, {useEffect, useRef, useState} from "react";

import Layout from "@/components/Layout";
import {Form} from "@govtechsg/sgds-react";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import FormulaModal from "@/components/FormulaModal";
import {toast} from "react-toastify";

import PersonalData from "@/components/AppHomes/TabContent/PersonalData";
import Application from "@/components/AppHomes/TabContent/Application";
import FamilyDetails from "@/components/AppHomes/TabContent/FamilyDetails";
import {
    getApplicationHomes,
    getMTCaseNotes,
    getMTCases,
    getMTCDetails,
    getMtConsent,
    getMTCRequestResult,
    getMtIncomes,
    postAppeal,
    postApplicationHomes,
} from "@/apis/app-records/homes/api";
import {getListExercise} from "@/apis/preparation/app-timeline/api";
import {getScholarship, upsertScholarship,getApplicationList} from "@/apis/app-records/maintenance/api";
import OriginalDetails from "@/components/AppHomes/TabContent/OriginalDetails";
import MTRequest from "@/components/AppHomes/TabContent/MTRequest";
import Appeal from "@/components/AppHomes/TabContent/Appeal";
import ScholarshipDetails from "@/components/AppHomes/TabContent/ScholarshipDetails";
import CustomModal from "@/components/CustomModal";
import {getParents} from "@/apis/app-records/parents/api";
import BreadCrumbHomes from "@/components/AppHomes/BreadCrumbHomes";
import FormFilterHomes from "@/components/AppHomes/FormFilterHomes";
import WrapperSwiperHomes from "@/components/AppHomes/WrapperSwiperHomes";
import EditAndSaveButtonHomes from "@/components/AppHomes/EditAndSaveButtonHomes";
import {
    getInitializeOptions,
    processGetListSiblingNYP,
    processGetMTCRequest,
    processGetStudentDetail,
    processTransferToAnotherExercise
} from "@/components/AppHomes/FunctionHomes";

function AppHomes() {
    const MEANS_TEST_ID = "";
    const ACK_NO = "";
    const NYP_CONSENT_ID = "";
    const DETAIL_TAG = "";

    const [isOpen, setIsOpen] = useState(true);
    const contentRef = useRef(null);
    const [retrieved, setRetrieved] = useState(false);

    const [isDisabled, setIsDisabled] = useState(true);
    const [scholarshipCount, setScholarshipCount] = useState([1]);
    const [siblingCount, setAddNewSiblingCount] = useState([1]);
    const [showFormulaModal, setShowFormulaModal] = useState(false);

    const [listAcadYears, setListAcadYears] = useState([]);
    const [listExercise, setListExercise] = useState([]);
    const [listAdmNo, setListAdmNo] = useState([]);

    const [listParent, setListParent] = useState([]);
    const [listSibling, setListSibling] = useState([]);
    const [listScholarship, setListScholarship] = useState([]);

    const [listMTCRequest, setListMTCRequest] = useState([]);
    const [listMTCRequestResult, setListMTCRequestResult] = useState([]);
    const [listMTCases, setListMTCases] = useState([]);
    const [listMTCaseNotes, setListMTCaseNotes] = useState([]);
    const [listMTCDetails, setListMTCDetails] = useState([]);
    const [details, setDetails] = useState([]);
    const [listMTIncomes, setMTIncomes] = useState([]);
    const [listMTConsents, setListMTConsents] = useState([]);
    const [listOriginalDetails, setListOriginalDetails] = useState([]);
    const [listMTCDetailsOriginal, setListMTCDetailsOriginal] = useState([]);
    const [listSiblingInNYP, setListSiblingInNYP] = useState([]);

    const [scholarshipDetail, setScholarshipDetail] = useState([]);
    const [
        showTransferToAnotherExerciseModal,
        setShowTransferToAnotherExerciseModal,
    ] = useState(false);
    const [transferToAnotherExerciseValue, setTransferToAnotherExerciseValue] =
        useState(0);

    // variable selected
    const [academicYear, setAcademicYear] = useState();
    const [selectedListExercise, setSelectedListExercise] = useState(null);
    const [selectedListAdmNo, setSelectedListAdmNo] = useState(null);
    const [applicationDetail, setApplicationDetail] = useState({});
    const [studentDetail, setStudentDetail] = useState({});
    const [maritalStatus, setMaritalStatus] = useState({label: "", value: ""});
    const [dialectGroup, setDialectGroup] = useState({label: "", value: ""});
    const [housingType, setHousingType] = useState({label: "", value: ""});
    const [nypRemarks, setNYPRemarks] = useState([]);
    const [paRemarks, setPARemarks] = useState([]);
    const [selectedAwardType, setSelectedAwardType] = useState({
        label: "",
        value: "",
        code: "",
    });
    const [selectedAwardCode, setSelectedAwardCode] = useState({
        label: "",
        value: "",
    });

    const [bodyTab, setBodyTab] = useState({
        PersonalParticulars: true,
        Application: false,
        FamilyDetails: false,
        ResultDetails: false,
        OtherDetails: false,
        OriginalDetails: false,
        MTRequestDetails: false,
        Appeal: false,
    });

    //OPTIONS
    const [specialMeansStatusOptions, setSpecialMeansStatusOptions] = useState(
        []
    );
    const [residentialOptions, setResidentialOptions] = useState([]);
    const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
    const [consentTypeOptions, setConsentTypeOptions] = useState([]);
    const [consentScopeOptions, setConsentScopeOptions] = useState([]);
    const [consentStatusOptions, setConsentStatusOptions] = useState([]);
    const [legalCapacityOptions, setLegalCapacityOptions] = useState([]);
    const [mtRequestStatusOptions, setMtRequestStatusOptions] = useState([]);
    const [specialCaseOptions, setSpecialCaseOptions] = useState([]);
    const [careArrangementOptions, setCareArrangementOptions] = useState([]);
    const [relationshipOptions, setRelationshipOptions] = useState([]);
    const [statusTagOptions, setStatusTagOptions] = useState([]);
    const [caseTypeOptions, setCaseTypeOptions] = useState([]);
    const [occupationOptions, setOccupationOptions] = useState([]);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const handleAcademicYearChange = (e) => {
        const newValue = e.value;
        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
            setAcademicYear(newValue);
            getListOfExercise(newValue);
        }
    };

    const handleListExerciseChange = (e) => {
        const newValue = e.value;
        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
            setSelectedListExercise(newValue);
        }
        getApplicationList({ACAD_YR: academicYear, EXERCISE: newValue}).then(
            (resp) => {
              if (resp?.response?.data?.message) {
                if (
                  resp?.response?.data?.message?.length > 0 &&
                  Array.isArray(resp?.response?.data?.message)
                ) {
                  resp?.response?.data?.message?.map((contentErr) => {
                    toast.error(contentErr?.msg);
                  });
                } else {
                  toast.error(resp?.response?.data?.message);
                }
              } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
              } else {
                if (resp.status == 200) {
                  let listContent = [];
                  resp?.data?.data?.map((item) => {
                    if (listContent.some((e) => e?.value === item?.ADM_NO)) {
                    } else {
                      listContent.push({ value: item?.ADM_NO, label: item?.ADM_NO });
                    }
                  });
                  setListAdmNo(listContent);
                }
              }
            }
          );
    };

    const handleListAdmNoChange = (e) => {
        const newValue = e.value;
        setSelectedListAdmNo(newValue);
    };

    const handleClickEdit = () => {
        if(!retrieved){
            toast.warning("No Data")
            return
        }
        setIsDisabled(!isDisabled);
    };

    const handleAddNewScholarship = () => {
        setScholarshipCount([...scholarshipCount, 1]);
    };

    const handleRemoveNewScholarship = (index) => {
        if (index == 0) {
            toast.error("Cannot delete the first scholarship");
            return;
        }
        const updatedScholarshipCount = scholarshipCount.filter(
            (_, i) => i !== index
        );
        setScholarshipCount(updatedScholarshipCount);
    };

    const handleAddNewSibling = () => {
        setAddNewSiblingCount([...siblingCount, 1]);
    };

    const handleRemoveSiblingCount = (index) => {
        if (index == 0) {
            toast.error("Cannot delete the first sibling");
            return
        }
        const updatedSiblingCount = siblingCount.filter((_, i) => i !== index);
        setAddNewSiblingCount(updatedSiblingCount);
    };

    const getDetailofApplicationHomes = () => {
        getApplicationHomes(
            academicYear,
            selectedListExercise,
            selectedListAdmNo
            // "240657P"
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                    setRetrieved(false)
                } else {
                    toast.error(resp?.response?.data?.message);
                    setRetrieved(false)
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
                setRetrieved(false)
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data?.length > 0) {
                        setApplicationDetail(resp?.data?.data[0]);
                        toast.success("Success get data");
                        setRetrieved(true)
                    }
                }
            }
            // setApplicationDetail(content);
        });
    };

    const getListOfExercise = (year) => {
        getListExercise(year).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    let listContent = [];
                    resp?.data?.data?.map((item) => {
                        listContent.push({value: item?.EXERCISE, label: item?.EXERCISE});
                    });
                    setListExercise(listContent);
                }
            }
        });
    };

    const getDetailofStudentInformation = () => {
        processGetStudentDetail(selectedListAdmNo, academicYear, setStudentDetail);
    };

    const getListSiblinginNYP = () => {
        processGetListSiblingNYP(
            selectedListAdmNo,
            academicYear,
            setListSiblingInNYP
        );
    };

    const getListMTCRequest = () => {
        processGetMTCRequest(
            academicYear,
            selectedListExercise,
            selectedListAdmNo,
            setListMTCRequest
        );
    };
    const getListMTCRequestResult = () => {
        getMTCRequestResult().then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setListMTCRequestResult(resp?.data?.data);
                    }
                }
            }
        });
    };
    const getListMTCases = () => {
        getMTCases().then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setListMTCases(resp?.data?.data);
                    }
                }
            }
        });
    };
    const getListMTCaseNotes = () => {
        getMTCaseNotes().then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setListMTCaseNotes(resp?.data?.data);
                    }
                }
            }
        });
    };

    const getListMTCDetails = () => {
        getMTCDetails(academicYear, selectedListExercise, selectedListAdmNo, "A").then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data) {
                            setListMTCDetails(resp?.data?.data);
                        }
                    }
                }
            }
        );
    };

    const getListMTCDetailsOriginal = () => {
        getMTCDetails(
            academicYear,
            selectedListExercise,
            selectedListAdmNo,
            "Z"
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setListMTCDetailsOriginal(resp?.data?.data);
                    }
                }
            }
        });
    };

    const getListMTIncomes = () => {
        getMtIncomes(MEANS_TEST_ID).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setMTIncomes(resp?.data?.data);
                    }
                }
            }
        });
    };

    const getListMTConsents = () => {
        getMtConsent(ACK_NO, NYP_CONSENT_ID, DETAIL_TAG).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setListMTConsents(resp?.data?.data);
                    }
                }
            }
        });
    };

    const getListOriginalDetails = () => {
        getMtConsent(ACK_NO, NYP_CONSENT_ID, "Z").then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setListOriginalDetails(resp?.data?.data);
                    }
                }
            }
        });
    };

    const getScholarshipDetail = () => {
        getScholarship(academicYear, selectedListExercise, selectedListAdmNo).then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data) {
                            setScholarshipDetail(resp?.data?.data);
                        }
                    }
                }
            }
        );
    };

    const getFamilyDetails = () => {
        getParents(academicYear, selectedListExercise, selectedListAdmNo).then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data?.length > 0) {
                            setListParent(resp?.data?.data);
                        } else {
                            toast.warning("Parent Data is empty");
                            setListParent([
                                {
                                    RELATIONSHIP: "F",
                                    ID: "",
                                    NAME: "",
                                    STATUS: "",
                                    ID_NO: "",
                                    MTHLY_INCOME_CONTRIB: "",
                                    REMARK: "",
                                    CHILD_SUPPORT: "",
                                    BIRTHDATE: "",
                                    OTHER_INCOME: null,
                                    FAAS_OCCUPATIONS: "",
                                },
                                {
                                    RELATIONSHIP: "M",
                                    ID: "",
                                    NAME: "",
                                    STATUS: "",
                                    ID_NO: "",
                                    MTHLY_INCOME_CONTRIB: "",
                                    REMARK: "",
                                    CHILD_SUPPORT: "",
                                    BIRTHDATE: "",
                                    OTHER_INCOME: null,
                                    FAAS_OCCUPATIONS: "",
                                },
                            ]);
                        }
                    }
                }
            }
        );
    };

    const handleSubmit = () => {
        getDetailofApplicationHomes();
        getDetailofStudentInformation();
        getListMTCRequest();
        getListMTCRequestResult();
        // getListMTCases();
        getListMTCaseNotes();
        getListMTCDetails();
        getListMTIncomes();
        getListMTConsents();
        getListOriginalDetails();
        getScholarshipDetail();
        getListMTCDetailsOriginal();
        getListSiblinginNYP();
        getFamilyDetails();
    };

    const handleChange = (property, value) => {
        setApplicationDetail((prevState) => ({
            ...prevState,
            [property]: value,
        }));
    };

    const submitToApplicationHomes = () => {
        const data = {
            ACAD_YR: academicYear,
            EXERCISE: selectedListExercise,
            ADM_NO: selectedListAdmNo,
            COMCARE: applicationDetail?.COMCARE,
            SPECIAL_CIRCUMSTANCES: applicationDetail?.SPECIAL_CIRCUMSTANCES,
            APPLN_STATUS: applicationDetail?.APPLN_STATUS,
            VERIFY_STATUS: applicationDetail?.VERIFY_STATUS,
            DATA_FLAG: applicationDetail?.DATA_FLAG,
            REC_AWARD_TYPE: selectedAwardType.value,
            REC_AWARD_CODE: selectedAwardCode.value,
            REQUEST_TYPE: applicationDetail?.REQUEST_TYPE,

            APPROVAL_STATUS_REASON: applicationDetail?.APPROVAL_STATUS_REASON,
            REASON_FOR_CHANGES: applicationDetail?.REASON_FOR_CHANGES,
            REMARK_DESC: applicationDetail?.REMARK_DESC,
            SPECIAL_DETAILS: applicationDetail?.SPECIAL_DETAILS,
            SPECIAL_NOTE: applicationDetail?.SPECIAL_NOTE,

            CHARGE_IN_COURT: applicationDetail.CHARGE_IN_COURT,
            REMARKS1: applicationDetail.REMARKS1,
            REMARK: applicationDetail.REMARK,
            CPF_APPROVED_TAG: applicationDetail.CPF_APPROVED_TAG,
            INTACT_FAMILY_TAG: applicationDetail.INTACT_FAMILY_TAG,
            COMP_SPONSOR_TAG: applicationDetail.COMP_SPONSOR_TAG,
            MOE_SUBSIDISED: applicationDetail.MOE_SUBSIDISED,

            // DEPENDENT: "3",
            // INDEPEND_MTHLY_CONTRIB: "50",
            // CCC_INCOME: "100",
            // GROSS_INCOME: "200",
            // MPCI: "250",
            // FPCI: "300",
            // CHARGE_IN_COURT: "Y",
            // APPLN_DETAILS: null,
            // APPLN_STATUS: "D",
            // REASON_FOR_CHANGES: null,
            // REC_AWARD_TYPE: null,
            // REC_AWARD_CODE: null,
            // SPECIAL_DETAILS: null,
            // ACK_NO: "ACK1234",
            // INTACT_FAMILY_TAG: null,
            // FAMILY_MEMBERS: "4",
            // SPECIAL_NOTE: null,
            // SCHOLAR_SPONSOR_TAG: "Y",
            // DIALECT_GRP: "group A",
            // HOMEHOUSETYPE: "reg",
            // MTC_APPEAL_REMARK: null,
            // SPECIAL_CASE: null,
            // REMARK: "",
            // REMARKS1: "",
            // LAST_UPD_ID: "141517A",
            // MTC_DETAILS: [
            //   {
            //     DETAIL_TAG: "A",
            //     ACK_NO: "ACK1234",
            //     ACAD_YR: "2041",
            //     FAAS_EXERCISE: "1",
            //     NBFA_EXERCISE: "1",
            //     ADM_NO: "141517A",
            //     HH_UIN: "S6876111E",
            //     HH_NAME: "Ong Mary",
            //     OTHER_INCOME: null,
            //     MARITAL_STATUS: null,
            //     RES_STATUS: "A",
            //     DOB: "2022-01-23",
            //     GENDER: "F",
            //     MTC_TAG: null,
            //     RELATIONSHIP: "HRC002",
            //     SPECIAL_MEANS_STATUS: null,
            //     ISNOVALIDAUTH: null,
            //     REMARKS: null,
            //     MTREQUESTID: null,
            //     MEANS_TEST_ID: null,
            //     STATUS_TAG: null,
            //     CHANGE_TYPE: null,
            //     INCOME_PERIOD: null,
            //     AVG_MTHLY_INCOME: null,
            //     MT_MEMBER_INDICATORS: null,
            //     OCCUPATION: null,
            //     REUSABLE_CONSENTID: null,
            //     REUSABLE_CONSENTSCOPE: null,
            //   },
            // ],
            // SCHOLARSHIPS: [
            //   {
            //     SCHOLAR_SPONSOR: "MMM Foundation",
            //     TUTFEE_C_TAG: "Y",
            //     L_ALLOWANCE_C_TAG: "Y",
            //     L_ALLOWANCE_C_DETAILS: "0",
            //     OTHERS_C_TAG: "Y",
            //     OTHERS_C_DETAILS: null,
            //     L_ALLOWANCE_C_TYPE: null,
            //     LAST_UPD_ID: "236",
            //     REC_TAG: "B",
            //     GOVBUR_QUANTUM: "3000",
            //     GOVBUR_PERIOD: "Yearly",
            //   },
            // ],
        };

        let mergedData;
        if (listMTCRequest.length > 0) {
            mergedData = {...data, MT_REQUEST_ID: listMTCRequest[0].ID};
        } else {
            mergedData = data;
        }

        let contentFinalData = {
            DATA: [mergedData],
        };

        postApplicationHomes(contentFinalData).then((resp) => {
            console.log(resp);
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                toast.success("Application HOMES has been saved");
            }
        });
    };

    const submitTransferToAnotherExercise = () => {
        processTransferToAnotherExercise(
            academicYear,
            selectedListAdmNo,
            selectedListExercise,
            transferToAnotherExerciseValue
        );
    };

    const submitAppealForm = () => {
        postAppeal(details).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                toast.success("Appeal updated successfully");
            }
        });
    };

    const submitToScholarships = () => {
        upsertScholarship({DATA: scholarshipDetail})
            .then((resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    toast.success("Scholarship updated successfully");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChangeListScholarship = (field, value, index) => {
        setScholarshipDetail((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[index] = {...updatedItems[index], [field]: value};
            return updatedItems;
        });
    };

    const handleSubmitApplicationForm = () => {
        setIsDisabled(!isDisabled);
        submitToApplicationHomes();
        submitAppealForm();
        submitToScholarships();
    };

    useEffect(() => {
        getInitializeOptions(
            setListAcadYears,
            setListAdmNo,
            setNYPRemarks,
            setPARemarks,
            setSpecialMeansStatusOptions,
            setResidentialOptions,
            setMaritalStatusOptions,
            setConsentScopeOptions,
            setLegalCapacityOptions,
            setConsentTypeOptions,
            setConsentStatusOptions,
            setMtRequestStatusOptions,
            setSpecialCaseOptions,
            setCareArrangementOptions,
            setRelationshipOptions,
            setStatusTagOptions,
            setCaseTypeOptions,
            setOccupationOptions
        );
    }, []);

    return (
        <Layout currentNav={"app-records"}>
            <BreadCrumbHomes/>

            <sgds-content-header-top>
                <h2>Application HOMES</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <div className="row">
                        <div className="col">
                            <h3>Data Retrieval</h3>
                        </div>
                    </div>

                    <FormFilterHomes
                        contentRef={contentRef}
                        isOpen={isOpen}
                        listAcadYears={listAcadYears}
                        listExercise={listExercise}
                        listAdmNo={listAdmNo}
                        handleSubmit={handleSubmit}
                        handleAcademicYearChange={handleAcademicYearChange}
                        handleListExerciseChange={handleListExerciseChange}
                        handleListAdmNoChange={handleListAdmNoChange}
                        academicYear={academicYear}
                    />

                    <button
                        onClick={toggleCollapse}
                        className="btn-clear text-blue ps-0 mt-3"
                    >
                        {isOpen ? "Hide " : "Expand "}{" "}
                        {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                    </button>
                </div>

                <WrapperSwiperHomes bodyTab={bodyTab} setBodyTab={setBodyTab}/>

                {/* Tab content */}
                {bodyTab.PersonalParticulars && (
                    <React.Fragment>
                        <div>
                            {isDisabled ? (
                                <button
                                    className="bg-white border-1 px-3 rounded-2 "
                                    onClick={handleClickEdit}
                                >
                                    <i className={`bi bi-pencil`}></i>
                                    {"  "}Edit
                                </button>
                            ) : (
                                <button
                                    className="bg-white border-1 px-3 rounded-2 text-green-500"
                                    onClick={handleSubmitApplicationForm}
                                >
                                    <i className={`bi bi-check-lg`}></i>
                                    {"  "}Save
                                </button>
                            )}
                        </div>

                        <PersonalData
                            applicationDetail={applicationDetail}
                            setApplicationDetail={setApplicationDetail}
                            studentDetail={studentDetail}
                            selectedListExercise={selectedListExercise}
                            selectedListAdmNo={selectedListAdmNo}
                            options
                            isDisabled={isDisabled}
                            handleClickEdit={handleClickEdit}
                            academicYear={academicYear}
                            handleAcademicYearChange={handleAcademicYearChange}
                            maritalStatus={maritalStatus}
                            setMaritalStatus={setMaritalStatus}
                            dialectGroup={dialectGroup}
                            setDialectGroup={setDialectGroup}
                            housingType={housingType}
                            setHousingType={setHousingType}
                            handleChange={handleChange}
                        />
                    </React.Fragment>
                )}

                {bodyTab.Application && (
                    <React.Fragment>
                        <EditAndSaveButtonHomes
                            isDisabled={isDisabled}
                            handleClickEdit={handleClickEdit}
                            handleSubmitApplicationForm={handleSubmitApplicationForm}
                        />

                        <Application
                            applicationDetail={applicationDetail}
                            setApplicationDetail={setApplicationDetail}
                            studentDetail={studentDetail}
                            selectedListExercise={selectedListExercise}
                            selectedListAdmNo={selectedListAdmNo}
                            options
                            isDisabled={isDisabled}
                            handleClickEdit={handleClickEdit}
                            academicYear={academicYear}
                            handleAcademicYearChange={handleAcademicYearChange}
                            maritalStatus={maritalStatus}
                            setMaritalStatus={setMaritalStatus}
                            dialectGroup={dialectGroup}
                            setDialectGroup={setDialectGroup}
                            housingType={housingType}
                            setHousingType={setHousingType}
                            handleChange={handleChange}
                            listMTCRequest={listMTCRequest}
                            selectedAwardType={selectedAwardType}
                            selectedAwardCode={selectedAwardCode}
                            setSelectedAwardType={setSelectedAwardType}
                            setSelectedAwardCode={setSelectedAwardCode}
                            setShowTransferToAnotherExerciseModal={
                                setShowTransferToAnotherExerciseModal
                            }
                            nypRemarks={nypRemarks}
                            paRemarks={paRemarks}
                        />
                    </React.Fragment>
                )}

                {bodyTab.FamilyDetails && (
                    <React.Fragment>
                        <EditAndSaveButtonHomes
                            isDisabled={isDisabled}
                            handleClickEdit={handleClickEdit}
                            handleSubmitApplicationForm={handleSubmitApplicationForm}
                        />
                        <FamilyDetails
                            ACK_YR={academicYear}
                            ACK_NO={ACK_NO}
                            selectedListExercise={selectedListExercise}
                            selectedListAdmNo={selectedListAdmNo}
                            isDisabled={isDisabled}
                            applicationDetail={applicationDetail}
                            listMTIncomes={listMTIncomes}
                            listMTConsents={listMTConsents}
                            listMTCases={listMTCases}
                            listMTCaseNotes={listMTCaseNotes}
                            listMTCRequestResult={listMTCRequestResult}
                            listOriginalDetails={listOriginalDetails}
                            listMTCDetails={listMTCDetails}
                            listSiblingInNYP={listSiblingInNYP}
                            scholarshipDetail={scholarshipDetail}
                            studentDetail={studentDetail}
                            handleClickEdit={() => setIsDisabled(!isDisabled)}
                            details={details}
                            setDetails={setDetails}
                            specialMeansStatusOptions={specialMeansStatusOptions}
                            maritalStatusOptions={maritalStatusOptions}
                            residentialOptions={residentialOptions}
                            consentTypeOptions={consentScopeOptions}
                            consentScopeOptions={consentScopeOptions}
                            consentStatusOptions={consentStatusOptions}
                            legalCapacityOptions={legalCapacityOptions}
                            relationshipOptions={relationshipOptions}
                            occupationOptions={occupationOptions}
                        />
                    </React.Fragment>
                )}

                {bodyTab.OtherDetails && (
                    <React.Fragment>
                        <EditAndSaveButtonHomes
                            isDisabled={isDisabled}
                            handleClickEdit={handleClickEdit}
                            handleSubmitApplicationForm={handleSubmitApplicationForm}
                        />
                        <ScholarshipDetails
                            isDisabled={isDisabled}
                            applicationDetail={applicationDetail}
                            listMTIncomes={listMTIncomes}
                            listMTConsents={listMTConsents}
                            listMTCases={listMTCases}
                            listMTCaseNotes={listMTCaseNotes}
                            listMTCRequestResult={listMTCRequestResult}
                            listOriginalDetails={listOriginalDetails}
                            listMTCDetails={listMTCDetails}
                            scholarshipDetail={scholarshipDetail}
                            studentDetail={studentDetail}
                            handleClickEdit={() => setIsDisabled(!isDisabled)}
                            handleChange={handleChange}
                            handleChangeListScholarship={handleChangeListScholarship}
                            setScholarshipDetail={setScholarshipDetail}
                            submitToScholarships={submitToScholarships}
                        />
                    </React.Fragment>
                )}

                {bodyTab.OriginalDetails && (
                    <React.Fragment>
                        <OriginalDetails
                            isDisabled={isDisabled}
                            applicationDetail={applicationDetail}
                            listMTIncomes={listMTIncomes}
                            listMTConsents={listMTConsents}
                            listMTCases={listMTCases}
                            listMTCaseNotes={listMTCaseNotes}
                            listMTCRequestResult={listMTCRequestResult}
                            listOriginalDetails={listOriginalDetails}
                            listMTCDetails={listMTCDetails}
                            listMTCDetailsOriginal={listMTCDetailsOriginal}
                            scholarshipDetail={scholarshipDetail}
                            details={details}
                        />
                    </React.Fragment>
                )}

                {bodyTab.MTRequestDetails && (
                    <React.Fragment>
                        <MTRequest
                            isDisabled={true}
                            applicationDetail={applicationDetail}
                            listMTIncomes={listMTIncomes}
                            listMTConsents={listMTConsents}
                            listMTCases={listMTCases}
                            listMTCaseNotes={listMTCaseNotes}
                            listMTCRequestResult={listMTCRequestResult}
                            listMTCDetails={listMTCDetails}
                            details={details}
                        />
                    </React.Fragment>
                )}

                {bodyTab.Appeal && (
                    <React.Fragment>
                        <EditAndSaveButtonHomes
                            isDisabled={isDisabled}
                            handleClickEdit={handleClickEdit}
                            handleSubmitApplicationForm={handleSubmitApplicationForm}
                        />

                        <Appeal
                            handleClickEdit={() => setIsDisabled(!isDisabled)}
                            ACK_YR={academicYear}
                            ACK_NO={ACK_NO}
                            isDisabled={isDisabled}
                            applicationDetail={applicationDetail}
                            listMTIncomes={listMTIncomes}
                            listMTConsents={listMTConsents}
                            listMTCases={listMTCases}
                            listMTCaseNotes={listMTCaseNotes}
                            listMTCRequestResult={listMTCRequestResult}
                            listMTCDetails={listMTCDetails}
                            familyDetails={details}
                            details={details}
                            setDetails={setDetails}
                            selectedListExercise={selectedListExercise}
                            selectedListAdmNo={selectedListAdmNo}
                            consentTypeOptions={consentTypeOptions}
                            consentScopeOptions={consentScopeOptions}
                            consentStatusOptions={consentStatusOptions}
                            legalCapacityOptions={legalCapacityOptions}
                            relationshipOptions={relationshipOptions}
                            occupationOptions={occupationOptions}
                        />
                    </React.Fragment>
                )}
            </sgds-content-body>

            <FormulaModal
                show={showFormulaModal}
                handleClose={() => setShowFormulaModal(!showFormulaModal)}
            />

            {/* Transfer to Another Exercise Modal */}
            <CustomModal
                show={showTransferToAnotherExerciseModal}
                handleClose={() =>
                    setShowTransferToAnotherExerciseModal(
                        !showTransferToAnotherExerciseModal
                    )
                }
                title="Transfer To Another Exercise"
                description={
                    <>
                        <div>
                            <p>Current Info:</p>
                            <div>
                                Academy Year: {academicYear}
                                <br/>
                                Exercise: {selectedListExercise}
                                <br/>
                                Adm. No.: {selectedListAdmNo}
                            </div>
                        </div>
                        <Form.Group>
                            <Form.Label>Transfer to Exercise</Form.Label>
                            <Form.Control
                                className=""
                                type={`text`}
                                // disabled={true}
                                value={transferToAnotherExerciseValue}
                                onChange={(e) =>
                                    setTransferToAnotherExerciseValue(e.target.value)
                                }
                            ></Form.Control>
                        </Form.Group>
                    </>
                }
                action={() => submitTransferToAnotherExercise()}
                submitButtonText={"Submit"}
            />
        </Layout>
    );
}

export default AppHomes;
