import React, {useEffect, useState} from "react";

import {Button, Col, Form, FormCheck, Row} from "@govtechsg/sgds-react";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

const options = [
    {value: "chocolate", label: "Chocolate"},
    {value: "strawberry", label: "Strawberry"},
    {value: "vanilla", label: "Vanilla"},
];

function GovernmentBursary({
                               isDisabled,
                               scholarshipDetail,
                               setListScholarship,
                               handleChangeListScholarship,
                               tobeDeletedScholar,
                               handleChangeTobeDeleted,
                               data
                           }) {
    const [isScholarship, setIsScholarship] = useState(false);

    const handleAddNewScholarship = () => {
        console.log(scholarshipDetail);
        console.log(scholarshipDetail);
        setListScholarship([
            ...scholarshipDetail,
            {
                SCHOLAR_SPONSOR: "",
                TUTFEE_C_TAG: "",
                L_ALLOWANCE_C_TAG: "",
                L_ALLOWANCE_C_DETAILS: "0",
                L_ALLOWANCE_C_TYPE: "",
                OTHERS_C_TAG: "",
                OTHERS_C_DETAILS: "",
                GOVBUR_TAG: "Y",
            },
        ]);
    };

    const handleRemoveNewScholarship = (id, index) => {
        if (index == 0) {
            toast.error("Cannot delete the first scholarship");
            return;
        }

        //if id
        if (id) {
            //push to array state tobeDeletedScholar
            handleChangeTobeDeleted([...tobeDeletedScholar, id]);
        }
        const updatedScholarships = [...scholarshipDetail];
        setListScholarship(updatedScholarships.filter((_, i) => i !== index));
    };

    const handleScholarshipRadio = (e) => {
        if (e.target.value == "yes") {
            setIsScholarship(true);
        } else {
            setIsScholarship(false);
        }
    };

    useEffect(() => {
        if(data?.GOVBUR_TAG === "Y"){
            setIsScholarship(true)
        }
    }, []);

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>
                                I am receiving a government bursary in the current academic year
                            </Form.Label>
                            <FormCheck
                                id="option-1"
                                label="Yes"
                                name="radio_checks1"
                                type="radio"
                                value="yes"
                                checked={isScholarship == true}
                                onChange={handleScholarshipRadio}
                                disabled={isDisabled}
                            />
                            <FormCheck
                                id="option-2"
                                label="No"
                                name="radio_checks1"
                                type="radio"
                                value="no"
                                checked={isScholarship == false}
                                onChange={handleScholarshipRadio}
                                disabled={isDisabled}
                            />
                        </Form.Group>
                    </Col>
                    {isScholarship ? (
                        <React.Fragment>
                            <div>
                                <Button
                                    type="button"
                                    disabled={isDisabled}
                                    variant="btn btn-link ps-0 text-decoration-none"
                                    onClick={() => handleAddNewScholarship()}
                                >
                                    <i class="bi bi-plus-circle"></i> {"  "} Add government
                                    bursary
                                </Button>
                            </div>

                            {scholarshipDetail?.map((item, index) => {
                                if (item?.GOVBUR_TAG == "Y") {
                                    return (
                                        <div className="mt-3" key={index}>
                                            <h4>
                                                #{index + 1} Government Bursary
                                                <button
                                                    className={`btn-clear`}
                                                    type={"button"}
                                                    disabled={isDisabled}
                                                    onClick={() => {
                                                        handleRemoveNewScholarship(item.ID, index);
                                                    }}
                                                >
                                                    <i className={`bi bi-trash`}></i>
                                                </button>
                                            </h4>
                                            <Row>
                                                <Col xs={12}>
                                                    <Form.Group>
                                                        <Form.Label>Name of Government Bursary</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={item?.SCHOLAR_SPONSOR}
                                                            onChange={(e) =>
                                                                handleChangeListScholarship(
                                                                    "SCHOLAR_SPONSOR",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group>
                                                        {" "}
                                                        <Form.Label>Quantum</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={item?.GOVBUR_QUANTUM}
                                                            onChange={(e) =>
                                                                handleChangeListScholarship(
                                                                    "GOVBUR_QUANTUM",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group className={`d-flex flex-column`}>
                                                        {" "}
                                                        <Form.Label>Period</Form.Label>
                                                        <DatePicker
                                                            selected={item?.GOVBUR_PERIOD ? moment(item.GOVBUR_PERIOD, 'MM/YYYY').toDate() : null}
                                                            className={`form-control`}
                                                            onChange={(date) => {
                                                                if (date == null) {
                                                                    handleChangeListScholarship(
                                                                        "GOVBUR_PERIOD",
                                                                        "",
                                                                        index
                                                                    );
                                                                } else {
                                                                    handleChangeListScholarship(
                                                                        "GOVBUR_PERIOD",
                                                                        moment(date).format("MM/YYYY"),
                                                                        index
                                                                    );
                                                                }
                                                            }}
                                                            dateFormat="MM-YYYY"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            disabled={isDisabled}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                }
                            })}
                        </React.Fragment>
                    ) : null}
                </Row>
            </div>
        </React.Fragment>
    );
}

export default GovernmentBursary;
