import {Button,} from "@govtechsg/sgds-react";
import Layout from "@/components/Layout";
import React, {useEffect, useState} from "react";
import CustomModal from "@/components/CustomModal";
import {toast} from "react-toastify";
import UploadModal from "@/components/UploadModal";
import CspcMenu from "@/components/Preparation/SetupCspc/Menu";
import Select from "react-select";
import * as XLSX from "xlsx";
import {
    deletePC,
    getCSList,
    getPCList,
    postAppendPC,
    postOverwritePC,
    postPC,
    putPC,
} from "@/apis/preparation/setup-cspc/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import SortableTable from "@/components/SortableTable";

export default function PostalCode() {
    const [data, setData] = useState([
        {
            POSTAL_CODE: "1",
            FAAS_CS_CODES: "1",
            CS_CODE: "1",
            CS_NAME: "1",
        },
    ]);

    const [header, setHeader] = useState([
        {key: 'index', label: '#', required: false},
        {key: 'POSTAL_CODE', label: 'Postal Code', required: true},
        {key: 'CS_CODE', label: 'CS Code', required: true},
        {key: 'CS_NAME', label: 'CS Name', required: false},
        {key: 'action', label: '', required: false},
    ]);

    const [options, setOptions] = useState([]);

    const [editArr, setEditArr] = useState([true]);

    const [show, setShow] = useState(false);

    const [showUpload, setShowUpload] = useState(false);

    const [selectedId, setSelectedId] = useState("");

    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [selectedOption, setSelectedOption] = useState();

    const [selectedFile, setSelectedFile] = useState(null);

    const [isEditing, setIsEditing] = useState(false);

    const [search, setSearch] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isNextPage, setIsNextPage] = useState(true);
    const itemsPerPage = 20;

    const handleFileSelect = (file) => {
        setSelectedFile(file);
    };

    const postOverwrite = () => {
        if (!selectedFile) {
            toast.error(`Select file`);

            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet);

            postOverwritePC(jsonData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Record updated successfully `);
                        getPostalCodes();
                        setShow(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const postAppend = () => {
        if (!selectedFile) {
            toast.error(`Select file`);

            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet);

            postAppendPC(jsonData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Record updated successfully `);
                        getPostalCodes();
                        setShow(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const handleAddRow = () => {
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setData((prevArr) => {
            const newObj = {
                POSTAL_CODE: "",
                FAAS_CS_CODES: "",
                CS_CODE: "",
                CS_NAME: "",
            };
            return [newObj, ...prevArr];
        });

        setEditArr((prevEditArr) => {
            return [false, ...prevEditArr];
        });

        setIsEditing(true);
    };

    const handleEdit = (index) => {

        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = false;
            return newArr;
        });

        setIsEditing(true);
    };

    const handleSubmitEdit = (index, PCID) => {
        if (!data[index].POSTAL_CODE || !data[index].FAAS_CS_CODES) {
            toast.error("Please fill out the required fields")
            return false
        }

        const PCData = {
            POSTAL_CODE: data[index].POSTAL_CODE,
            FAAS_CS_CODES: data[index].FAAS_CS_CODES,
        };

        //submit data
        if (PCID) {
            //update
            putPC(PCID, PCData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getCSCodes();
                        getPostalCodes();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });
                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            //post
            postPC(PCData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getCSCodes();
                        getPostalCodes();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });
                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        }
    };

    const handleDeleteRow = () => {
        if (selectedId) {
            deletePC(selectedId)
                .then((response) => {
                    toast.success("Record deleted successfully");
                    getPostalCodes();
                    setShow(false);
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            const updatedData = [...data];

            updatedData.splice(selectedIndex, 1);

            setData(updatedData);

            setShow(false);

            setIsEditing(false)

            const updatedEditArray = [...editArr];
            updatedEditArray.splice(selectedIndex, 1);
            setEditArr(updatedEditArray);
        }
    };

    const handleChange = (index, field, value) => {
        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            [field]: value,
        };

        setData(updatedData);
    };

    const handleChangeOption = (selectedOption, index) => {
        const updatedFields = {
            FAAS_CS_CODES: selectedOption.value,
            CS_NAME: selectedOption.csName,
            CS_CODE: selectedOption.label,
        };

        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            ...updatedFields,
        };

        setData(updatedData);
    };

    const getPostalCodes = () => {
        getPCList(search, itemsPerPage, currentPage)
            .then((response) => {
                setCurrentPage(response?.data?.currentPage);
                setTotalPages(response?.data?.totalPage);
                setIsNextPage(response?.data?.nextPage);

                const remapData = response?.data.data.map((item) => ({
                    ID: item.ID,
                    POSTAL_CODE: item.POSTAL_CODE,
                    FAAS_CS_CODES: item.FAAS_CS_CODES?.ID,
                    CS_CODE: item.FAAS_CS_CODES?.CS_CODE,
                    CS_NAME: item.FAAS_CS_CODES?.CS_NAME,
                }));

                setData(remapData);

                setTotalPages(response?.data?.totalPage);
                setIsNextPage(response?.data?.nextPage);

                const newArr = Array.from({length: response?.data.data.length}).fill(
                    true
                );

                setEditArr(newArr);
            })
            .catch((error) => {
                toast.error(error.response?.data.message);
            });
    };

    const getCSCodes = () => {
        getCSList("", "0")
            .then((response) => {
                const responseOptions = response?.data?.data?.map((item) => ({
                    value: item.ID,
                    label: item.CS_CODE,
                    csName: item.CS_NAME,
                }));
                setOptions(responseOptions);
            })
            .catch((error) => {
                toast.error(error.response?.data.message);
            });
    };

    const handleCancelEdit = (index) => {
        if (data[index].new_data === true) {
            const updatedData = data.filter(item => !item.new_data);
            setData(updatedData);
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = true;
            return newArr;
        });

        setIsEditing(false);
    }

    const renderActionButtons = (row, rowIndex) => (
        <div className={`d-flex gap-1`}>
            {!editArr[rowIndex] ? (
                <div className={`d-flex gap-1`}>
                    <button
                        className={`btn-clear text-green-500`}
                        onClick={() => handleSubmitEdit(rowIndex, row.ID)}
                    >
                        <i className={`bi bi-check-lg`}></i>
                        Save
                    </button>

                    <button
                        className={`btn-clear text-red-500`}
                        onClick={() => handleCancelEdit(rowIndex)}
                    >
                        <i className={`bi bi-x-circle`}></i>
                        {` `}
                        Cancel
                    </button>
                </div>
            ) : (
                <button
                    className={`btn-clear`}
                    disabled={isEditing}
                    onClick={() => handleEdit(rowIndex)}
                >
                    <i className={`bi bi-pencil`}></i>
                    {` `}
                    Edit
                </button>
            )
            }

            <button
                className={`btn-clear`}
                disabled={rowIndex == 0 ? row?.ID ? isEditing : false : isEditing}
                onClick={() => {
                    setShow(true);
                    setSelectedId(row.ID ?? "");
                    setSelectedIndex(rowIndex);
                }}
            >
                <i className={`bi bi-trash`}></i>
            </button>
        </div>
    );

    const renderCellContent = (row, rowIndex, key) => (
        key === "CS_NAME" ? (
            row[key]
        ) : (
            editArr[rowIndex] ? (
                row[key]
            ) : (
                key === "CS_CODE" ? (
                    <Select
                        options={options}
                        value={{
                            value: row.FAAS_CS_CODES,
                            label: row.CS_CODE,
                            csName: row.CS_NAME,
                        }}
                        onChange={(e) => handleChangeOption(e, rowIndex)}
                    />
                ) : (
                    <input
                        type={"text"}
                        value={row[key]}
                        onChange={(e) => handleChange(rowIndex, key, e.target.value)}
                    />
                )

            )
        )


    );

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handler to go to the previous page
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        getCSCodes();
        getPostalCodes();
    }, [search, currentPage]);

    return (
        <Layout currentNav={"preparation"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Setup Preparation</Breadcrumb.Item>
                <Breadcrumb.Item active>Postal & CS Code Mapping</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Postal & CS Code Mapping</h2>
            </sgds-content-header-top>
            <sgds-content-area-bottom>
                <div className="d-flex gap-3">
                    <Button
                        variant={`outline-dark`}
                        onClick={handleAddRow}
                        className={`d-flex gap-3`}
                    >
                        <i className="bi bi-plus-circle"></i>
                        Add
                    </Button>

                    <Button
                        variant={`outline-dark`}
                        onClick={() => setShowUpload(true)}
                        className={`d-flex gap-3`}
                    >
                        <i className="bi bi-plus-circle"></i>
                        Upload new file
                    </Button>
                </div>
            </sgds-content-area-bottom>

            <div className="my-3">
                <CspcMenu activeMenu="cspc_postal"/>
            </div>

            <div className="input-group mb-3 w-25">
                <input
                    type="text"
                    className="form-control border-end-0"
                    placeholder="Filter by code"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <span
                    className="input-group-text"
                    style={{background: "none", borderLeft: "none"}}
                >
          <i className="bi-sliders"></i>
        </span>
            </div>

            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm`}>
                    <SortableTable headers={header} data={data} renderActionButtons={renderActionButtons}
                                   renderCellContent={renderCellContent} setData={setData}/>

                    <div className="text-center">
                        <button
                            className={`btn-clear`}
                            onClick={prevPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
              {" "}
                            Page {currentPage} of {totalPages}{" "}
            </span>
                        <button
                            className={`btn-clear`}
                            onClick={nextPage}
                            disabled={!isNextPage}
                        >
                            Next
                        </button>
                    </div>
                    {/*<Table responsive>*/}
                    {/*    <TableHeader>*/}
                    {/*        <TableRow>*/}
                    {/*            <TableHeaderCell width={"5%"}>#</TableHeaderCell>*/}
                    {/*            <TableHeaderCell>Postal Code*/}
                    {/*                <span className={`text-danger`}>{" "}*</span>*/}
                    {/*            </TableHeaderCell>*/}
                    {/*            <TableHeaderCell>CS Code</TableHeaderCell>*/}
                    {/*            <TableHeaderCell>CS Name</TableHeaderCell>*/}
                    {/*            <TableHeaderCell></TableHeaderCell>*/}
                    {/*        </TableRow>*/}
                    {/*    </TableHeader>*/}

                    {/*    <TableBody>*/}
                    {/*        {data?.map((row, index) => (*/}
                    {/*            <TableRow key={index}>*/}
                    {/*                <TableDataCell>{index + 1}</TableDataCell>*/}
                    {/*                <TableDataCell>*/}
                    {/*                    {!editArr[index] ? (*/}
                    {/*                        <input*/}
                    {/*                            type={"text"}*/}
                    {/*                            value={row.POSTAL_CODE}*/}
                    {/*                            onChange={(e) =>*/}
                    {/*                                handleChange(index, "POSTAL_CODE", e.target.value)*/}
                    {/*                            }*/}
                    {/*                        />*/}
                    {/*                    ) : (*/}
                    {/*                        row.POSTAL_CODE*/}
                    {/*                    )}*/}
                    {/*                </TableDataCell>*/}
                    {/*                <TableDataCell>*/}
                    {/*                    {!editArr[index] ? (*/}
                    {/*                        <Select*/}
                    {/*                            options={options}*/}
                    {/*                            value={{*/}
                    {/*                                value: row.FAAS_CS_CODES,*/}
                    {/*                                label: row.CS_CODE,*/}
                    {/*                                csName: row.CS_NAME,*/}
                    {/*                            }}*/}
                    {/*                            onChange={(e) => handleChangeOption(e, index)}*/}
                    {/*                        />*/}
                    {/*                    ) : (*/}
                    {/*                        row.CS_CODE*/}
                    {/*                    )}*/}
                    {/*                </TableDataCell>*/}
                    {/*                <TableDataCell>{row.CS_NAME}</TableDataCell>*/}
                    {/*                <TableDataCell>*/}
                    {/*                    <div className={`d-flex gap-1`}>*/}
                    {/*                        {!editArr[index] ? (*/}
                    {/*                            <button*/}
                    {/*                                className={`btn-clear text-green-500`}*/}
                    {/*                                onClick={() => handleSubmitEdit(index, row.ID)}*/}
                    {/*                            >*/}
                    {/*                                <i className={`bi bi-check-lg`}></i>*/}
                    {/*                                {` `}*/}
                    {/*                                Save*/}
                    {/*                            </button>*/}
                    {/*                        ) : (*/}
                    {/*                            <button*/}
                    {/*                                className={`btn-clear`}*/}
                    {/*                                onClick={() => handleEdit(index)}*/}
                    {/*                            >*/}
                    {/*                                <i className={`bi bi-pencil`}></i>*/}
                    {/*                                {` `}*/}
                    {/*                                Edit*/}
                    {/*                            </button>*/}
                    {/*                        )}*/}

                    {/*                        <button*/}
                    {/*                            className={`btn-clear`}*/}
                    {/*                            onClick={() => {*/}
                    {/*                                setShow(true);*/}
                    {/*                                setSelectedId(row.ID ?? "");*/}
                    {/*                                setSelectedIndex(index);*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            <i className={`bi bi-trash`}></i>*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                </TableDataCell>*/}
                    {/*            </TableRow>*/}
                    {/*        ))}*/}
                    {/*    </TableBody>*/}
                    {/*</Table>*/}
                </div>
            </sgds-content-body>
            <CustomModal
                show={show}
                handleClose={() => setShow(false)}
                action={handleDeleteRow}
                title={`Delete record?`}
                description={`The selected record will be removed from the list. Proceed to delete?`}
            />

            <UploadModal
                show={showUpload}
                handleClose={() => setShowUpload(false)}
                title={`Upload Postal Code`}
                onFileSelect={handleFileSelect}
                actionOverwrite={postOverwrite}
                actionAppend={postAppend}
                templateURL={`https://docs.google.com/spreadsheets/d/1OS8W2TdRlgeM-XvMeeMhKc1y49VUtcqkGfpONRDUmY8/edit?gid=0#gid=0`}
            />
        </Layout>
    );
}
