import React, {useEffect, useState} from "react";

import {Button, Col, Form, FormCheck, Row} from "@govtechsg/sgds-react";
import {toast} from "react-toastify";
import Select from "react-select";
import {LivingAllowance} from "@/apis/app-records/maintenance/status";

const options = [
    {value: "chocolate", label: "Chocolate"},
    {value: "strawberry", label: "Strawberry"},
    {value: "vanilla", label: "Vanilla"},
];

function OtherScholarshipBeneficiary({
                                         isDisabled,
                                         scholarshipDetail,
                                         setListScholarship,
                                         handleChangeListScholarship,
                                         tobeDeletedScholar,
                                         handleChangeTobeDeleted,
                                         data
                                     }) {
    const [isScholarship, setIsScholarship] = useState(false);

    console.log(scholarshipDetail)

    const handleAddNewScholarship = () => {
        setListScholarship([
            ...scholarshipDetail,
            {
                SCHOLAR_SPONSOR: "",
                TUTFEE_C_TAG: "",
                L_ALLOWANCE_C_TAG: "",
                L_ALLOWANCE_C_DETAILS: "0",
                L_ALLOWANCE_C_TYPE: "",
                OTHERS_C_TAG: "",
                OTHERS_C_DETAILS: "",
                GOVBUR_TAG: "N",
            },
        ]);
    };

    const handleRemoveNewScholarship = (id, index) => {
        if (index == 0) {
            toast.error("Cannot delete the first scholarship");
            return;
        }

        //if id
        if (id) {
            //push to array state tobeDeletedScholar
            handleChangeTobeDeleted([...tobeDeletedScholar, id]);
        }
        const updatedScholarships = [...scholarshipDetail];
        setListScholarship(updatedScholarships.filter((_, i) => i !== index));
    };

    const handleScholarshipRadio = (e) => {
        if (e.target.value == "yes") {
            setIsScholarship(true);
        } else {
            setIsScholarship(false);
        }
    };

    useEffect(() => {
        if (scholarshipDetail?.length > 0) {
            setIsScholarship(true)
        }

        if(data?.SCHOLAR_SPONSOR_TAG === "Y"){
            setIsScholarship(true)
        }

    }, [scholarshipDetail]);

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>
                                I am receiving a scholarship or sponsorship in the current
                                academic year
                            </Form.Label>
                            <FormCheck
                                id="option-1"
                                label="Yes"
                                name="radio_checks"
                                type="radio"
                                value="yes"
                                checked={isScholarship}
                                onChange={handleScholarshipRadio}
                                disabled={isDisabled}
                            />
                            <FormCheck
                                id="option-2"
                                label="No"
                                name="radio_checks"
                                type="radio"
                                value="no"
                                checked={!isScholarship}
                                onChange={handleScholarshipRadio}
                                disabled={isDisabled}
                            />
                        </Form.Group>
                    </Col>
                    {isScholarship ? (
                        <React.Fragment>
                            <div>
                                <Button
                                    type="button"
                                    variant="btn btn-link ps-0 text-decoration-none"
                                    disabled={isDisabled}
                                    onClick={() => handleAddNewScholarship()}
                                >
                                    <i class="bi bi-plus-circle"></i> {"  "} Add Scholarship
                                </Button>
                            </div>

                            {scholarshipDetail?.map((item, index) => {
                                if (item?.GOVBUR_TAG != "Y") {
                                    return (
                                        <div className="mt-3" key={index}>
                                            <h4>
                                                #{index + 1} Scholarship
                                                <button
                                                    className={`btn-clear`}
                                                    type={"button"}
                                                    disabled={isDisabled}
                                                    onClick={() => {
                                                        handleRemoveNewScholarship(item.ID, index);
                                                    }}
                                                >
                                                    <i className={`bi bi-trash`}></i>
                                                </button>
                                            </h4>
                                            <Row>
                                                <Col xs={12}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Name of Scholarship / Sponsorship
                                                        </Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled}
                                                            value={item?.SCHOLAR_SPONSOR}
                                                            onChange={(e) =>
                                                                handleChangeListScholarship(
                                                                    "SCHOLAR_SPONSOR",
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12}>
                                                    <h4>Scholarship/Sponsorship</h4>
                                                    <Form.Group>
                                                        <FormCheck
                                                            id={`tuition_fee${index}`}
                                                            label="Tuition Fees"
                                                            name="tuition_fee "
                                                            type="checkbox"
                                                            checked={item?.TUTFEE_C_TAG === "Y"}
                                                            onChange={(e) =>
                                                                handleChangeListScholarship(
                                                                    "TUTFEE_C_TAG",
                                                                    e.target.checked ? "Y" : "",
                                                                    index
                                                                )
                                                            }
                                                            disabled={isDisabled}
                                                        />
                                                        <FormCheck
                                                            id={`living_allowance${index}`}
                                                            label="Living Allowance"
                                                            name="living_allowance"
                                                            type="checkbox"
                                                            checked={item?.L_ALLOWANCE_C_TAG === "Y"}
                                                            onChange={(e) =>
                                                                handleChangeListScholarship(
                                                                    "L_ALLOWANCE_C_TAG",
                                                                    e.target.checked ? "Y" : "",
                                                                    index
                                                                )
                                                            }
                                                            disabled={isDisabled}
                                                        />
                                                        {item?.L_ALLOWANCE_C_TAG === "Y" && (
                                                            <Row className="ms-md-3">
                                                                <Col xs={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>Amount</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            disabled={isDisabled}
                                                                            min={0}
                                                                            value={item?.L_ALLOWANCE_C_DETAILS}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                const numericValue = Number(value);
                                                                                if (
                                                                                    !isNaN(numericValue) &&
                                                                                    numericValue >= 0
                                                                                ) {
                                                                                    handleChangeListScholarship(
                                                                                        "L_ALLOWANCE_C_DETAILS",
                                                                                        value,
                                                                                        index
                                                                                    );
                                                                                }
                                                                            }}
                                                                        ></Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>Type</Form.Label>
                                                                        <Select
                                                                            options={LivingAllowance()}
                                                                            isDisabled={isDisabled}
                                                                            value={LivingAllowance().find(option => option.value === item?.L_ALLOWANCE_C_TYPE)}
                                                                            onChange={(option) => handleChangeListScholarship(
                                                                                "L_ALLOWANCE_C_TYPE",
                                                                                option.value,
                                                                                index
                                                                            )}
                                                                        />
                                                                        {/*<Form.Control*/}
                                                                        {/*  type="text"*/}
                                                                        {/*  disabled={isDisabled}*/}
                                                                        {/*  value={item?.L_ALLOWANCE_C_TYPE}*/}
                                                                        {/*  onChange={(e) =>*/}
                                                                        {/*    handleChangeListScholarship(*/}
                                                                        {/*      "L_ALLOWANCE_C_TYPE",*/}
                                                                        {/*      e.target.value,*/}
                                                                        {/*      index*/}
                                                                        {/*    )*/}
                                                                        {/*  }*/}
                                                                        {/*></Form.Control>*/}
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        <FormCheck
                                                            id={`others${index}`}
                                                            label="Others (please specify)"
                                                            name="others "
                                                            type="checkbox"
                                                            checked={item?.OTHERS_C_TAG === "Y"}
                                                            onChange={(e) =>
                                                                handleChangeListScholarship(
                                                                    "OTHERS_C_TAG",
                                                                    e.target.checked ? "Y" : "",
                                                                    index
                                                                )
                                                            }
                                                            disabled={isDisabled}
                                                        />

                                                        {item?.OTHERS_C_TAG === "Y" && (
                                                            <Row className="ms-md-3">
                                                                <Col xs={12}>
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            Other coverage details
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            value={item?.OTHERS_C_DETAILS}
                                                                            onChange={(e) =>
                                                                                handleChangeListScholarship(
                                                                                    "OTHERS_C_DETAILS",
                                                                                    e.target.value,
                                                                                    index
                                                                                )
                                                                            }
                                                                            disabled={isDisabled}
                                                                        ></Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                }
                            })}
                        </React.Fragment>
                    ) : null}
                </Row>
            </div>
        </React.Fragment>
    );
}

export default OtherScholarshipBeneficiary;
