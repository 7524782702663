export function ApplicationStatus() {
  return [
    { label: "", value: null },
    { label: "Recommended", value: "R" },
    { label: "Pending Approved", value: "P" },
    { label: "Pending Approved with Changes", value: "C" },
    { label: "Approved", value: "A" },
    { label: "Not Successful", value: "N" },
    { label: "Rescind", value: "E" },
  ];
}

export function VerificationStatus() {
  return [
    { label: "", value: null },
    { label: "I (Include)", value: "I" },
    { label: "E (Exclude)", value: "E" },
    { label: "P (Pending Document)", value: "P" },
  ];
}

export function DataFlagStatus() {
  return [
    { label: "", value: null },
    { label: "Withdrawn", value: "W" },
    { label: "Withhold", value: "H" },
    { label: "Regular", value: "R" },
    { label: "Update", value: "U" },
  ];
}

export function appVerificationOrderBy() {
  return [
    { label: "", value: null },
    { label: "Admission No.", value: "ADM_NO" },
    { label: "CCC Income & Name", value: "CCC_INCOME" },
    { label: "MPCI & Name", value: "MPCI" },
    { label: "FPCI & Name", value: "FPCI" },
  ];
}

export function specialMeansStatus() {
  return [
    { label: "", value: null },
    { label: "None", value: "NO" },
    { label: "Dual Income", value: "DI" },
    { label: "Change In Income", value: "CI" },
    { label: "Loss of Income", value: "LI" },
    { label: "Odd-Job Worker", value: "OJ" },
    { label: "MHA Invest", value: "MI" },
    { label: "Casual Workers", value: "CW" },
    { label: "Others", value: "OT" },
  ];
}

export function appRecommendationOrderBy() {
  return [
    { label: "", value: null },
    { label: "CCC Income & Name", value: "CCC_INCOME" },
    { label: "Gross Income & Name", value: "GROSS_INCOME" },
    { label: "MPCI & Name", value: "MPCI" },
    { label: "MPCI, Family Members & Name", value: "MPCI,FAMILY_MEMBERS" },
    {
      label: "MPCI, GPA, Family Members & Name",
      value: "MPCI,FAMILY_MEMBERS",
    },
    { label: "CWA, Study Stage & Name", value: "ADM_NO" },
    { label: "GPA, Study Stage & Name", value: "ADM_NO" },
    { label: "Entryqual, Agg/GPA", value: "ADM_NO" },
    { label: "Adm. No.", value: "ADM_NO" },
    {
      label: "MPCI, Housing Type, Family Member",
      value: "MPCI,FAMILY_MEMBERS",
    },
  ];
}

export function LivingAllowance() {
  return [
    { label: "", value: null },
    { label: "M (Monthly)", value: "M" },
    { label: "A (Annual)", value: "A" },
    { label: "O (One-off)", value: "O" },
  ];
}
