import axios from "axios";
import { apexAxiosCall } from "@/utils/apiCall";

// {
//     "ACAD_YR": "2024",
//     "EXERCISE": "6",
//     "START_DATE": "2024-10-01 12:00:00",
//     "START_TIME": "1200",
//     "END_DATE": "2024-11-01 12:00:00",
//     "END_TIME": "1200",
//     "CUTOFF_INCOME": "1000",
//     "MPCI_INCOME": "2000",
//     "MULTIPLE_AWARD_PER_AY": "Y",
//     "REMARK": "this is remark description",
//     "VSTATUS_END_DATE": null,
//     "ALLOW_SPONSOR_TAG": null,
//     "ATTACHMENT1": null,
//     "ATTACHMENT2": null,
//     "ATTACHMENT3": null,
//     "SGCITIZEN_TAG": null,
//     "SGPR_TAG": "Y",
//     "NONSG_PR_TAG": null,
//     "FASOP_TAG": "Y",
//     "HOMES_TAG": "Y",
//     "ADM_TAG": null
// }

export async function getTimeline(acadYear, exercise) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/timelines?ACAD_YEAR=${acadYear}&EXERCISE=${exercise}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function postTimeline(timelineData) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/timelines`,
      timelineData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function putTimeline(timelineID, timelineData) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/timelines/${timelineID}`,
      timelineData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteTimeline(selectedId) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/timelines/${selectedId}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getStudentList(search) {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/getStudentList",
    {
      studstatus: "",
      transfer_tag: "N",
      page: "1",
      limit: "20",
      search: search,
      sort: "id_no",
    }
  );
}

export function getTimelineDT(acadYear, exercise, courseCode = "") {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/timelines-dts?ACAD_YEAR=${acadYear}&EXERCISE=${exercise}&COURSE_CODE=${courseCode}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteTimelineDT(id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/timelines-dts/${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getTimelineADM(acadYear, exercise) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/timelines-adms?ACAD_YEAR=${acadYear}&EXERCISE=${exercise}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteTimelineADM(id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/timelines-adms/${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
