import axios from "axios";
import { createUrlWithParams } from "@/components/utils/utils";

export async function GetApplications(
  ACAD_YR = "",
  EXERCISE = "",
  ADM_NO = "",
  APPLN_STATUS = "",
  VERIFY_STATUS = "",
  REC_AWARD_TYPE = "",
  REC_AWARD_CODE = ""
) {
  let params = {};
  if (ACAD_YR !== null) {
    params = {
      ...params,
      ACAD_YR: ACAD_YR,
    };
  }
  if (EXERCISE !== null) {
    params = {
      ...params,
      EXERCISE: EXERCISE,
    };
  }
  if (ADM_NO !== null) {
    params = {
      ...params,
      ADM_NO: ADM_NO,
    };
  }
  if (APPLN_STATUS !== null) {
    params = {
      ...params,
      APPLN_STATUS: APPLN_STATUS,
    };
  }
  if (VERIFY_STATUS !== null) {
    params = {
      ...params,
      VERIFY_STATUS: VERIFY_STATUS,
    };
  }
  if (REC_AWARD_TYPE !== null) {
    params = {
      ...params,
      REC_AWARD_TYPE: REC_AWARD_TYPE,
    };
  }
  if (REC_AWARD_CODE !== null) {
    params = {
      ...params,
      REC_AWARD_CODE: REC_AWARD_CODE,
    };
  }

  let baseUrl = process.env.REACT_APP_API_URL + `/api/v1/applications`;
  const url = createUrlWithParams(baseUrl, params);

  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function GetShortlistApplicants(
  ACAD_YR = 2025,
  EXERCISE = 11,
  REC_AWARD_TYPE = "",
  REC_AWARD_CODE = "",
  course_tag = "Y"
) {
  let params = {};
  if (ACAD_YR !== null) {
    params = {
      ...params,
      ACAD_YR: ACAD_YR,
    };
  }
  if (EXERCISE !== null) {
    params = {
      ...params,
      EXERCISE: EXERCISE,
    };
  }
  if (REC_AWARD_TYPE !== null) {
    params = {
      ...params,
      REC_AWARD_TYPE: REC_AWARD_TYPE,
    };
  }
  if (REC_AWARD_CODE !== null) {
    params = {
      ...params,
      REC_AWARD_CODE: REC_AWARD_CODE,
    };
  }
  if (course_tag !== null) {
    params = {
      ...params,
      course_tag: course_tag,
    };
  }

  params = {
    ...params,
    APPLN_STATUS: "R",
  };
  let baseUrl = process.env.REACT_APP_API_URL + `/api/v1/applications`;
  const url = createUrlWithParams(baseUrl, params);

  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function getRecommendedApplicant(
  ACAD_YR,
  EXERCISE,
  ADM_NO,
  REC_AWARD_TYPE,
  REC_AWARD_CODE,
  RECOMMENDED_DATE_FROM,
  RECOMMENDED_DATE_TO,
  FORM_PRINTED_DATE_FROM,
  FORM_PRINTED_DATE_TO,
  ORDER_BY_FIELD = CREATED_AT,
  ORDER_BY_DIRECTION = "DESC"
) {
  let params = {};
  if (ACAD_YR !== null) {
    params = {
      ...params,
      ACAD_YR: ACAD_YR,
    };
  }

  if (EXERCISE !== null) {
    params = {
      ...params,
      EXERCISE: EXERCISE,
    };
  }
  if (ADM_NO !== null) {
    params = {
      ...params,
      ADM_NO: ADM_NO,
    };
  }

  if (REC_AWARD_TYPE !== null) {
    params = {
      ...params,
      REC_AWARD_TYPE: REC_AWARD_TYPE,
    };
  }

  if (REC_AWARD_CODE !== null) {
    params = {
      ...params,
      REC_AWARD_CODE: REC_AWARD_CODE,
    };
  }
  if (RECOMMENDED_DATE_FROM !== null) {
    params = {
      ...params,
      RECOMMENDED_DATE_FROM: RECOMMENDED_DATE_FROM,
    };
  }
  if (RECOMMENDED_DATE_TO !== null) {
    params = {
      ...params,
      RECOMMENDED_DATE_TO: RECOMMENDED_DATE_TO,
    };
  }
  if (FORM_PRINTED_DATE_FROM !== null) {
    params = {
      ...params,
      FORM_PRINTED_DATE_FROM: FORM_PRINTED_DATE_FROM,
    };
  }
  if (FORM_PRINTED_DATE_TO !== null) {
    params = {
      ...params,
      FORM_PRINTED_DATE_TO: FORM_PRINTED_DATE_TO,
    };
  }

  if (ORDER_BY_FIELD !== null) {
    params = {
      ...params,
      ORDER_BY_FIELD: ORDER_BY_FIELD,
    };
  }

  if (ORDER_BY_DIRECTION !== null) {
    params = {
      ...params,
      ORDER_BY_DIRECTION: ORDER_BY_DIRECTION,
    };
  }
  let baseUrl =
    process.env.REACT_APP_API_URL +
    `/api/v1/applications/reports/recommends/applicants`;
  const url = createUrlWithParams(baseUrl, params);

  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function getApplicantSummary(ACAD_YR, EXERCISE) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/reports/applicants/summaries?ACAD_YR=${ACAD_YR}&EXERCISE=${EXERCISE}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}
