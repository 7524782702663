import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import "react-toastify/dist/ReactToastify.css";
import CustomNavbar from "@/components/CustomNavbar";
import Toc from "@/components/Toc";
import { toast, ToastContainer } from "react-toastify";

import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "@/authConfig";
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { useSessionContext } from "@/context/sessionContext";
import axios from "axios";
import { getStaffDetail } from "@/apis/utilities/api";

const msalInstance = new PublicClientApplication(msalConfig);

export default function Layout(props) {
  const [openSidebar, setOpenSidebar] = useState(true);
  const [openRightbar, setOpenRightbar] = useState(true);
  const widthScreen = window.innerWidth;
  const { instance, accounts } = useMsal();

  const { idToken, setIdToken } = useSessionContext();

  const requestIdToken = async () => {
    const account = accounts[0];
    instance
      .acquireTokenRedirect(loginRequest)
      .then((resp) => toast.info("Authentication..."))
      .catch((err) => console.error(err));

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      const token = response.idToken;
      setIdToken(token);
      sessionStorage.setItem("idToken", token); // Save ID token to sessionStorage
      console.log("ID Token:", token);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(loginRequest);
      } else {
        console.error(error);
      }
    }
  };

  const scheduleTokenRenewal = (tokenExpiration) => {
    const tokenExpiryTime = new Date(tokenExpiration * 1000);
    const currentTime = new Date();
    const timeoutDuration = tokenExpiryTime - currentTime - 300000; // Renew 5 minutes before expiration

    if (timeoutDuration > 0) {
      setTimeout(() => {
        requestIdToken();
      }, timeoutDuration);
    } else {
      requestIdToken();
    }
  };

  // axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.defaults.headers = {
    // Authorization: `Bearer ${accounts[0]?.idToken}`,
    Authorization: `Bearer ${process.env.REACT_APP_FAAS_AUTH_API_KEY}`,
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        //place your reentry code
        // sessionStorage.removeItem("idToken");
        // instance.logoutRedirect();
        requestIdToken();
      }
      return error;
    }
  );

  useEffect(() => {
    setTimeout(async () => {
      if (accounts.length > 0) {
        await instance
          .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response) => {
            const token = response.idToken;
            setIdToken(token);

            sessionStorage.setItem("username", response?.account?.username);
            sessionStorage.setItem("accessToken", response.accessToken);
            sessionStorage.setItem("idToken", token);
            const decodedToken = JSON.parse(atob(token.split(".")[1]));
            scheduleTokenRenewal(decodedToken.exp);

            getStaffDetail(response?.account?.username).then((response) => {
              if (response?.data?.data) {
                const responseData = response?.data?.data
                const roleList = responseData?.[0]?.roleList;
                if (roleList) {
                  const roles = roleList?.map((item) => item.role);
                  if (roles?.length > 0) {
                    sessionStorage.setItem("roles", JSON.stringify(roles));
                    sessionStorage.setItem("role", roles[0]);
                    sessionStorage.setItem("staffId", responseData?.[0]?.staffId);
                    }
                }

              }
            });
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(loginRequest);
            } else {
              console.error(error);
            }
          });
      }
    }, 1000);
  }, [accounts, instance]);


  return (
    <>
      <CustomNavbar
        currentActiveNav={props.currentNav}
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        openRightbar={openRightbar}
        setOpenRightbar={setOpenRightbar}
      />

      <sgds-template-grid
        class={`${openSidebar ? "with-sidenav" : "without-sidenav"} ${
          props?.toc
            ? openRightbar && widthScreen >= 991
              ? "with-toc"
              : "without-toc"
            : "without-toc"
        } `}
      >
        <Sidebar
          currentNav={props.currentNav}
          visibilityValue={openSidebar ? "visible" : "hidden"}
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
        />

        <sgds-content-area className="gap-4">
          {props.children}
        </sgds-content-area>

        <Toc currentNav={props.currentNav} />
      </sgds-template-grid>
      <Footer />
      <ToastContainer />
    </>
  );
}
