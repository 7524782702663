import axios from "axios";
import { apexAxiosCall } from "@/utils/apiCall";

export async function getAdmNo(ACAD_YR, EXERCISE) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/applications?ACAD_YR=${ACAD_YR}&EXERCISE=${EXERCISE}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function getApplication(
  ACAD_YR,
  EXERCISE,
  ADM_NO,
  VERIFY_STATUS,
  ORDERBYFIELD = "",
  IS_CHECKED_NOT_ENROLLED = false
) {
  let URL = `${process.env.REACT_APP_API_URL}/api/v1/applications?ACAD_YR=${ACAD_YR}&EXERCISE=${EXERCISE}&ADM_NO=${ADM_NO}&isAnalytic=true&VERIFY_STATUS=${VERIFY_STATUS}`;

  if (ORDERBYFIELD !== "") {
    URL = `${process.env.REACT_APP_API_URL}/api/v1/applications?ACAD_YR=${ACAD_YR}&EXERCISE=${EXERCISE}&ADM_NO=${ADM_NO}&isAnalytic=true&VERIFY_STATUS=${VERIFY_STATUS}&orderByField=${ORDERBYFIELD}&orderByDirection=ASC`;
  }

  if (IS_CHECKED_NOT_ENROLLED) {
    URL += "&APPLN_STATUS_NOT_EQUALS=D";
  }

  return (
    axios
      // .get(`${process.env.REACT_APP_API_URL}/api/v1/applications?isAnalytic=true`)
      .get(URL)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err?.message);
        return err;
      })
  );
}

export async function postStudentList(student_ids, is_not_enrolled = false) {
  let studStatus = "E";
  if (is_not_enrolled) {
    studStatus = "";
  }

  return apexAxiosCall(
    process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/getStudentList",
    {
      student_id: student_ids,
      studstatus: studStatus,
      transfer_tag: "N",
      page: "0",
      limit: "0",
      search: "",
      sort: "id_no",
    }
  );
}

export async function patchApplication(applicationData) {
  return axios
    .patch(`${process.env.REACT_APP_API_URL}/api/v1/applications`, {
      DATA: Array.isArray(applicationData)
        ? applicationData
        : [applicationData],
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function deleteApplication(ACAD_YR, EXERCISE, ADM_NO) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/api/v1/applications?ACAD_YR=${ACAD_YR}&EXERCISE=${EXERCISE}&ADM_NO=${ADM_NO}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}
