import axios from "axios";

// CSCode

// {
//     "CS_CODE": "TB",
//     "CS_NAME": "Tanjong Pagar",
//     "TEL1": null,
//     "TEL2": null,
//     "FAX": null
// }

export async function getCSList(search, limit = 0, page) {
    return axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/cscodes?CS_CODE=${search}&limit=${limit}&page=${page}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export function postCS(CSData) {
    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/cscodes`,
            CSData
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export function putCS(CSID, CSData) {
    return axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/cscodes/${CSID}`,
            CSData
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export function deleteCS(selectedId) {
    return axios
        .delete(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/cscodes/${selectedId}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export function postAppendCS(jsonData) {
    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/cscodes/append`,
            {
                DATA: jsonData,
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export function postOverwriteCS(jsonData) {
    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/cscodes/overwrite`,
            {
                DATA: jsonData,
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

// Postal Code

// {
//     "POSTAL_CODE": "3311",
//     "FAAS_CS_CODES_ID": "a6482c28-6384-4a61-8074-009e96a5381d"
// }

export async function getPCList(search, limit, page) {
    return axios
        .get(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/postalcodes?POSTAL_CODE=${search}&limit=${limit}&page=${page}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export function postPC(PCData) {
    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/postalcodes`,
            PCData
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export function putPC(PCID, PCData) {
    return axios
        .put(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/postalcodes/${PCID}`,
            PCData
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export function deletePC(selectedId) {
    return axios
        .delete(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/postalcodes/${selectedId}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export function postAppendPC(jsonData) {
    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/postalcodes/append`,
            {
                DATA: jsonData,
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export function postOverwritePC(jsonData) {
    return axios
        .post(
            `${process.env.REACT_APP_API_URL}/api/v1/applications/postalcodes/overwrite`,
            {
                DATA: jsonData,
            }
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}
