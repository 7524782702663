import React, {useState} from "react";
import {Col, Form, FormCheck, Row, Table,} from "@govtechsg/sgds-react";
import {Accordion} from "@govtechsg/sgds-react/Accordion";

function OtherDetails({isDisabled, details, studentDetail, onDataChange, handleApplicationChange}) {

    const roleList = JSON.parse(sessionStorage.getItem('roles')) || [];

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>Sponsor</Form.Label>
                            <Form.Control type="text" disabled={true}
                                          value={""}
                                // onChange={(e) => handleChange("COMP_SPONSOR_TAG", e.target.value)}
                                // value={details?.COMP_SPONSOR_TAG}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group>
                            <Form.Label>Tuition Fee Loan</Form.Label>
                            <Form.Control type="text" disabled={true} value={studentDetail?.tutFeeLoan}></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group>
                            <Form.Label>Mendaki Tertiary Fees Subsidy (%)</Form.Label>
                            <Form.Control type="text" disabled={true}
                                          value={studentDetail?.mendakiSubsidy}></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </div>

            <div className="mt-4">
                <Accordion defaultActiveKey="1" className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Award History
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Table responsive>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Award Type</Table.HeaderCell>
                                                    <Table.HeaderCell>Award</Table.HeaderCell>
                                                    <Table.HeaderCell>Year of Award</Table.HeaderCell>
                                                    <Table.HeaderCell>Award Amount</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {studentDetail?.studFinAssistList?.map((award) => (
                                                    <Table.Row>
                                                        <Table.DataCell>{award?.awardType}</Table.DataCell>
                                                        <Table.DataCell>{award?.award}</Table.DataCell>
                                                        <Table.DataCell>{award?.yrAwarded}</Table.DataCell>
                                                        <Table.DataCell>{award?.amtAwarded}</Table.DataCell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="mt-4">
                <Accordion defaultActiveKey="1" className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Charged-in-court
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <FormCheck
                                                id="Charged_in_a_court_of_law_in_any_country"
                                                label="Charged in a court of law in any country"
                                                name="Charged_in_a_court_of_law_in_any_country "
                                                type="checkbox"
                                                checked={details?.CHARGE_IN_COURT === "Y"}
                                                disabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                                onChange={(e) => handleApplicationChange("CHARGE_IN_COURT", e.target.checked ? "Y" : "")}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="mt-4">
                <Accordion defaultActiveKey="1" className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Application Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                disabled={isDisabled}
                                                onChange={(e) => handleApplicationChange("REMARK", e.target.value)}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="mt-4">
                <Accordion defaultActiveKey="1" className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Summary Assessment
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <FormCheck
                                                id="CPF_approved_funds"
                                                label="CPF Approved Funds"
                                                name="CPF_approved_funds "
                                                type="checkbox"
                                                disabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                                checked={details?.CPF_APPROVED_TAG === "Y"}
                                                onChange={(e) => handleApplicationChange("CPF_APPROVED_TAG", e.target.checked ? "Y" : "")}
                                            />
                                            <FormCheck
                                                id="Intact_family"
                                                label="Intact Family"
                                                name="intact_family "
                                                type="checkbox"
                                                disabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                                checked={details?.INTACT_FAMILY_TAG === "Y"}
                                                onChange={(e) => handleApplicationChange("INTACT_FAMILY_TAG", e.target.checked ? "Y" : "")}
                                            />
                                            <FormCheck
                                                id="company_sponsored"
                                                label="Company Sponsored"
                                                name="company_sponsored "
                                                type="checkbox"
                                                disabled={isDisabled}
                                                checked={details?.COMP_SPONSOR_TAG === "Y"}
                                                onChange={(e) => handleApplicationChange("COMP_SPONSOR_TAG", e.target.checked ? "Y" : "")}
                                            />
                                            <FormCheck
                                                id="playing_moe_subsidised_fees"
                                                label="Playing MOE Subsidised Fees"
                                                name="playing_moe_subsidised_fees "
                                                type="checkbox"
                                                disabled={isDisabled}
                                                checked={details?.MOE_SUBSIDISED === "Y"}
                                                onChange={(e) => handleApplicationChange("MOE_SUBSIDISED", e.target.checked ? "Y" : "")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Recommended in NPPS</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={studentDetail?.NPPS}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Recommended in FASOP</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={studentDetail?.FASOP}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </React.Fragment>
    );
}

export default OtherDetails;
