import Layout from "@/components/Layout";
import {Button, Col, FileUpload, Form, Row} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {
    deleteTimeline,
    deleteTimelineADM,
    deleteTimelineDT,
    getStudentList,
    getTimeline,
    getTimelineADM,
    getTimelineDT,
    postTimeline,
} from "@/apis/preparation/setup-timeline/api";

import moment from "moment";
import {toast} from "react-toastify";
import Select from "react-select";
import {postStudentList} from "@/apis/app-records/verification/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import CustomModal from "@/components/CustomModal";
import {getAcademyYear, getExercise} from "@/apis/timeline/api";
import CreatableSelect from "react-select/creatable";
import * as XLSX from "xlsx";

export default function SetupTimeline() {
    const [academicYear, setAcademicYear] = useState();

    const [exercise, setExercise] = useState(null);

    const [id, setId] = useState("");

    const [isRetrieved, setIsRetrieved] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [dataAcademyYear, setDataAcademyYear] = useState([]);

    const [dataExercise, setDataExercise] = useState([]);

    const [selectedAcadYearOption, setSelectedAcadYearOption] = useState();

    const [selectedExerciseOption, setSelectedExerciseOption] = useState(null);

    const [initialStudentOptions, setInitialStudentOptions] = useState([]);

    const [data, setData] = useState({
        ID: "",
        ACAD_YR: "",
        EXERCISE: "",
        START_DATE: "",
        START_TIME: "",
        END_DATE: "",
        END_TIME: "",
        VSTATUS_END_DATE: "",
        MULTIPLE_AWARD_PER_AY: "",
        FASOP_TAG: "",
        ALLOW_SPONSOR_TAG: "",
        HOMES_TAG: "",
        SGCITIZEN_TAG: "",
        SGPR_TAG: "",
        NONSG_PR_TAG: "",
        CUTOFF_INCOME: "",
        MPCI_INCOME: "",
        REMARK: "",
        ADM_TAG: "",
        TIMELINE_DT: [
            {
                ACAD_YR: "",
                EXERCISE: "",
                COURSE_CODE: "",
            },
        ],
        TIMELINE_ADM: [
            {
                ACAD_YR: "",
                EXERCISE: "",
                ADM_NO: "",
            },
        ],
    });

    const [courses, setCourses] = useState([
        {
            ACAD_YR: academicYear,
            EXERCISE: exercise,
            COURSE_CODE: "",
            options: [],
        },
    ]);

    const [students, setStudents] = useState([
        {
            ACAD_YR: academicYear,
            EXERCISE: exercise,
            ADM_NO: "",
            options: initialStudentOptions,
        },
    ]);

    const [editable, setEditable] = useState({
        applicationDisabled: true,
        courseDisabled: true,
        eligibleDisable: true,
        applicantDisable: true,
        cutoffDisable: true,
        remarksDisable: true,
    });

    const [selectedFile, setSelectedFile] = useState({});

    const onChangeFile = (file) => {
        setSelectedFile(file);
    };

    const handleEdit = (name) => {
        setEditable((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    const handleChange = (field, value) => {
        setData({
            ...data,
            [field]: value,
        });
    };

    const handleGetListAcademyYear = async () => {
        try {
            setAcademicYear(null);

            const response = await getAcademyYear();

            setDataAcademyYear(
                response?.data?.data?.map((item) => ({
                    label: item?.ACAD_YR,
                    value: item?.ACAD_YR,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListExercise = async () => {
        const params = {
            ACAD_YR: academicYear,
        };

        try {
            setExercise(null);

            const response = await getExercise(params);

            setDataExercise(
                response?.data?.data?.map((item) => ({
                    label: item?.EXERCISE,
                    value: item?.EXERCISE,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleSubmit = () => {
        if (!academicYear || !exercise) {
            toast.error(
                "Please enter the academic year and exercise before proceeding!"
            );
            return false;
        }

        if (!data?.START_DATE || !data?.END_DATE || !academicYear || !exercise) {
            toast.error("Please fill up the required fields");
            return false;
        }

        // if (courses.some((course) => course.COURSE_CODE === "")) {
        //     toast.error("Please fill up the required fields");
        //     return false;
        // }

        if (data.ADM_TAG === "E") {
            if (students.some((student) => student.ADM_NO === "")) {
                toast.error("Please fill up the required fields");
                return false;
            }
        }

        let mergedData = {};

        if (id) {
            mergedData = {
                ID: data?.ID,
                ACAD_YR: academicYear,
                ADM_TAG: data?.ADM_TAG,
                EXERCISE: exercise,
                START_DATE: moment(data?.START_DATE).format("YYYY-MM-DD hh:mm:ss"),
                START_TIME: data?.START_TIME,
                END_DATE: data?.END_DATE
                    ? moment(data?.END_DATE).format("YYYY-MM-DD hh:mm:ss")
                    : null,
                END_TIME: data?.END_TIME,
                VSTATUS_END_DATE: data?.VSTATUS_END_DATE
                    ? moment(data?.VSTATUS_END_DATE).format("YYYY-MM-DD")
                    : null,
                MULTIPLE_AWARD_PER_AY: data?.MULTIPLE_AWARD_PER_AY,
                FASOP_TAG: data?.FASOP_TAG,
                ALLOW_SPONSOR_TAG: data?.ALLOW_SPONSOR_TAG,
                HOMES_TAG: data?.HOMES_TAG,
                SGCITIZEN_TAG: data?.SGCITIZEN_TAG,
                SGPR_TAG: data?.SGPR_TAG,
                NONSG_PR_TAG: data?.NONSG_PR_TAG,
                CUTOFF_INCOME: data?.CUTOFF_INCOME,
                MPCI_INCOME: data?.MPCI_INCOME,
                REMARK: data?.REMARK,
                TIMELINE_DT: courses.map((course) => ({
                    ...course,
                    ACAD_YR: academicYear,
                    EXERCISE: exercise,
                })),
                TIMELINE_ADM: students.map((student) => ({
                    ...student,
                    ACAD_YR: academicYear,
                    EXERCISE: exercise,
                })),
            };
        } else {
            mergedData = {
                ACAD_YR: academicYear,
                ADM_TAG: data?.ADM_TAG,
                EXERCISE: exercise,
                START_DATE: moment(data?.START_DATE).format("YYYY-MM-DD hh:mm:ss"),
                START_TIME: data?.START_TIME,
                END_DATE: moment(data?.END_DATE).format("YYYY-MM-DD hh:mm:ss"),
                END_TIME: data?.END_TIME,
                VSTATUS_END_DATE: moment(data?.VSTATUS_END_DATE).format("YYYY-MM-DD"),
                MULTIPLE_AWARD_PER_AY: data?.MULTIPLE_AWARD_PER_AY,
                FASOP_TAG: data?.FASOP_TAG,
                ALLOW_SPONSOR_TAG: data?.ALLOW_SPONSOR_TAG,
                HOMES_TAG: data?.HOMES_TAG,
                SGCITIZEN_TAG: data?.SGCITIZEN_TAG,
                SGPR_TAG: data?.SGPR_TAG,
                NONSG_PR_TAG: data?.NONSG_PR_TAG,
                CUTOFF_INCOME: data?.CUTOFF_INCOME,
                MPCI_INCOME: data?.MPCI_INCOME,
                REMARK: data?.REMARK,
                TIMELINE_DT: courses.map((course) => ({
                    ...course,
                    ACAD_YR: academicYear,
                    EXERCISE: exercise,
                })),
                TIMELINE_ADM: students.map((student) => ({
                    ...student,
                    ACAD_YR: academicYear,
                    EXERCISE: exercise,
                })),
            };
        }

        postTimeline(mergedData)
            .then((response) => {
                if (response?.response?.data?.message) {
                    if (
                        response?.response?.data?.message?.length > 0 &&
                        Array.isArray(response?.response?.data?.message)
                    ) {
                        response?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(response?.response?.data?.message);
                    }
                } else if (response?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    toast.success(`Changes saved successfully`);
                    handleEdit("applicationDisabled");
                    handleGetTimeline();
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            });
    };

    const handleGetTimeline = () => {
        getTimeline(academicYear, exercise).then((response) => {
            if (response?.data?.data?.length > 0) {
                setData(response?.data?.data[0]);
                setId(response?.data?.data[0].ID);
            } else {
                setId("");

                setCourses([
                    {
                        ACAD_YR: academicYear,
                        EXERCISE: exercise,
                        COURSE_CODE: "",
                        options: [],
                    },
                ]);

                setStudents([
                    {
                        ACAD_YR: academicYear,
                        EXERCISE: exercise,
                        ADM_NO: "",
                        options: initialStudentOptions,
                    },
                ]);

                setData({
                    ACAD_YR: "",
                    EXERCISE: "",
                    START_DATE: "",
                    START_TIME: "",
                    END_DATE: "",
                    END_TIME: "",
                    VSTATUS_END_DATE: "",
                    MULTIPLE_AWARD_PER_AY: "",
                    FASOP_TAG: "",
                    ALLOW_SPONSOR_TAG: "",
                    HOMES_TAG: "",
                    SGCITIZEN_TAG: "",
                    SGPR_TAG: "",
                    NONSG_PR_TAG: "",
                    CUTOFF_INCOME: "",
                    MPCI_INCOME: "",
                    REMARK: "",
                    ADM_TAG: "",
                    TIMELINE_DT: [
                        {
                            ACAD_YR: "",
                            EXERCISE: "",
                            COURSE_CODE: "",
                        },
                    ],
                    TIMELINE_ADM: [
                        {
                            ACAD_YR: "",
                            EXERCISE: "",
                            ADM_NO: "",
                        },
                    ],
                });

                toast.warning("Data does not exist, this will create new one");
            }
        });

        getTimelineDT(academicYear, exercise, "").then((response) => {
            if (response?.data?.data?.length > 0) {
                setCourses(response?.data?.data);
            }
        });

        getTimelineADM(academicYear, exercise).then(async (response) => {
            if (response?.data?.data?.length > 0) {
                const modifiedItems = response?.data?.data?.map((item) => ({
                    ...item,
                    name: "",
                }));

                setStudents(modifiedItems);

                const itemsMap = modifiedItems.reduce((map, item) => {
                    map[item.ADM_NO] = item;
                    return map;
                }, {});

                const students_id = response?.data?.data
                    ?.map((application) => application.ADM_NO)
                    .join(",");

                if (students_id) {
                    postStudentList(students_id, true).then((response2) => {
                        response2?.data?.data?.forEach((student) => {
                            const item = itemsMap[student.admNo];
                            if (item) {
                                setStudents((prevItems) =>
                                    prevItems.map((prevItem) => {
                                        if (prevItem.ADM_NO === item.ADM_NO) {
                                            return {
                                                ...prevItem,
                                                name: student.name,
                                            };
                                        }
                                        return prevItem;
                                    })
                                );
                            }
                        });
                    });
                }
            }
        });
    };

    const handleRetrieveData = () => {
        if (academicYear && exercise) {
            handleGetTimeline();
        } else {
            setId("");
            setCourses([
                {
                    ACAD_YR: academicYear,
                    EXERCISE: exercise,
                    COURSE_CODE: "",
                    options: [],
                },
            ]);

            setStudents([
                {
                    ACAD_YR: academicYear,
                    EXERCISE: exercise,
                    ADM_NO: "",
                    options: initialStudentOptions,
                },
            ]);
            setData({
                ID: "",
                ACAD_YR: "",
                EXERCISE: "",
                START_DATE: "",
                START_TIME: "",
                END_DATE: "",
                END_TIME: "",
                VSTATUS_END_DATE: "",
                MULTIPLE_AWARD_PER_AY: "",
                FASOP_TAG: "",
                ALLOW_SPONSOR_TAG: "",
                HOMES_TAG: "",
                SGCITIZEN_TAG: "",
                SGPR_TAG: "",
                NONSG_PR_TAG: "",
                CUTOFF_INCOME: "",
                MPCI_INCOME: "",
                REMARK: "",
                ADM_TAG: "",
                TIMELINE_DT: [
                    {
                        ACAD_YR: "",
                        EXERCISE: "",
                        COURSE_CODE: "",
                    },
                ],
                TIMELINE_ADM: [
                    {
                        ACAD_YR: "",
                        EXERCISE: "",
                        ADM_NO: "",
                    },
                ],
            });

            toast.warning("Data does not exist, this will create new one");
        }

        setIsRetrieved(true);
    };

    const handleAddCourseRow = () => {
        const newCourse = {
            ACAD_YR: academicYear,
            EXERCISE: exercise,
            COURSE_CODE: "",
            options: [],
        };

        setCourses([...courses, newCourse]);
    };

    const handleChangeCourseInput = (input, index) => {
        setTimeout(() => {
            getTimelineDT(academicYear, exercise, input).then((response) => {
                if (response?.data?.data?.length > 0) {
                    const options = response?.data?.data?.map((course) => ({
                        value: course.COURSE_CODE,
                        label: course.COURSE_CODE,
                    }));

                    setCourses((prevCourses) => {
                        const updatedCourses = [...prevCourses];
                        updatedCourses[index].options = options;
                        return updatedCourses;
                    });
                }
            });
        }, 500);
    };

    const handleChangeCourseCode = (index, option) => {
        const updatedCourses = [...courses];
        updatedCourses[index].COURSE_CODE = option;
        // updatedCourses[index].COURSE_CODE = option.value;
        setCourses(updatedCourses);
    };

    const handleRemoveCourse = (index) => {
        if (courses.length === 1) {
            toast.warning("Last row cannot be removed");
            return;
        }

        const updatedCourses = courses.filter((course, i) => {
            if (i === index) {
                //remove
                deleteTimelineDT(course?.ID).then((e) => {
                    toast.success("Course code has been removed");
                });
            }
            return i !== index;
        });
        setCourses(updatedCourses);
    };

    const handleCreateCourse = (inputValue, index) => {
        const newOption = createOption(inputValue);

        setCourses((prevCourses) => {
            const updatedCourses = [...prevCourses];
            updatedCourses[index].options = [
                ...(updatedCourses[index].options || []),
                newOption,
            ];
            updatedCourses[index].COURSE_CODE = newOption.value;

            return updatedCourses;
        });
    };

    const handleAddStudentRow = () => {
        const newStudent = {
            ACAD_YR: academicYear,
            EXERCISE: exercise,
            ADM_NO: "",
            options: initialStudentOptions,
        };

        setStudents([...students, newStudent]);
    };

    const handleChangeStudentInput = (input, index) => {
        if (input) {
            setTimeout(() => {
                getStudentList(input).then((response) => {
                    const options = response?.data?.data?.map((student) => ({
                        value: student.admNo,
                        label: student.admNo,
                        name: student.name,
                    }));

                    setStudents((prevStudents) => {
                        const updatedStudents = [...prevStudents];
                        updatedStudents[index].options = options;
                        return updatedStudents;
                    });
                });
            }, 500);
        }
    };

    const handleChangeStudent = (index, selectedOption) => {
        console.log(selectedOption);
        const updatedStudents = [...students];
        updatedStudents[index].ADM_NO = selectedOption.value;
        setStudents(updatedStudents);
    };

    const handleDelete = () => {
        if (!data.ID) {
            toast.error("ID not found!");
            return;
        }

        deleteTimeline(data?.ID)
            .then((response) => {
                if (response?.response?.data?.message) {
                    if (
                        response?.response?.data?.message?.length > 0 &&
                        Array.isArray(response?.response?.data?.message)
                    ) {
                        response?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(response?.response?.data?.message);
                    }
                } else if (response?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    toast.success("Record deleted successfully");

                    setData({
                        ID: "",
                        ACAD_YR: "",
                        EXERCISE: "",
                        START_DATE: "",
                        START_TIME: "",
                        END_DATE: "",
                        END_TIME: "",
                        VSTATUS_END_DATE: "",
                        MULTIPLE_AWARD_PER_AY: "",
                        FASOP_TAG: "",
                        ALLOW_SPONSOR_TAG: "",
                        HOMES_TAG: "",
                        SGCITIZEN_TAG: "",
                        SGPR_TAG: "",
                        NONSG_PR_TAG: "",
                        CUTOFF_INCOME: "",
                        MPCI_INCOME: "",
                        REMARK: "",
                        ADM_TAG: "",
                        TIMELINE_DT: [
                            {
                                ACAD_YR: "",
                                EXERCISE: "",
                                COURSE_CODE: "",
                            },
                        ],
                        TIMELINE_ADM: [
                            {
                                ACAD_YR: "",
                                EXERCISE: "",
                                ADM_NO: "",
                            },
                        ],
                    });
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    const handleRemoveStudent = (index) => {
        const updatedStudents = students.filter((student, i) => {
            if (i == index) {
                deleteTimelineADM(student?.ID).then((e) => {
                    toast.success(
                        `Student ${student?.name} with ${student?.ADM_NO} has been removed`
                    );
                });
            }
            return i !== index;
        });
        setStudents(updatedStudents);
    };

    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ""),
    });

    const handleCreateAcadYear = (inputValue) => {
        const newOption = createOption(inputValue);
        setDataAcademyYear((prev) => [...prev, newOption]);
        setSelectedAcadYearOption(newOption);
        setAcademicYear(newOption.value);
    };

    const handleCreateExercise = (inputValue) => {
        const newOption = createOption(inputValue);
        setDataExercise((prev) => [...prev, newOption]);
        setSelectedExerciseOption(newOption);
        setExercise(newOption.value);
    };

    const convertTimeStringToDate = (timeString) => {
        if (!timeString || timeString.length !== 4) return "";

        const hours = parseInt(timeString.slice(0, 2), 10);
        const minutes = parseInt(timeString.slice(2), 10);

        const now = new Date();
        return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            hours,
            minutes
        );
    };

    useEffect(() => {
        handleGetListAcademyYear();

        // getStudentList().then((response) => {
        //   const options = response?.data?.data?.map((student) => ({
        //     value: student.admNo,
        //     label: student.admNo,
        //     name: student.name,
        //   }));

        //   setInitialStudentOptions(options);
        // });
    }, []);

    useEffect(() => {
        const file = selectedFile[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            const existingAdmNos = new Set(students.map((student) => student.ADM_NO));

            const filteredData = jsonData.filter(
                (row) => !existingAdmNos.has(row.ADM_NO)
            );

            const students_id = filteredData.map((row) => row.ADM_NO).join(",");

            postStudentList(students_id).then((response) => {
                const newStudents = response?.data?.data || [];
                const updatedData = filteredData.map((row) => {
                    const matchingStudent = newStudents.find(
                        (student) => student.admNo === row.ADM_NO
                    );
                    return {
                        ...row,
                        name: matchingStudent
                            ? matchingStudent.name
                            : "Student name not found",
                    };
                });

                setStudents((prevStudents) => [...prevStudents, ...updatedData]);
            });
        };
        reader.readAsArrayBuffer(file);
    }, [selectedFile]);

    // useEffect(() => {
    //   if (initialStudentOptions?.length > 0) {
    //     setStudents((prevStudents) => {
    //       const updatedStudents = prevStudents.map((student) => ({
    //         ...student,
    //         options: initialStudentOptions,
    //       }));
    //       return updatedStudents;
    //     });
    //   }
    // }, [initialStudentOptions]);

    useEffect(() => {
        handleGetListExercise();
    }, [academicYear]);

    return (
        <Layout currentNav={"preparation"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Setup Preparation</Breadcrumb.Item>
                <Breadcrumb.Item active>Setup Application Timeline</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <div className="d-flex gap-3">
                    <h2>Setup Application Timeline</h2>

                    {/*<button*/}
                    {/*    className={`btn-clear text-blue d-flex align-items-center gap-1`}*/}
                    {/*    type={"button"}*/}
                    {/*>*/}
                    {/*    <i className="bi bi-box-arrow-up-right"></i>*/}
                    {/*    Export*/}
                    {/*</button>*/}
                </div>
            </sgds-content-header-top>
            <sgds-content-area-bottom>
                <Row className={`align-items-end`}>
                    <Col xs={3}>
                        <Form.Group>
                            <Form.Label>
                                Academic Year
                                <span className={`text-danger`}> *</span>
                            </Form.Label>
                            <CreatableSelect
                                onCreateOption={handleCreateAcadYear}
                                onChange={(option) => {
                                    setSelectedAcadYearOption(option);
                                    setAcademicYear(option.value);
                                }}
                                options={dataAcademyYear}
                                value={selectedAcadYearOption}
                            />
                            {/*<Form.Control*/}
                            {/*    type={"number"}*/}
                            {/*    onChange={(e) => setAcademicYear(e.target.value)}*/}
                            {/*></Form.Control>*/}
                        </Form.Group>
                    </Col>

                    <Col xs={2}>
                        <Form.Group>
                            <Form.Label>
                                Exercise
                                <span className={`text-danger`}> *</span>
                            </Form.Label>
                            <CreatableSelect
                                onCreateOption={handleCreateExercise}
                                onChange={(option) => {
                                    setSelectedExerciseOption(option);
                                    setExercise(option.value);
                                }}
                                options={dataExercise}
                                value={selectedExerciseOption}
                            />
                            {/*<Form.Control*/}
                            {/*    type={"number"}*/}
                            {/*    onChange={(e) => setExercise(e.target.value)}*/}
                            {/*></Form.Control>*/}
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Button
                            className={`btn-primary`}
                            type={"button"}
                            onClick={handleRetrieveData}
                        >
                            Retrieve
                        </Button>
                    </Col>
                </Row>
            </sgds-content-area-bottom>

            <sgds-content-body>
                {isRetrieved && (
                    <Row className={`gap-3`}>
                        <Col>
                            <div>
                                {!editable.applicationDisabled ? (
                                    <button
                                        className={`btn-clear text-green-500 d-flex align-items-center gap-1`}
                                        onClick={handleSubmit}
                                    >
                                        <i className={`bi bi-check-lg`}></i>
                                        Save
                                    </button>
                                ) : (
                                    <button
                                        className={`btn-clear`}
                                        type={"button"}
                                        onClick={() => handleEdit("applicationDisabled")}
                                    >
                                        <i className={`bi bi-pencil`}></i>
                                        {` `}Edit
                                    </button>
                                )}
                                {data?.ID &&
                                data?.FASOP_TAG !== "Y" &&
                                data?.HOMES_TAG !== "Y" ? (
                                    <button
                                        className={`btn-clear `}
                                        type={"button"}
                                        onClick={() => setShowDeleteModal(true)}
                                    >
                                        <i className={`bi bi-trash`}></i>
                                        {` `}Delete
                                    </button>
                                ) : null}
                            </div>
                        </Col>

                        <Col xs={12}>
                            <div className={`p-3 bg-white shadow-sm`}>
                                <div className={`d-flex justify-content-between`}>
                                    <h3 className={`text-blue fw-bold`}>Application</h3>
                                </div>

                                <div className={`d-flex flex-column gap-3`}>
                                    <Row>
                                        <Col xs={6}>
                                            <Form.Group className={`d-flex flex-column`}>
                                                <Form.Label>
                                                    Start Date
                                                    <span className={`text-danger`}> *</span>
                                                </Form.Label>
                                                <DatePicker
                                                    className={`form-control`}
                                                    selected={data?.START_DATE}
                                                    onChange={(date) => {
                                                        if (date == null) {
                                                            handleChange("START_DATE", "");
                                                        } else {
                                                            handleChange(
                                                                "START_DATE",
                                                                moment(date).format("YYYY-MM-DD HH:mm:ss")
                                                            )
                                                            // handleChange("END_DATE", "")
                                                        }
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    disabled={editable.applicationDisabled}
                                                    required={true}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Group className={`d-flex flex-column`}>
                                                <Form.Label>
                                                    Start Time
                                                    <span className={`text-danger`}> *</span>
                                                </Form.Label>
                                                <DatePicker
                                                    className={`form-control`}
                                                    selected={convertTimeStringToDate(data?.START_TIME)}
                                                    onChange={(date) => {
                                                        if (!date) return "0000";
                                                        const hours = date.getHours();
                                                        const minutes = date.getMinutes();
                                                        const formattedHours = hours
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const formattedMinutes = minutes
                                                            .toString()
                                                            .padStart(2, "0");
                                                        handleChange(
                                                            "START_TIME",
                                                            `${formattedHours}${formattedMinutes}`
                                                        );
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={1}
                                                    timeCaption="Time"
                                                    dateFormat="HH:mm"
                                                    disabled={editable.applicationDisabled}
                                                    required={true}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Group className={`d-flex flex-column`}>
                                                <Form.Label>End Date
                                                    <span className={`text-danger`}> *</span>
                                                </Form.Label>
                                                <DatePicker
                                                    className={`form-control`}
                                                    selected={data?.END_DATE}
                                                    onChange={(date) => {
                                                        if (date == null) {
                                                            handleChange("END_DATE", "");
                                                        } else {
                                                            handleChange(
                                                                "END_DATE",
                                                                moment(date).format("YYYY-MM-DD HH:mm:ss")
                                                            );
                                                        }
                                                    }}
                                                    minDate={data?.START_DATE}
                                                    dateFormat="dd/MM/yyyy"
                                                    disabled={
                                                        editable.applicationDisabled || !data?.START_DATE
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Group className={`d-flex flex-column`}>
                                                <Form.Label>End Time</Form.Label>
                                                <DatePicker
                                                    className={`form-control`}
                                                    selected={convertTimeStringToDate(data?.END_TIME)}
                                                    onChange={(date) => {
                                                        if (!date) return "0000";
                                                        const hours = date.getHours();
                                                        const minutes = date.getMinutes();
                                                        const formattedHours = hours
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const formattedMinutes = minutes
                                                            .toString()
                                                            .padStart(2, "0");
                                                        handleChange(
                                                            "END_TIME",
                                                            `${formattedHours}${formattedMinutes}`
                                                        );
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={1}
                                                    timeCaption="Time"
                                                    dateFormat="HH:mm"
                                                    disabled={editable.applicationDisabled}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Group className={`d-flex flex-column`}>
                                                <Form.Label>
                                                    View Application Status End Date{" "}
                                                    <span className={`text-danger`}> *</span>
                                                </Form.Label>
                                                <DatePicker
                                                    className={`form-control`}
                                                    selected={data?.VSTATUS_END_DATE}
                                                    onChange={(date) => {
                                                        if (date == null) {
                                                            handleChange("VSTATUS_END_DATE", "");
                                                        } else {
                                                            handleChange(
                                                                "VSTATUS_END_DATE",
                                                                moment(date).format("YYYY-MM-DD HH:mm:ss")
                                                            );
                                                        }
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    disabled={editable.applicationDisabled}
                                                    required={true}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={data?.MULTIPLE_AWARD_PER_AY === "Y"}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "MULTIPLE_AWARD_PER_AY",
                                                            e.target.checked ? "Y" : ""
                                                        )
                                                    }
                                                    id="app-1cb"
                                                    disabled={editable.applicationDisabled}
                                                />
                                                <label className="form-check-label" htmlFor="app-1cb">
                                                    Multiple award per ACAD year
                                                </label>
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={data?.ALLOW_SPONSOR_TAG === "Y"}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "ALLOW_SPONSOR_TAG",
                                                            e.target.checked ? "Y" : ""
                                                        )
                                                    }
                                                    id="app-2cb"
                                                    disabled={editable.applicationDisabled}
                                                />
                                                <label className="form-check-label" htmlFor="app-2cb">
                                                    Applicants with sponsorship
                                                </label>
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={data?.FASOP_TAG === "Y"}
                                                    id="app-3cb"
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "FASOP_TAG",
                                                            e.target.checked ? "Y" : ""
                                                        )
                                                    }
                                                    disabled={editable.applicationDisabled}
                                                />
                                                <label className="form-check-label" htmlFor="app-3cb">
                                                    FASOP application
                                                </label>
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={data?.HOMES_TAG === "Y"}
                                                    id="app-4cb"
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "HOMES_TAG",
                                                            e.target.checked ? "Y" : ""
                                                        )
                                                    }
                                                    disabled={editable.applicationDisabled}
                                                />
                                                <label className="form-check-label" htmlFor="app-4cb">
                                                    HOMES application
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className={`p-3 bg-white shadow-sm`}>
                                <div className={`d-flex justify-content-between`}>
                                    <h3 className={`text-blue fw-bold`}>
                                        Applicant citizenship status
                                    </h3>
                                </div>

                                <div className={`d-flex flex-column`}>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="applicantRad"
                                            id="applicantRad1"
                                            checked={data?.SGCITIZEN_TAG === "Y"}
                                            onChange={(e) => {
                                                handleChange(
                                                    "SGCITIZEN_TAG",
                                                    e.target.checked ? "Y" : ""
                                                );
                                                // handleChange("SGPR_TAG", "");
                                                // handleChange("NONSG_PR_TAG", "");
                                            }}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="applicantRad1">
                                            SG Citizen
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="applicantRad"
                                            id="applicantRad2"
                                            checked={data?.SGPR_TAG === "Y"}
                                            onChange={(e) => {
                                                // handleChange("SGCITIZEN_TAG", "");
                                                handleChange("SGPR_TAG", e.target.checked ? "Y" : "");
                                                // handleChange("NONSG_PR_TAG", "");
                                            }}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="applicantRad2">
                                            SG PR
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="applicantRad"
                                            id="applicantRad3"
                                            checked={data?.NONSG_PR_TAG === "Y"}
                                            onChange={(e) => {
                                                // handleChange("SGCITIZEN_TAG", "");
                                                // handleChange("SGPR_TAG", "");
                                                handleChange(
                                                    "NONSG_PR_TAG",
                                                    e.target.checked ? "Y" : ""
                                                );
                                            }}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="applicantRad3">
                                            Non SG Citizen / PR
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={`p-3 bg-white shadow-sm`}>
                                <div className={`d-flex justify-content-between`}>
                                    <h3 className={`text-blue fw-bold`}>Cutoff Income</h3>
                                </div>

                                <div className={`d-flex flex-column gap-3`}>
                                    <Row>
                                        <Col xs={6}>
                                            <Form.Group>
                                                <Form.Label>GHI</Form.Label>
                                                <Form.Text className="text-muted">
                                                    Gross Household Income
                                                </Form.Text>
                                                <Form.Control
                                                    type={`number`}
                                                    value={data?.CUTOFF_INCOME}
                                                    onChange={(e) =>
                                                        handleChange("CUTOFF_INCOME", e.target.value)
                                                    }
                                                    disabled={editable.applicationDisabled}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Group>
                                                <Form.Label>MPCI</Form.Label>
                                                <Form.Text className="text-muted">
                                                    Monthly Per Capita Income
                                                </Form.Text>
                                                <Form.Control
                                                    type={`number`}
                                                    value={data?.MPCI_INCOME}
                                                    onChange={(e) =>
                                                        handleChange("MPCI_INCOME", e.target.value)
                                                    }
                                                    disabled={editable.applicationDisabled}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12}>
                                            <div className={`text-muted fst-italic mt-3`}>
                                                Meeting either one of the conditions qualifies the
                                                student for eligibility
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className={`p-3 bg-white shadow-sm`}>
                                <div className={`d-flex justify-content-between`}>
                                    <h3 className={`text-blue fw-bold`}>Remarks</h3>
                                </div>

                                <div className={`d-flex flex-column`}>
                                    <Form.Group>
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            value={data?.REMARK}
                                            onChange={(e) => handleChange("REMARK", e.target.value)}
                                            placeholder="write something"
                                            disabled={editable.applicationDisabled}
                                        />
                                    </Form.Group>

                                    <div className={`text-muted fst-italic mt-3`}>
                                        Remarks will be shown in Student Portal
                                    </div>
                                </div>
                            </div>

                            <div className={`p-3 bg-white shadow-sm`}>
                                <h3 className={`text-blue fw-bold`}>
                                    Course
                                </h3>

                                <Form.Text>e.g. %DF% or %UF%</Form.Text>

                                <div className={`d-flex flex-column gap-3`}>
                                    <Row
                                        className={`align-items-center`}
                                        style={{rowGap: "10px"}}
                                    >
                                        {courses?.length > 0 &&
                                            courses.map((course, index) => (
                                                <React.Fragment key={index}>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <Form.Control
                                                                type={"text"}
                                                                value={course?.COURSE_CODE}
                                                                onChange={(e) =>
                                                                    handleChangeCourseCode(index, e.target.value)
                                                                }
                                                                disabled={editable.applicationDisabled}
                                                            />
                                                            {/*<CreatableSelect*/}
                                                            {/*    value={{*/}
                                                            {/*        value: course.COURSE_CODE,*/}
                                                            {/*        label: course.COURSE_CODE,*/}
                                                            {/*    }}*/}
                                                            {/*    options={course.options}*/}
                                                            {/*    isDisabled={editable.applicationDisabled}*/}
                                                            {/*    onCreateOption={(input) => handleCreateCourse(input, index)}*/}
                                                            {/*    onInputChange={(input) =>*/}
                                                            {/*        handleChangeCourseInput(input, index)*/}
                                                            {/*    }*/}
                                                            {/*    onChange={(selectedOption) =>*/}
                                                            {/*        handleChangeCourseCode(index, selectedOption)*/}
                                                            {/*    }*/}
                                                            {/*/>*/}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className={`d-flex gap-3`}>
                                                        {index === 0 && (
                                                            <button
                                                                className={`btn-clear d-flex align-items-center gap-1 ${
                                                                    editable.applicationDisabled
                                                                        ? "text-black-50"
                                                                        : "text-blue"
                                                                }`}
                                                                disabled={editable.applicationDisabled}
                                                                onClick={() => handleAddCourseRow()}
                                                            >
                                                                <i className={`bi bi-plus-circle`}></i>
                                                                add course
                                                            </button>
                                                        )}
                                                        <button
                                                            className={`btn-clear d-flex align-items-center gap-1 ${
                                                                editable.applicationDisabled
                                                                    ? "text-black-50"
                                                                    : "text-red-500"
                                                            }`}
                                                            disabled={editable.applicationDisabled}
                                                            onClick={() => handleRemoveCourse(index)}
                                                        >
                                                            <i className={`bi bi-dash-circle`}></i>
                                                            remove course
                                                        </button>
                                                    </Col>
                                                </React.Fragment>
                                            ))}
                                    </Row>
                                </div>
                            </div>

                            <div className={`p-3 bg-white shadow-sm`}>
                                <h3 className={`text-blue fw-bold`}>Eligible Student</h3>

                                <div className={`d-flex flex-column`}>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="studentRad"
                                            id="studentRad1"
                                            checked={data?.ADM_TAG === null ?? false}
                                            onChange={() => handleChange("ADM_TAG", null)}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="studentRad1">
                                            All Student
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="studentRad"
                                            id="studentRad3"
                                            checked={data?.ADM_TAG === "A" ?? false}
                                            onChange={() => handleChange("ADM_TAG", "A")}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="studentRad3">
                                            Student with scholarship
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="studentRad"
                                            id="studentRad2"
                                            checked={data?.ADM_TAG === "E" ?? false}
                                            onChange={() => handleChange("ADM_TAG", "E")}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="studentRad2">
                                            Eligible student listed
                                            <span className={`text-danger`}> *</span>
                                        </label>
                                    </div>

                                    {data?.ADM_TAG ? (
                                        <div className={`d-flex flex-column gap-2`}>
                                            <div className={`bg-gray p-3`}>
                                                {students?.length > 0 &&
                                                    students?.map((student, index) => (
                                                        <React.Fragment key={index}>
                                                            <Row>
                                                                <Col xs={3}>
                                                                    <Select
                                                                        value={{
                                                                            value: student.ADM_NO,
                                                                            label: student.ADM_NO,
                                                                        }}
                                                                        options={student.options}
                                                                        isDisabled={editable.applicationDisabled}
                                                                        onInputChange={(input) =>
                                                                            handleChangeStudentInput(input, index)
                                                                        }
                                                                        onChange={(selectedOption) =>
                                                                            handleChangeStudent(index, selectedOption)
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        placeholder={"Student name"}
                                                                        value={
                                                                            student?.options?.find(
                                                                                (option) =>
                                                                                    option.value === student.ADM_NO
                                                                            )?.name ||
                                                                            (student.name ?? "-")
                                                                        }
                                                                        disabled={editable.applicationDisabled}
                                                                        readOnly={true}
                                                                    ></Form.Control>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable.applicationDisabled
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable.applicationDisabled}
                                                                        onClick={() => handleRemoveStudent(index)}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove student
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    ))}
                                            </div>

                                            <button
                                                className={`btn-clear d-flex align-items-center gap-1 ${
                                                    editable.applicationDisabled
                                                        ? "text-black-50"
                                                        : "text-blue"
                                                }`}
                                                disabled={editable.applicationDisabled}
                                                onClick={handleAddStudentRow}
                                            >
                                                <i className={`bi bi-plus-circle`}></i>
                                                add student
                                            </button>

                                            <FileUpload
                                                controlId="fileupload1"
                                                onChangeFile={onChangeFile}
                                                selectedFile={selectedFile}
                                                disabled={editable.applicationDisabled}
                                            >
                                                <i className="bi bi-upload me-2"></i>Choose a file
                                            </FileUpload>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
            </sgds-content-body>
            <CustomModal
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                action={handleDelete}
                title={`Delete Timeline`}
                description={`Do you wish to proceed?`}
            />
        </Layout>
    );
}
