import {Accordion} from "@govtechsg/sgds-react/Accordion";
import {Col, Form, Row} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {postAppeal} from "@/apis/app-records/homes/api";
import {toast} from "react-toastify";
import Select from "react-select";

export default function Appeal({
                                   ACK_NO,
                                   ACK_YR,
                                   isDisabled,
                                   applicationDetail,
                                   listMTCRequestResult,
                                   listMTCDetails,
                                   listMTIncomes,
                                   listMTConsents,
                                   listMTCases,
                                   listMTCaseNotes,
                                   handleClickEdit,
                                   familyDetails,
                                   details,
                                   setDetails,
                                   selectedListExercise,
                                   selectedListAdmNo,
                                   consentTypeOptions,
                                   consentScopeOptions,
                                   consentStatusOptions,
                                   legalCapacityOptions,
                                   relationshipOptions,
                                   occupationOptions
                               }) {
    const [copiedItems, setCopiedItems] = useState([]);

    const [counter, setCounter] = useState(0);

    const handleAddFamily = () => {
        const newDetail = {
            ID: "",
            DETAIL_TAG: "P",
            ACK_NO: ACK_NO,
            ACAD_YR: ACK_YR,
            FAAS_EXERCISE: selectedListExercise,
            NBFA_EXERCISE: "",
            ADM_NO: selectedListAdmNo,
            HH_UIN: "",
            HH_NAME: "",
            OTHER_INCOME: "",
            MARITAL_STATUS: "",
            RES_STATUS: "",
            DOB: "",
            GENDER: "",
            MTC_TAG: "A",
            RELATIONSHIP: "",
            SPECIAL_MEANS_STATUS: "",
            ISNOVALIDAUTH: "",
            REMARKS: "",
            MTREQUESTID: "",
            MEANS_TEST_ID: "",
            STATUS_TAG: "",
            CHANGE_TYPE: "",
            INCOME_PERIOD: "",
            AVG_MTHLY_INCOME: "",
            MT_MEMBER_INDICATORS: "",
            OCCUPATION: "",
            REUSABLE_CONSENTID: "",
            REUSABLE_CONSENTSCOPE: "",
            LAST_UPD_ID: sessionStorage.getItem("staffId")
                ? sessionStorage.getItem("staffId")
                : process.env.REACT_APP_LAST_UPD_ID,
            MT_CONSENT_DETAILS: [{
                ID: "",
                DETAIL_TAG: "",
                ACK_NO: "",
                HH_UIN: "",
                CONSENT_TYPE: "",
                CONSENT_SCOPE: "",
                NYP_CONSENT_ID: "",
                NYP_CONSENT_NAME: "",
                NYP_CONSENT_EMAIL: "",
                NYP_CONSENT_STATUS: "",
                NYP_CONSENT_DATE: "",
                NYP_MANUAL_CONSENT: "",
                REC_CREATE_DATE: "",
                LAST_UPD_ID: sessionStorage.getItem('role') ? sessionStorage.getItem('role') : process.env.REACT_APP_LAST_UPD_ID,
                LEGAL_CAPACITY: "",
            }],
        };

        setDetails([...details, newDetail]);
    };

    const handleRemoveFamily = (index, familyID) => {
        if (familyID) {
            return false;
        }

        setDetails((prevState) => {
            return prevState.filter((_, i) => i !== index);
        });
    };

    const handleChange = (detailIndex, field, value, consentIndex = 0) => {
        const updatedDetails = details.map((detail, i) => {
            if (i === detailIndex) {
                if (field.startsWith("MT_CONSENT_DETAILS.")) {
                    const consentField = field.replace("MT_CONSENT_DETAILS.", "");
                    return {
                        ...detail,
                        MT_CONSENT_DETAILS: detail.MT_CONSENT_DETAILS.map((consent, j) => {
                            if (j === consentIndex) {
                                return {
                                    ...consent,
                                    [consentField]: value,
                                };
                            }
                            return consent;
                        }),
                    };
                }
                return {
                    ...detail,
                    [field]: value,
                };
            }
            return detail;
        });

        setDetails(updatedDetails);
    };

    const handleSubmitForm = () => {
        postAppeal(details).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                toast.success("Record updated successfully");
            }
        });
    };

    const handleAddConsent = (index) => {
        const newConsent = {
            ID: "",
            DETAIL_TAG: "",
            ACK_NO: ACK_NO,
            HH_UIN: "",
            CONSENT_TYPE: "",
            CONSENT_SCOPE: "",
            NYP_CONSENT_ID: "",
            NYP_CONSENT_NAME: "",
            NYP_CONSENT_EMAIL: "",
            NYP_CONSENT_STATUS: "",
            NYP_CONSENT_DATE: "",
            NYP_MANUAL_CONSENT: "",
            REC_CREATE_DATE: "",
            LAST_UPD_ID: sessionStorage.getItem("staffId")
                ? sessionStorage.getItem("staffId")
                : process.env.REACT_APP_LAST_UPD_ID,
            LEGAL_CAPACITY: "",
        };
        const updatedDetails = details.map((detail, i) => {
            if (i === index) {
                return {
                    ...detail,
                    MT_CONSENT_DETAILS: [...detail.MT_CONSENT_DETAILS, newConsent],
                };
            }
            return detail;
        });

        setDetails(updatedDetails);
    }

    const handleRemoveConsent = (detailIndex, consentIndex) => {
        const updatedDetails = details.map((detail, i) => {
            if (i === detailIndex) {
                return {
                    ...detail,
                    MT_CONSENT_DETAILS: detail.MT_CONSENT_DETAILS.filter((_, cIndex) => cIndex !== consentIndex),
                };
            }
            return detail;
        });

        setDetails(updatedDetails);
    }

    const handleCopyFamily = () => {
        // Create a deep copy of the array of objects
        const itemsCopy = familyDetails.map((item) => ({...item, DETAIL_TAG: "P"}));

        // Set the copied array of objects to the new state
        setCopiedItems(itemsCopy);

        const combinedArray = [...familyDetails, ...itemsCopy];

        setDetails(combinedArray);
    };

    useEffect(() => {
        // const consentLookup = listMTConsents.reduce((acc, consent) => {
        //   acc[consent.ACK_NO] = {
        //     ID: consent?.ID,
        //     DETAIL_TAG: consent?.DETAIL_TAG,
        //     ACK_NO: consent?.ACK_NO,
        //     HH_UIN: consent?.HH_UIN,
        //     CONSENT_TYPE: consent?.CONSENT_TYPE,
        //     CONSENT_SCOPE: consent?.CONSENT_SCOPE,
        //     NYP_CONSENT_ID: consent?.NYP_CONSENT_ID,
        //     NYP_CONSENT_NAME: consent?.NYP_CONSENT_NAME,
        //     NYP_CONSENT_EMAIL: consent?.NYP_CONSENT_EMAIL,
        //     NYP_CONSENT_STATUS: consent?.NYP_CONSENT_STATUS,
        //     NYP_CONSENT_DATE: consent?.NYP_CONSENT_DATE,
        //     NYP_MANUAL_CONSENT: consent?.NYP_MANUAL_CONSENT,
        //     REC_CREATE_DATE: consent?.REC_CREATE_DATE,
        //     LAST_UPD_ID: consent?.LAST_UPD_ID,
        //     LEGAL_CAPACITY: consent?.LEGAL_CAPACITY,
        //   };
        //   return acc;
        // }, {});
        // const remapDetails = listMTCDetails?.map((detail) => {
        //   const consent = consentLookup[detail.ACK_NO] || {};
        //   return {
        //     ID: detail?.ID,
        //     DETAIL_TAG: detail?.DETAIL_TAG,
        //     ACK_NO: detail?.ACK_NO,
        //     ACAD_YR: detail?.ACAD_YR,
        //     FAAS_EXERCISE: detail?.FAAS_EXERCISE,
        //     NBFA_EXERCISE: detail?.NBFA_EXERCISE,
        //     ADM_NO: detail?.ADM_NO,
        //     HH_UIN: detail?.HH_UIN,
        //     HH_NAME: detail?.HH_NAME,
        //     OTHER_INCOME: detail?.OTHER_INCOME,
        //     MARITAL_STATUS: detail?.MARITAL_STATUS,
        //     RES_STATUS: detail?.RES_STATUS,
        //     DOB: detail?.DOB,
        //     GENDER: detail?.GENDER,
        //     MTC_TAG: detail?.MTC_TAG,
        //     RELATIONSHIP: detail?.RELATIONSHIP,
        //     SPECIAL_MEANS_STATUS: detail?.SPECIAL_MEANS_STATUS,
        //     ISNOVALIDAUTH: detail?.ISNOVALIDAUTH,
        //     REMARKS: detail?.REMARKS,
        //     MTREQUESTID: detail?.MTREQUESTID,
        //     MEANS_TEST_ID: detail?.MEANS_TEST_ID,
        //     STATUS_TAG: detail?.STATUS_TAG,
        //     CHANGE_TYPE: detail?.CHANGE_TYPE,
        //     INCOME_PERIOD: detail?.INCOME_PERIOD,
        //     AVG_MTHLY_INCOME: detail?.AVG_MTHLY_INCOME,
        //     MT_MEMBER_INDICATORS: detail?.MT_MEMBER_INDICATORS,
        //     OCCUPATION: detail?.OCCUPATION,
        //     REUSABLE_CONSENTID: detail?.REUSABLE_CONSENTID,
        //     REUSABLE_CONSENTSCOPE: detail?.REUSABLE_CONSENTSCOPE,
        //     LAST_UPD_ID: detail?.LAST_UPD_ID,
        //     MT_CONSENT_DETAILS: consent,
        //   };
        // });
        // setDetails(remapDetails);
    }, [listMTCDetails, listMTConsents]);

    return (
        <>
            {/*<div className="">*/}
            {/*    <Accordion defaultActiveKey="1" className="">*/}
            {/*        <Accordion.Item eventKey="0" className="shadow border-0">*/}
            {/*            <Accordion.Header className="header-text text-blue">*/}
            {/*                Appeal Details*/}
            {/*            </Accordion.Header>*/}
            {/*            <Accordion.Body>*/}
            {/*                <div>*/}
            {/*                    <Row>*/}
            {/*                        <Col xs={12}>*/}
            {/*                            <Form.Group>*/}
            {/*                                <Form.Label>Appeal Remarks</Form.Label>*/}
            {/*                                <Form.Control*/}
            {/*                                    as="textarea"*/}
            {/*                                    disabled={isDisabled}*/}
            {/*                                ></Form.Control>*/}
            {/*                            </Form.Group>*/}
            {/*                        </Col>*/}
            {/*                    </Row>*/}
            {/*                </div>*/}
            {/*            </Accordion.Body>*/}
            {/*        </Accordion.Item>*/}
            {/*    </Accordion>*/}
            {/*</div>*/}

            {/*
      <div>
        {isDisabled ? (
          <button
            className="bg-white border-1 px-3 rounded-2 "
            onClick={handleClickEdit}
          >
            <i className={`bi bi-pencil`}></i>
            {"  "}Edit
          </button>
        ) : (
          <button
            className="bg-white border-1 px-3 rounded-2 text-green-500"
            onClick={handleSubmitForm}
          >
            <i className={`bi bi-check-lg`}></i>
            {"  "}Save
          </button>
        )}
      </div>

      */}

            <div className="">
                <Accordion defaultActiveKey="1" className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Family Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                {!isDisabled ? (
                                    <Row>
                                        <Col xs={details?.length > 0 ? 12 : 6}>
                                            <button
                                                type={"button"}
                                                className={`btn-clear text-blue`}
                                                onClick={handleAddFamily}
                                            >
                                                <i className={`bi-plus-circle`}></i> Add family
                                            </button>
                                        </Col>
                                        {details?.length > 0 &&
                                        details?.some((content) => content.DETAIL_TAG === "P") ? (
                                            ""
                                        ) : (
                                            <Col xs={6}>
                                                <button
                                                    type={"button"}
                                                    className={`btn-clear text-blue`}
                                                    onClick={() => handleCopyFamily()}
                                                >
                                                    <i className={`bi-plus-circle`}></i> Copy from family
                                                    details tab
                                                </button>
                                            </Col>
                                        )}
                                    </Row>
                                ) : null}

                                {details?.map((content, index) => {
                                    if (content?.DETAIL_TAG === "P") {
                                        return (
                                            <React.Fragment key={index}>
                                                <Row className={`mt-3`}>
                                                    <Col xs={12}>
                                                        <Form.Label>
                                                            Family #{index + 1}{" "}
                                                            <button
                                                                className={`btn-clear`}
                                                                onClick={() =>
                                                                    handleRemoveFamily(index, content?.ID)
                                                                }
                                                            >
                                                                <i className={`bi-trash`}></i>
                                                            </button>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Form.Group>
                                                            <Form.Label>Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={true}
                                                                value={content?.HH_NAME}
                                                                onChange={(e) =>
                                                                    handleChange(index, "HH_NAME", e.target.value)
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                NRIC/Fin/Passport No. (if foreigner)
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={true}
                                                                value={content?.HH_UIN}
                                                                onChange={(e) =>
                                                                    handleChange(index, "HH_UIN", e.target.value)
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group className={`d-flex flex-column`}>
                                                            <Form.Label>Date of Birth</Form.Label>
                                                            <DatePicker
                                                                selected={content?.DOB}
                                                                className={`form-control`}
                                                                onChange={(date) => {
                                                                    if (date == null) {
                                                                        handleChange(index, "DOB", "");
                                                                    } else {
                                                                        handleChange(
                                                                            index,
                                                                            "DOB",
                                                                            moment(date).format("YYYY-MM-DD")
                                                                        );
                                                                    }
                                                                }}
                                                                dateFormat="dd/MM/yyyy"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <Form.Label>Age</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={true}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <Form.Label>Gender </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={true}
                                                                value={content?.GENDER}
                                                                onChange={(e) =>
                                                                    handleChange(index, "GENDER", e.target.value)
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <Form.Label>Relationship Status</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={true}
                                                                value={content?.RELATIONSHIP}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        index,
                                                                        "RELATIONSHIP",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <Form.Label>Residential Status</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={true}
                                                                value={content?.RES_STATUS}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        index,
                                                                        "RES_STATUS",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <Form.Label>Marital Status</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={true}
                                                                value={content?.MARITAL_STATUS}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        index,
                                                                        "MARITAL_STATUS",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className={`mt-3`}>
                                                    <Col xs={12}>
                                                        <Form.Group>
                                                            <Form.Label>Reusable Consent</Form.Label>
                                                            <Form.Text>
                                                                If no, please specify Consent NRIC and Name)
                                                            </Form.Text>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={true}
                                                                value={content?.REUSABLE_CONSENTSCOPE}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        index,
                                                                        "REUSABLE_CONSENTSCOPE",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <Form.Label>Reusable Consent ID</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={true}
                                                                value={content?.REUSABLE_CONSENTID}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        index,
                                                                        "REUSABLE_CONSENTID",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <Form.Label>Reusable Consent Scope</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={true}
                                                                value={content?.REUSABLE_CONSENTSCOPE}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        index,
                                                                        "REUSABLE_CONSENTSCOPE",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <Form.Label>Is No Valid Auth</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={true}
                                                                value={content?.ISNOVALIDAUTH}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        index,
                                                                        "ISNOVALIDAUTH",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <h5 className={`mt-5`}>Consent Detail</h5>

                                                    <div>
                                                        <button
                                                            className={`btn-clear text-blue`}
                                                            onClick={() => handleAddConsent(index)}
                                                            disabled={isDisabled}
                                                        >
                                                            <i className={`bi-plus-circle`}></i> Add consent detail
                                                        </button>
                                                    </div>

                                                    {content?.MT_CONSENT_DETAILS?.map((condetail, detailIndex) => (
                                                        <Row key={detailIndex}>
                                                            <Col xs={12}>
                                                                Consent Detail #{detailIndex + 1}
                                                                <button
                                                                    className={`btn-clear text-red-500`}
                                                                    onClick={() => handleRemoveConsent(index, detailIndex)}
                                                                    disabled={isDisabled}
                                                                >
                                                                    <i className={`bi-dash-circle`}></i> Remove consent detail
                                                                </button>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Consent Type</Form.Label>
                                                                    <Select
                                                                        isDisabled={isDisabled}
                                                                        options={consentTypeOptions}
                                                                        value={consentTypeOptions.find(option => option.value === condetail?.CONSENT_TYPE)}
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                index,
                                                                                "MT_CONSENT_DETAILS.CONSENT_TYPE",
                                                                                e.value,
                                                                                detailIndex
                                                                            )
                                                                        }
                                                                    />
                                                                    {/*<Form.Control*/}
                                                                    {/*    type={`text`}*/}
                                                                    {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                    {/*    value={detail?.MT_CONSENT_DETAILS?.CONSENT_TYPE}*/}
                                                                    {/*    onChange={(e) =>*/}
                                                                    {/*        handleChange(*/}
                                                                    {/*            index,*/}
                                                                    {/*            "MT_CONSENT_DETAILS.CONSENT_TYPE",*/}
                                                                    {/*            e.target.value*/}
                                                                    {/*        )*/}
                                                                    {/*    }*/}
                                                                    {/*></Form.Control>*/}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Consent Scope</Form.Label>
                                                                    <Select
                                                                        isDisabled={isDisabled}
                                                                        options={consentScopeOptions}
                                                                        value={consentScopeOptions.find(option => option.value === condetail?.CONSENT_SCOPE)}
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                index,
                                                                                "MT_CONSENT_DETAILS.CONSENT_SCOPE",
                                                                                e.value,
                                                                                detailIndex
                                                                            )
                                                                        }
                                                                    />
                                                                    {/*<Form.Control*/}
                                                                    {/*    type={`text`}*/}
                                                                    {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                    {/*    value={detail?.MT_CONSENT_DETAILS?.CONSENT_SCOPE}*/}
                                                                    {/*    onChange={(e) =>*/}
                                                                    {/*        handleChange(*/}
                                                                    {/*            index,*/}
                                                                    {/*            "MT_CONSENT_DETAILS.CONSENT_SCOPE",*/}
                                                                    {/*            e.target.value*/}
                                                                    {/*        )*/}
                                                                    {/*    }*/}
                                                                    {/*></Form.Control>*/}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Legal Capacity</Form.Label>
                                                                    <Select
                                                                        isDisabled={isDisabled}
                                                                        options={legalCapacityOptions}
                                                                        value={legalCapacityOptions.find(option => option.value === condetail?.LEGAL_CAPACITY)}
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                index,
                                                                                "MT_CONSENT_DETAILS.LEGAL_CAPACITY",
                                                                                e.value,
                                                                                detailIndex
                                                                            )
                                                                        }
                                                                    />
                                                                    {/*<Form.Control*/}
                                                                    {/*    type={`text`}*/}
                                                                    {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                    {/*    value={*/}
                                                                    {/*        detail?.MT_CONSENT_DETAILS?.LEGAL_CAPACITY*/}
                                                                    {/*    }*/}
                                                                    {/*    onChange={(e) =>*/}
                                                                    {/*        handleChange(*/}
                                                                    {/*            index,*/}
                                                                    {/*            "MT_CONSENT_DETAILS.LEGAL_CAPACITY",*/}
                                                                    {/*            e.target.value*/}
                                                                    {/*        )*/}
                                                                    {/*    }*/}
                                                                    {/*></Form.Control>*/}
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Manual Consent</Form.Label>
                                                                    <Form.Control
                                                                        type={`text`}
                                                                        disabled={isDisabled}
                                                                        value={
                                                                            condetail?.NYP_MANUAL_CONSENT
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                index,
                                                                                "MT_CONSENT_DETAILS.NYP_MANUAL_CONSENT",
                                                                                e.target.value,
                                                                                detailIndex
                                                                            )
                                                                        }
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Consent NRIC</Form.Label>
                                                                    <Form.Control
                                                                        type={`text`}
                                                                        disabled={isDisabled}
                                                                        value={
                                                                            condetail?.NYP_CONSENT_ID
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                index,
                                                                                "MT_CONSENT_DETAILS.NYP_CONSENT_ID",
                                                                                e.target.value,
                                                                                detailIndex
                                                                            )
                                                                        }
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Consent Name</Form.Label>
                                                                    <Form.Control
                                                                        type={`text`}
                                                                        disabled={isDisabled}
                                                                        value={
                                                                            condetail?.NYP_CONSENT_NAME
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                index,
                                                                                "MT_CONSENT_DETAILS.NYP_CONSENT_NAME",
                                                                                e.target.value,
                                                                                detailIndex
                                                                            )
                                                                        }
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Consent Email</Form.Label>
                                                                    <Form.Control
                                                                        type={`email`}
                                                                        disabled={isDisabled}
                                                                        value={
                                                                            condetail?.NYP_CONSENT_EMAIL
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                index,
                                                                                "MT_CONSENT_DETAILS.NYP_CONSENT_EMAIL",
                                                                                e.target.value,
                                                                                detailIndex
                                                                            )
                                                                        }
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Consent Status</Form.Label>
                                                                    <Select
                                                                        isDisabled={isDisabled}
                                                                        options={consentStatusOptions}
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                index,
                                                                                "MT_CONSENT_DETAILS.NYP_CONSENT_STATUS",
                                                                                e.value,
                                                                                detailIndex
                                                                            )
                                                                        }
                                                                    />
                                                                    {/*<Form.Control*/}
                                                                    {/*    type={`text`}*/}
                                                                    {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                    {/*    value={*/}
                                                                    {/*        detail?.MT_CONSENT_DETAILS?.NYP_CONSENT_STATUS*/}
                                                                    {/*    }*/}
                                                                    {/*    onChange={(e) =>*/}
                                                                    {/*        handleChange(*/}
                                                                    {/*            index,*/}
                                                                    {/*            "MT_CONSENT_DETAILS.NYP_CONSENT_STATUS",*/}
                                                                    {/*            e.target.value*/}
                                                                    {/*        )*/}
                                                                    {/*    }*/}
                                                                    {/*></Form.Control>*/}
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Consent Date</Form.Label>
                                                                    <Form.Control
                                                                        type={`text`}
                                                                        disabled={isDisabled}
                                                                        value={
                                                                            condetail?.NYP_CONSENT_DATE
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                index,
                                                                                "MT_CONSENT_DETAILS.NYP_CONSENT_DATE",
                                                                                e.target.value,
                                                                                detailIndex
                                                                            )
                                                                        }
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    ))}

                                                    {/*<Col xs={6}>*/}
                                                    {/*    <Form.Group>*/}
                                                    {/*        <Form.Label>Consent Type</Form.Label>*/}
                                                    {/*        <Form.Control*/}
                                                    {/*            type="text"*/}
                                                    {/*            disabled={isDisabled}*/}
                                                    {/*            value={*/}
                                                    {/*                content?.MT_CONSENT_DETAILS?.CONSENT_TYPE*/}
                                                    {/*            }*/}
                                                    {/*            onChange={(e) =>*/}
                                                    {/*                handleChange(*/}
                                                    {/*                    index,*/}
                                                    {/*                    "MT_CONSENT_DETAILS.CONSENT_TYPE",*/}
                                                    {/*                    e.target.value*/}
                                                    {/*                )*/}
                                                    {/*            }*/}
                                                    {/*        ></Form.Control>*/}
                                                    {/*    </Form.Group>*/}
                                                    {/*</Col>*/}
                                                    {/*<Col xs={6}>*/}
                                                    {/*    <Form.Group>*/}
                                                    {/*        <Form.Label>Consent Scope</Form.Label>*/}
                                                    {/*        <Form.Control*/}
                                                    {/*            type="text"*/}
                                                    {/*            disabled={isDisabled}*/}
                                                    {/*            value={*/}
                                                    {/*                content?.MT_CONSENT_DETAILS?.CONSENT_SCOPE*/}
                                                    {/*            }*/}
                                                    {/*            onChange={(e) =>*/}
                                                    {/*                handleChange(*/}
                                                    {/*                    index,*/}
                                                    {/*                    "MT_CONSENT_DETAILS.CONSENT_SCOPE",*/}
                                                    {/*                    e.target.value*/}
                                                    {/*                )*/}
                                                    {/*            }*/}
                                                    {/*        ></Form.Control>*/}
                                                    {/*    </Form.Group>*/}
                                                    {/*</Col>*/}
                                                    {/*<Col xs={6}>*/}
                                                    {/*    <Form.Group>*/}
                                                    {/*        <Form.Label>Legal Capacity</Form.Label>*/}
                                                    {/*        <Form.Control*/}
                                                    {/*            type="text"*/}
                                                    {/*            disabled={isDisabled}*/}
                                                    {/*            value={*/}
                                                    {/*                content?.MT_CONSENT_DETAILS?.LEGAL_CAPACITY*/}
                                                    {/*            }*/}
                                                    {/*            onChange={(e) =>*/}
                                                    {/*                handleChange(*/}
                                                    {/*                    index,*/}
                                                    {/*                    "MT_CONSENT_DETAILS.LEGAL_CAPACITY",*/}
                                                    {/*                    e.target.value*/}
                                                    {/*                )*/}
                                                    {/*            }*/}
                                                    {/*        ></Form.Control>*/}
                                                    {/*    </Form.Group>*/}
                                                    {/*</Col>*/}
                                                    {/*<Col xs={6}>*/}
                                                    {/*    <Form.Group>*/}
                                                    {/*        <Form.Label>Manual Consent</Form.Label>*/}
                                                    {/*        <Form.Control*/}
                                                    {/*            type="text"*/}
                                                    {/*            disabled={isDisabled}*/}
                                                    {/*            value={*/}
                                                    {/*                content?.MT_CONSENT_DETAILS*/}
                                                    {/*                    ?.NYP_MANUAL_CONSENT*/}
                                                    {/*            }*/}
                                                    {/*            onChange={(e) =>*/}
                                                    {/*                handleChange(*/}
                                                    {/*                    index,*/}
                                                    {/*                    "MT_CONSENT_DETAILS.NYP_MANUAL_CONSENT",*/}
                                                    {/*                    e.target.value*/}
                                                    {/*                )*/}
                                                    {/*            }*/}
                                                    {/*        ></Form.Control>*/}
                                                    {/*    </Form.Group>*/}
                                                    {/*</Col>*/}
                                                    {/*<Col xs={6}>*/}
                                                    {/*    <Form.Group>*/}
                                                    {/*        <Form.Label>Consent NRIC</Form.Label>*/}
                                                    {/*        <Form.Control*/}
                                                    {/*            type="text"*/}
                                                    {/*            disabled={isDisabled}*/}
                                                    {/*            value={*/}
                                                    {/*                content?.MT_CONSENT_DETAILS?.NYP_CONSENT_ID*/}
                                                    {/*            }*/}
                                                    {/*            onChange={(e) =>*/}
                                                    {/*                handleChange(*/}
                                                    {/*                    index,*/}
                                                    {/*                    "MT_CONSENT_DETAILS.NYP_CONSENT_ID",*/}
                                                    {/*                    e.target.value*/}
                                                    {/*                )*/}
                                                    {/*            }*/}
                                                    {/*        ></Form.Control>*/}
                                                    {/*    </Form.Group>*/}
                                                    {/*</Col>*/}
                                                    {/*<Col xs={6}>*/}
                                                    {/*    <Form.Group>*/}
                                                    {/*        <Form.Label>Consent Name</Form.Label>*/}
                                                    {/*        <Form.Control*/}
                                                    {/*            type="text"*/}
                                                    {/*            disabled={isDisabled}*/}
                                                    {/*            value={*/}
                                                    {/*                content?.MT_CONSENT_DETAILS?.NYP_CONSENT_NAME*/}
                                                    {/*            }*/}
                                                    {/*            onChange={(e) =>*/}
                                                    {/*                handleChange(*/}
                                                    {/*                    index,*/}
                                                    {/*                    "MT_CONSENT_DETAILS.NYP_CONSENT_NAME",*/}
                                                    {/*                    e.target.value*/}
                                                    {/*                )*/}
                                                    {/*            }*/}
                                                    {/*        ></Form.Control>*/}
                                                    {/*    </Form.Group>*/}
                                                    {/*</Col>*/}
                                                    {/*<Col xs={6}>*/}
                                                    {/*    <Form.Group>*/}
                                                    {/*        <Form.Label>Consent Status</Form.Label>*/}
                                                    {/*        <Form.Control*/}
                                                    {/*            type="text"*/}
                                                    {/*            disabled={isDisabled}*/}
                                                    {/*            value={*/}
                                                    {/*                content?.MT_CONSENT_DETAILS*/}
                                                    {/*                    ?.NYP_CONSENT_STATUS*/}
                                                    {/*            }*/}
                                                    {/*            onChange={(e) =>*/}
                                                    {/*                handleChange(*/}
                                                    {/*                    index,*/}
                                                    {/*                    "MT_CONSENT_DETAILS.NYP_CONSENT_STATUS",*/}
                                                    {/*                    e.target.value*/}
                                                    {/*                )*/}
                                                    {/*            }*/}
                                                    {/*        ></Form.Control>*/}
                                                    {/*    </Form.Group>*/}
                                                    {/*</Col>*/}
                                                    {/*<Col xs={6}>*/}
                                                    {/*    <Form.Group className={`d-flex flex-column`}>*/}
                                                    {/*        <Form.Label>Consent Date</Form.Label>*/}
                                                    {/*        <DatePicker*/}
                                                    {/*            selected={*/}
                                                    {/*                content?.MT_CONSENT_DETAILS?.NYP_CONSENT_DATE*/}
                                                    {/*            }*/}
                                                    {/*            className={`form-control`}*/}
                                                    {/*            onChange={(date) => {*/}
                                                    {/*                if (date == null) {*/}
                                                    {/*                    handleChange(*/}
                                                    {/*                        index,*/}
                                                    {/*                        "MT_CONSENT_DETAILS.NYP_CONSENT_DATE",*/}
                                                    {/*                        ""*/}
                                                    {/*                    );*/}
                                                    {/*                } else {*/}
                                                    {/*                    handleChange(*/}
                                                    {/*                        index,*/}
                                                    {/*                        "MT_CONSENT_DETAILS.NYP_CONSENT_DATE",*/}
                                                    {/*                        moment(date).format("YYYY-MM-DD")*/}
                                                    {/*                    );*/}
                                                    {/*                }*/}
                                                    {/*            }}*/}
                                                    {/*            dateFormat="dd/MM/yyyy"*/}
                                                    {/*            showMonthDropdown*/}
                                                    {/*            showYearDropdown*/}
                                                    {/*            disabled={isDisabled}*/}
                                                    {/*        />*/}
                                                    {/*    </Form.Group>*/}
                                                    {/*</Col>*/}
                                                    <Col xs={12}>
                                                        <Form.Group>
                                                            <Form.Label>Remarks</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled={isDisabled}
                                                                value={content?.REMARKS}
                                                                onChange={(e) =>
                                                                    handleChange(index, "REMARKS", e.target.value)
                                                                }
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        );
                                    }
                                })}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}
