import React from "react";
import axios from "axios";

export function getSiblings(acadYear, exercise, adm_no) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/siblings?ACAD_YR=${acadYear}&EXERCISE=${exercise}&ADM_NO=${adm_no}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateSiblings(params) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/siblings`,
      params
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteSiblings(id) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/siblings/${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
