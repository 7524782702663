import React from "react";
import axios from "axios";

export function getParents(acadYear, exercise, adm_no) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/parents?ACAD_YR=${acadYear}&EXERCISE=${exercise}&ADM_NO=${adm_no}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function updateParents(params) {
  return axios
    .patch(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/parents`,
      params
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
