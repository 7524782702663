import axios from "axios";

/*

Code Type:
* DG = Dialect
* HT = Housing Type
* MS = Marital Status
* RS = RelationShip
* */

// {
//     "CODE_TYPE": "DG",
//     "CODE": "HA",
//     "DESCRIPTION": "hakka",
//     "CONTENT1": null,
//     "INFO1": null,
//     "INFO2": null
// }

export async function getCodeList(codeType, limit=20, page) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/codes?CODE_TYPE=${codeType}&limit=${limit}&page=${page}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function postCode(CodeData) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/codes`,
      CodeData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function putCode(CodeID, CodeData) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/codes/${CodeID}`,
      CodeData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteCode(selectedId) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/codes/${selectedId}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function postOverwriteCode(CodeData) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/codes/overwrite`,
      CodeData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function postAppendCode(CodeData) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/codes/append`,
      CodeData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
