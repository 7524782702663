import axios from "axios";

// {
//     "AWARD_TYPE": "MMM",
//     "AWARD": "singaporeI",
//     "QUANTUM": 20000
// }

export async function getAwardList(limit = 0, page = 1) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/awards/details?limit=${limit}&page=${page}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function getAwardListWithParams(awardType, award) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/awards/details?AWARD_TYPE=${awardType}&AWARD=${award}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function postAward(awardData) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/awards/details`,
      awardData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function putAward(awardID, awardData) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/awards/details/${awardID}`,
      awardData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteAward(selectedId) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/awards/details/${selectedId}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
