import axios from "axios";

// {
//     "CODE_TYPE": "DG",
//     "CODE": "HA",
//     "DESCRIPTION": "hakka",
//     "CONTENT1": null,
//     "INFO1": null,
//     "INFO2": null
// }

export async function getComcareList(adminNo, schemeName,limit, page) {
  return axios
    .get(
      `${process.env.REACT_APP_API_HOMES_URL}/api/v1/homes/comcares?ADM_NO=${adminNo}&SCHEMES_NAME=${schemeName}&limit=${limit}&page=${page}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function postComcare(CodeData) {
  return axios
    .post(
      `${process.env.REACT_APP_API_HOMES_URL}/api/v1/homes/comcares`,
      CodeData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function putComcare(CodeID, CodeData) {
  return axios
    .put(
      `${process.env.REACT_APP_API_HOMES_URL}/api/v1/homes/comcares/${CodeID}`,
      CodeData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteComcare(selectedId) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_HOMES_URL}/api/v1/homes/comcares/${selectedId}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function postAppendComcare(jsonData) {
  return axios
    .post(
      `${process.env.REACT_APP_API_HOMES_URL}/api/v1/homes/comcares/append`,
      {
        DATA: jsonData,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function postOverwriteComcare(jsonData) {
  return axios
    .post(
      `${process.env.REACT_APP_API_HOMES_URL}/api/v1/homes/comcares/overwrite`,
      {
        DATA: jsonData,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
