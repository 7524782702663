import { Accordion } from "@govtechsg/sgds-react/Accordion";
import { Col, Form, Row, Button } from "@govtechsg/sgds-react";
import React, { useEffect, useState } from "react";
import {
  submitApplicationDetail,
  upsertScholarship,
} from "@/apis/app-records/maintenance/api";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

export default function ScholarshipDetails({
  isDisabled,
  applicationDetail,
  listMTCRequestResult,
  listMTCDetails,
  listMTIncomes,
  listMTConsents,
  listMTCases,
  listMTCaseNotes,
  listOriginalDetails,
  studentDetail,
  handleClickEdit,
  handleChange,
  handleChangeListScholarship,
  scholarshipDetail,
  setScholarshipDetail,
  submitToScholarships,
}) {
  const [appDetail, setAppDetail] = useState({});
  const [schoDetail, setSchoDetail] = useState({});
  const [applyingScholar, setApplyingScholar] = useState(false);
  const [applyingGovBursary, setApplyingGovBursary] = useState(false);

  const handleChangeApp = (stateName, field, value) => {
    if (stateName === "appDetail") {
      setAppDetail((prev) => ({
        ...prev,
        [field]: value,
      }));
    } else if (stateName === "schoDetail") {
      setSchoDetail((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleAddNewScholarship = (rec_tag) => {
    setScholarshipDetail([
      ...scholarshipDetail,
      {
        SCHOLAR_SPONSOR: "",
        TUTFEE_C_TAG: "",
        L_ALLOWANCE_C_TAG: "",
        L_ALLOWANCE_C_DETAILS: "0",
        L_ALLOWANCE_C_TYPE: "",
        OTHERS_C_TAG: "",
        OTHERS_C_DETAILS: "",
        GOVBUR_PERIOD: "",
        GOVBUR_QUANTUM: "",
        REC_TAG: rec_tag,
      },
    ]);
  };

  const handleSubmitForm = () => {
    const dataApplication = {
      ID: appDetail.ID,
      CHARGE_IN_COURT: appDetail.CHARGE_IN_COURT,
      REMARKS1: appDetail.REMARKS1,
      REMARK: appDetail.REMARK,
      CPF_APPROVED_TAG: appDetail.CPF_APPROVED_TAG,
      INTACT_FAMILY_TAG: appDetail.INTACT_FAMILY_TAG,
      COMP_SPONSOR_TAG: appDetail.COMP_SPONSOR_TAG,
      MOE_SUBSIDISED: appDetail.MOE_SUBSIDISED,
    };

    const dataScholarship = {
      ID: schoDetail.ID,
      SCHOLAR_SPONSOR: schoDetail.SCHOLAR_SPONSOR,
      TUTFEE_C_TAG: schoDetail.TUTFEE_C_TAG,
      L_ALLOWANCE_C_TAG: schoDetail.L_ALLOWANCE_C_TAG,
      L_ALLOWANCE_C_DETAILS: schoDetail.L_ALLOWANCE_C_DETAILS,
      L_ALLOWANCE_C_TYPE: schoDetail.L_ALLOWANCE_C_TYPE,
      OTHERS_C_TAG: schoDetail.OTHERS_C_TAG,
      OTHERS_C_DETAILS: schoDetail.OTHERS_C_DETAILS,
    };

    /*
    submitApplicationDetail(dataApplication)
      .then((resp) => {
        if (resp?.response?.data?.message) {
          if (
            resp?.response?.data?.message?.length > 0 &&
            Array.isArray(resp?.response?.data?.message)
          ) {
            resp?.response?.data?.message?.map((contentErr) => {
              toast.error(contentErr?.msg);
            });
          } else {
            toast.error(resp?.response?.data?.message);
          }
        } else if (resp?.response?.status == 404) {
          toast.error("Data not found");
        } else {
          toast.success("Record updated successfully");
        }
      })
      .catch((error) => {
        console.log(error);
      });
      */

    upsertScholarship(dataScholarship)
      .then((resp) => {
        if (resp?.response?.data?.message) {
          if (
            resp?.response?.data?.message?.length > 0 &&
            Array.isArray(resp?.response?.data?.message)
          ) {
            resp?.response?.data?.message?.map((contentErr) => {
              toast.error(contentErr?.msg);
            });
          } else {
            toast.error(resp?.response?.data?.message);
          }
        } else if (resp?.response?.status == 404) {
          toast.error("Data not found");
        } else {
          toast.success("Record updated successfully");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setSchoDetail(scholarshipDetail);

    if (Object.keys(scholarshipDetail || {}).length > 0) {
      setApplyingScholar(true);
    } else {
      setApplyingScholar(false);
    }
  }, [applicationDetail, scholarshipDetail]);

  return (
    <>
      {/* 
      <div>
        {isDisabled ? (
          <button
            className="bg-white border-1 px-3 rounded-2 "
            onClick={handleClickEdit}
          >
            <i className={`bi bi-pencil`}></i>
            {"  "}Edit
          </button>
        ) : (
          <button
            className="bg-white border-1 px-3 rounded-2 text-green-500"
            onClick={handleSubmitForm}
          >
            <i className={`bi bi-check-lg`}></i>
            {"  "}Save
          </button>
        )}
      </div>
    
    */}

      <div className="">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Scholarship
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Sponsor (NYP)</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={studentDetail?.sponsorNypDesc}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Tuition Fees</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={studentDetail?.tutFeeLoan}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Mendaki tertiary Fees Subsidiary</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={studentDetail?.mendakiSubsidy}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Text>
                        I am applying / receiving a scholarship or sponsorship
                        in the current academic year
                      </Form.Text>
                      <Form.Check
                        id="option-1"
                        label="Yes"
                        disabled={isDisabled}
                        checked={applyingScholar}
                        onChange={() => setApplyingScholar(true)}
                      ></Form.Check>
                      <Form.Check
                        id="option-2"
                        label="No"
                        disabled={isDisabled}
                        checked={!applyingScholar}
                        onChange={() => setApplyingScholar(false)}
                      ></Form.Check>
                    </Form.Group>
                  </Col>
                  {applyingScholar ? (
                    <>
                      <div>
                        <Button
                          type="button"
                          variant="btn btn-link ps-0 text-decoration-none"
                          onClick={() => handleAddNewScholarship("")}
                        >
                          <i class="bi bi-plus-circle"></i> {"  "} Add
                          Scholarship
                        </Button>
                      </div>

                      {scholarshipDetail?.length > 0
                        ? scholarshipDetail?.map((content, index) => {
                            if (content?.REC_TAG != "B") {
                              return (
                                <React.Fragment>
                                  <Col xs={12}>
                                    <Form.Label>
                                      #{index + 1} Scholar
                                    </Form.Label>
                                  </Col>
                                  <Col xs={12}>
                                    <Form.Group>
                                      <Form.Label>
                                        Name of Scholarship / Sponsorship
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        disabled={isDisabled}
                                        value={content?.SCHOLAR_SPONSOR}
                                        onChange={(e) => {
                                          handleChangeListScholarship(
                                            "SCHOLAR_SPONSOR",
                                            e.target.value,
                                            index
                                          );
                                        }}
                                      ></Form.Control>
                                    </Form.Group>
                                  </Col>

                                  <Col xs={12}>
                                    <Form.Group>
                                      <Form.Label>Facilities</Form.Label>
                                      <Form.Check
                                        id="option-3"
                                        label="Tuition Fees"
                                        disabled={isDisabled}
                                        checked={content?.TUTFEE_C_TAG === "Y"}
                                        onChange={(e) => {
                                          handleChangeListScholarship(
                                            "TUTFEE_C_TAG",
                                            e.target.checked ? "Y" : "",
                                            index
                                          );
                                        }}
                                      ></Form.Check>
                                    </Form.Group>

                                    <Form.Check
                                      id="option-4"
                                      label="Living Allowance"
                                      disabled={isDisabled}
                                      checked={
                                        content?.L_ALLOWANCE_C_TAG === "Y"
                                      }
                                      onChange={(e) => {
                                        handleChangeListScholarship(
                                          "L_ALLOWANCE_C_TAG",
                                          e.target.checked ? "Y" : "",
                                          index
                                        );
                                      }}
                                    ></Form.Check>

                                    {content?.L_ALLOWANCE_C_TAG === "Y" ? (
                                      <Row className={`ms-4`}>
                                        <Col xs={6}>
                                          <Form.Group>
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control
                                              type="text"
                                              disabled={isDisabled}
                                              value={
                                                content?.L_ALLOWANCE_C_DETAILS
                                              }
                                              onChange={(e) => {
                                                handleChangeListScholarship(
                                                  "L_ALLOWANCE_C_DETAILS",
                                                  e.target.value,
                                                  index
                                                );
                                              }}
                                            ></Form.Control>
                                          </Form.Group>
                                        </Col>
                                        <Col xs={6}>
                                          <Form.Group>
                                            <Form.Label>Type</Form.Label>
                                            <Form.Control
                                              type="text"
                                              disabled={isDisabled}
                                              value={
                                                content?.L_ALLOWANCE_C_TYPE
                                              }
                                              onChange={(e) => {
                                                handleChangeListScholarship(
                                                  "L_ALLOWANCE_C_TYPE",
                                                  e.target.value,
                                                  index
                                                );
                                              }}
                                            ></Form.Control>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    ) : null}

                                    <Form.Check
                                      id="option-5"
                                      label="Others (please specify)"
                                      disabled={isDisabled}
                                      checked={content?.OTHERS_C_TAG === "Y"}
                                      onChange={(e) => {
                                        handleChangeListScholarship(
                                          "OTHERS_C_TAG",
                                          e.target.checked ? "Y" : "",
                                          index
                                        );
                                      }}
                                    ></Form.Check>
                                    {content?.OTHERS_C_TAG === "Y" ? (
                                      <Row className={`ms-4`}>
                                        <Col xs={12}>
                                          <Form.Group>
                                            <Form.Label>
                                              Other coverage Details
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              disabled={isDisabled}
                                              value={content?.OTHERS_C_DETAILS}
                                              onChange={(e) => {
                                                handleChangeListScholarship(
                                                  "OTHERS_C_DETAILS",
                                                  e.target.value,
                                                  index
                                                );
                                              }}
                                            ></Form.Control>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    ) : null}
                                  </Col>
                                </React.Fragment>
                              );
                            }
                          })
                        : ""}
                    </>
                  ) : null}
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Government Bursary
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Text>
                        I am receiving a government bursary in the current
                        academic year
                      </Form.Text>
                      <Form.Check
                        id="option-gov-1"
                        label="Yes"
                        disabled={isDisabled}
                        checked={applyingGovBursary}
                        onChange={() => setApplyingGovBursary(true)}
                      ></Form.Check>
                      <Form.Check
                        id="option-gov-2"
                        label="No"
                        disabled={isDisabled}
                        checked={!applyingGovBursary}
                        onChange={() => setApplyingGovBursary(false)}
                      ></Form.Check>
                    </Form.Group>
                  </Col>
                  {applyingGovBursary ? (
                    <>
                      <div>
                        <Button
                          type="button"
                          variant="btn btn-link ps-0 text-decoration-none"
                          onClick={() => handleAddNewScholarship("B")}
                        >
                          <i class="bi bi-plus-circle"></i> {"  "} Add Gov
                          Bursary
                        </Button>
                      </div>

                      {scholarshipDetail?.length > 0
                        ? scholarshipDetail?.map((content, index) => {
                            if (content?.REC_TAG == "B") {
                              return (
                                <React.Fragment>
                                  <Col xs={12}>
                                    <Form.Label>
                                      #{index + 1} Government Bursary
                                    </Form.Label>
                                  </Col>
                                  <Col xs={12}>
                                    <Form.Group>
                                      <Form.Label>
                                        Name of Government Bursary
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        disabled={isDisabled}
                                        value={content?.SCHOLAR_SPONSOR}
                                        onChange={(e) => {
                                          handleChangeListScholarship(
                                            "SCHOLAR_SPONSOR",
                                            e.target.value,
                                            index
                                          );
                                        }}
                                      ></Form.Control>
                                    </Form.Group>
                                  </Col>
                                  <Col xs={6}>
                                    <Form.Group>
                                      {" "}
                                      <Form.Label>Quantum</Form.Label>
                                      <Form.Control
                                        type={`text`}
                                        disabled={isDisabled}
                                        value={content?.GOVBUR_QUANTUM}
                                        onChange={(e) => {
                                          handleChangeListScholarship(
                                            "GOVBUR_QUANTUM",
                                            e.target.value,
                                            index
                                          );
                                        }}
                                      ></Form.Control>
                                    </Form.Group>
                                  </Col>
                                  <Col xs={6}>
                                    <Form.Group
                                      className={`d-flex flex-column`}
                                    >
                                      <Form.Label>Period</Form.Label>
                                      <DatePicker
                                        selected={content?.GOVBUR_PERIOD}
                                        className={`form-control`}
                                        onChange={(date) => {
                                          if (date == null) {
                                            handleChangeListScholarship(
                                              "GOVBUR_PERIOD",
                                              "",
                                              index
                                            );
                                          } else {
                                            handleChangeListScholarship(
                                              "GOVBUR_PERIOD",
                                              moment(date).format("YYYY-MM-DD"),
                                              index
                                            );
                                          }
                                        }}
                                        dateFormat="MM-YYYY"
                                        showMonthDropdown
                                        showYearDropdown
                                        disabled={isDisabled}
                                      />
                                    </Form.Group>
                                  </Col>
                                </React.Fragment>
                              );
                            }
                          })
                        : ""}
                    </>
                  ) : null}
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Charged-in-court
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Check
                      label={`Charged in a court of law in any country`}
                      id={`cic-0`}
                      checked={applicationDetail?.CHARGE_IN_COURT === "Y"}
                      onChange={(e) =>
                        handleChange(
                          "CHARGE_IN_COURT",
                          e.target.checked ? "Y" : ""
                        )
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Application Details
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control
                        as="textarea"
                        disabled={isDisabled}
                        value={applicationDetail?.REMARK}
                        onChange={(e) => handleChange("REMARK", e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Other Details
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Check
                      label={`CPF Approved Funds`}
                      id={`cic-1`}
                      checked={applicationDetail?.CPF_APPROVED_TAG === "Y"}
                      onChange={(e) =>
                        handleChange(
                          "CPF_APPROVED_TAG",
                          e.target.checked ? "Y" : ""
                        )
                      }
                    />
                    <Form.Check
                      label={`Intact Family`}
                      id={`cic-2`}
                      checked={applicationDetail?.INTACT_FAMILY_TAG === "Y"}
                      onChange={(e) =>
                        handleChange(
                          "INTACT_FAMILY_TAG",
                          e.target.checked ? "Y" : ""
                        )
                      }
                    />
                    <Form.Check
                      label={`Company Sponsored`}
                      id={`cic-3`}
                      checked={applicationDetail?.COMP_SPONSOR_TAG === "Y"}
                      onChange={(e) =>
                        handleChange(
                          "COMP_SPONSOR_TAG",
                          e.target.checked ? "Y" : ""
                        )
                      }
                    />
                    <Form.Check
                      label={`Paying MOE Subsidised Fees`}
                      id={`cic-4`}
                      checked={applicationDetail?.MOE_SUBSIDISED === "Y"}
                      onChange={(e) =>
                        handleChange(
                          "MOE_SUBSIDISED",
                          e.target.checked ? "Y" : ""
                        )
                      }
                    />
                  </Col>

                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Recommended in NPPS</Form.Label>
                      <Form.Control
                        type={"text"}
                        disabled={true}
                        value={studentDetail?.NPPS}
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Recommended in FASOP</Form.Label>
                      <Form.Control
                        type={"text"}
                        disabled={true}
                        value={studentDetail?.FASOP}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}
