import {Accordion, Col, Form, Row, Table} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import {postAppeal} from "@/apis/app-records/homes/api";
import moment from "moment";
import DatePicker from "react-datepicker";
import CommonModal from "@/components/CommonModal";
import {toast} from "react-toastify";
import Select from "react-select";

const options = [
    {value: "chocolate", label: "Chocolate"},
    {value: "strawberry", label: "Strawberry"},
    {value: "vanilla", label: "Vanilla"},
];

const genderOptions = [
    {value: "M", label: "Male"},
    {value: "F", label: "Female"},
];

function FamilyDetails({
                           isDisabled,
                           applicationDetail,
                           listMTCRequestResult,
                           listMTCDetails,
                           listMTIncomes,
                           listMTConsents,
                           listMTCases,
                           listMTCaseNotes,
                           listMTCDetailsOriginal,
                           listOriginalDetails,
                           listSiblingInNYP,
                           handleClickEdit,
                           ACK_NO,
                           ACK_YR,
                           selectedListExercise,
                           selectedListAdmNo,
                           details,
                           setDetails,
                           specialMeansStatusOptions,
                           residentialOptions,
                           maritalStatusOptions,
                           consentTypeOptions,
                           consentStatusOptions,
                           consentScopeOptions,
                           legalCapacityOptions,
                           relationshipOptions,
                           occupationOptions,
                       }) {
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(-1);
    let counter = 0;

    const handleAddFamily = () => {
        const newDetail = {
            ID: "",
            DETAIL_TAG: "A",
            ACK_NO: ACK_NO,
            ACAD_YR: ACK_YR,
            FAAS_EXERCISE: selectedListExercise,
            NBFA_EXERCISE: "",
            ADM_NO: selectedListAdmNo,
            HH_UIN: "",
            HH_NAME: "",
            OTHER_INCOME: "",
            MARITAL_STATUS: "",
            RES_STATUS: "",
            DOB: "",
            GENDER: "",
            MTC_TAG: "A",
            RELATIONSHIP: "",
            SPECIAL_MEANS_STATUS: "",
            ISNOVALIDAUTH: "",
            REMARKS: "",
            MTREQUESTID: "",
            MEANS_TEST_ID: "",
            STATUS_TAG: "",
            CHANGE_TYPE: "",
            INCOME_PERIOD: "",
            AVG_MTHLY_INCOME: "",
            MT_MEMBER_INDICATORS: "",
            OCCUPATION: "",
            REUSABLE_CONSENTID: "",
            REUSABLE_CONSENTSCOPE: "",
            LAST_UPD_ID: sessionStorage.getItem("staffId")
                ? sessionStorage.getItem("staffId")
                : process.env.REACT_APP_LAST_UPD_ID,
            MT_CONSENT_DETAILS: [{
                ID: "",
                DETAIL_TAG: "",
                ACK_NO: ACK_NO,
                HH_UIN: "",
                CONSENT_TYPE: "",
                CONSENT_SCOPE: "",
                NYP_CONSENT_ID: "",
                NYP_CONSENT_NAME: "",
                NYP_CONSENT_EMAIL: "",
                NYP_CONSENT_STATUS: "",
                NYP_CONSENT_DATE: "",
                NYP_MANUAL_CONSENT: "",
                REC_CREATE_DATE: "",
                LAST_UPD_ID: sessionStorage.getItem("staffId")
                    ? sessionStorage.getItem("staffId")
                    : process.env.REACT_APP_LAST_UPD_ID,
                LEGAL_CAPACITY: "",
            }],
        };

        setDetails([...details, newDetail]);
    };

    const handleRemoveFamily = (index, familyID) => {
        if (familyID) {
            postAppeal([
                {
                    ID: familyID,
                    MTC_TAG: "D",
                },
            ]).then((response) => {
                console.log(response);
                setDeleteModal(false);
            });
        }

        setDetails((prevState) => {
            return prevState.filter((_, i) => i !== index);
        });

        setDeleteModal(false);
    };

    const handleAddConsent = (index) => {
        const newConsent = {
            ID: "",
            DETAIL_TAG: "",
            ACK_NO: ACK_NO,
            HH_UIN: "",
            CONSENT_TYPE: "",
            CONSENT_SCOPE: "",
            NYP_CONSENT_ID: "",
            NYP_CONSENT_NAME: "",
            NYP_CONSENT_EMAIL: "",
            NYP_CONSENT_STATUS: "",
            NYP_CONSENT_DATE: "",
            NYP_MANUAL_CONSENT: "",
            REC_CREATE_DATE: "",
            LAST_UPD_ID: sessionStorage.getItem("staffId")
                ? sessionStorage.getItem("staffId")
                : process.env.REACT_APP_LAST_UPD_ID,
            LEGAL_CAPACITY: "",
        };
        const updatedDetails = details.map((detail, i) => {
            if (i === index) {
                return {
                    ...detail,
                    MT_CONSENT_DETAILS: [...detail.MT_CONSENT_DETAILS, newConsent],
                };
            }
            return detail;
        });

        setDetails(updatedDetails);
    }

    const handleRemoveConsent = (detailIndex, consentIndex) => {
        const updatedDetails = details.map((detail, i) => {
            if (i === detailIndex) {
                return {
                    ...detail,
                    MT_CONSENT_DETAILS: detail.MT_CONSENT_DETAILS.filter((_, cIndex) => cIndex !== consentIndex),
                };
            }
            return detail;
        });

        setDetails(updatedDetails);
    }

    const handleChange = (detailIndex, field, value, consentIndex = 0) => {
        const updatedDetails = details.map((detail, i) => {
            if (i === detailIndex) {
                if (field.startsWith("MT_CONSENT_DETAILS.")) {
                    const consentField = field.replace("MT_CONSENT_DETAILS.", "");
                    return {
                        ...detail,
                        MT_CONSENT_DETAILS: detail.MT_CONSENT_DETAILS.map((consent, j) => {
                            if (j === consentIndex) {
                                return {
                                    ...consent,
                                    [consentField]: value,
                                };
                            }
                            return consent;
                        }),
                    };
                }
                return {
                    ...detail,
                    [field]: value,
                };
            }
            return detail;
        });

        setDetails(updatedDetails);
    };

    // const handleChange = (index, field, value) => {
    //     const updatedDetails = details.map((detail, i) => {
    //         if (i === index) {
    //             if (field.startsWith("MT_CONSENT_DETAILS.")) {
    //                 const consentField = field.replace("MT_CONSENT_DETAILS.", "");
    //                 return {
    //                     ...detail,
    //                     MT_CONSENT_DETAILS: {
    //                         ...detail.MT_CONSENT_DETAILS,
    //                         [consentField]: value,
    //                     },
    //                 };
    //             }
    //             return {
    //                 ...detail,
    //                 [field]: value,
    //             };
    //         }
    //         return detail;
    //     });
    //     setDetails(updatedDetails);
    // };

    const handleSubmitForm = () => {
        postAppeal(details).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                toast.success("Record updated successfully");
            }
        });
    };

    useEffect(() => {
        const consentLookup = listMTConsents.reduce((acc, consent) => {
            acc[consent.ACK_NO] = {
                ID: consent?.ID,
                DETAIL_TAG: consent?.DETAIL_TAG,
                ACK_NO: consent?.ACK_NO,
                HH_UIN: consent?.HH_UIN,
                CONSENT_TYPE: consent?.CONSENT_TYPE,
                CONSENT_SCOPE: consent?.CONSENT_SCOPE,
                NYP_CONSENT_ID: consent?.NYP_CONSENT_ID,
                NYP_CONSENT_NAME: consent?.NYP_CONSENT_NAME,
                NYP_CONSENT_EMAIL: consent?.NYP_CONSENT_EMAIL,
                NYP_CONSENT_STATUS: consent?.NYP_CONSENT_STATUS,
                NYP_CONSENT_DATE: consent?.NYP_CONSENT_DATE,
                NYP_MANUAL_CONSENT: consent?.NYP_MANUAL_CONSENT,
                REC_CREATE_DATE: consent?.REC_CREATE_DATE,
                LAST_UPD_ID: consent?.LAST_UPD_ID,
                LEGAL_CAPACITY: consent?.LEGAL_CAPACITY,
            };
            return acc;
        }, {});

        const remapDetails = listMTCDetails?.map((detail) => {
            const consent = consentLookup[detail.ACK_NO] || {};

            return {
                ID: detail?.ID,
                DETAIL_TAG: detail?.DETAIL_TAG,
                ACK_NO: detail?.ACK_NO,
                ACAD_YR: detail?.ACAD_YR,
                FAAS_EXERCISE: detail?.FAAS_EXERCISE,
                NBFA_EXERCISE: detail?.NBFA_EXERCISE,
                ADM_NO: detail?.ADM_NO,
                HH_UIN: detail?.HH_UIN,
                HH_NAME: detail?.HH_NAME,
                OTHER_INCOME: detail?.OTHER_INCOME,
                MARITAL_STATUS: detail?.MARITAL_STATUS,
                RES_STATUS: detail?.RES_STATUS,
                DOB: detail?.DOB,
                GENDER: detail?.GENDER,
                MTC_TAG: detail?.MTC_TAG,
                RELATIONSHIP: detail?.RELATIONSHIP,
                SPECIAL_MEANS_STATUS: detail?.SPECIAL_MEANS_STATUS,
                ISNOVALIDAUTH: detail?.ISNOVALIDAUTH,
                REMARKS: detail?.REMARKS,
                MTREQUESTID: detail?.MTREQUESTID,
                MEANS_TEST_ID: detail?.MEANS_TEST_ID,
                STATUS_TAG: detail?.STATUS_TAG,
                CHANGE_TYPE: detail?.CHANGE_TYPE,
                INCOME_PERIOD: detail?.INCOME_PERIOD,
                AVG_MTHLY_INCOME: detail?.AVG_MTHLY_INCOME,
                MT_MEMBER_INDICATORS: detail?.MT_MEMBER_INDICATORS,
                OCCUPATION: detail?.OCCUPATION,
                REUSABLE_CONSENTID: detail?.REUSABLE_CONSENTID,
                REUSABLE_CONSENTSCOPE: detail?.REUSABLE_CONSENTSCOPE,
                LAST_UPD_ID: detail?.LAST_UPD_ID,
                MT_CONSENT_DETAILS: consent,
            };
        });

        setDetails(remapDetails);
    }, [listMTCDetails, listMTConsents]);

    return (
        <React.Fragment>
            {/*
      <div>
        {isDisabled ? (
          <button
            className="bg-white border-1 px-3 rounded-2 "
            onClick={handleClickEdit}
          >
            <i className={`bi bi-pencil`}></i>
            {"  "}Edit
          </button>
        ) : (
          <button
            className="bg-white border-1 px-3 rounded-2 text-green-500"
            onClick={handleSubmitForm}
          >
            <i className={`bi bi-check-lg`}></i>
            {"  "}Save
          </button>
        )}
      </div>

      */}
            <div className="">
                <Accordion defaultActiveKey="1" className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Family Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Income Point Value PCI</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={listMTCRequestResult?.INCOMEPOINTVALUE_PCI}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Income Point Value Gross Income</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={listMTCRequestResult?.INCOMEPOINTVALUE_GI}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Family Member</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.FAMILY_MEMBERS}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Family Member Consent Status</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Family Members
                        </Accordion.Header>
                        <Accordion.Body>
                            <button
                                className={`btn-clear text-blue`}
                                onClick={handleAddFamily}
                                disabled={isDisabled}
                            >
                                <i className={`bi-plus-circle`}></i> Add family member
                            </button>
                            <div>
                                {details?.map((detail, index) => {
                                    if (detail?.DETAIL_TAG === "A") {
                                        return (
                                            <Row>
                                                <Col xs={12} className={`fw-bold`}>
                                                    #{index + 1} Member{" "}
                                                    {!isDisabled ? (
                                                        <button
                                                            className={`btn-clear`}
                                                            onClick={() => {
                                                                setSelectedId(detail?.ID);
                                                                setSelectedIndex(index);
                                                                setDeleteModal(true);
                                                            }}
                                                        >
                                                            <i className={`bi-trash`}></i>
                                                        </button>
                                                    ) : detail.MTC_TAG === "D" ? (
                                                        <p className={`text-danger`}>Deleted</p>
                                                    ) : (
                                                        <p className={`text-danger`}>Added</p>
                                                    )}
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            value={detail?.HH_NAME}
                                                            onChange={(e) =>
                                                                handleChange(index, "HH_NAME", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            NRIC/Fin/Passport No. (if foreigner)
                                                            <span className={`text-danger`}> *</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            value={detail?.HH_UIN}
                                                            onChange={(e) =>
                                                                handleChange(index, "HH_UIN", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group className={`d-flex flex-column`}>
                                                        <Form.Label>Date of Birth</Form.Label>
                                                        <DatePicker
                                                            selected={detail?.DOB}
                                                            className={`form-control`}
                                                            onChange={(date) => {
                                                                if (date == null) {
                                                                    handleChange(index, "DOB", "");
                                                                } else {
                                                                    handleChange(
                                                                        index,
                                                                        "DOB",
                                                                        moment(date).format("YYYY-MM-DD")
                                                                    );
                                                                }
                                                            }}
                                                            dateFormat="dd/MM/yyyy"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Age</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Gender</Form.Label>
                                                        <Select
                                                            options={genderOptions}
                                                            isDisabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            value={{
                                                                value: detail?.GENDER,
                                                                label:
                                                                    detail?.GENDER == "M" ? "Male" : "Female",
                                                            }}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "GENDER",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Relationship Status</Form.Label>
                                                        <Select
                                                            options={relationshipOptions}
                                                            isDisabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            value={relationshipOptions.find(option => option.value === detail?.RELATIONSHIP)}
                                                            // value={{
                                                            //     value: detail?.RELATIONSHIP,
                                                            //     label: detail?.RELATIONSHIP,
                                                            // }}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "RELATIONSHIP",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Residental Status</Form.Label>
                                                        <Select
                                                            options={residentialOptions}
                                                            isDisabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "RES_STATUS",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Martial Status</Form.Label>
                                                        <Select
                                                            options={maritalStatusOptions}
                                                            isDisabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "MARITAL_STATUS",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className={`fw-bold my-3 text-blue`}>
                                                    Income Details
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Occupation</Form.Label>
                                                        <Select
                                                            options={occupationOptions}
                                                            isDisabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "OCCUPATION",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Avg Monthly Income</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            value={detail?.AVG_MTHLY_INCOME}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "AVG_MTHLY_INCOME",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Other income</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            value={detail?.OTHER_INCOME}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "OTHER_INCOME",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Remarks</Form.Label>
                                                        <Form.Text className="text-muted">
                                                            Reason if occupation status is unemployed
                                                        </Form.Text>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            value={detail?.REMARKS}
                                                            onChange={(e) =>
                                                                handleChange(index, "REMARKS", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Special Means Status</Form.Label>
                                                        <Select
                                                            isDisabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            options={specialMeansStatusOptions}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "SPECIAL_MEANS_STATUS",
                                                                    e.value
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className={`fw-bold my-3 text-blue`}>
                                                    Consent Details
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Reusable Consent</Form.Label>
                                                        <Form.Text className="text-muted">
                                                            If no, please specify Consent NRIC and Name
                                                        </Form.Text>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.REUSABLE_CONSENTSCOPE}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "REUSABLE_CONSENTSCOPE",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Reusable Consent ID</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.REUSABLE_CONSENTID}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "REUSABLE_CONSENTID",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Reusable Consent Scope</Form.Label>
                                                        <Select
                                                            isDisabled={true}
                                                            options={consentScopeOptions}
                                                            value={consentScopeOptions.find(option => option.value === applicationDetail?.REUSABLE_CONSENTSCOPE)}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "REUSABLE_CONSENTSCOPE",
                                                                    e.value
                                                                )
                                                            }
                                                        />
                                                        {/*<Form.Control*/}
                                                        {/*    type={`text`}*/}
                                                        {/*    disabled={true}*/}
                                                        {/*    value={detail?.REUSABLE_CONSENTSCOPE}*/}
                                                        {/*    onChange={(e) =>*/}
                                                        {/*        handleChange(*/}
                                                        {/*            index,*/}
                                                        {/*            "REUSABLE_CONSENTSCOPE",*/}
                                                        {/*            e.target.value*/}
                                                        {/*        )*/}
                                                        {/*    }*/}
                                                        {/*></Form.Control>*/}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Is No Valid Auth</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.ISNOVALIDAUTH}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "ISNOVALIDAUTH",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <h5 className={`mt-5`}>Consent Detail</h5>

                                                <div>
                                                    <button
                                                        className={`btn-clear text-blue`}
                                                        onClick={() => handleAddConsent(index)}
                                                        disabled={isDisabled}
                                                    >
                                                        <i className={`bi-plus-circle`}></i> Add consent detail
                                                    </button>
                                                </div>

                                                {detail?.MT_CONSENT_DETAILS?.map((condetail, detailIndex) => (
                                                    <Row key={detailIndex}>
                                                        <Col xs={12}>
                                                            Consent Detail #{detailIndex + 1}
                                                            <button
                                                                className={`btn-clear text-red-500`}
                                                                onClick={() => handleRemoveConsent(index, detailIndex)}
                                                                disabled={isDisabled}
                                                            >
                                                                <i className={`bi-dash-circle`}></i> Remove consent detail
                                                            </button>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Type</Form.Label>
                                                                <Select
                                                                    isDisabled={condetail?.NYP_MANUAL_CONSENT === "Y" || isDisabled || detail.MTC_TAG !== "D"}
                                                                    options={consentTypeOptions}
                                                                    value={consentTypeOptions.find(option => option.value === condetail?.CONSENT_TYPE)}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "MT_CONSENT_DETAILS.CONSENT_TYPE",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={detail?.MT_CONSENT_DETAILS?.CONSENT_TYPE}*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.CONSENT_TYPE",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Scope</Form.Label>
                                                                <Select
                                                                    isDisabled={condetail?.NYP_MANUAL_CONSENT === "Y" || isDisabled || detail.MTC_TAG !== "D"}
                                                                    options={consentScopeOptions}
                                                                    value={consentScopeOptions.find(option => option.value === condetail?.CONSENT_SCOPE)}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "MT_CONSENT_DETAILS.CONSENT_SCOPE",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={detail?.MT_CONSENT_DETAILS?.CONSENT_SCOPE}*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.CONSENT_SCOPE",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Legal Capacity</Form.Label>
                                                                <Select
                                                                    isDisabled={condetail?.NYP_MANUAL_CONSENT === "Y" || isDisabled || detail.MTC_TAG !== "D"}
                                                                    options={legalCapacityOptions}
                                                                    value={legalCapacityOptions.find(option => option.value === condetail?.LEGAL_CAPACITY)}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "MT_CONSENT_DETAILS.LEGAL_CAPACITY",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={*/}
                                                                {/*        detail?.MT_CONSENT_DETAILS?.LEGAL_CAPACITY*/}
                                                                {/*    }*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.LEGAL_CAPACITY",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Manual Consent</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                                    value={
                                                                        condetail?.NYP_MANUAL_CONSENT
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "MT_CONSENT_DETAILS.NYP_MANUAL_CONSENT",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent NRIC</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                                    value={
                                                                        condetail?.NYP_CONSENT_ID
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "MT_CONSENT_DETAILS.NYP_CONSENT_ID",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Name</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                                    value={
                                                                        condetail?.NYP_CONSENT_NAME
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "MT_CONSENT_DETAILS.NYP_CONSENT_NAME",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Email</Form.Label>
                                                                <Form.Control
                                                                    type={`email`}
                                                                    disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                                    value={
                                                                        condetail?.NYP_CONSENT_EMAIL
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "MT_CONSENT_DETAILS.NYP_CONSENT_EMAIL",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Status</Form.Label>
                                                                <Select
                                                                    isDisabled={condetail?.NYP_MANUAL_CONSENT === "Y" || isDisabled || detail.MTC_TAG !== "D"}
                                                                    options={consentStatusOptions}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "MT_CONSENT_DETAILS.NYP_CONSENT_STATUS",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={*/}
                                                                {/*        detail?.MT_CONSENT_DETAILS?.NYP_CONSENT_STATUS*/}
                                                                {/*    }*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.NYP_CONSENT_STATUS",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Date</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={condetail?.NYP_MANUAL_CONSENT === "Y" || isDisabled || detail.MTC_TAG !== "D"}
                                                                    value={
                                                                        condetail?.NYP_CONSENT_DATE
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "MT_CONSENT_DETAILS.NYP_CONSENT_DATE",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                ))}

                                                <Col xs={12}>
                                                    <Form.Group>
                                                        <Form.Label>Remarks</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={isDisabled && detail.MTC_TAG !== "D"}
                                                            value={detail?.REMARKS}
                                                            onChange={(e) =>
                                                                handleChange(index, "REMARKS", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        );
                                    }
                                })}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Siblings Studying in NYP
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12} className="">
                                        <Table responsive>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>#</Table.HeaderCell>
                                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                                    <Table.HeaderCell>Adm. No.</Table.HeaderCell>
                                                    <Table.HeaderCell>Course</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {listSiblingInNYP?.familyStudyInNYPList?.map(
                                                    (sibling, index) => (
                                                        <Table.Row key={index}>
                                                            <Table.DataCell>{index + 1}</Table.DataCell>
                                                            <Table.DataCell>{sibling.name}</Table.DataCell>
                                                            <Table.DataCell>{sibling.admNo}</Table.DataCell>
                                                            <Table.DataCell>
                                                                {sibling.courseCode} - {sibling.courseName}
                                                            </Table.DataCell>
                                                        </Table.Row>
                                                    )
                                                )}
                                            </Table.Body>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <CommonModal
                show={deleteModal}
                handleClose={() => setDeleteModal(false)}
                description={`Are you sure you want to delete family member?`}
                title={`Delete family member?`}
                submitButtonText={"Yes"}
                action={() => handleRemoveFamily(selectedIndex, selectedId)}
            />
        </React.Fragment>
    );
}

export default FamilyDetails;
