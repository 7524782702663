import { Accordion } from "@govtechsg/sgds-react/Accordion";
import {
  Col,
  Form,
  Row,
  TableBody,
  TableDataCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@govtechsg/sgds-react";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";

export default function MTRequest({
  isDisabled,
  applicationDetail,
  listMTCRequestResult,
  listMTCDetails,
  listMTIncomes,
  listMTConsents,
  listMTCases,
  listMTCaseNotes,
  mtRequestStatusOptions,
  specialCaseOptions,
  careArrangementOptions,
  relationshipOptions,
  maritalStatusOptions,
  residentialOptions,
  specialMeansStatusOptions,
  consentScopeOptions,
  consentTypeOptions,
  details,
}) {
  const [totalCCCIncome, setCCCIncome] = useState(0);
  const [totalGrossIncome, setTotalGrossIncome] = useState(0);

  useEffect(() => {
    let total = 0;
    details?.map((content) => {
      if (content?.MTC_TAG != "D") {
        total += Number(content?.AVG_MTHLY_INCOME);
      }
    });
    setTotalGrossIncome(total);
    setCCCIncome(total / (details?.length > 0 ? details?.length : 1));
  }, [details]);

  return (
    <>
      <div className="">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Application
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Request status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.STATUS_TAG}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Mean Test Date</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.MT_DATE}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Mean Test Computed On</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.MT_COMPUTED_ON}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>
                        Changes after Mean Test Computation
                      </Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.CHANGESAFTERMTCOMPUTATION}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Income Point Value PCI </Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={totalCCCIncome}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Income Point Value Gross Income </Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={totalGrossIncome}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Escalated to</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.ESCALATED_TO}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Escalated from</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.ESCALATED_FROM}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>TLC Indicator</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.TLC_INDICATOR}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Special Case </Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.SPECIAL_CASE}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Reviewed by</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.REVIEWED_BY}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Application ID</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.APPLICATION_ID}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Request status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.STATUS_TAG}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Care Arrangement </Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.CARE_ARRANGEMENT}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Contact Number </Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.CONTACT_NUMBER}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCRequestResult?.EMAIL_ADDRESS}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Family Details
            </Accordion.Header>
            <Accordion.Body>
              <div>
                {details?.map((detail, index) => {
                  if (detail?.MTC_TAG == "A") {
                    return (
                      <Row key={index}>
                        <Col xs={12}>
                          <Form.Label>Family #{index + 1}</Form.Label>
                        </Col>
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              disabled={isDisabled}
                              value={detail?.HH_NAME}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label>
                              NRIC/Fin/Passport No. (if foreigner)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              disabled={isDisabled}
                              value={detail?.HH_UIN}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                              type="text"
                              disabled={isDisabled}
                              value={moment(detail?.DOB).format("YYYY-MM-DD")}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label>Age</Form.Label>
                            <Form.Control
                              type="text"
                              disabled={isDisabled}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label>Gender </Form.Label>
                            <Form.Control
                              type="text"
                              disabled={isDisabled}
                              value={detail?.GENDER}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label>Relationship Status</Form.Label>
                            <Form.Control
                              type="text"
                              disabled={isDisabled}
                              value={detail?.RELATIONSHIP}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label>Residential Status</Form.Label>
                            <Form.Control
                              type="text"
                              disabled={isDisabled}
                              value={detail?.RES_STATUS}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label>Marital Status</Form.Label>
                            <Form.Control
                              type="text"
                              disabled={isDisabled}
                              value={detail?.MARITAL_STATUS}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    );
                  }
                })}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Income Details
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Occupation</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.OCCUPATION}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Other income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.OTHER_INCOME}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Remarks</Form.Label>
                      <Form.Text>
                        Reason if occupation status is unemployed
                      </Form.Text>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.OTHER_INCOME}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Income Period</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.INCOME_PERIOD}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Average Monthly Income </Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.AVG_MTHLY_INCOME}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Special Means Status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.SPECIAL_MEANS_STATUS}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>MT Member Indicator</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.MT_MEMBER_INDICATORS}
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col xs={12}>
                    <Form.Label>Income (Monthly)</Form.Label>
                  </Col>

                  {listMTIncomes.map((income, index) => (
                    <>
                      <Col xs={12}>
                        <h5 className={`text-black-50`}>{income?.MONTH}</h5>
                      </Col>

                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>Income Type</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={isDisabled}
                            value={income?.INCOME_TYPE}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>Income Amount</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={isDisabled}
                            value={income?.INCOME_TYPE}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </>
                  ))}
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Consent Details
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Reusable Consent</Form.Label>
                      <Form.Text>
                        If no, please specify Consent NRIC and Name)
                      </Form.Text>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.REUSABLE_CONSENTSCOPE}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group>
                      <Form.Label>Reusable Consent ID</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.REUSABLE_CONSENTID}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group>
                      <Form.Label>Reusable Consent Scope</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.REUSABLE_CONSENTSCOPE}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group>
                      <Form.Label>Is No Valid Auth</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.ISNOVALIDAUTH}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Consent Type</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTConsents?.CONSENT_TYPE}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Consent Scope</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTConsents?.CONSENT_SCOPE}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Legal Capacity</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTConsents?.LEGAL_CAPACITY}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Manual Consent</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTConsents?.NYP_MANUAL_CONSENT}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Consent NRIC</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTConsents?.NYP_CONSENT_ID}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Consent Name</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTConsents?.NYP_CONSENT_NAME}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Consent Status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTConsents?.NYP_CONSENT_STATUS}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Consent Date</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTConsents?.NYP_CONSENT_DATE}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.REMARKS}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Mean Test Results
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>MT Request ID</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.MTREQUESTID}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Mean Test ID</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.MEANS_TEST_ID}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Status Tag</Form.Label>

                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.STATUS_TAG}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Change Type</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isDisabled}
                        value={listMTCDetails?.CHANGE_TYPE}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Case Details
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Label>Case Detail</Form.Label>
                  </Col>

                  <Col xs={12}>
                    <Table responsive>
                      <TableHeader>
                        <TableHeaderCell>#</TableHeaderCell>
                        <TableHeaderCell>Case ID</TableHeaderCell>
                        <TableHeaderCell>Case Type</TableHeaderCell>
                        <TableHeaderCell>Case Subject</TableHeaderCell>
                        <TableHeaderCell>Case Status</TableHeaderCell>
                        <TableHeaderCell>Last Update</TableHeaderCell>
                      </TableHeader>

                      <TableBody>
                        {listMTCases?.map((ecase, index) => (
                          <TableRow key={index}>
                            <TableDataCell>{ecase?.index + 1}</TableDataCell>
                            <TableDataCell>{ecase?.CASE_ID}</TableDataCell>
                            <TableDataCell>{ecase?.CASE_TYPE}</TableDataCell>
                            <TableDataCell>{ecase?.CASE_SUBJECT}</TableDataCell>
                            <TableDataCell>{ecase?.CASE_STATUS}</TableDataCell>
                            <TableDataCell>{ecase?.UPDATED_AT}</TableDataCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Col>
                </Row>
              </div>

              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Label>Case Detail</Form.Label>
                  </Col>

                  <Col xs={12}>
                    <Table responsive>
                      <TableHeader>
                        <TableHeaderCell>#</TableHeaderCell>
                        <TableHeaderCell>Case ID</TableHeaderCell>
                        <TableHeaderCell>Case Notes</TableHeaderCell>
                        {/*<TableHeaderCell>*/}
                        {/*    Case Status*/}
                        {/*</TableHeaderCell>*/}
                        <TableHeaderCell>Last Update</TableHeaderCell>
                      </TableHeader>

                      <TableBody>
                        <TableRow>
                          {listMTCaseNotes?.map((ecase, index) => (
                            <TableRow key={index}>
                              <TableDataCell>{ecase?.index + 1}</TableDataCell>
                              <TableDataCell>{ecase?.CASE_ID}</TableDataCell>
                              {/*<TableDataCell>{ecase?.CASE_STATUS}</TableDataCell>*/}
                              <TableDataCell>{ecase?.UPDATED_AT}</TableDataCell>
                            </TableRow>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}
