import axios from "axios";

export async function getListApplications(params) {
    return axios.get(process.env.REACT_APP_API_URL + "/api/v1/applications", {
        params
    }).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}

export async function patchApplications(params) {
    return axios.patch(process.env.REACT_APP_API_URL + "/api/v1/applications", params).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}

export async function postDownloadNewRecommendList(params) {
    return axios.post(process.env.REACT_APP_API_URL + "/api/v1/applications/recommends/new", params).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}

export async function postDownloadNewRecommendListWithNewApplication(params) {
    return axios.post(process.env.REACT_APP_API_URL + "/api/v1/applications/recommends/withnew", params).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}

export async function postDownloadNewRecommendListWithoutNewApplication(params) {
    return axios.post(process.env.REACT_APP_API_URL + "/api/v1/applications/recommends/withoutnew", params).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}

export async function getRecommendList(params) {
    return axios.post(process.env.REACT_APP_API_URL + "/api/v1/applications/recommendations/basedoncriteria",
        params
    ).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}
