import axios from "axios";

export async function getDetailCriteria(params) {
    return axios.get(process.env.REACT_APP_API_URL + "/api/v1/applications/criteria/faas", {
        params
    }).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}

export async function postSaveCriteria(params) {
    return axios.post(process.env.REACT_APP_API_URL + "/api/v1/applications/criteria/faas", params).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}

export async function getCourseCriteria(params) {
    return axios.get(process.env.REACT_APP_API_URL + "/api/v1/applications/criteria/courses", {
        params
    }).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}

export async function getCitizenshipCriteria(params) {
    return axios.get(process.env.REACT_APP_API_URL + "/api/v1/applications/citizenships", {
        params
    }).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}

export async function getCWACriteria(params) {
    return axios.get(process.env.REACT_APP_API_URL + "/api/v1/applications/cwas", {
        params
    }).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}

export async function getGPACriteria(params) {
    return axios.get(process.env.REACT_APP_API_URL + "/api/v1/applications/gpas", {
        params
    }).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}

export async function getExcludedAwardCriteria(params) {
    return axios.get(process.env.REACT_APP_API_URL + "/api/v1/applications/criteria/awards", {
        params
    }).then((response) => {
        return response;
    }).catch((err) => {
        console.error(err?.message);
        return err;
    });
}
