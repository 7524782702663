import React from "react";

function EditAndSaveButtonHomes({
  isDisabled,
  handleClickEdit,
  handleSubmitApplicationForm,
}) {
  return (
    <div>
      {isDisabled ? (
        <button
          className="bg-white border-1 px-3 rounded-2 "
          onClick={handleClickEdit}
        >
          <i className={`bi bi-pencil`}></i>
          {"  "}Edit
        </button>
      ) : (
        <button
          className="bg-white border-1 px-3 rounded-2 text-green-500"
          onClick={handleSubmitApplicationForm}
        >
          <i className={`bi bi-check-lg`}></i>
          {"  "}Save
        </button>
      )}
    </div>
  );
}

export default EditAndSaveButtonHomes;
