import axios from "axios";

// {
//     "OCCUPATION": "STUDENT",
//     "INCOME_CONTRIBUTION": "Y",
//     "INDEPENDENT": "Y",
//     "REMARK": "Y"
// }

export async function getOccupationList(limit, page) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/occupations?limit=${limit}&page=${page}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function postOccupation(OCData) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/occupations`,
      OCData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function putOccupation(OCID, OCData) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/occupations/${OCID}`,
      OCData
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function deleteOccupation(selectedId) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/api/v1/applications/occupations/${selectedId}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
