import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "@govtechsg/sgds-react";
import Select from "react-select";

import {getCodeList} from "@/apis/preparation/setup-dialect-etc/api";
import {getCodeTypeSasList} from "@/apis/sims/api";

const options = [
    {value: "chocolate", label: "Chocolate"},
    {value: "strawberry", label: "Strawberry"},
    {value: "vanilla", label: "Vanilla"},
];

function PersonalData({
                          data,
                          studentDetail,
                          setShowCCAModal,
                          isDisabled,
                          maritalStatus,
                          setMaritalStatus,
                          dialectGroup,
                          setDialectGroup,
                          housingType,
                          setHousingType,
                          setShowTransferToAnotherExerciseModal,
                          isReadOnly = false,
                      }) {
    // list of options
    const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
    const [dialectGroupOptions, setDialectGroupOptions] = useState([]);
    const [housingTypeOptions, setHousingTypeOptions] = useState([]);
    const roleList = JSON.parse(sessionStorage.getItem("roles")) || [];

    const getMaritalStatusOptions = () => {
        getCodeList("MS")
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({
                            value: item?.CODE,
                            label: `${item?.CODE} - ${item?.DESCRIPTION}`,
                            code: item?.CODE,
                        });
                    });
                    setMaritalStatusOptions(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const getDialectGroupOptions = () => {
        getCodeList("DG")
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({
                            value: item?.CODE,
                            label: `${item?.CODE} - ${item?.DESCRIPTION}`,
                            code: item?.CODE,
                        });
                    });
                    setDialectGroupOptions(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const getHousingTypeOptions = () => {
        getCodeList("H")
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({
                            value: item?.CODE,
                            label: `${item?.CODE} - ${item?.DESCRIPTION}`,
                            code: item?.CODE,
                        });
                    });
                    setHousingTypeOptions(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        setMaritalStatus({
            label: maritalStatusOptions.map((content) => {
                if (data?.MARITAL_STATUS == content?.code) {
                    return content?.label;
                }
            }),
            value: data?.MARITAL_STATUS,
        });
        setDialectGroup({
            label: dialectGroupOptions.map((content) => {
                if (data?.DIALECT_GRP == content?.code) {
                    return content?.label;
                }
            }),
            value: data?.DIALECT_GRP,
        });
        setHousingType({
            label: housingTypeOptions.map((content) => {
                if (data?.HOMEHOUSETYPE == content?.code) {
                    return content?.label;
                }
            }),
            value: data?.HOMEHOUSETYPE,
        });
    }, [data?.MARITAL_STATUS, data?.DIALECT_GRP, data?.HOMEHOUSETYPE]);

    const onChangeMaritalStatus = (selectedOption) => {
        setMaritalStatus({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };
    const onChangeDialectGroup = (selectedOption) => {
        setDialectGroup({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };
    const onChangeHousingType = (selectedOption) => {
        setHousingType({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };

    const [studentStatus, setStudentStatus] = useState({})

    const getStudentStatus = () => {
        getCodeTypeSasList("50", studentDetail?.studStatus)
            .then((response) => {
                setStudentStatus(response?.data?.data?.[0])
            })
    }

    const [studentCitizenship, setStudentCitizenship] = useState({})

    const getStudentCitizenship = () => {
        getCodeTypeSasList("12", studentDetail?.citizen)
            .then((response) => {
                console.log(response.data)
                setStudentCitizenship(response?.data?.data?.[0])
            })
    }

    useEffect(() => {
        getStudentStatus()
        getStudentCitizenship()
    }, [studentDetail]);

    useEffect(() => {
        getMaritalStatusOptions();
        getDialectGroupOptions();
        getHousingTypeOptions();

    }, []);

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={12} className="col-5-custom">
                        <Form.Group>
                            <Form.Label>Academic Year<span className={`text-danger`}> *</span></Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.ACAD_YR}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="col-5-custom">
                        <Form.Group>
                            <Form.Label>Exercise<span className={`text-danger`}> *</span></Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.EXERCISE}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="col-5-custom">
                        <Form.Group>
                            <Form.Label>Adm No.<span className={`text-danger`}> *</span></Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.ADM_NO}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="col-5-custom">
                        <Form.Group>
                            <Form.Label>ID No.</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={studentDetail?.idNo}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="col-5-custom">
                        <Form.Group>
                            <Form.Label>Ack. No.</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.ACK_NO}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={12} className="mt-3">
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={studentDetail?.name}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="mt-3">
                        <Form.Group>
                            <Form.Label>Student Status</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={`${studentStatus?.code} (${studentStatus?.description})`}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="mt-3">
                        <Form.Group>
                            <Form.Label>Course</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={
                                    studentDetail?.courseList?.length > 0
                                        ? studentDetail?.courseList[0]?.courseName
                                        : ""
                                }
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="mt-3">
                        <Form.Group>
                            <Form.Label>Citizenship</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={`${studentCitizenship?.code} (${studentCitizenship?.description})`}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="mt-3">
                        <Form.Group>
                            <Form.Label>PR</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.PR_STATUS}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="mt-3">
                        <Form.Group>
                            <Form.Label>Contact No. (Term Tel)</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={studentDetail?.termTel}
                            ></Form.Control>
                        </Form.Group>
                    </Col>

                    <Col xs={6} className="mt-3">
                        <Form.Group>
                            <Form.Label>Contact No. (Mobile)</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={studentDetail?.handphone}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="mt-3">
                        <Form.Group>
                            <Form.Label>Correspondence Address</Form.Label>
                            <Form.Control
                                className=""
                                type={`text`}
                                disabled={true}
                                value={studentDetail?.termAddr1}
                            ></Form.Control>
                            <Form.Control
                                className="mt-1"
                                type={`text`}
                                disabled={true}
                                value={studentDetail?.termAddr2}
                            ></Form.Control>
                            <Form.Control
                                className="mt-1"
                                type={`text`}
                                disabled={true}
                                value={studentDetail?.termAddr3}
                            ></Form.Control>
                            <Form.Control
                                className="mt-1"
                                type={`text`}
                                disabled={true}
                                value={studentDetail?.termState}
                            ></Form.Control>
                            <Form.Control
                                className="mt-1"
                                type={`text`}
                                disabled={true}
                                value={studentDetail?.termCountry}
                            ></Form.Control>
                            <Form.Control
                                className="mt-1"
                                type={`text`}
                                disabled={true}
                                value={studentDetail?.termPostCode}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="mt-3">
                        <Form.Group>
                            <Form.Label>Marital Status</Form.Label>
                            <Select
                                options={maritalStatusOptions}
                                isDisabled={isDisabled}
                                value={maritalStatus}
                                onChange={onChangeMaritalStatus}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={4} className="mt-3">
                        <Form.Group>
                            <Form.Label>Dialect Group</Form.Label>
                            <Select
                                options={dialectGroupOptions}
                                isDisabled={isDisabled}
                                value={dialectGroup}
                                onChange={onChangeDialectGroup}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={4} className="mt-3">
                        <Form.Group>
                            <Form.Label>Housing Type</Form.Label>
                            <Select
                                options={housingTypeOptions}
                                isDisabled={isDisabled}
                                value={housingType}
                                onChange={onChangeHousingType}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
            <div className="mt-3">
                <button
                    className="btn btn-outline-dark"
                    onClick={() => setShowCCAModal(true)}
                >
                    View CCA
                </button>

                {isReadOnly ? (
                    ""
                ) : (
                    roleList.includes("FAAS_TEMP") ? null : (<button
                        className="btn ms-3 btn-primary"
                        onClick={() => setShowTransferToAnotherExerciseModal(true)}
                    >
                        Transfer to another exercise
                    </button>)

                )}
            </div>
        </React.Fragment>
    );
}

export default PersonalData;
