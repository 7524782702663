import Layout from "@/components/Layout";
import {Button, Col, Form, Row} from "@govtechsg/sgds-react";
import Select from "react-select";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import React, {useEffect, useRef, useState} from "react";
import {Accordion} from "@govtechsg/sgds-react/Accordion";
import {getCodeList, getCodeTypeSasList} from "@/apis/sims/api";
import {
    getCitizenshipCriteria,
    getCourseCriteria,
    getCWACriteria,
    getDetailCriteria,
    getGPACriteria,
    getExcludedAwardCriteria,
    postSaveCriteria,
} from "@/apis/faas/api";
import {useSearchParams} from "react-router-dom";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {toast} from "react-toastify";
import {getTimelineDT} from "@/apis/preparation/setup-timeline/api";
import CommonModal from "@/components/CommonModal";

export default function Criteria() {
    const [isOpen, setIsOpen] = useState(true);
    const [retrieved, setRetrieved] = useState(false);

    const [dataAward, setDataAward] = useState([]);
    const [dataAwardType, setDataAwardType] = useState([]);
    const [dataDetailCriteria, setDataDetailCriteria] = useState([]);

    const [loadingListAward, setLoadingListAward] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const [awardOptions, setAwardOptions] = useState([]);

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [citizenship, setCitizenship] = useState([
        {
            id: 0,
            CITIZENSHIP: "",
            options: [],
        },
    ]);
    const [course, setCourse] = useState([
        {
            id: 0,
            COURSE_CODE: "",
            options: [],
        },
    ]);
    const [cwa, setCWA] = useState([
        {
            id: 0,
            CWA: "",
            STUDY_STAGE: "",
            options: [],
        },
    ]);
    const [eligibilityCriteria, setEligibilityCriteria] = useState({
        eligibilityCriteria: "",
        prStatus: null,
        feeLoanScheme: null,
        awardAmount: 0,
        ghiIncome: 0,
        mpciIncome: 0,
        fpciIncome: 0,
        oLevelAggregate: 0,
        aLevelAggregate: 0,
        iteAcadGpa: 0,
    });
    const [excludeReceived, setExcludeReceived] = useState([
        {
            id: 0,
            description: "",
            receivedReward: "",
            sn: "",
        },
    ]);
    const [gpa, setGPA] = useState([
        {
            id: 0,
            GPA: "",
            STUDY_STAGE: "",
            options: [],
        },
    ]);
    const [retrieval, setRetrieval] = useState({
        award: null,
        awardType: null,
    });

    const [editable, setEditable] = useState(true);

    const contentRef = useRef(null);

    const refAwardInformation = useRef(null);
    const refEligibilityCriteria = useRef(null);
    const refCourse = useRef(null);
    const refCitizenship = useRef(null);
    const refCWA = useRef(null);
    const refGPA = useRef(null);
    const refExcludeReceived = useRef(null);

    const [courseOptions, setCourseOptions] = useState([]);
    const [citizenshipOptions, setCitizenshipOptions] = useState([]);
    const [cwaOptions, setCWAOptions] = useState([]);
    const [gpaOptions, setGPAOptions] = useState([]);

    useEffect(() => {
        if (
            searchParams.has("scrollTo") &&
            refAwardInformation?.current &&
            refEligibilityCriteria?.current &&
            refCourse?.current &&
            refCitizenship?.current &&
            refCWA?.current &&
            refGPA?.current &&
            refExcludeReceived?.current
        ) {
            switch (searchParams.get("scrollTo")) {
                case "Award Information":
                    ScrollToElement(refAwardInformation.current);
                    break;
                case "Eligibility Criteria":
                    ScrollToElement(refEligibilityCriteria.current);
                    break;
                case "Course":
                    ScrollToElement(refCourse.current);
                    break;
                case "Citizenship":
                    ScrollToElement(refCitizenship.current);
                    break;
                case "CWA":
                    ScrollToElement(refCWA.current);
                    break;
                case "GPA":
                    ScrollToElement(refGPA.current);
                    break;
                case "Exclude Received Award Type":
                    ScrollToElement(refExcludeReceived.current);
                    break;
                default:
                    break;
            }

            searchParams.delete("scrollTo");
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);

    const ScrollToElement = (element = refAwardInformation.current) => {
        if (element) {
            element.scrollIntoView({behavior: "smooth"});
        }
    };

    useEffect(() => {
        handleGetListAwardType();
    }, []);

    const handleGetListAward = async (codeType = "") => {
        setLoadingListAward(true);

        try {
            const response = await getCodeList(codeType);

            setDataAward(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingListAward(false);
        }
    };

    const handleGetListAwardType = async (codeType = "AWARDTYPECODE") => {
        try {
            const response = await getCodeList(codeType);

            setDataAwardType(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );

            setAwardOptions(response?.data?.data?.map((item) => ({
                label: item?.code,
                value: item?.code,
                description: item?.description,
            })))

        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleChangeAwardType = (value) => {
        setRetrieval({
            award: null,
            awardType: value,
        });
        handleGetListAward(value?.value);
    };

    const handleSaveCriteria = (e) => {
        e.preventDefault()

        if (!eligibilityCriteria?.ghiIncome) {
            toast.warning("Please fill up the GHI Income");
            return false;
        }

        const params = {
            CRITERIA: {
                ID: eligibilityCriteria?.ID ?? "",
                AWARD_TYPE: retrieval?.awardType?.value,
                AWARD: retrieval?.award?.value,
                CRITERIA: eligibilityCriteria?.eligibilityCriteria,
                PR_STATUS: eligibilityCriteria?.prStatus?.value,
                ON_TUTFEE_LOAN: eligibilityCriteria?.feeLoanScheme?.value,
                AWARD_AMOUNT: eligibilityCriteria?.awardAmount,
                CCC_INCOME: eligibilityCriteria?.ghiIncome,
                FPCI: eligibilityCriteria?.fpciIncome,
                OLAGG: eligibilityCriteria?.oLevelAggregate,
                ALAGG: eligibilityCriteria?.aLevelAggregate,
                ITE_GPA: eligibilityCriteria?.iteAcadGpa,
                MPCI: eligibilityCriteria?.mpciIncome,
            },
            COURSE_CRITERIA: [
                ...course.map((item) => ({
                    AWARD_TYPE: retrieval?.awardType?.value,
                    AWARD: retrieval?.award?.value,
                    COURSE_CODE: item?.COURSE_CODE,
                    ID: item?.ID ?? ""
                })),
            ],
            CITIZENSHIP: [
                ...citizenship.map((item) => ({
                    AWARD_TYPE: retrieval?.awardType?.value,
                    AWARD: retrieval?.award?.value,
                    CITIZENSHIP: item?.CITIZENSHIP,
                    ID: item?.ID ?? ""
                })),
            ],
            CWA: [
                ...cwa.map((item) => ({
                    AWARD_TYPE: retrieval?.awardType?.value,
                    AWARD: retrieval?.award?.value,
                    CWA: item?.CWA,
                    STUDY_STAGE: item?.STUDY_STAGE,
                    ID: item?.ID ?? ""
                })),
            ],
            GPA: [
                ...gpa.map((item) => ({
                    AWARD_TYPE: retrieval?.awardType?.value,
                    AWARD: retrieval?.award?.value,
                    GPA: item?.GPA,
                    STUDY_STAGE: item?.STUDY_STAGE,
                    ID: item?.ID ?? ""
                })),
            ],
            AWARD_CRITERIA: [
                ...excludeReceived.map((item) => ({
                    AWARD_TYPE: retrieval?.awardType?.value,
                    AWARD: retrieval?.award?.value,
                    RECEIVED_AWARD_TYPE: item?.receivedReward?.value,
                    ID: item?.ID ?? ""
                })),
            ],
        };

        // console.log(course);
        // console.log(citizenship);
        // console.log(cwa);
        // console.log(gpa);
        // console.log(excludeReceived);
        console.log(params);

        params.CRITERIA = Object.keys(params?.CRITERIA)
            .filter(function (k) {
                return (
                    params?.CRITERIA[k] !== null && params?.CRITERIA[k] !== undefined
                );
            })
            .reduce(function (acc, k) {
                acc[k] = params?.CRITERIA[k];
                return acc;
            }, {});

        // if (dataDetailCriteria?.[0]?.ID) {
        //     params.CRITERIA.ID = dataDetailCriteria?.[0]?.ID;
        //     params.COURSE_CRITERIA = params.COURSE_CRITERIA.map((item) => ({
        //         ID: dataDetailCriteria?.[0]?.ID,
        //         ...item,
        //     }));
        //     params.CITIZENSHIP = params.CITIZENSHIP.map((item) => ({
        //         ID: dataDetailCriteria?.[0]?.ID,
        //         ...item,
        //     }));
        //     params.CWA = params.CWA.map((item) => ({
        //         ID: dataDetailCriteria?.[0]?.ID,
        //         ...item,
        //     }));
        //     params.GPA = params.GPA.map((item) => ({
        //         ID: dataDetailCriteria?.[0]?.ID,
        //         ...item,
        //     }));
        //     params.AWARD_CRITERIA = params.AWARD_CRITERIA.map((item) => ({
        //         ID: dataDetailCriteria?.[0]?.ID,
        //         ...item,
        //     }));
        // }

        postSaveCriteria(params)
            .then((resp) => {
                if (resp?.response?.data?.message) {
                    if (
                      resp?.response?.data?.message?.length > 0 &&
                      Array.isArray(resp?.response?.data?.message)
                    ) {
                      resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                      });
                    } else {
                      toast.error(resp?.response?.data?.message);
                    }
                  } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                  } else {
                    if (resp.status == 200) {
                      toast.success("Criteria saved successfully");
                    }
                  }
            })
            .catch((err) => console.log(err))
            .finally(() => setEditable(true));
    };

    const handleSubmitRetrieval = async (e) => {
        e?.preventDefault();

        try {
            const params = {
                AWARD_TYPE: retrieval?.awardType?.value,
                AWARD: retrieval?.award?.value,
            };

            const response = await getDetailCriteria(params);

            const data = response?.data?.data;

            console.log(data)
            console.log(data)
            if (data?.length > 0) {
                setEligibilityCriteria({
                    ID: data?.[0]?.ID ?? "",
                    eligibilityCriteria: data?.[0]?.CRITERIA,
                    prStatus: {
                        label: data?.[0]?.PR_STATUS == "Y" ? "PR" : "Non-PR", 
                        value: data?.[0]?.PR_STATUS
                    },
                    feeLoanScheme: {
                        label: data?.[0]?.ON_TUTFEE_LOAN == "Y" ? "Tuition Loan Scheme" : "Not On Tuition Loan Scheme",
                        value: data?.[0]?.ON_TUTFEE_LOAN
                    },
                    awardAmount: data?.[0]?.AWARD_AMOUNT,
                    ghiIncome: data?.[0]?.CCC_INCOME,
                    mpciIncome: data?.[0]?.MPCI,
                    fpciIncome: data?.[0]?.FPCI,
                    oLevelAggregate: data?.[0]?.OLAGG,
                    aLevelAggregate: data?.[0]?.ALAGG,
                    iteAcadGpa: data?.[0]?.ITE_GPA,
                });
            } else {
                setEligibilityCriteria({
                    ID: "",
                    eligibilityCriteria: "",
                    prStatus: {
                        label: "", 
                        value: ""
                    },
                    feeLoanScheme: {
                        label: "",
                        value: ""
                    },
                    awardAmount: "",
                    ghiIncome: "",
                    mpciIncome: "",
                    fpciIncome: "",
                    oLevelAggregate: "",
                    aLevelAggregate: "",
                    iteAcadGpa: "",
                });

            }
            setDataDetailCriteria(data);


            //get semua data criteria

            //Course
            const courseResponse = await getCourseCriteria(params);

            const courseData = courseResponse?.data?.data;


            const courseOptions = courseResponse?.data?.data?.map((option) => ({
                value: option.COURSE_CODE,
                label: option.COURSE_CODE,
            }));

            const updatedCourse = courseData?.map((course) => ({
                ...course,
                options: courseOptions,
            }));

            setCourse(updatedCourse);

            setCourseOptions(courseOptions);

            //Citizenship
            const citizenshipResponse = await getCitizenshipCriteria(params);

            const citizenshipOptionResponse = await getCodeTypeSasList("12");

            const citizenshipData = citizenshipResponse?.data?.data;

            const citizenshipOptions = citizenshipOptionResponse?.data?.data?.map(
                (option) => ({
                    value: option.code,
                    label: `${option.code} - ${option.description}`,
                })
            );

            const updatedCitizenship = citizenshipData?.map((citizenship) => ({
                ...citizenship,
                options: citizenshipOptions,
            }));

            setCitizenship(updatedCitizenship);

            setCitizenshipOptions(citizenshipOptions);

            //CWA
            const cwaResponse = await getCWACriteria(params);

            const cwaData = cwaResponse?.data?.data;

            const cwaOptions = cwaResponse?.data?.data?.map((option) => ({
                value: option.STUDY_STAGE,
                label: option.STUDY_STAGE,
                cwa: option.CWA,
            }));

            const updatedCWA = cwaData?.map((cwa) => ({
                ...cwa,
                options: cwaOptions,
            }));

            setCWA(updatedCWA);

            setCWAOptions(cwaOptions);

            //GPA
            const gpaResponse = await getGPACriteria(params);

            const gpaData = gpaResponse?.data?.data;

            const gpaOptions = gpaResponse?.data?.data?.map((option) => ({
                value: option.STUDY_STAGE,
                label: option.STUDY_STAGE,
                gpa: option.GPA,
            }));

            const updatedGPA = gpaData?.map((gpa) => ({
                ...gpa,
                options: gpaOptions,
            }));

            setGPA(updatedGPA);

            setGPAOptions(gpaOptions);


            const excludedReceivedResponse = await getExcludedAwardCriteria(params);
            const excludedReceivedData = excludedReceivedResponse?.data?.data;
           
            const updatedExcludedReceivedData = excludedReceivedData?.map((content) => {

                let awardContent = awardOptions?.filter((option) => option?.value == content.RECEIVED_AWARD_TYPE)
                
                return({
                ...content,
                description: awardContent?.length > 0 ? awardContent[0]?.description : "",
                receivedReward: {
                    label: awardContent?.length > 0 ? awardContent[0]?.description : "",
                    value: content?.RECEIVED_AWARD_TYPE,
                },
            })});

            setExcludeReceived(updatedExcludedReceivedData);

            setRetrieved(true);
        } catch (error) {
            setRetrieved(false);
        }
    };

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const handleChangeCourseInput = (input, index) => {
        //api dont handle search
        setTimeout(() => {
            //ganti api getcourse criteria
            getTimelineDT("", "", input).then((response) => {
                if (response?.data?.data?.length > 0) {
                    const options = response?.data?.data?.map((course) => ({
                        value: course.COURSE_CODE,
                        label: course.COURSE_CODE,
                    }));

                    setCourse((prevCourses) => {
                        const updatedCourses = [...prevCourses];
                        updatedCourses[index].options = options;
                        return updatedCourses;
                    });
                }
            });
        }, 500);
    };

    return (
        <Layout currentNav={"processing"} toc={true}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Application Processing</Breadcrumb.Item>
                <Breadcrumb.Item active>Setup Recommendation Criteria</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Setup Recommendation Criteria</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className="d-flex gap-4 flex-column">
                    <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                        <div className="row">
                            <div className="col">
                                <h3>Data Retrieval</h3>
                            </div>
                        </div>

                        <Form
                            ref={contentRef}
                            className={` d-flex flex-column gap-3 collapse-animation`}
                            style={{
                                overflow: isOpen ? "unset" : "hidden",
                                maxHeight: isOpen
                                    ? `${
                                        contentRef?.current?.scrollHeight == null
                                            ? "100%"
                                            : contentRef?.current?.scrollHeight
                                    }px`
                                    : "0px",
                            }}

                            onSubmit={handleSubmitRetrieval}
                        >
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Award Type</Form.Label>
                                        <Select
                                            options={dataAwardType}
                                            onChange={(value) => handleChangeAwardType(value)}
                                            placeholder={`Types of Award`}
                                            value={retrieval?.awardType}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Award</Form.Label>
                                        <Select
                                            isDisabled={
                                                !retrieval?.awardType?.value || loadingListAward
                                            }
                                            isLoading={loadingListAward}
                                            options={dataAward}
                                            onChange={(value) =>
                                                setRetrieval((prevState) => ({
                                                    ...prevState,
                                                    award: value,
                                                }))
                                            }
                                            placeholder={`Award`}
                                            value={retrieval?.award}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>
                                    <Button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={
                                            !retrieval?.awardType?.value || !retrieval?.award?.value
                                        }
                                    >
                                        Retrieve
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <button
                            onClick={toggleCollapse}
                            className="btn-clear btn-link ps-0 mt-3 d-flex gap-2 align-items-center"
                        >
                            {isOpen ? "Hide " : "Expand "}{" "}
                            {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                        </button>
                    </div>

                    {retrieved && (
                        <>
                            {editable ? (
                                <button
                                    className="bg-white border-1 d-flex gap-1 px-3 rounded-2"
                                    style={{width: "fit-content"}}
                                    onClick={() => setEditable(false)}
                                >
                                    <i className={`bi bi-pencil`}></i>Edit
                                </button>
                            ) : (
                                <button
                                    className={`bg-white border-1 text-green-500 d-flex gap-1 px-3 rounded-2`}
                                    style={{width: "fit-content"}}
                                    onClick={handleSaveCriteria}
                                >
                                    <i className={`bi bi-check-lg`}></i>Save
                                </button>
                            )}

                            <Accordion ref={refAwardInformation}>
                                <Accordion.Item className={`border-0 shadow`}>
                                    <Accordion.Header className="header-text text-blue">
                                        Award Information
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group>
                                            <Form.Label>Award Type</Form.Label>
                                            <Form.Control
                                                type={"text"}
                                                readOnly={true}
                                                placeholder={`Types of Award`}
                                                value={retrieval?.awardType?.label}
                                            ></Form.Control>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Award</Form.Label>
                                            <Form.Control
                                                type={"text"}
                                                readOnly={true}
                                                placeholder={`Award`}
                                                value={retrieval?.award?.label}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ref={refEligibilityCriteria}>
                                <Accordion.Item className={`border-0 shadow`}>
                                    <Accordion.Header className="header-text text-blue">
                                        Eligibility Criteria
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col xs={12}>
                                                <Form.Group>
                                                    <Form.Label>Eligibility Criteria</Form.Label>
                                                    <Form.Control
                                                        type={"text"}
                                                        required={true}
                                                        disabled={editable}
                                                        onChange={(e) =>
                                                            setEligibilityCriteria((prevState) => ({
                                                                ...prevState,
                                                                eligibilityCriteria: e?.target?.value,
                                                            }))
                                                        }
                                                        value={eligibilityCriteria?.eligibilityCriteria}
                                                        isInvalid={eligibilityCriteria?.eligibilityCriteria?.length <= 0}
                                                    ></Form.Control>
                                                    <Form.Control.Feedback type={"invalid"}>Is Required</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>PR Status</Form.Label>
                                                    <Select
                                                        backspaceRemovesValue={true}
                                                        isDisabled={editable}
                                                        options={[
                                                            {label: "PR", value: "Y"},
                                                            {
                                                                label: "Non-PR",
                                                                value: "N",
                                                            },
                                                        ]}
                                                        onChange={(value) =>
                                                            setEligibilityCriteria((prevState) => ({
                                                                ...prevState,
                                                                prStatus: value,
                                                            }))
                                                        }
                                                        placeholder={`PR Status`}
                                                        value={eligibilityCriteria?.prStatus}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>On Tuition Fee Loan Scheme</Form.Label>
                                                    <Select
                                                        backspaceRemovesValue={true}
                                                        isDisabled={editable}
                                                        options={[
                                                            {
                                                                label: "Tuition Loan Scheme",
                                                                value: "Y",
                                                            },
                                                            {
                                                                label: "Not On Tuition Loan Scheme",
                                                                value: "N",
                                                            },
                                                        ]}
                                                        onChange={(value) =>
                                                            setEligibilityCriteria((prevState) => ({
                                                                ...prevState,
                                                                feeLoanScheme: value,
                                                            }))
                                                        }
                                                        value={eligibilityCriteria?.feeLoanScheme}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>Less than Awards Amount</Form.Label>
                                                    <Form.Control
                                                        type={"text"}
                                                        disabled={editable}
                                                        onChange={(e) => {
                                                            const value = e.target.value;

                                                            const regex = /^\d*\.?\d{0,2}$/;

                                                            if (value === "" || regex.test(value)) {
                                                                setEligibilityCriteria((prevState) => ({
                                                                    ...prevState,
                                                                    awardAmount: value,
                                                                }));
                                                            }
                                                        }}
                                                        value={eligibilityCriteria?.awardAmount}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Row className={`align-items-center`}>
                                                    <Col xs={5}>
                                                        <Form.Group>
                                                            <Form.Label>Less than GHI</Form.Label>
                                                            <Form.Control
                                                                type={"text"}
                                                                disabled={editable}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;

                                                                    const regex = /^\d*\.?\d{0,2}$/;

                                                                    if (value === "" || regex.test(value)) {
                                                                        setEligibilityCriteria((prevState) => ({
                                                                            ...prevState,
                                                                            ghiIncome: e?.target?.value,
                                                                        }));
                                                                    }
                                                                }}
                                                                value={eligibilityCriteria?.ghiIncome}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={1} className={`mt-4`}>
                                                        OR
                                                    </Col>

                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Less than MPCI</Form.Label>
                                                            <Form.Control
                                                                type={"text"}
                                                                disabled={editable}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;

                                                                    const regex = /^\d*\.?\d{0,2}$/;

                                                                    if (value === "" || regex.test(value)) {
                                                                        setEligibilityCriteria((prevState) => ({
                                                                            ...prevState,
                                                                            mpciIncome: e?.target?.value,
                                                                        }));
                                                                    }
                                                                }}
                                                                value={eligibilityCriteria?.mpciIncome}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            {/*<Col xs={6}>*/}
                                            {/*    <Form.Group>*/}
                                            {/*        <Form.Label>Less than FPCI</Form.Label>*/}
                                            {/*        <Form.Control*/}
                                            {/*            type={"text"}*/}
                                            {/*            disabled={editable}*/}
                                            {/*            onChange={(e) => {*/}
                                            {/*                const value = e.target.value;*/}

                                            {/*                const regex = /^\d*\.?\d{0,2}$/;*/}

                                            {/*                if (value === '' || regex.test(value)) {*/}
                                            {/*                    setEligibilityCriteria((prevState) => ({*/}
                                            {/*                        ...prevState, fpciIncome: e?.target?.value,*/}
                                            {/*                    }))*/}
                                            {/*                }*/}
                                            {/*            }}*/}
                                            {/*            value={eligibilityCriteria?.fpciIncome}*/}
                                            {/*        ></Form.Control>*/}
                                            {/*    </Form.Group>*/}
                                            {/*</Col>*/}

                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Less than &apos;O&apos; level aggregate
                                                    </Form.Label>
                                                    <Form.Control
                                                        type={"text"}
                                                        disabled={editable}
                                                        onChange={(e) => {
                                                            const value = e.target.value;

                                                            const regex = /^\d*$/;

                                                            if (value === "" || regex.test(value)) {
                                                                setEligibilityCriteria((prevState) => ({
                                                                    ...prevState,
                                                                    oLevelAggregate: e?.target?.value,
                                                                }));
                                                            }
                                                        }}
                                                        value={eligibilityCriteria?.oLevelAggregate}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>

                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        More than &apos;A&apos; Level Aggregate
                                                    </Form.Label>
                                                    <Form.Control
                                                        type={"text"}
                                                        disabled={editable}
                                                        onChange={(e) => {
                                                            const value = e.target.value;

                                                            const regex = /^\d*$/;

                                                            if (value === "" || regex.test(value)) {
                                                                setEligibilityCriteria((prevState) => ({
                                                                    ...prevState,
                                                                    aLevelAggregate: e?.target?.value,
                                                                }));
                                                            }
                                                        }}
                                                        value={eligibilityCriteria?.aLevelAggregate}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>

                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>More than ITE ACAD GPA</Form.Label>
                                                    <Form.Control
                                                        type={"text"}
                                                        disabled={editable}
                                                        onChange={(e) => {
                                                            const value = e.target.value;

                                                            const regex = /^\d*\.?\d{0,2}$/;

                                                            if (value === "" || regex.test(value)) {
                                                                setEligibilityCriteria((prevState) => ({
                                                                    ...prevState,
                                                                    iteAcadGpa: e?.target?.value,
                                                                }));
                                                            }
                                                        }}
                                                        value={eligibilityCriteria?.iteAcadGpa}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ref={refCourse}>
                                <Accordion.Item className={`border-0 shadow`}>
                                    <Accordion.Header className="header-text text-blue">
                                        Course
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className={`text-muted fst-italic my-3`}>
                                            Leave the fields blank to indicate all type of Course can
                                            be recommended for the award.
                                            <button
                                                className={`btn-clear d-flex align-items-center gap-1 ${
                                                    editable ? "text-black-50" : "text-blue"
                                                }`}
                                                disabled={editable}
                                                onClick={() =>
                                                    setCourse((prevState) => [
                                                        ...prevState,
                                                        {
                                                            id:
                                                                prevState?.[prevState?.length - 1]
                                                                    ?.id + 1,
                                                            COURSE_CODE: "",
                                                            options: courseOptions,
                                                        },
                                                    ])
                                                }
                                            >
                                                <i className={`bi bi-plus-circle`}></i>
                                                add row
                                            </button>

                                        </div>

                                        <div className={`d-flex flex-column gap-2`}>
                                            {course?.length > 0
                                                ? course?.map((item, index) => {
                                                    return (
                                                        <Row
                                                            key={item?.id}
                                                            className={`align-items-center`}
                                                        >
                                                            <Col xs={2}>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        disabled={true}
                                                                        onChange={(e) => {
                                                                            const currentData = [...course];
                                                                            const index = currentData?.findIndex(
                                                                                (data) => data?.id === item?.id
                                                                            );

                                                                            currentData[index].sn = e.target.value;

                                                                            setCourse(currentData);
                                                                        }}
                                                                        placeholder={`S/N`}
                                                                        value={index + 1}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={7}>
                                                                <Form.Group>
                                                                    <Form.Control type={"text"}
                                                                                  disabled={editable}
                                                                                  value={course[index].COURSE_CODE}
                                                                                  onChange={(e) => {
                                                                                      const currentData = [...course];

                                                                                      currentData[index].COURSE_CODE =
                                                                                          e.target.value;

                                                                                      setCourse(currentData);
                                                                                  }}
                                                                    />

                                                                    {/*<Select*/}
                                                                    {/*    value={{*/}
                                                                    {/*        value: course[index].COURSE_CODE,*/}
                                                                    {/*        label: course[index].COURSE_CODE,*/}
                                                                    {/*    }}*/}
                                                                    {/*    options={course[index].options}*/}
                                                                    {/*    isDisabled={editable}*/}
                                                                    {/*    // onInputChange={(input) =>*/}
                                                                    {/*    //     handleChangeCourseInput(input, index)*/}
                                                                    {/*    // }*/}
                                                                    {/*    onChange={(selectedOption) => {*/}
                                                                    {/*        const currentData = [...course];*/}
                                                                    {/*        // const index = currentData?.findIndex((data) => data?.id === item?.id);*/}

                                                                    {/*        currentData[index].COURSE_CODE =*/}
                                                                    {/*            selectedOption.value;*/}

                                                                    {/*        setCourse(currentData);*/}
                                                                    {/*    }}*/}
                                                                    {/*/>*/}
                                                                </Form.Group>
                                                            </Col>

                                                            {index === 0 ? (
                                                                <Col xs={3} className={`d-flex gap-2`}>
                                                                    {/* 
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable}
                                                                        onClick={() => {
                                                                            // const index = course?.findIndex(
                                                                            //     (data) => data?.ID === item?.ID
                                                                            // );
                                                                            let currentData = [...course];

                                                                            if (currentData?.length === 1) {
                                                                                toast.warning(
                                                                                    "Cannot remove last row"
                                                                                );
                                                                                return;
                                                                            }

                                                                            currentData?.splice(index, 1);

                                                                            setCourse(currentData);
                                                                        }}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove row
                                                                    </button>
                                                                    
                                                                    */}
                                                                </Col>
                                                            ) : (
                                                                <Col xs={3}>
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable}
                                                                        onClick={() => {
                                                                            // const index = course?.findIndex(
                                                                            //     (courseData) => courseData?.ID === item?.ID
                                                                            // );

                                                                            let currentData = [...course];

                                                                            currentData?.splice(index, 1);

                                                                            setCourse(currentData);
                                                                        }}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove row
                                                                    </button>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    );
                                                })
                                                : null}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ref={refCitizenship}>
                                <Accordion.Item className={`border-0 shadow`}>
                                    <Accordion.Header className="header-text text-blue">
                                        Citizenship
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className={`text-muted fst-italic my-3`}>
                                            Leave the fields blank to indicate all type of Citizenship
                                            can be recommended for the award.
                                            <button
                                                className={`btn-clear d-flex align-items-center gap-1 ${
                                                    editable ? "text-black-50" : "text-blue"
                                                }`}
                                                disabled={editable}
                                                onClick={() =>
                                                    setCitizenship((prevState) => [
                                                        ...prevState,
                                                        {
                                                            id:
                                                                prevState?.[prevState?.length - 1]
                                                                    ?.id + 1,
                                                            CITIZENSHIP: "",
                                                            options: citizenshipOptions,
                                                        },
                                                    ])
                                                }
                                            >
                                                <i className={`bi bi-plus-circle`}></i>
                                                add row
                                            </button>

                                        </div>

                                        <div className={`d-flex flex-column gap-2`}>
                                            {citizenship?.length > 0
                                                ? citizenship?.map((item, index) => {
                                                    return (
                                                        <Row
                                                            key={item?.id}
                                                            className={`align-items-center`}
                                                        >
                                                            <Col xs={2}>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        disabled={true}
                                                                        onChange={(e) => {
                                                                            const currentData = [...citizenship];
                                                                            const index = currentData?.findIndex(
                                                                                (data) => data?.ID === item?.ID
                                                                            );

                                                                            currentData[index].sn = e.target.value;

                                                                            setCitizenship(currentData);
                                                                        }}
                                                                        placeholder={`S/N`}
                                                                        value={index + 1}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={7}>
                                                                <Form.Group>
                                                                    <Select
                                                                        value={citizenship[index].options.find(option => option.value === citizenship[index].CITIZENSHIP)}
                                                                        options={citizenship[index].options}
                                                                        isDisabled={editable}
                                                                        onChange={(selectedOption) => {
                                                                            const currentData = [...citizenship];
                                                                            // const index = currentData?.findIndex((data) => data?.id === item?.id);

                                                                            currentData[index].CITIZENSHIP =
                                                                                selectedOption.value;

                                                                            setCitizenship(currentData);
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>

                                                            {index === 0 ? (
                                                                <Col xs={3} className={`d-flex gap-2`}>
                                                                    {/* 
                                                                    
                                                                    
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable}
                                                                        onClick={() => {
                                                                            // const index = citizenship?.findIndex(
                                                                            //     (data) => data?.ID === item?.ID
                                                                            // );
                                                                            let currentData = [...citizenship];

                                                                            if (currentData?.length === 1) {
                                                                                toast.warning(
                                                                                    "Cannot remove last row"
                                                                                );
                                                                                return;
                                                                            }

                                                                            currentData?.splice(index, 1);

                                                                            setCitizenship(currentData);
                                                                        }}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove row
                                                                    </button>
                                                                    
                                                                    */}
                                                                </Col>
                                                            ) : (
                                                                <Col xs={3}>
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable}
                                                                        onClick={() => {
                                                                            // const index = citizenship?.findIndex(
                                                                            //     (data) => data?.ID === item?.ID
                                                                            // );
                                                                            let currentData = [...citizenship];

                                                                            currentData?.splice(index, 1);

                                                                            setCitizenship(currentData);
                                                                        }}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove row
                                                                    </button>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    );
                                                })
                                                : null}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ref={refCWA}>
                                <Accordion.Item className={`border-0 shadow`}>
                                    <Accordion.Header className="header-text text-blue">
                                        Study Stage & CWA
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className={`text-muted fst-italic my-3`}>
                                            Leave the fields blank to indicate all type of Course can
                                            be recommended for the award.
                                            <button
                                                className={`btn-clear d-flex align-items-center gap-1 ${
                                                    editable ? "text-black-50" : "text-blue"
                                                }`}
                                                disabled={editable}
                                                onClick={() =>
                                                    setCWA((prevState) => [
                                                        ...prevState,
                                                        {
                                                            id:
                                                                prevState?.[prevState?.length - 1]
                                                                    ?.id + 1,
                                                            STUDY_STAGE: "",
                                                            CWA: "",
                                                            options: cwaOptions,
                                                        },
                                                    ])
                                                }
                                            >
                                                <i className={`bi bi-plus-circle`}></i>
                                                add row
                                            </button>

                                        </div>

                                        <div className={`d-flex flex-column gap-2`}>
                                            {cwa?.length > 0
                                                ? cwa?.map((item, index) => {
                                                    return (
                                                        <Row
                                                            key={item?.id}
                                                            className={`align-items-center`}
                                                        >
                                                            <Col xs={2}>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        disabled={true}
                                                                        onChange={(e) => {
                                                                            const currentData = [...cwa];
                                                                            const index = currentData?.findIndex(
                                                                                (data) => data?.id === item?.id
                                                                            );

                                                                            currentData[index].sn = e.target.value;

                                                                            setCWA(currentData);
                                                                        }}
                                                                        placeholder={`S/N`}
                                                                        value={index + 1}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={3}>
                                                                <Form.Group>
                                                                    <Form.Control type={"text"}
                                                                                  value={item?.STUDY_STAGE}
                                                                                  placeholder={`Input Study Stage`}
                                                                                  onChange={(e) => {
                                                                                      const currentData = [...cwa]
                                                                                      currentData[index].STUDY_STAGE =
                                                                                          e.target.value;
                                                                                      setCWA(currentData)
                                                                                  }}
                                                                                  disabled={editable}
                                                                    ></Form.Control>
                                                                    {/*<Select*/}
                                                                    {/*    value={{*/}
                                                                    {/*        value: cwa[index].STUDY_STAGE,*/}
                                                                    {/*        label: cwa[index].STUDY_STAGE,*/}
                                                                    {/*    }}*/}
                                                                    {/*    options={cwa[index].options}*/}
                                                                    {/*    isDisabled={editable}*/}
                                                                    {/*    onChange={(selectedOption) => {*/}
                                                                    {/*        const currentData = [...cwa];*/}

                                                                    {/*        currentData[index].STUDY_STAGE =*/}
                                                                    {/*            selectedOption.value;*/}
                                                                    {/*        currentData[index].CWA =*/}
                                                                    {/*            selectedOption.cwa;*/}

                                                                    {/*        setCWA(currentData);*/}
                                                                    {/*    }}*/}
                                                                    {/*/>*/}
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={4}>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        type={"number"}
                                                                        disabled={editable}
                                                                        placeholder={`More than or equal to CWA`}
                                                                        value={item?.CWA}
                                                                        onChange={(e) => {
                                                                            const currentData = [...cwa]
                                                                            currentData[index].CWA =
                                                                                e.target.value;
                                                                            setCWA(currentData);
                                                                        }}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>

                                                            {index === 0 ? (
                                                                <Col xs={3} className={`d-flex gap-2`}>
                                                                    {/* 
                                                                    
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable}
                                                                        onClick={() => {
                                                                            // const index = cwa?.findIndex(
                                                                            //     (data) => data?.ID === item?.ID
                                                                            // );
                                                                            let currentData = [...cwa];

                                                                            if (currentData?.length === 1) {
                                                                                toast.warning(
                                                                                    "Cannot remove last row"
                                                                                );
                                                                                return;
                                                                            }

                                                                            currentData?.splice(index, 1);

                                                                            setCWA(currentData);
                                                                        }}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove row
                                                                    </button>
                                                                    
                                                                    */}
                                                                </Col>
                                                            ) : (
                                                                <Col xs={3}>
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable}
                                                                        onClick={() => {
                                                                            // const index = cwa?.findIndex(
                                                                            //     (data) => data?.ID === item?.ID
                                                                            // );
                                                                            let currentData = [...cwa];

                                                                            currentData?.splice(index, 1);

                                                                            setCWA(currentData);
                                                                        }}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove row
                                                                    </button>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    );
                                                })
                                                : null}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ref={refGPA}>
                                <Accordion.Item className={`border-0 shadow`}>
                                    <Accordion.Header className="header-text text-blue">
                                        Study Stage & GPA
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className={`text-muted fst-italic my-3`}>
                                            Leave the fields blank to indicate all students in any
                                            stage and GPA can be recommended for the award.
                                            <button
                                                className={`btn-clear d-flex align-items-center gap-1 ${
                                                    editable ? "text-black-50" : "text-blue"
                                                }`}
                                                disabled={editable}
                                                onClick={() =>
                                                    setGPA((prevState) => [
                                                        ...prevState,
                                                        {
                                                            id:
                                                                prevState?.[prevState?.length - 1]
                                                                    ?.id + 1,
                                                            gpa: "",
                                                            sn: "",
                                                            studyStage: "",
                                                        },
                                                    ])
                                                }
                                            >
                                                <i className={`bi bi-plus-circle`}></i>
                                                add row
                                            </button>

                                        </div>

                                        <div className={`d-flex flex-column gap-2`}>
                                            {gpa?.length > 0
                                                ? gpa?.map((item, index) => {
                                                    return (
                                                        <Row
                                                            key={item?.id}
                                                            className={`align-items-center`}
                                                        >
                                                            <Col xs={2}>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        disabled={true}
                                                                        onChange={(e) => {
                                                                            const currentData = [...gpa];
                                                                            const index = currentData?.findIndex(
                                                                                (data) => data?.id === item?.id
                                                                            );

                                                                            currentData[index].sn = e.target.value;

                                                                            setGPA(currentData);
                                                                        }}
                                                                        placeholder={`S/N`}
                                                                        value={index + 1}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={3}>
                                                                <Form.Group>
                                                                    <Form.Control type={"text"}
                                                                                  placeholder={`Input Study Stage`}
                                                                                  value={item?.STUDY_STAGE}
                                                                                  onChange={(e) => {
                                                                                      const currentData = [...gpa]
                                                                                      currentData[index].STUDY_STAGE =
                                                                                          e.target.value;
                                                                                      setGPA(currentData)
                                                                                  }}
                                                                                  disabled={editable}
                                                                    ></Form.Control>

                                                                    {/*<Select*/}
                                                                    {/*    value={{*/}
                                                                    {/*        value: gpa[index].STUDY_STAGE,*/}
                                                                    {/*        label: gpa[index].STUDY_STAGE,*/}
                                                                    {/*    }}*/}
                                                                    {/*    options={gpa[index].options}*/}
                                                                    {/*    isDisabled={editable}*/}
                                                                    {/*    onChange={(selectedOption) => {*/}
                                                                    {/*        const currentData = [...gpa];*/}

                                                                    {/*        currentData[index].STUDY_STAGE =*/}
                                                                    {/*            selectedOption.value;*/}
                                                                    {/*        currentData[index].GPA =*/}
                                                                    {/*            selectedOption.gpa;*/}

                                                                    {/*        setGPA(currentData);*/}
                                                                    {/*    }}*/}
                                                                    {/*/>*/}
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={4}>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        type={"number"}
                                                                        disabled={editable}
                                                                        placeholder={`More than or equal to GPA`}
                                                                        value={item?.GPA}
                                                                        onChange={(e) => {
                                                                            const currentData = [...gpa]
                                                                            currentData[index].GPA =
                                                                                e.target.value;
                                                                            setGPA(currentData);
                                                                        }}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>

                                                            {index === 0 ? (
                                                                <Col xs={3} className={`d-flex gap-2`}>
                                                                    {/* 
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable}
                                                                        onClick={() => {
                                                                            let currentData = [...gpa];

                                                                            if (currentData?.length === 1) {
                                                                                toast.warning(
                                                                                    "Cannot remove last row"
                                                                                );
                                                                                return;
                                                                            }

                                                                            currentData?.splice(index, 1);

                                                                            setGPA(currentData);
                                                                        }}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove row
                                                                    </button>
                                                                     */}
                                                                </Col>
                                                            ) : (
                                                                <Col xs={3}>
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable}
                                                                        onClick={(e) => {

                                                                            // const index = gpa?.findIndex(
                                                                            //     (data) => data?.ID === item?.ID
                                                                            // );
                                                                            let currentData = [...gpa];

                                                                            currentData?.splice(index, 1);

                                                                            setGPA(currentData);
                                                                        }}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove row
                                                                    </button>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    );
                                                })
                                                : null}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ref={refExcludeReceived}>
                                <Accordion.Item className={`border-0 shadow`}>
                                    <Accordion.Header className="header-text text-blue">
                                        Exclude Received Award Type
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className={`text-muted fst-italic my-3`}>
                                            based on year awarded in student financial award.
                                            <button
                                                className={`btn-clear d-flex align-items-center gap-1 ${
                                                    editable ? "text-black-50" : "text-blue"
                                                }`}
                                                disabled={editable}
                                                onClick={() =>
                                                    setExcludeReceived((prevState) => [
                                                        ...prevState,
                                                        {
                                                            id:
                                                                prevState?.[prevState?.length - 1]
                                                                    ?.id + 1,
                                                            description: "",
                                                            sn: "",
                                                            receivedReward: {
                                                                label: "",
                                                                value: ""
                                                            },
                                                        },
                                                    ])
                                                }
                                            >
                                                <i className={`bi bi-plus-circle`}></i>
                                                add row
                                            </button>

                                        </div>

                                        <div className={`d-flex flex-column gap-2`}>
                                            {excludeReceived?.length > 0
                                                ? excludeReceived?.map((item, index) => {
                                                    return (
                                                        <Row
                                                            key={item?.id}
                                                            className={`align-items-center`}
                                                        >
                                                            <Col xs={2}>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        disabled={true}
                                                                        onChange={(e) => {
                                                                            const currentData = [
                                                                                ...excludeReceived,
                                                                            ];
                                                                            currentData[index].sn =
                                                                                e.target.value;
                                                                            setExcludeReceived(currentData);
                                                                        }}
                                                                        placeholder={`S/N`}
                                                                        value={index + 1}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={3}>
                                                                <Form.Group>
                                                                    <Select
                                                                        backspaceRemovesValue={true}
                                                                        isDisabled={editable}
                                                                        options={awardOptions}
                                                                        onChange={(option) => {
                                                                            const currentData = [
                                                                                ...excludeReceived,
                                                                            ];

                                                                            currentData[index].receivedReward =
                                                                                option;

                                                                            currentData[index].description =
                                                                                option.description;

                                                                            setExcludeReceived(currentData);
                                                                        }}
                                                                        placeholder={`Received Reward`}
                                                                        value={awardOptions.find(option => option.value === item?.receivedReward?.value)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>

                                                            <Col xs={4}>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        disabled={true}
                                                                        onChange={(e) => {
                                                                            const currentData = [
                                                                                ...excludeReceived,
                                                                            ];

                                                                            currentData[index].description =
                                                                                e.target.value;

                                                                            setExcludeReceived(currentData);
                                                                        }}
                                                                        placeholder={`Description`}
                                                                        value={item?.description}
                                                                    ></Form.Control>
                                                                </Form.Group>
                                                            </Col>

                                                            {index === 0 ? (

                                                                <Col xs={3} className={`d-flex gap-2`}>
                                                                    {/* 
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable}
                                                                        onClick={() => {
                                                                            let currentData = [...excludeReceived];

                                                                            if (currentData?.length === 1) {
                                                                                toast.warning(
                                                                                    "Cannot remove last row"
                                                                                );
                                                                                return;
                                                                            }

                                                                            currentData?.splice(index, 1);

                                                                            setExcludeReceived(currentData);
                                                                        }}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove row
                                                                    </button>
                                                                    
                                                                    */}
                                                                </Col>
                                                            ) : (
                                                                <Col xs={3}>
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable}
                                                                        onClick={() => {
                                                                            // const index =
                                                                            //     excludeReceived?.findIndex(
                                                                            //         (data) => data?.ID === item?.ID
                                                                            //     );
                                                                            let currentData = [...excludeReceived];

                                                                            currentData?.splice(index, 1);

                                                                            setExcludeReceived(currentData);
                                                                        }}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove row
                                                                    </button>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    );
                                                })
                                                : null}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                    )}
                </div>
                <CommonModal
                    show={showDeleteModal}
                    handleClose={() => setShowDeleteModal(false)}
                    description={`Are you sure you want to delete?`}
                    title={`Delete?`}
                    submitButtonText={"Yes"}
                    action={() => {return null}}
                />
                <CommonModal
                    show={showDeleteModal}
                    handleClose={() => setShowDeleteModal(false)}
                    description={`Are you sure you want to delete?`}
                    title={`Delete?`}
                    submitButtonText={"Yes"}
                    action={() => {
                        return null
                    }}
                />
                <CommonModal
                    show={showDeleteModal}
                    handleClose={() => setShowDeleteModal(false)}
                    description={`Are you sure you want to delete?`}
                    title={`Delete?`}
                    submitButtonText={"Yes"}
                    action={() => {return false}}
                />
                <CommonModal
                    show={showDeleteModal}
                    handleClose={() => setShowDeleteModal(false)}
                    description={`Are you sure you want to delete?`}
                    title={`Delete?`}
                    submitButtonText={"Yes"}
                    action={() => {return false}}
                />

            </sgds-content-body>
        </Layout>
    );
}
