import React from "react";
import { Col, Form, FormCheck, Row } from "@govtechsg/sgds-react";
import { Accordion } from "@govtechsg/sgds-react/Accordion";
import moment from "moment";

function OriginalDetails({
  isDisabled,
  details,
  parentLog,
  siblingLog,
  studentDetail,
  handleShowFormulaModal,
}) {
  return (
    <React.Fragment>
      <div>
        <Row>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Last Update ID</Form.Label>
              <Form.Control
                type="text"
                disabled={true}
                value={details?.LAST_UPD_ID}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Last Update Date</Form.Label>
              <Form.Control
                type="text"
                disabled={true}
                value={
                  details?.UPDATED_AT
                    ? moment(details?.UPDATED_AT).format("DD/MM/YYYY hh:mm:ss")
                    : ""
                }
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="mt-4">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Father
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "F"
                          )?.NAME
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "F"
                          )?.STATUS
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>NRIC / Passport No</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "F"
                          )?.ID_NO
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Birth Date</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={moment(
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "F"
                          )?.BIRTHDATE
                        ).format("DD/MM/YYYY")}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Age</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "F"
                          )?.AGE
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  {/*<Col xs={6}>*/}
                  {/*    <Form.Group>*/}
                  {/*        <Form.Label>Occupation</Form.Label>*/}
                  {/*        <Form.Control*/}
                  {/*            type="text"*/}
                  {/*            disabled={true}*/}
                  {/*            value={`-`}*/}
                  {/*        ></Form.Control>*/}
                  {/*    </Form.Group>*/}
                  {/*</Col>*/}
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Other Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "F"
                          )?.OTHER_INCOME ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Gross Monthly Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "F"
                          )?.MTHLY_INCOME_CONTRIB ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Child Support</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "F"
                          )?.CHILD_SUPPORT ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Remark</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "F"
                          )?.REMARK ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="mt-4">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Mother
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "M"
                          )?.NAME
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "M"
                          )?.STATUS
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>NRIC / Passport No</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "M"
                          )?.ID_NO
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Birth Date</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={moment(
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "M"
                          )?.BIRTHDATE
                        ).format("DD/MM/YYYY")}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Age</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "M"
                          )?.AGE
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  {/*<Col xs={6}>*/}
                  {/*    <Form.Group>*/}
                  {/*        <Form.Label>Occupation</Form.Label>*/}
                  {/*        <Form.Control*/}
                  {/*            type="text"*/}
                  {/*            disabled={true}*/}
                  {/*            value={`-`}*/}
                  {/*        ></Form.Control>*/}
                  {/*    </Form.Group>*/}
                  {/*</Col>*/}
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Other Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "M"
                          )?.OTHER_INCOME ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Gross Monthly Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "M"
                          )?.MTHLY_INCOME_CONTRIB ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Child Support</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "M"
                          )?.CHILD_SUPPORT ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Remark</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.RELATIONSHIP === "M"
                          )?.REMARK ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="mt-4">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Sibling / Relatives
            </Accordion.Header>
            <Accordion.Body>
              {siblingLog?.map((sibling, index) => (
                <React.Fragment>
                  <div className={`mt-3`}>
                    <h4>#{index + 1} Sibling / Relatives</h4>
                  </div>
                  <div>
                    <Row>
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.NAME}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>NRIC / Passport No</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.ID_NO}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>Birth Date</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={moment(sibling?.BIRTHDATE).format(
                              "DD/MM/YYYY"
                            )}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>Age</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.AGE}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>Relationship</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.RELATIONSHIP}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>Marital Status</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.MARITAL_STATUS}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      {/*<Col xs={6}>*/}
                      {/*    <Form.Group>*/}
                      {/*        <Form.Label>Occupation</Form.Label>*/}
                      {/*        <Form.Control*/}
                      {/*            type="text"*/}
                      {/*            disabled={true}*/}
                      {/*            value={`-`}*/}
                      {/*        ></Form.Control>*/}
                      {/*    </Form.Group>*/}
                      {/*</Col>*/}
                      {/*<Col xs={6}>*/}
                      {/*    <Form.Group>*/}
                      {/*        <Form.Label>Other Income</Form.Label>*/}
                      {/*        <Form.Control*/}
                      {/*            type="text"*/}
                      {/*            disabled={true}*/}
                      {/*            value={sibling?.OTHER_INCOME}*/}
                      {/*        ></Form.Control>*/}
                      {/*    </Form.Group>*/}
                      {/*</Col>*/}
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>Monthly Contribution</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.MTHLY_CONTRIB}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>Remark</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.REMARK}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="mt-4">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Applicant
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={4}>
                    <Form.Group>
                      <Form.Label>Marital Status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.MARITAL_STATUS}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group>
                      <Form.Label>Dialect Group</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.DIALECT_GRP}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group>
                      <Form.Label>Housing Type</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.HOMEHOUSETYPE}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Applicant's Gross Monthly Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.MTHLY_INCOME}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Applicant's Other Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.OTHER_INCOME}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>GHI</Form.Label>
                      <Form.Text className="text-muted">
                        Gross Household Income
                      </Form.Text>

                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.GROSS_INCOME}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>MPCI</Form.Label>
                      <Form.Text className="text-muted">
                        Monthly Per Capital Income
                      </Form.Text>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.MPCI}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Number of Family</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.FAMILY_MEMBERS}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} className="mt-4">
                    <button
                      className={"btn btn-clear"}
                      onClick={() => handleShowFormulaModal()}
                    >
                      <u>Check formula note</u>
                    </button>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="mt-4">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Charged-in-court
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <FormCheck
                        id="Charged_in_a_court_of_law_in_any_country"
                        label="Charged in a court of law in any country"
                        name="Charged_in_a_court_of_law_in_any_country "
                        type="checkbox"
                        checked={details?.CHARGE_IN_COURT === "Y"}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="mt-4">
        <Accordion defaultActiveKey="1" className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Educational Information
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <FormCheck
                        id="Paying_moe_subsidised_fee"
                        label="Paying MOE subsidised fee"
                        name="Paying_moe_subsidised_fee "
                        type="checkbox"
                        disabled={true}
                        checked={details?.MOE_SUBSIDISED === "Y"}
                      />
                      <FormCheck
                        id="Company_sponsored"
                        label="Company Sponsored"
                        name="company_sponsored "
                        type="checkbox"
                        disabled={true}
                        checked={details?.COMP_SPONSOR_TAG === "Y"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </React.Fragment>
  );
}

export default OriginalDetails;
