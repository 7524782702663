import axios from "axios";
import { getJwt } from "@/apex";
import { apexAxiosCall } from "@/utils/apiCall";

export function getStudentList(params = {}) {
  let defaultParams = {
    studstatus: "",
    transfer_tag: "N",
    page: "1",
    limit: "20",
    finassist_tag: "Y",
    search: "",
    sort: "id_no",
  };

  params = { ...defaultParams, ...params };
  return apexAxiosCall(
    process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/getStudentList",
    params
  );
}

export function getStudentDetail(admNo, acadYear = null, params = {}) {
  let defaultParams = {
    studstatus: "",
    course_tag: "Y",
    gceo_tag: "Y",
    gcea_tag: "Y",
    finassist_tag: "Y",
    fasop_tag: "Y",
    nbfa_tag: "Y",
    transfer_tag: "Y",
    cca_tag: "Y",
    parent_tag: "Y",
  };
  let params_ = { ...defaultParams, ...params };
  if (acadYear !== null) {
    params_.fa_acad_yr = acadYear;
  }

  return apexAxiosCall(
    process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/getStudentInfo",
    {
      student_id: admNo,
      ...params_,
    }
  );
}

export function getCodeTypeSasList(codeTypeParams, code = "") {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/getSASCodeListing",
    {
      codeType: codeTypeParams,
      code,
    }
  );
}

export function saveStudentFinassists(input) {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/saveStudFinAssist",
    {
      data: input,
    }
  );
}

export function saveAwards(input) {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/saveSASAward",
    {
      data: input,
    }
  );
}

export async function getCodeList(codeType, code = "") {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/getSASCodeListing",
    {
      codeType,
      code,
    }
  );
}

export function getStudentListSiblingNYP(admNo, acadYear = null, params = {}) {
  let defaultParams = {
    studstatus: "",
    course_tag: "Y",
    gceo_tag: "Y",
    gcea_tag: "Y",
    finassist_tag: "Y",
    fasop_tag: "Y",
    nbfa_tag: "Y",
    transfer_tag: "Y",
    cca_tag: "Y",
    parent_tag: "Y",
    studynyp_tag: "Y",
  };
  let params_ = { ...defaultParams, ...params };
  if (acadYear !== null) {
    params_.fa_acad_yr = acadYear;
  }

  return apexAxiosCall(
    process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/getStudentInfo",
    {
      student_id: admNo,
      ...params_,
    }
  );
}

export function getCallingAPEXAPI() {
  getJwt(
    {
      codeType: "COURSE",
      code: "",
    },
    "POST"
  ).then(async (resp) => {
    console.log("Token POST getJWT dipanggil di fungsi sendiri");
    console.log(resp);
  });

  getJwt({}, "GET").then(async (resp) => {
    // console.log("Token GET getJWT dipanggil di fungsi sendiri");
    // console.log(resp);
    /*
    setTimeout(async () => {
      console.log(resp);
      // NYP
      axios
        .post(
          process.env.REACT_APP_NYP_SIMS_API_URL +
            "/resources/sp/getStudentList",
          {
            student_id: "240657P,240998D",
            studstatus: "W",
            transfer_tag: "N",
            page: "0",
            limit: "0",
            search: "",
            sort: "id_no",
          },
          {
            headers: {
              "x-apex-jwt": resp,
            },
          }
        )
        .then((responseDariApex) => {
          console.log("response API from APEX");
          console.log(responseDariApex);
        })
        .catch((err) => {
          console.error(err);
        });
    }, 0);
    */
  });
}
