import axios from "axios";


export async function getDataUnmatch(
    ACAD_YR = "",
    REC_AWARD_TYPE = "",
    REC_AWARD_CODE = ""
) {
    return axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/applications/postals/unmatch?ACAD_YR=${ACAD_YR}&REC_AWARD_TYPE=${REC_AWARD_TYPE}&REC_AWARD_CODE=${REC_AWARD_CODE}`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((response) => {
            return response;
        })
        .catch((err) => {
            console.error(err?.message);
            return err;
        });
}
