import {Accordion, Col, Form, FormCheck, Row} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {getCodeList} from "@/apis/preparation/setup-dialect-etc/api";

const PersonalData = ({
                          applicationDetail,
                          setApplicationDetail,
                          studentDetail,
                          isDisabled,
                          options,
                          handleClickEdit,
                          academicYear,
                          handleAcademicYearChange,
                          selectedListExercise,
                          selectedListAdmNo,
                          maritalStatus,
                          setMaritalStatus,
                          dialectGroup,
                          setDialectGroup,
                          housingType,
                          setHousingType,
                          handleChange,
                      }) => {
    const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
    const [dialectGroupOptions, setDialectGroupOptions] = useState([]);
    const [housingTypeOptions, setHousingTypeOptions] = useState([]);

    const getMaritalStatusOptions = () => {
        getCodeList("MS")
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({value: item?.ID, label: item?.CODE});
                    });
                    setMaritalStatusOptions(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const getDialectGroupOptions = () => {
        getCodeList("DG")
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({value: item?.ID, label: item?.CODE});
                    });
                    setDialectGroupOptions(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const getHousingTypeOptions = () => {
        getCodeList("H")
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({value: item?.ID, label: item?.CODE});
                    });
                    setHousingTypeOptions(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        setMaritalStatus({
            label: applicationDetail?.MARITAL_STATUS,
            value: applicationDetail?.MARITAL_STATUS,
        });
        setDialectGroup({
            label: applicationDetail?.DIALECT_GRP,
            value: applicationDetail?.DIALECT_GRP,
        });
        setHousingType({
            label: applicationDetail?.HOMEHOUSETYPE,
            value: applicationDetail?.HOMEHOUSETYPE,
        });
    }, [
        applicationDetail?.MARITAL_STATUS,
        applicationDetail?.DIALECT_GRP,
        applicationDetail?.HOMEHOUSETYPE,
    ]);

    const onChangeMaritalStatus = (selectedOption) => {
        setMaritalStatus({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };
    const onChangeDialectGroup = (selectedOption) => {
        setDialectGroup({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };
    const onChangeHousingType = (selectedOption) => {
        setHousingType({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };

    useEffect(() => {
        getMaritalStatusOptions();
        getDialectGroupOptions();
        getHousingTypeOptions();
    }, []);

    return (
        <>
            <div className="">
                <Accordion defaultActiveKey="1" className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Personal Data
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={4} className="">
                                        <Form.Group>
                                            <Form.Label>Academic Year<span
                                                className={`text-danger`}> *</span></Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.ACAD_YR}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4} className="">
                                        <Form.Group>
                                            <Form.Label>Exercise<span className={`text-danger`}> *</span></Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.EXERCISE}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4} className="">
                                        <Form.Group>
                                            <Form.Label>Adm No.<span className={`text-danger`}> *</span></Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.ADM_NO}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.name}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>
                                                NRIC/Fin/Passport No. (if foreigner)
                                            </Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.idNo}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Student Status</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.studStatus}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Course</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={
                                                    studentDetail?.courseList?.length > 0
                                                        ? studentDetail?.courseList[0]?.courseName
                                                        : ""
                                                }
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Citizenship</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.citizen}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Residential Status</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Contact Number</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.termTel}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Housing Type</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.HOMEHOUSETYPE}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Martial Status</Form.Label>
                                            <Select
                                                options={maritalStatusOptions}
                                                isDisabled={isDisabled}
                                                value={maritalStatus}
                                                onChange={onChangeMaritalStatus}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={6} className="mt-3">
                                        <Form.Group>
                                            <Form.Label>Dialect Group</Form.Label>
                                            <Select
                                                options={dialectGroupOptions}
                                                isDisabled={isDisabled}
                                                value={dialectGroup}
                                                onChange={onChangeDialectGroup}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Residential Address
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Block/House No. & Street Name</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={`${studentDetail?.termAddr1 ?? ""} ${studentDetail?.termAddr2 ?? ""} ${studentDetail?.termAddr3 ?? ""}`}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Postal Code</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.termPostCode}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Floor Unit No</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.termUnitno}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Others
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12} className="">
                                        <FormCheck
                                            id="onComcare"
                                            label="On Comcare"
                                            name="onComcare "
                                            type="checkbox"
                                            checked={applicationDetail?.COMCARE === "Y"}
                                            onChange={(e) =>
                                                handleChange("COMCARE", e.target.checked ? "Y" : null)
                                            }
                                            disabled={isDisabled}
                                        />
                                    </Col>
                                    <Col xs={12} className="">
                                        <Form.Group>
                                            <Form.Label>Special Circumstances</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                disabled={isDisabled}
                                                value={applicationDetail?.SPECIAL_CIRCUMSTANCES}
                                                onChange={(e) => {
                                                    handleChange("SPECIAL_CIRCUMSTANCES", e.target.value);
                                                }}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
};

export default PersonalData;
