import axios from "axios";

export function getAcademyYear() {
  return axios.get(process.env.REACT_APP_API_URL + "/api/v1/applications/timelines/distincts/acadyears").then((response) => {
    return response;
  }).catch((err) => {
    return err;
  });
}

export function getExercise(params) {
  return axios.get(process.env.REACT_APP_API_URL + "/api/v1/applications/timelines/distincts/exercises", {
    params
  }).then((response) => {
    return response;
  }).catch((err) => {
    return err;
  });
}