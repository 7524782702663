import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {Button, Col, Form, FormCheck, Modal, Row, Table,} from "@govtechsg/sgds-react";
import Select, {components} from "react-select";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import FormulaModal from "@/components/FormulaModal";
import {getListAcadYears, getListExercise,} from "@/apis/preparation/app-timeline/api";
import {
    deleteApplication,
    getAdmNo,
    getApplication,
    patchApplication,
    postStudentList,
} from "@/apis/app-records/verification/api";
import {appVerificationOrderBy, VerificationStatus,} from "@/apis/app-records/maintenance/status";
import {toast} from "react-toastify";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';

function AppVerification() {
    const navigate = useNavigate();

    const handleSubmit = () => {
    };
    const [isOpen, setIsOpen] = useState(true);
    const contentRef = useRef(null);

    const [isDisabled, setIsDisabled] = useState(true);
    const [showFormulaModal, setShowFormulaModal] = useState(false);
    const [showModalGroupEdit, setModalGroupEdit] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const [analytic, setAnalytic] = useState({
        include: 0,
        exclude: 0,
        pendingDocument: 0,
        pendingVerif: 0,
    });

    const [academicYearOptions, setAcademicYearOptions] = useState([]);

    const [exerciseOptions, setExerciseOptions] = useState([]);

    const [admNoOptions, setAdmNoOptions] = useState([]);

    const [selectedAcademicYearOption, setSelectedAcademicYearOption] =
        useState(null);

    const [selectedExerciseOption, setSelectedExerciseOption] = useState(null);

    const [selectedAdmNoOption, setSelectedAdmNoOption] = useState(null);

    const [
        selectedVerificationStatusOption,
        setSelectedVerificationStatusOption,
    ] = useState(null);

    const [selectedOrderBy, setSelectedOrderBy] = useState(null);

    const [isNotEnrolledChecked, setIsNotEnrolledChecked] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const [checkboxRemark, setCheckboxRemark] = useState(false);

    const [checkboxStatusVerify, setCheckboxStatusVerify] = useState(false);

    const [groupRemark, setGroupRemark] = useState("");

    const [groupStatusVerify, setGroupStatusVerify] = useState("");

    const [items, setItems] = useState([]);

    const [columnVisibilityOptions, setColumnVisibilityOptions] = useState([
        {value: "age", label: "SNo", visible: true, columnEditable: false},
        {
            value: "ADM_NO",
            label: "Admin No.",
            visible: true,
            columnEditable: false,
        },
        {value: "name", label: "Name", visible: true, columnEditable: false},
        {value: "course", label: "Course", visible: true, columnEditable: false},
        {
            value: "GROSS_INCOME",
            label: "GHI",
            visible: true,
            columnEditable: false,
        },
        {
            value: "DEPENDENT",
            label: "Dependent",
            visible: true,
            columnEditable: false,
        },
        {value: "MPCI", label: "MPCI", visible: true, columnEditable: false},
        {
            value: "COMP_SPONSOR_TAG",
            label: "Company Sponsored",
            visible: true,
            columnEditable: false,
        },
        {
            value: "MOE_SUBSIDISED",
            label: "MOE SS",
            visible: true,
            columnEditable: false,
        },
        {
            value: "SCHOLAR_SPONSOR_TAG",
            label: "Sponsor",
            visible: true,
            columnEditable: false,
        },
        {
            value: "VERIFY_STATUS",
            label: "Verification Status",
            visible: true,
            columnEditable: true,
        },
        {value: "REMARK", label: "Remarks", visible: true, columnEditable: true},
    ]);

    const handleExport = () => {
        if(items.length <= 0){
            toast.error("No Data To Export")
            return false
        }

        const visibleColumns = columnVisibilityOptions
            .filter(col => col.visible && col.value !== 'age')
            .map(col => col.value);

        // Map header labels
        const headers = columnVisibilityOptions
            .filter(col => col.visible)
            .map(col => col.label);

        if (headers.length > 0 && columnVisibilityOptions.find(col => col.value === 'age').visible) {
            headers[0] = 'SNo';
        }

        const wsData = [
            headers,
            ...items.map((item, index) => [
                index + 1,
                ...visibleColumns.map(col => item[col] || '')
            ])
        ];

        const ws = XLSX.utils.aoa_to_sheet(wsData);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Application Verification.xlsx');
    }

    const customOption = (props) => (
        <div>
            <components.Option {...props}>
                <input
                    type={"checkbox"}
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );

    const [selectAll, setSelectAll] = useState(false);
    const [groupEdit, setGroupEdit] = useState([]);

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;

        setSelectAll(newSelectAll);

        const newItems = items.map((item) => ({
            ...item,
            checked: newSelectAll && !!item.name,
        }));

        setItems(newItems);

        const itemsForUpdate = items.filter((item) => !!item.name);

        updateGroupEditForm(newSelectAll ? itemsForUpdate : []);
    };

    const handleSelectItem = (id) => {
        const newItems = items.map((item) => {
            if (item.ID === id) {
                if (!item.name) {
                    return {...item, checked: false};
                }
                return {...item, checked: !item.checked};
            }
            return item;
        });
        setItems(newItems);
        setSelectAll(newItems.every((item) => item.checked));
        updateGroupEditForm(newItems.filter((item) => item.checked));
    };

    const handleEdit = (id) => {
        setItems(
            items.map((item) => {
                if (item.ID === id) {
                    return {...item, editable: !item.editable};
                }
                return item;
            })
        );
    };

    const handleSave = (id) => {
        items.map((item) => {
            if (item.ID === id) {
                const updatedItem = {
                    ID: item.ID,
                    ACAD_YR: item.ACAD_YR,
                    EXERCISE: item.EXERCISE,
                    ADM_NO: item.ADM_NO,
                    GROSS_INCOME: item.GROSS_INCOME,
                    DEPENDENT: item.DEPENDENT,
                    MPCI: item.MPCI,
                    COMP_SPONSOR_TAG: item.COMP_SPONSOR_TAG,
                    MOE_SUBSIDISED: item.MOE_SUBSIDISED,
                    SCHOLAR_SPONSOR_TAG: item.SCHOLAR_SPONSOR_TAG,
                    VERIFY_STATUS: item.VERIFY_STATUS ?? "",
                    REMARK: item.REMARK ?? "",
                };

                patchApplication(updatedItem).then((resp) => {
                    if (resp?.response?.data?.message) {
                        if (
                            resp?.response?.data?.message?.length > 0 &&
                            Array.isArray(resp?.response?.data?.message)
                        ) {
                            resp?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(resp?.response?.data?.message);
                        }
                    } else if (resp?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        if (resp.status == 200) {
                            toast.success(`Record updated successfully`);
                            handleGetApplication();
                        }
                    }
                });
            }
        });
    };

    const handleChange = (id, field, value, index) => {
        setItems(
            items.map((item) => {
                if (item.ID === id) {
                    return {...item, [field]: value};
                }
                return item;
            })
        );
    };

    const handleDelete = (acadYear, exercise, admNo) => {
        deleteApplication(acadYear, exercise, admNo).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success(`Record deleted successfully`);
                    handleGetApplication();
                }
            }
        });
    };

    const applyGroupEdit = () => {
        if (!checkboxRemark && !checkboxStatusVerify) {
            toast.warning("Please choose the action before proceeding");

            return false;
        }

        if (!groupRemark && !groupStatusVerify) {
            toast.warning("Please complete the form before proceeding");

            return false;
        }

        //submit
        const updatedItem = groupEdit.map((item) => {
            const newItem = {...item};

            if (checkboxRemark) {
                newItem.REMARK = groupRemark;
            }

            if (checkboxStatusVerify) {
                newItem.VERIFY_STATUS = groupStatusVerify;
            }

            return newItem;
        });

        patchApplication(updatedItem).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success(`Record updated successfully`);
                    handleGetApplication();
                    setModalGroupEdit(false);
                    setGroupEdit([]);
                    setCheckboxRemark(false);
                    setCheckboxStatusVerify(false);
                    setGroupRemark("");
                    setGroupStatusVerify("");
                    setSelectAll(false);
                }
            }
        });
    };

    const updateGroupEditForm = (selectedItems) => {
        if (selectedItems.length === 0) {
            setGroupEdit([]);
            return;
        }

        setGroupEdit(
            selectedItems.map((item) => ({
                ID: item.ID,
                ACAD_YR: item.ACAD_YR,
                EXERCISE: item.EXERCISE,
                ADM_NO: item.ADM_NO,
            }))
        );

        // const name = selectedItems.map((item) => item.name).join(", ");
        // const age = selectedItems.map((item) => item.age).join(", ");
        // const role = selectedItems.map((item) => item.role).join(", ");
        // setGroupEdit({name, age, role});
    };

    const toggleColumnVisibility = (key) => {
        setColumnVisibilityOptions(
            columnVisibilityOptions.map((column) =>
                column.value === key ? {...column, visible: true} : column
            )
        );
    };

    const getAcademicYearOptions = () => {
        getListAcadYears().then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setAcademicYearOptions(
                        resp?.data?.data.map((option) => ({
                            label: option.ACAD_YR,
                            value: option.ACAD_YR,
                        }))
                    );
                }
            }
        });
    };

    const getExerciseOptions = (academicYear) => {
        getListExercise(academicYear).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setExerciseOptions(
                        resp?.data?.data.map((option) => ({
                            label: option.EXERCISE,
                            value: option.EXERCISE,
                        }))
                    );
                }
            }
        });
    };

    const getAdmNoOptions = (year, exercise) => {
        getAdmNo(year, exercise).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setAdmNoOptions(
                        resp?.data?.data.map((option) => ({
                            label: option.ADM_NO,
                            value: option.ADM_NO,
                        }))
                    );
                }
            }
        });
    };

    const handleChangeAcademicYearOption = (academicYear) => {
        if (academicYear !== null) {
            getExerciseOptions(academicYear?.value);
        } else {
            setExerciseOptions([]);
        }
        setSelectedAcademicYearOption(academicYear);
        setSelectedExerciseOption(null);
    };

    const handleChangeExerciseOption = (exercise) => {
        if (exercise !== null && selectedAcademicYearOption) {
            getAdmNoOptions(selectedAcademicYearOption?.value, exercise?.value);
        } else {
            setAdmNoOptions([]);
        }
        setSelectedExerciseOption(exercise);
        setSelectedAdmNoOption(null);
    };

    const handleChangeAdmNoOption = (admNo) => {
        setSelectedAdmNoOption(admNo);
    };

    const handleChangeVerificationStatusOption = (verificationStatus) => {
        setSelectedVerificationStatusOption(verificationStatus);
    };

    const handleChangeOrderBy = (selectedOption) => {
        setSelectedOrderBy(selectedOption);
    };

    const handleGetApplication = () => {
        const academicYear = selectedAcademicYearOption?.value ?? "";
        const exercise = selectedExerciseOption?.value ?? "";
        const admNo = selectedAdmNoOption?.value ?? "";
        const verifStatus = selectedVerificationStatusOption?.value ?? "";
        const orderField = selectedOrderBy?.value ?? "";
        const isCheckedNoEnrolled = isNotEnrolledChecked;

        getApplication(
            academicYear,
            exercise,
            admNo,
            verifStatus,
            orderField,
            isCheckedNoEnrolled
        ).then((response) => {
            const modifiedItems = response?.data?.data.map((item) => ({
                ...item,
                checked: false,
                editable: false,
                name: "",
                course: "",
            }));

            setItems(modifiedItems);

            const itemsMap = modifiedItems.reduce((map, item) => {
                map[item.ADM_NO] = item;
                return map;
            }, {});

            const students_id = response?.data.data
                ?.map((application) => application.ADM_NO)
                .join(",");

            if (students_id) {
                postStudentList(students_id, isNotEnrolledChecked ? true : false).then(
                    (response2) => {
                        response2?.data?.data.forEach((student) => {
                            const item = itemsMap[student.admNo];
                            if (item) {
                                setItems((prevItems) =>
                                    prevItems.map((prevItem) => {
                                        if (prevItem.ADM_NO === item.ADM_NO) {
                                            return {
                                                ...prevItem,
                                                name: student.name,
                                                course: student?.courseList[0]?.courseName,
                                            };
                                        }
                                        return prevItem;
                                    })
                                );
                            }
                        });
                    }
                );
            }

            setAnalytic(response?.data?.analytic);
        });
    };

    const retrieveApplicationData = (e) => {
        e.preventDefault();
        handleGetApplication();
    };

    const handleSelectedVisibilityOptions = (selectedOptions) => {
        // console.log(selectedOptions)

        setSelectedOptions(selectedOptions);

        const updatedOptions = columnVisibilityOptions.map((option) => ({
            ...option,
            visible: selectedOptions.some(
                (selected) => selected.value === option.value
            ),
        }));

        setColumnVisibilityOptions(updatedOptions);
    };

    useEffect(() => {
        getAcademicYearOptions();
        setSelectedOptions(
            columnVisibilityOptions.map((option) => ({
                value: option.value,
                label: option.label,
            }))
        );
    }, []);

    return (
        <Layout currentNav={"app-records"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Administer Application Records</Breadcrumb.Item>
                <Breadcrumb.Item active>Application Verification</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Application Verification</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <div className="row">
                        <div className="col">
                            <h3>Data Retrieval</h3>
                        </div>
                    </div>

                    <Form
                        ref={contentRef}
                        className={` d-flex flex-column gap-3 collapse-animation
            }`}
                        style={{
                            overflow: isOpen ? "unset" : "hidden",
                            maxHeight: isOpen
                                ? `${
                                    contentRef?.current?.scrollHeight == null
                                        ? "100%"
                                        : contentRef?.current?.scrollHeight
                                }px`
                                : "0px",
                        }}
                        onSubmit={retrieveApplicationData}
                    >
                        <Row>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Academic Year</Form.Label>
                                    <Select
                                        isClearable={true}
                                        options={academicYearOptions}
                                        value={selectedAcademicYearOption}
                                        onChange={handleChangeAcademicYearOption}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Exercise</Form.Label>
                                    <Select
                                        isClearable={true}
                                        options={exerciseOptions}
                                        value={selectedExerciseOption}
                                        onChange={handleChangeExerciseOption}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Adm. No.</Form.Label>
                                    <Select
                                        isClearable={true}
                                        options={admNoOptions}
                                        value={selectedAdmNoOption}
                                        onChange={handleChangeAdmNoOption}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {/*<Col xs={8} className="">*/}
                            {/*    <Form.Group>*/}
                            {/*        <Form.Label>Name</Form.Label>*/}
                            {/*        <Form.Control type={`text`} disabled={false}></Form.Control>*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Verification Status</Form.Label>
                                    <Select
                                        isClearable={true}
                                        options={VerificationStatus()}
                                        value={selectedVerificationStatusOption}
                                        onChange={handleChangeVerificationStatusOption}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Order By</Form.Label>
                                    <Select
                                        isClearable={true}
                                        options={appVerificationOrderBy()}
                                        value={selectedOrderBy}
                                        onChange={handleChangeOrderBy}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={3} className="d-flex align-items-end">
                                {" "}
                                <Form.Group className="">
                                    <FormCheck
                                        id="isNotEnrolled"
                                        label="Is Not Enrolled"
                                        name="is_not_enrolled "
                                        type="checkbox"
                                        onChange={(e) =>
                                            setIsNotEnrolledChecked(e.target.checked ? true : false)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <Button type="submit" className="btn btn-primary">
                                    Retrieve
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    <button
                        onClick={toggleCollapse}
                        className="btn-clear text-blue ps-0 mt-3"
                    >
                        {isOpen ? "Hide " : "Expand "}{" "}
                        {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                    </button>
                </div>

                <div className="p-3 bg-white shadow-sm border rounded border-1">
                    <Row>
                        <Col xs={12} md={4} className="mt-3">
                            <Form.Group>
                                <Form.Label>Application Included</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={isDisabled}
                                    value={analytic?.include}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="mt-3">
                            <Form.Group>
                                <Form.Label>Application Excluded</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={isDisabled}
                                    value={analytic?.exclude}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="mt-3">
                            <Form.Group>
                                <Form.Label>Application Pending</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={isDisabled}
                                    value={analytic?.pendingDocument}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="mt-3">
                            <Form.Group>
                                <Form.Label>Application Pending Verification</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={isDisabled}
                                    value={analytic?.pendingVerif}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="mt-3">
                            <Form.Group>
                                <Form.Label>Total Applicant Retrieved</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={isDisabled}
                                    value={
                                        analytic &&
                                        Object.values(analytic).reduce(
                                            (acc, currentValue) => acc + currentValue,
                                            0
                                        )
                                    }
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4 p-3 bg-white shadow-sm border rounded border-1">
                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Columns visibility</Form.Label>
                                <Select
                                    isMulti
                                    value={selectedOptions}
                                    options={columnVisibilityOptions}
                                    components={{Option: customOption}}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={handleSelectedVisibilityOptions}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end align-items-end">
                            <button
                                type="button"
                                className="btn btn-outline-primary me-3"
                                onClick={() => {
                                    if (groupEdit.length > 0) {
                                        setModalGroupEdit(true)
                                    } else {
                                        toast.warning("Please select at least 1 record")
                                    }
                                }}
                            >
                                Group Edit
                            </button>
                            <button type="button" className="btn btn-primary" onClick={handleExport}>
                                Export
                            </button>
                        </Col>
                        {/*<Col xs={12}>*/}
                        {/*    {columnVisibilityOptions.map((column) => (*/}
                        {/*        <div*/}
                        {/*            key={column.value}*/}
                        {/*            className="form-check form-check-inline"*/}
                        {/*        >*/}
                        {/*            <input*/}
                        {/*                className="form-check-input"*/}
                        {/*                type="checkbox"*/}
                        {/*                id={`checkbox-${column.value}`}*/}
                        {/*                checked={column.visible}*/}
                        {/*                onChange={() => toggleColumnVisibility(column.value)}*/}
                        {/*            />*/}
                        {/*            <label*/}
                        {/*                className="form-check-label"*/}
                        {/*                htmlFor={`checkbox-${column.value}`}*/}
                        {/*            >*/}
                        {/*                {column.label}*/}
                        {/*            </label>*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                        {/*</Col>*/}

                        <Col xs={12}>
                            <Table responsive>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            <FormCheck
                                                id="table_no"
                                                label=""
                                                name="table_no "
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                            />
                                        </Table.HeaderCell>
                                        {columnVisibilityOptions.map(
                                            (column) =>
                                                column.visible && (
                                                    <Table.HeaderCell key={column.value}>
                                                        {column.label}
                                                    </Table.HeaderCell>
                                                )
                                        )}
                                        <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {items?.map((item, index) => (
                                        <Table.Row key={index}>
                                            <Table.DataCell>
                                                {" "}
                                                <FormCheck
                                                    id="table_no"
                                                    label=""
                                                    name="table_no "
                                                    type="checkbox"
                                                    checked={item.checked}
                                                    onChange={() => handleSelectItem(item.ID)}
                                                />
                                            </Table.DataCell>
                                            {columnVisibilityOptions.map(
                                                (column) =>
                                                    column.visible && (
                                                        <td key={column.value}>
                                                            {item.editable && column.columnEditable ? (
                                                                column.value === "COMP_SPONSOR_TAG" ||
                                                                column.value === "MOE_SUBSIDISED" ||
                                                                column.value === "SCHOLAR_SPONSOR_TAG" ? (
                                                                    <input
                                                                        type={"checkbox"}
                                                                        checked={item[column.value] === "Y"}
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                item.ID,
                                                                                column.value,
                                                                                e.target.checked ? "Y" : "",
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                ) : column.value === "VERIFY_STATUS" ? (
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        options={VerificationStatus()}
                                                                        value={{
                                                                            label: item[column.value],
                                                                            value: item[column.value],
                                                                        }}
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                item.ID,
                                                                                column.value,
                                                                                e.value,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        type={
                                                                            column.value === "age" ? "number" : "text"
                                                                        }
                                                                        value={item[column.value]}
                                                                        onChange={(e) =>
                                                                            handleChange(
                                                                                item.ID,
                                                                                column.value,
                                                                                e.target.value,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                )
                                                            ) : column.value === "age" ? (
                                                                index + 1
                                                            ) : column.value === "COMP_SPONSOR_TAG" ||
                                                            column.value === "MOE_SUBSIDISED" ||
                                                            column.value === "SCHOLAR_SPONSOR_TAG" ? (
                                                                <input
                                                                    type={"checkbox"}
                                                                    checked={item[column.value] === "Y"}
                                                                    disabled={true}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            item.ID,
                                                                            column.value,
                                                                            e.target.checked ? "Y" : "",
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            ) : column.value == "ADM_NO" ? (
                                                                <React.Fragment>
                                                                    <Link to={`/app-records/app-maintenance?academicYearParams=${item?.ACAD_YR}&exerciseParams=${item?.EXERCISE}&admNoParams=${item?.ADM_NO}`} target="_blank" >{item[column.value]}</Link>
                                                                    

                                                                    {/* 
                                                                    <a
                                                                        href={`/app-records/app-maintenance?academicYearParams=${item?.ACAD_YR}&exerciseParams=${item?.EXERCISE}&admNoParams=${item?.ADM_NO}`}
                                                                        // href="javascript:void(0);"
                                                                        className="fs-m"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                        // onClick={() =>
                                                                        //     navigate(
                                                                        //         `/app-records/app-maintenance?academicYearParams=${item?.ACAD_YR}&exerciseParams=${item?.EXERCISE}&admNoParams=${item?.ADM_NO}`
                                                                        //     )
                                                                        // }
                                                                        // rel="noopener noreferrer"
                                                                    >
                                                                        {item[column.value]}
                                                                    </a>
                                                                    
                                                                    */}
                                                                    
                                                                </React.Fragment>
                                                            ) : (
                                                                item[column.value]
                                                            )}
                                                        </td>
                                                    )
                                            )}

                                            <Table.DataCell>
                                                {item.editable ? (
                                                    <button
                                                        className={`btn-clear text-green-500`}
                                                        onClick={() => handleSave(item.ID)}
                                                    >
                                                        <i className={`bi bi-check-lg`}></i> Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        className={`btn-clear`}
                                                        onClick={() => handleEdit(item.ID)}
                                                    >
                                                        <i className={`bi bi-pencil`}></i>
                                                    </button>
                                                )}

                                                {/*
                                                <button
                                                    className={`btn-clear`}
                                                    onClick={() => {
                                                        handleDelete(
                                                            item.ACAD_YR,
                                                            item.EXERCISE,
                                                            item.ADM_NO
                                                        );
                                                    }}
                                                >
                                                    <i className={`bi bi-trash`}></i>
                                                </button>

                                                */}
                                            </Table.DataCell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </sgds-content-body>
            <FormulaModal
                show={showFormulaModal}
                handleClose={() => setShowFormulaModal(!showFormulaModal)}
            />
            <Modal
                size={"lg"}
                show={showModalGroupEdit}
                onHide={() => setModalGroupEdit(!showModalGroupEdit)}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="selected-highlight p-3">
                        Selected {groupEdit.map((indv) => indv.ADM_NO + ` `)}
                    </div>
                    <div>
                        <Row>
                            <Col xs={12} className="mt-4">
                                <h6>Choose Action</h6>
                            </Col>
                            <Col xs={12}>
                                <FormCheck
                                    id="verification_status"
                                    label="Verification Status"
                                    name="verification_status "
                                    type="checkbox"
                                    checked={checkboxStatusVerify}
                                    onChange={() =>
                                        setCheckboxStatusVerify(!checkboxStatusVerify)
                                    }
                                />
                                <FormCheck
                                    id="remarks"
                                    label="Remarks"
                                    name="remarks "
                                    type="checkbox"
                                    checked={checkboxRemark}
                                    onChange={() => setCheckboxRemark(!checkboxRemark)}
                                />
                            </Col>
                            <Col xs={12} className="mt-4">
                                <h6>Add remarks</h6>
                            </Col>
                            <Col xs={12}>
                                <Row className="mt-3">
                                    <Col xs={3}>Verification Status</Col>
                                    <Col xs={9}>
                                        <Select
                                            options={VerificationStatus()}
                                            isDisabled={!checkboxStatusVerify}
                                            onChange={(e) => setGroupStatusVerify(e.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={3}>Remarks</Col>
                                    <Col xs={9}>
                                        <Form.Control
                                            type="text"
                                            value={groupEdit.age}
                                            disabled={!checkboxRemark}
                                            onChange={(e) => setGroupRemark(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`gap-2`}>
                    <Button
                        variant="outline-dark"
                        onClick={() => setModalGroupEdit(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            applyGroupEdit();
                        }}
                    >
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    );
}

export default AppVerification;
