import React from 'react'
import axios from 'axios'
import { getJwt } from "@/apex";

export function apexAxiosCall(url, params, method = "POST") {
  return getJwt(params, method).then(async (resp) => {
    if (method == "POST") {
      // NYP
      return await axios
        .post(url, params, {
          headers: {
            "x-apex-jwt": resp,
          },
        }
        )
        .then((responseAPI) => {
          return responseAPI
        })
        .catch((err) => {
          console.error(err);
        });
    }

  });
}

