import {
  Button,
  Col,
  Dropdown,
  Form,
  FormCheck,
  Modal,
  Row,
  Table,
} from "@govtechsg/sgds-react";
import Select, { components } from "react-select";
import React, { useEffect, useRef, useState } from "react";
import Layout from "@/components/Layout";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { getAcademyYear, getExercise } from "@/apis/timeline/api";
import { getCodeList, getStudentList } from "@/apis/sims/api";
import {
  getListApplications,
  getRecommendList,
  patchApplications,
} from "@/apis/applications/api";
import CustomModal from "@/components/CustomModal";
import { deleteApplication } from "@/apis/app-records/verification/api";
import { toast } from "react-toastify";
import { Breadcrumb } from "@govtechsg/sgds-react/Breadcrumb";
import { getSchemeList } from "@/apis/preparation/setup-scheme/api";

export default function Application() {
  const [dataAcademyYear, setDataAcademyYear] = useState([]);
  const [dataAdminNo, setDataAdminNo] = useState([]);
  const [dataApplications, setDataApplications] = useState([]);
  const [dataAward, setDataAward] = useState([]);
  const [dataAwardType, setDataAwardType] = useState([]);
  const [dataExercise, setDataExercise] = useState([]);
  const [dataName, setDataName] = useState([]);

  const [academyYear, setAcademyYear] = useState(null);
  const [adminNo, setAdminNo] = useState(null);
  const [applicationStatus, setApplicationStatus] = useState(null);
  const [award, setAward] = useState(null);
  const [awardType, setAwardType] = useState(null);
  const [exercise, setExercise] = useState(null);
  const [name, setName] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [recommended, setRecommended] = useState({
    applicationStatus: null,
    remarks: "",
    specialDetails: "",
  });

  const [loadingAcademyYear, setLoadingAcademyYear] = useState(true);
  const [loadingListAward, setLoadingListAward] = useState(false);

  const [isOpen, setIsOpen] = useState(true);
  const [showDeleteApplication, setShowDeleteApplication] = useState(false);

  const contentRef = useRef(null);
  const refTimeoutSearch = useRef(null);

  const listOrderBy = [
    { label: "CCC Income & Name", value: "CCC_INCOME" },
    { label: "Gross Income & Name", value: "GROSS_INCOME" },
    { label: "MPCI & Name", value: "MPCI" },
    {
      label: "MPCI, Family Members & Name",
      value: "MPCI, Family Members & Name",
    },
    {
      label: "MPCI, GPA, Family Members & Name",
      value: "MPCI, GPA, Family Members & Name",
    },
    { label: "FPCI & Name", value: "FPCI & Name" },
    { label: "CWA, Study Stage & Name", value: "CWA, Study Stage & Name" },
    { label: "GPA, Study Stage & Name", value: "GPA, Study Stage & Name" },
    { label: "Entryqual, Agg/GPA", value: "Entryqual, Agg/GPA" },
    { label: "Adm No", value: "ADM_NO" },
    // { label: 'FPCI, Housing Type, Family Member', value: 'FPCI, Housing Type, Family Member' },
    { label: "MPCI, Housing Type, Family Member", value: "MPCI" },
    // {label: "Academy Year", value: "ACAD_YR"},
    // {label: "Exercise", value: "EXERCISE"},
    // {label: "Admission No", value: "AdmNo"},
    // {label: "Award Type", value: "AWARD_TYPE"},
    // {label: "Award", value: "AWARD"},
  ];

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const [groupEdit, setGroupEdit] = useState({ name: "", age: "" });

  const [selectAll, setSelectAll] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [show, setShow] = useState(false);

  const [isNotEnrolledChecked, setIsNotEnrolledChecked] = useState(false);

  const [schemeOptions, setSchemeOptions] = useState([]);

  const [selectedSchemeOption, setSelectedSchemeOption] = useState(null);

  useEffect(() => {
    if (academyYear?.value) handleGetListExercise();
  }, [academyYear]);

  useEffect(() => {
    if (awardType?.value) handleGetListAward(awardType?.value);
  }, [awardType]);

  const handleDeleteApplication = () => {
    if (selectedItem) {
      deleteApplication(
        selectedItem?.ACAD_YR,
        selectedItem?.EXERCISE,
        selectedItem?.ADM_NO
      )
        .then(() => {
          handleGetListApplications();
          setShowDeleteApplication(false);
          setSelectedItem(null);
          toast.success("Record deleted successfully");
        })
        .catch((error) => {
          toast.error(error.response?.data.message);
        });
    }
  };

  const handleGetListAcademyYear = async () => {
    setLoadingAcademyYear(true);

    try {
      setAcademyYear(null);

      const response = await getAcademyYear();

      setDataAcademyYear(
        response?.data?.data?.map((item) => ({
          label: item?.ACAD_YR,
          value: item?.ACAD_YR,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoadingAcademyYear(false);
    }
  };

  const handleGetListAdminNo = async (search = "") => {
    try {
      setAdminNo(null);
      setDataAdminNo([]);

      const params = {
        student_id: "",
        studstatus: "",
        transfer_tag: "N",
        page: 1,
        limit: 20,
        search: search,
        sort: "id_no",
      };

      const response = await getStudentList(params);

      setDataAdminNo(
        response?.data?.data?.map((item) => ({
          label: item?.admNo,
          value: item?.admNo,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleGetListApplications = async (e) => {
    e?.preventDefault();

    try {
      const params = {
        APPLN_STATUS: applicationStatus?.value,
        ACAD_YR: academyYear?.value,
        EXERCISE: exercise?.value,
        ADM_NO: adminNo?.value,
        AWARD_TYPE: awardType?.value,
        AWARD: award?.value,
      };

      // const params = {
      //     ACAD_YR: "2025",
      //     EXERCISE: "11",
      //     ADM_NO: "",
      //     AWARD_TYPE: "05",
      //     AWARD: "ASTAR",
      //     APPLN_STATUS: "",
      // };

      // const params = {
      //   ACAD_YR: academyYear?.value,
      //   EXERCISE: exercise?.value,
      //   ADM_NO: adminNo?.value,
      //   APPLN_STATUS: applicationStatus?.value,
      //   REC_AWARD_TYPE: awardType?.value,
      //   REC_AWARD_CODE: award?.value,
      //   orderByField: orderBy?.value,
      //   orderByDirection: "ASC",
      //   page: 1,
      //   limit: 10,
      // };

      const response = await getRecommendList(params);
      // const response = await getListApplications(params);

      console.log(response);

      if (response.response) {
        if (!response?.response?.data?.success) {
          toast.error(response?.response?.data?.message);
          return;
        }
      }

      if (response?.data?.data?.length === 0) {
        toast.warning("Application data is empty");
        return;
      }

      setDataApplications(response?.data?.data);

      // const modifiedItems = response?.data?.data?.map((item) => ({
      //   ...item,
      //   name: "",
      // }));
      //
      // setDataApplications(modifiedItems);
      //
      // const students_id = response?.data?.data
      //   ?.map((application) => application.ADM_NO)
      //   .join(",");
      //
      //   const itemsMap = modifiedItems.reduce((map, item) => {
      //       map[item.ADM_NO] = item;
      //       return map;
      //   }, {});
      //
      // if (students_id) {
      //   postStudentList(students_id, isNotEnrolledChecked ? true : false).then(
      //     (response2) => {
      //       response2?.data?.data?.forEach((student) => {
      //         const item = itemsMap[student.admNo];
      //         if (item) {
      //           setDataApplications((prevItems) =>
      //             prevItems.map((prevItem) => {
      //               if (prevItem.ADM_NO === item.ADM_NO) {
      //                 return {
      //                   ...prevItem,
      //                   name: student.name,
      //                 };
      //               }
      //               return prevItem;
      //             })
      //           );
      //         }
      //       });
      //     }
      //   );
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetListAward = async (codeType = "") => {
    setLoadingListAward(true);

    try {
      setAward(null);

      const response = await getCodeList(codeType);

      setDataAward(
        response?.data?.data?.map((item) => ({
          label: item?.description,
          value: item?.code,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoadingListAward(false);
    }
  };

  const handleGetListAwardType = async (codeType = "AWARDTYPECODE") => {
    try {
      setAwardType(null);

      const response = await getCodeList(codeType);

      setDataAwardType(
        response?.data?.data?.map((item) => ({
          label: item?.description,
          value: item?.code,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleGetListExercise = async () => {
    const params = {
      ACAD_YR: academyYear?.value,
    };

    try {
      setExercise(null);

      const response = await getExercise(params);

      setDataExercise(
        response?.data?.data?.map((item) => ({
          label: item?.EXERCISE,
          value: item?.EXERCISE,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleGetListName = async (search = "") => {
    try {
      setName(null);
      setDataName([]);

      const params = {
        student_id: "",
        studstatus: "",
        transfer_tag: "N",
        page: 1,
        limit: 10,
        search: search,
        sort: "id_no",
      };

      const response = await getStudentList(params);

      setDataName(
        response?.data?.data?.map((item) => ({
          label: item?.name,
          value: item?.name,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleSearch = (searchFunction = null, value = "") => {
    clearTimeout(refTimeoutSearch.current);

    if (value) {
      refTimeoutSearch.current = setTimeout(() => {
        searchFunction(value);
      }, 500);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) setItems([]);
    else setItems(dataApplications);

    setSelectAll((prevState) => !prevState);
  };

  const updateGroupEditForm = (selectedItems) => {
    if (selectedItems?.length === 0) {
      setGroupEdit({ name: "", age: "", role: "" });
      return;
    }
    const name = selectedItems.map((item) => item.name).join(", ");
    const age = selectedItems.map((item) => item.age).join(", ");
    const role = selectedItems.map((item) => item.role).join(", ");
    setGroupEdit({ name, age, role });
  };

  const handleSelectItem = (data) => {
    let currentItem = [...items];
    const index = items.findIndex((item) => item?.ID === data?.ID);

    if (index < 0) currentItem.push(data);
    else currentItem.splice(index, 1);

    setItems(currentItem);
    setSelectAll(
      dataApplications.every((item) =>
        currentItem?.find((data) => data?.ID === item?.ID)
      )
    );
    updateGroupEditForm(currentItem.filter((item) => item.checked));
  };

  const handleChange = (id, field, value) => {
    setItems(
      items.map((item) => {
        if (item.id === id) {
          return { ...item, [field]: value };
        }
        return item;
      })
    );
  };

  const handleEdit = (id) => {
    setItems(
      items.map((item) => {
        if (item.id === id) {
          return { ...item, editable: !item.editable };
        }
        return item;
      })
    );
  };

  const handleRecommended = async () => {
    try {
      const params = {
        DATA: [
          ...items.map((data) => ({
            ACAD_YR: data?.ACAD_YR ?? academyYear?.value,
            EXERCISE: data?.EXERCISE ?? exercise?.value,
            ADM_NO: data?.ADM_NO ?? adminNo?.value,
            VERIFY_STATUS: recommended?.applicationStatus?.value,
            REMARK: recommended?.remarks,
            REMARKS1: recommended?.specialDetails,
            VERIFY_STATUS_BY: sessionStorage.getItem("staffId")
              ? sessionStorage.getItem("staffId")
              : process.env.REACT_APP_LAST_UPD_ID,
            LAST_UPD_ID: sessionStorage.getItem("staffId")
              ? sessionStorage.getItem("staffId")
              : process.env.REACT_APP_LAST_UPD_ID,
          })),
        ],
      };

      await patchApplications(params);

      setItems([]);
      getListApplications();
      setRecommended({
        applicationStatus: null,
        remarks: "",
        specialDetails: "",
      });

      setShow(false);
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleGetSchemes = () => {
    getSchemeList()
      .then((response) => {
        setSchemeOptions(
          response?.data?.data.map((option) => ({
            value: option?.SCHEME_ID,
            label: option?.INSTITUTION,
            INSTITUTE_ID: option?.INSTITUTE_ID,
          }))
        );
      })
      .then((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const customOption = (props) => (
    <div>
      <components.Option {...props}>
        <input
          type={"checkbox"}
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );

  const toggleColumnVisibility = (key) => {
    setColumnVisibilityOptions(
      columnVisibilityOptions.map((column) =>
        column.value === key ? { ...column, visible: true } : column
      )
    );
  };

  const [columnVisibilityOptions, setColumnVisibilityOptions] = useState([
    { value: "age", label: "SNo", visible: true, columnEditable: false },
    {
      value: "ADM_NO",
      label: "Admin No.",
      visible: true,
      columnEditable: false,
    },
    {
      value: "ID",
      label: "ID No.",
      visible: true,
      columnEditable: false,
    },
    { value: "name", label: "Name", visible: true, columnEditable: false },
    {
      value: "courseName",
      label: "Course",
      visible: true,
      columnEditable: false,
    },
    {
      value: "studyStage",
      label: "Study Stage",
      visible: true,
      columnEditable: false,
    },
    {
      value: "MTHLY_INCOME",
      label: "Monthly Income",
      visible: true,
      columnEditable: false,
    },
    {
      value: "FAMILY_MEMBERS",
      label: "Members",
      visible: true,
      columnEditable: false,
    },
    {
      value: "DEPENDENT",
      label: "Dependant",
      visible: true,
      columnEditable: false,
    },
    {
      value: "MPCI",
      label: "MPCI",
      visible: true,
      columnEditable: false,
    },
    {
      value: "GPA",
      label: "GPA",
      visible: true,
      columnEditable: false,
    },
    {
      value: "citizen",
      label: "Citizen",
      visible: true,
      columnEditable: false,
    },
    { value: "pr", label: "PR", visible: true, columnEditable: false },
    {
      value: "HOMEHOUSETYPE",
      label: "Housing",
      visible: true,
      columnEditable: false,
    },
    {
      value: "OTHER_INCOME",
      label: "GHI Income",
      visible: true,
      columnEditable: false,
    },
    {
      value: "GROSS_INCOME",
      label: "Gross Income",
      visible: true,
      columnEditable: false,
    },
    {
      value: "AWARD",
      label: "Received Awards",
      visible: true,
      columnEditable: false,
    },
    { value: "gpa", label: "AGG/GPA", visible: true, columnEditable: false },
    { value: "cumWghtAvg", label: "CWA", visible: true, columnEditable: false },
    {
      value: "RECOMMENDED_DATE",
      label: "Recommended",
      visible: true,
      columnEditable: false,
    },
    {
      value: "REMARK",
      label: "Application Status Remarks",
      visible: true,
      columnEditable: false,
    },
    {
      value: "SPECIAL_DETAILS",
      label: "Special Details",
      visible: true,
      columnEditable: false,
    },
  ]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectedVisibilityOptions = (selectedOptions) => {
    setSelectedOptions(selectedOptions);

    const updatedOptions = columnVisibilityOptions.map((option) => ({
      ...option,
      visible: selectedOptions.some(
        (selected) => selected.value === option.value
      ),
    }));

    setColumnVisibilityOptions(updatedOptions);
  };

  useEffect(() => {
    handleGetListAcademyYear();
    handleGetListAdminNo();
    handleGetListAwardType();
    handleGetListName();
    handleGetSchemes();

    setSelectedOptions(
      columnVisibilityOptions.map((option) => ({
        value: option.value,
        label: option.label,
      }))
    );
  }, []);

  return (
    <Layout currentNav={"processing"} toc={false}>
      <Breadcrumb
        listProps={{
          className: "bg-light",
          foo: "bar",
        }}
      >
        <Breadcrumb.Item>Application Processing</Breadcrumb.Item>
        <Breadcrumb.Item active>Application Recommendation</Breadcrumb.Item>
      </Breadcrumb>

      <sgds-content-header-top>
        <h2>Application Recommendation</h2>
      </sgds-content-header-top>
      <sgds-content-body>
        <div className="d-flex gap-4 flex-column">
          <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
            <div className="row">
              <div className="col">
                <h3>Data Retrieval</h3>
              </div>
            </div>

            <Form
              ref={contentRef}
              className={` d-flex flex-column gap-3 collapse-animation`}
              style={{
                overflow: isOpen ? "unset" : "hidden",
                maxHeight: isOpen
                  ? `${
                      contentRef?.current?.scrollHeight == null
                        ? "100%"
                        : contentRef?.current?.scrollHeight
                    }px`
                  : "0px",
              }}
              onSubmit={handleGetListApplications}
            >
              <Row>
                <Col xs={3}>
                  <Form.Group>
                    <Form.Label>Academic Year</Form.Label>
                    <Select
                      onChange={(value) => setAcademyYear(value)}
                      options={dataAcademyYear}
                      isClearable={true}
                      placeholder={`Academic Year`}
                      value={academyYear}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group>
                    <Form.Label>Exercise</Form.Label>
                    <Select
                      isDisabled={!academyYear?.value}
                      isClearable={true}
                      onChange={(value) => setExercise(value)}
                      options={dataExercise}
                      value={exercise}
                    />
                  </Form.Group>
                </Col>
                {/*<Col xs={3}>*/}
                {/*  <Form.Group>*/}
                {/*    <Form.Label>Institution</Form.Label>*/}
                {/*    <Select*/}
                {/*        isClearable={true}*/}
                {/*        onChange={(value) => setSelectedSchemeOption(value)}*/}
                {/*        options={schemeOptions}*/}
                {/*        value={selectedSchemeOption}*/}
                {/*    />*/}
                {/*  </Form.Group>*/}
                {/*</Col>*/}
                <Col xs={3}>
                  <Form.Group>
                    <Form.Label>Admission No.</Form.Label>
                    <Select
                      onChange={(value) => setAdminNo(value)}
                      isClearable={true}
                      options={dataAdminNo}
                      onInputChange={(value) =>
                        handleSearch(handleGetListAdminNo, value)
                      }
                      value={adminNo}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group>
                    <Form.Label>Application Status</Form.Label>
                    <Select
                      backspaceRemovesValue={true}
                      isClearable={true}
                      onChange={(value) => setApplicationStatus(value)}
                      options={[
                        { label: "Pending Recommendation", value: "Z" },
                        { label: "Recommended", value: "R" },
                        { label: "Pending Approval", value: "P" },
                        { label: "Pending Approval with changes", value: "C" },
                        { label: "Rescind", value: "E" },
                      ]}
                      placeholder={`Application Status`}
                      value={applicationStatus}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Award Type</Form.Label>
                    <Select
                      isClearable={true}
                      onChange={(value) => setAwardType(value)}
                      options={dataAwardType}
                      value={awardType}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Award</Form.Label>
                    <Select
                      isClearable={true}
                      isDisabled={!awardType?.value}
                      onChange={(value) => setAward(value)}
                      options={dataAward}
                      value={award}
                    />
                  </Form.Group>
                </Col>
                {/*<Col xs={12}>*/}
                {/*  <Form.Group>*/}
                {/*    <Form.Label>Name</Form.Label>*/}
                {/*    <Select*/}
                {/*      isClearable={true}*/}
                {/*      onChange={(value) => setName(value)}*/}
                {/*      options={dataName}*/}
                {/*      onInputChange={(value) =>*/}
                {/*        handleSearch(handleGetListName, value)*/}
                {/*      }*/}
                {/*      value={name}*/}
                {/*    />*/}
                {/*  </Form.Group>*/}
                {/*</Col>*/}

                {/*<Col xs={4}>*/}
                {/*  <Form.Group>*/}
                {/*    <Form.Label>Order by</Form.Label>*/}
                {/*    <Select*/}
                {/*      isClearable={true}*/}
                {/*      onChange={(value) => setOrderBy(value)}*/}
                {/*      options={listOrderBy}*/}
                {/*      value={orderBy}*/}
                {/*    />*/}
                {/*  </Form.Group>*/}
                {/*</Col>*/}

                {/*<Col xs={4} className="mt-3 align-self-end">*/}
                {/*  <Form.Group className={`d-flex gap-2`}>*/}
                {/*    <input*/}
                {/*      type={`checkbox`}*/}
                {/*      className={`form-check-input`}*/}
                {/*      id={"enrollCB"}*/}
                {/*      disabled={false}*/}
                {/*      onChange={(e) =>*/}
                {/*        setIsNotEnrolledChecked(e.target.checked ? true : false)*/}
                {/*      }*/}
                {/*    />*/}
                {/*    <label htmlFor={"enrollCB"}>Non enrolled</label>*/}
                {/*  </Form.Group>*/}
                {/*</Col>*/}
              </Row>

              <Row>
                <Col xs={3}>
                  <Button type="submit" className="btn btn-primary">
                    Retrieve
                  </Button>
                </Col>
              </Row>
            </Form>

            <button
              onClick={() => setIsOpen(!isOpen)}
              className="btn-clear btn-link ps-0 mt-3 d-flex gap-2 align-items-center"
            >
              {isOpen ? "Hide " : "Expand "}{" "}
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </div>
        </div>

        {dataApplications?.length > 0 ? (
          <div className="mt-4 p-3 bg-white shadow-sm border rounded border-1">
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Columns visibility</Form.Label>
                  <Select
                    isMulti
                    value={selectedOptions}
                    options={columnVisibilityOptions}
                    components={{ Option: customOption }}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={handleSelectedVisibilityOptions}
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className="d-flex justify-content-end align-items-end gap-3"
              >
                <Dropdown>
                  <Dropdown.Toggle
                    className={`btn btn-outline-dark`}
                    disabled={items?.length <= 0}
                  >
                    <i className="bi bi-three-dots-vertical"></i>
                    Action
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setShow(true)}>
                      Recommend
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => console.log("Clicked")}>
                      Print family detail
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => console.log("Clicked")}>
                      View CCA
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <button type="button" className="btn btn-primary">
                                    Export
                                </button> */}
              </Col>
              <Col xs={12}>
                <Table responsive>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <FormCheck
                          id="table_no"
                          label=""
                          name="table_no "
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </Table.HeaderCell>
                      {columnVisibilityOptions.map(
                        (column) =>
                          column.visible && (
                            <Table.HeaderCell key={column.value}>
                              {column.label}
                            </Table.HeaderCell>
                          )
                      )}
                      {/*<Table.HeaderCell>SNo</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Adm No.</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>ID No.</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Name</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Course</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Study Stage</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Income</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Members</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Dependant</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>MPCI</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>GPA</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Citizen</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>PR</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Housing</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>GHI Income</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Gross Income</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Received Awards</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>AGG/CPA</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>CWA</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Recommended</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>*/}
                      {/*    Application Status Remarks*/}
                      {/*</Table.HeaderCell>*/}
                      {/*<Table.HeaderCell>Special Details</Table.HeaderCell>*/}
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {dataApplications.map((item, index) => (
                      <Table.Row key={index}>
                        <Table.DataCell>
                          {" "}
                          <FormCheck
                            id="table_no"
                            label=""
                            name="table_no "
                            type="checkbox"
                            checked={items?.find(
                              (data) => data?.ID === item?.ID
                            )}
                            onChange={() => handleSelectItem(item)}
                          />
                        </Table.DataCell>
                        {columnVisibilityOptions.map(
                          (column) =>
                            column.visible && (
                              <Table.DataCell>
                                {column.value === "courseName" ? (
                                  item?.courseList?.[0]?.courseName
                                ) : column.value === "studyStage" ? (
                                  item?.courseList?.[0]?.studyStage
                                ) : column.value === "AWARD" ? (
                                  item?.awardCriteria?.[0]?.AWARD
                                ) : column.value === "gpa" ? (
                                  item?.courseList?.[0]?.gpa
                                ) : column.value === "cumWghtAvg" ? (
                                  item?.courseList?.[0]?.cumWghtAvg
                                ) : column.value === "RECOMMENDED_DATE" ? (
                                  <Form.Check
                                    checked={item?.RECOMMENDED_DATE}
                                    disabled={true}
                                  />
                                ) : (
                                  item[column.value]
                                )}
                              </Table.DataCell>
                            )
                        )}
                        <Table.DataCell>
                          <div className={`d-flex gap-1`}>
                            {/* {item.editable ? (
                                                            <button
                                                                className={`btn-clear text-green-500`}
                                                                onClick={() => handleEdit(item.id)}
                                                            >
                                                                <i className={`bi bi-check-lg`}></i> Save
                                                            </button>
                                                        ) : ( */}
                            <button
                              className={`btn-clear`}
                              onClick={() => {
                                setItems([item]);
                                setShow(true);
                              }}
                            >
                              <i className={`bi bi-pencil`}></i>
                            </button>
                            {/* )} */}
                            {/* <button
                                                            className={`btn-clear`}
                                                            onClick={() => {
                                                                setSelectedItem(item);
                                                                setShowDeleteApplication(true);
                                                            }}
                                                        >
                                                            <i className={`bi bi-trash`}></i>
                                                        </button> */}
                          </div>
                        </Table.DataCell>
                        {/*<Table.DataCell>{index + 1}</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item.editable ? (*/}
                        {/*        <input*/}
                        {/*            type="text"*/}
                        {/*            value={item.ADM_NO}*/}
                        {/*            onChange={(e) =>*/}
                        {/*                handleChange(item.id, "name", e.target.value)*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*    ) : (*/}
                        {/*        item.ADM_NO*/}
                        {/*    )}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>{item?.ID ?? "-"}</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.name}*/}
                        {/*    /!*{item.editable ? (*!/*/}
                        {/*    /!*    <input*!/*/}
                        {/*    /!*        type="number"*!/*/}
                        {/*    /!*        value={item.age}*!/*/}
                        {/*    /!*        onChange={(e) =>*!/*/}
                        {/*    /!*            handleChange(item.id, "age", e.target.value)*!/*/}
                        {/*    /!*        }*!/*/}
                        {/*    /!*    />*!/*/}
                        {/*    /!*) : (*!/*/}
                        {/*    /!*    item.age*!/*/}
                        {/*    /!*)}*!/*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.courseList?.[0]?.courseName}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.courseList?.[0]?.studyStage}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.MTHLY_INCOME ? `$${item?.MTHLY_INCOME}` : "-"}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.FAMILY_MEMBERS ?? "-"}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.DEPENDENT ?? "-"}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>{item?.MPCI ?? "-"}</Table.DataCell>*/}
                        {/*<Table.DataCell>-</Table.DataCell>*/}
                        {/*<Table.DataCell>{item?.citizen}</Table.DataCell>*/}
                        {/*<Table.DataCell>{item?.pr ?? "-"}</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.HOMEHOUSETYPE ?? "-"}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.OTHER_INCOME ? `$${item?.OTHER_INCOME}` : "-"}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.GROSS_INCOME ? `$${item?.GROSS_INCOME}` : "-"}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.awardCriteria?.[0]?.AWARD}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.courseList?.[0]?.gpa}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    {item?.courseList?.[0]?.cumWghtAvg}*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell className="text-center">*/}
                        {/*    <Form.Check*/}
                        {/*        checked={item?.RECOMMENDED_DATE}*/}
                        {/*        disabled={true}*/}
                        {/*    />*/}
                        {/*</Table.DataCell>*/}
                        {/*<Table.DataCell>{item?.REMARK}</Table.DataCell>*/}
                        {/*<Table.DataCell>{item?.SPECIAL_DETAILS}</Table.DataCell>*/}
                        {/*<Table.DataCell>*/}
                        {/*    <div className={`d-flex gap-1`}>*/}
                        {/*        /!* {item.editable ? (*/}
                        {/*            <button*/}
                        {/*                className={`btn-clear text-green-500`}*/}
                        {/*                onClick={() => handleEdit(item.id)}*/}
                        {/*            >*/}
                        {/*                <i className={`bi bi-check-lg`}></i> Save*/}
                        {/*            </button>*/}
                        {/*        ) : ( *!/*/}
                        {/*        <button*/}
                        {/*            className={`btn-clear`}*/}
                        {/*            onClick={() => {*/}
                        {/*                setItems([item]);*/}
                        {/*                setShow(true);*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <i className={`bi bi-pencil`}></i>*/}
                        {/*        </button>*/}
                        {/*        /!* )} *!/*/}
                        {/*        /!* <button*/}
                        {/*            className={`btn-clear`}*/}
                        {/*            onClick={() => {*/}
                        {/*                setSelectedItem(item);*/}
                        {/*                setShowDeleteApplication(true);*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <i className={`bi bi-trash`}></i>*/}
                        {/*        </button> *!/*/}
                        {/*    </div>*/}
                        {/*</Table.DataCell>*/}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Col>
            </Row>
          </div>
        ) : null}
      </sgds-content-body>

      <Modal
        size={"lg"}
        show={show}
        onHide={() => setShow(false)}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Action - Recommend</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className={`justify-content-center`}>
            <Col xs={8} className={`bg-yellow-100 p-2 text-center`}>
              Selected{" "}
              {items
                ?.slice(0, 4)
                ?.map((item) => (item?.name !== "" ? item?.name : item?.ID))
                ?.join(", ")}
              {items?.length > 4 ? `and ${items?.length - 2} more` : ""}
            </Col>
          </Row>

          <div className={`d-flex flex-column gap-3 mt-3`}>
            <h5 className={`fw-bold mb-0`}>Add remarks</h5>

            <Row>
              <Col xs={4}>
                <label>Application Status</label>
              </Col>
              <Col xs={8}>
                <Select
                  backspaceRemovesValue={true}
                  onChange={(value) =>
                    setRecommended((prevState) => ({
                      ...prevState,
                      applicationStatus: value,
                    }))
                  }
                  options={[
                    { label: "Pending Recommendation", value: "Z" },
                    { label: "Recommended", value: "R" },
                    { label: "Pending Approval", value: "P" },
                    { label: "Pending Approval with changes", value: "C" },
                    { label: "Rescind", value: "E" },
                  ]}
                  placeholder={`Application Status`}
                  value={recommended?.applicationStatus}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={4}>
                <label>Application Status Remarks</label>
              </Col>
              <Col xs={8}>
                <Form.Control
                  type={"text"}
                  onChange={(e) =>
                    setRecommended((prevState) => ({
                      ...prevState,
                      remarks: e?.target?.value,
                    }))
                  }
                  value={recommended?.remarks}
                ></Form.Control>
              </Col>
            </Row>

            <Row>
              <Col xs={4}>
                <label>Special Details</label>
              </Col>
              <Col xs={8}>
                <Form.Control
                  type={"text"}
                  onChange={(e) =>
                    setRecommended((prevState) => ({
                      ...prevState,
                      specialDetails: e?.target?.value,
                    }))
                  }
                  value={recommended?.specialDetails}
                ></Form.Control>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className={`gap-2`}>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRecommended}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <CustomModal
        show={showDeleteApplication}
        handleClose={() => setShowDeleteApplication(false)}
        action={handleDeleteApplication}
        title={`Delete record?`}
        description={`The selected record will be removed from the list. Proceed to delete?`}
      />
    </Layout>
  );
}
