import React from "react";
import axios from "axios";

export async function getApplicationHomes(ACAD_YR, EXERCISE, ADM_NO) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/homes/online?ACAD_YR=${ACAD_YR}&EXERCISE=${EXERCISE}&ADM_NO=${ADM_NO}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function getMTCRequest(ACAD_YR, EXERCISE, ADM_NO) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/mt/requests?ACAD_YR=${ACAD_YR}&FAAS_EXERCISE=${EXERCISE}&ADM_NO=${ADM_NO}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function getMTCRequestResult(REQUEST_ID, MEAN_TEST_ID) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/mt/requests?REQUEST_ID=${REQUEST_ID}&MEAN_TEST_ID=${MEAN_TEST_ID}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function getMTCases(MEANS_TEST_ID, CASE_ID) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/mt/cases?MEANS_TEST_ID=${MEANS_TEST_ID}&CASE_ID=${CASE_ID}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function getMTCaseNotes(MEANS_TEST_ID, CASE_ID) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/mt/cases/notes?MEANS_TEST_ID=${MEANS_TEST_ID}&CASE_ID=${CASE_ID}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function getMTCDetails(
  ACAD_YR,
  EXERCISE,
  ADM_NO,
  DETAIL_TAG = ""
) {
  if (DETAIL_TAG == "") {
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/v1/applications/mtc/details?ACAD_YR=${ACAD_YR}&FAAS_EXERCISE=${EXERCISE}&ADM_NO=${ADM_NO}`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err?.message);
        return err;
      });
  } else {
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/v1/applications/mtc/details?ACAD_YR=${ACAD_YR}&FAAS_EXERCISE=${EXERCISE}&ADM_NO=${ADM_NO}&DETAIL_TAG=${DETAIL_TAG}`
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err?.message);
        return err;
      });
  }
}

export async function postApplicationHomes(data) {
  return axios
    .patch(process.env.REACT_APP_API_URL + `/api/v1/applications`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function getMtConsent(ACK_NO, NYP_CONSENT_ID, DETAIL_TAG) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/mt/consents?ACK_NO=${ACK_NO}&NYP_CONSENT_ID=${NYP_CONSENT_ID}&DETAIL_TAG=${DETAIL_TAG}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function getMtIncomes(MEANS_TEST_ID) {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        `/api/v1/applications/mt/incomes?MEANS_TEST_ID=${MEANS_TEST_ID}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function postAppeal(data) {
  return axios
    .post(process.env.REACT_APP_API_URL + `/api/v1/applications/mtc/details`, {
      DATA: data,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}
