import React from "react";

import {Button, Col, Form, Row,} from "@govtechsg/sgds-react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import { deleteSiblings } from "@/apis/app-records/siblings/api";

const statusOptions = [
    {
        label: "Living in the same household",
        value: "L",
    },
    {
        label: "Not living in the same household",
        value: "NL",
    },
    {
        label: "Deceased",
        value: "DE",
    },
    {
        label: "Divorced. Living with applicant",
        value: "DL",
    },
    {
        label: "Divorced. Not living with applicant",
        value: "DN",
    },
    {
        label: "In-Jail",
        value: "IJ",
    },
];

function Sibling({
                     listSibling,
                     setListSibling,
                     data,
                     studentDetail,
                     setShowFormulaModal,
                     isDisabled,
                     handleSiblingChange,
                     statusOccupationList,
                     getTotalSumIncome,
                     handleDeleteSiblings,
                     isReadOnly = false
                 }) {

    const handleAddNewSibling = () => {
        // setAddNewSiblingCount([...siblingCount, 1]);
        setListSibling([
            ...listSibling,
            {
                ACAD_YR: data?.ACAD_YR,
                EXERCISE: data?.EXERCISE,
                ADM_NO: data?.ADM_NO,
                NAME: "",
                BIRTHDATE: "",
                AGE: 0,
                FAAS_OCCUPATIONS: "",
                GROSS_MTHLY_INCOME: "",
                ID_NO: "",
                LAST_UPD_ID: sessionStorage.getItem('role') ? sessionStorage.getItem('role') : process.env.REACT_APP_LAST_UPD_ID,
                MARITAL_STATUS: "SINGLE",
                RELATIONSHIP: "P",
                REMARK: "",
                OCCUPATIONS: "",
                isIncomeContribute: "Y",
                isRemarkRequired: "Y",
            },
        ]);
    };

    const handleRemoveSiblingCount = (index) => {
        const siblingDetail = listSibling[index];
        handleDeleteSiblings(siblingDetail?.ID)
        const updatedSiblingCount = listSibling.filter((_, i) => i !== index);
        setListSibling(updatedSiblingCount);
    };

    return (
        <React.Fragment>
            {isReadOnly ?
                "" :
                <div>
                    <Button
                        type="button"
                        variant="btn btn-link ps-0 text-decoration-none"
                        onClick={() => handleAddNewSibling()}
                    >
                        <i class="bi bi-plus-circle"></i> {"  "} Add sibling
                    </Button>
                </div>
            }
            {listSibling.map((sibling, index) => {
                return (
                    <div className="mt-3" key={index}>
                        <div>
                            <h5>
                                #{index + 1} Sibling / Relatives
                                {isReadOnly ?
                                "" :

                                <button
                                    className={`btn-clear`}
                                    type={"button"}
                                    disabled={isDisabled}
                                    onClick={() => {
                                        if (confirm(`Are you sure want to delete ${sibling?.NAME}!`) === true) {
                                            handleRemoveSiblingCount(index);
                                        }
                                    }}
                                >
                                    <i className={`bi bi-trash`}></i>
                                </button>
                                }
                            </h5>
                        </div>
                        <Row>
                            <Col xs={6} className="">
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type={`text`}
                                        disabled={isDisabled}
                                        value={sibling?.NAME}
                                        onChange={(e) => {
                                            handleSiblingChange("NAME", e.target.value, index);
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group>
                                    <Form.Label>NRIC / Passport No</Form.Label>
                                    <Form.Control
                                        type={`text`}
                                        disabled={isDisabled}
                                        value={sibling?.ID_NO}
                                        onChange={(e) =>
                                            handleSiblingChange("ID_NO", e.target.value, index)
                                        }
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group className="d-flex flex-column">
                                    <Form.Label>Birth date</Form.Label>
                                    <DatePicker
                                        className={`form-control`}
                                        selected={sibling?.BIRTHDATE}
                                        onChange={(date) => {
                                            if (date == null) {
                                                handleSiblingChange("BIRTHDATE", "", index);
                                                handleSiblingChange("AGE", 0, index);
                                            } else {
                                                const choosenDate = moment(date).format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                );

                                                handleSiblingChange("BIRTHDATE", choosenDate, index);
                                                handleSiblingChange(
                                                    "AGE",
                                                    moment().diff(choosenDate, "years"),
                                                    index
                                                );
                                            }
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        maxDate={new Date()}
                                        disabled={isDisabled}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group>
                                    <Form.Label>Age</Form.Label>
                                    <Form.Control
                                        type={`text`}
                                        disabled={true}
                                        value={sibling?.AGE}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group>
                                    <Form.Label>Occupation</Form.Label>
                                    <Select
                                        options={statusOccupationList}
                                        isDisabled={isDisabled}
                                        value={sibling?.OCCUPATIONS}
                                        onChange={(selectedOption) => {
                                            handleSiblingChange(
                                                "FAAS_OCCUPATIONS",
                                                selectedOption.value,
                                                index
                                            );

                                            handleSiblingChange(
                                                "OCCUPATIONS",
                                                {
                                                    label: selectedOption.label,
                                                    value: selectedOption.value,
                                                    isIncomeContribute:
                                                    selectedOption.is_income_contribute,
                                                    isRemarkRequired: selectedOption.is_remark_required,
                                                },
                                                index
                                            );

                                            if (selectedOption.is_income_contribute !== "Y") {
                                                handleSiblingChange("GROSS_MTHLY_INCOME", 0, index);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            {/*
              <Col xs={6} className="">
                <Form.Group>
                  <Form.Label>Other Income</Form.Label>
                  <Form.Control
                    type={`text`}
                    disabled={isDisabled}
                    value={listSibling[currentIndex]?.OTHER_INCOME}
                    onChange={(e) => {
                      handleSiblingChange(
                        "OTHER_INCOME",
                        e.target.value,
                        currentIndex
                      );
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>

              */}
                            <Col xs={12} className="">
                                <Form.Group>
                                    <Form.Label>Gross Monthly Income</Form.Label>
                                    <Form.Control
                                        type={`text`}
                                        disabled={
                                            isDisabled
                                            // || sibling?.OCCUPATIONS?.isIncomeContribute !== "Y"
                                        }
                                        value={sibling?.GROSS_MTHLY_INCOME}
                                        onChange={(e) => {
                                            handleSiblingChange(
                                                "GROSS_MTHLY_INCOME",
                                                e.target.value,
                                                index
                                            );
                                            getTotalSumIncome();
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>

                            <Col xs={12} className="">
                                <Form.Group>
                                    <Form.Label>Remark</Form.Label>
                                    <Form.Control
                                        type={`text`}
                                        disabled={
                                            isDisabled
                                            // || sibling?.OCCUPATIONS?.isRemarkRequired !== "Y"
                                        }
                                        value={sibling?.REMARK}
                                        onChange={(e) => {
                                            handleSiblingChange("REMARK", e.target.value, index);
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </React.Fragment>
    );
}

export default Sibling;
