import React, { useState, useCallback, useEffect, useRef } from "react";
import Layout from "@/components/Layout";
import {
  Button,
  Col,
  Form,
  Row,
  Collapse,
  Tabs,
  Tab,
  Badge,
  FormCheck,
  Table,
} from "@govtechsg/sgds-react";
import Select from "react-select";
import {
  FaBellSlash,
  FaChevronDown,
  FaChevronUp,
  FaPray,
} from "react-icons/fa";
import CustomModal from "@/components/CustomModal";
import { getAcademyYear, getExercise } from "@/apis/timeline/api";
import { getCodeList, getStudentList, getStudentDetail } from "@/apis/sims/api";
import { VerificationStatus } from "@/apis/app-records/maintenance/status";
import { GetApplications, getRecommendedApplicant } from "@/apis/reports/api";
import jsPDF from "jspdf";
import { utils, writeFileXLSX } from "xlsx";
import autoTable from "jspdf-autotable";
import { Breadcrumb } from "@govtechsg/sgds-react/Breadcrumb";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";

import {
  getListAcadYears,
  getListExercise,
} from "@/apis/preparation/app-timeline/api";
import {
  getApplicationDetail,
  getApplicationList,
} from "@/apis/app-records/maintenance/api";

const options = [{ value: "CREATED_AT", label: "CREATED_AT" }];
const orderByList = [
  { label: "Admission No.", value: "ADM_NO" },
  { label: "ID No.", value: "ID_NO" },
  { label: "Name", value: "NAME" },
  { label: "Course Code", value: "COURSE_CODE" },
  { label: "Stud Status", value: "STUD_STATUS" },
  { label: "Date Recommended", value: "DATE_RECOMMEND" },
  { label: "Date Form Printed", value: "DATE_FORM_PRINTED" },
  { label: "Term Address 1", value: "TERM_ADDR1" },
  { label: "Term Address 2", value: "TERM_ADDR2" },
  { label: "Term Address 3", value: "TERM_ADDR3" },
  { label: "Term State", value: "TERM_STATE" },
  { label: "Term Country", value: "TERM_COUNTRY" },
  { label: "Term Postal Code", value: "TERM_POSTAL_CODE" },
];

function PrintRecommendedApplicants() {
  const [isOpen, setIsOpen] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);

  const refPDF = useRef();
  const refCSV = useRef();
  const headerRef = useRef();
  const tbl = useRef(null);

  const [dataApplications, setDataApplications] = useState(null);
  const [studentDetail, setStudentDetail] = useState(null);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [dateRangeForm, setDateRangeForm] = useState([null, null]);
  const [startDateForm, endDateForm] = dateRangeForm;

  const [academyYear, setAcademyYear] = useState(null);
  const [exercise, setExercise] = useState(null);
  const [admNo, setAdmNo] = useState(null);
  const [awardType, setAwardType] = useState(null);
  const [awardCode, setAwardCode] = useState(null);
  const [orderBy, setOrderBy] = useState("CREATED_AT");
  const [showRetrieve, setShowRetrieve] = useState(false);

  // list
  const [listAcademyYear, setListAcademyYear] = useState([]);
  const [listExercise, setListExercise] = useState([]);
  const [listAdmNo, setListAdmNo] = useState([]);
  const [listAwardType, setListAwardType] = useState([]);
  const [listAwardCode, setListAwardCode] = useState([]);

  const refTimeoutSearch = useRef(null);

  const handleSubmit = () => {};

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const exportToXlsx = useCallback(() => {
    const elt = tbl.current;
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "recommended-applicant.xlsx");
  }, [tbl]);

  const handleExport = () => {
    let optionChecked = "";

    let pdfChecked = refPDF.current.checked;
    let csvChecked = refCSV.current.checked;
    if (pdfChecked) {
      optionChecked = "PDF";
    } else if (csvChecked) {
      optionChecked = "CSV";
    }

    if (optionChecked == "PDF") {
      const docTable = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a3",
        margin: [10, 10, 10, 10],
      });
      autoTable(docTable, {
        html: "#my-table",
        willDrawCell: function (data) {
          if (data.row.section === "head") {
            data.cell.styles.fillColor = [9, 230, 42];
          }
        },
      });

      docTable.save("report-details.pdf");
    } else if (optionChecked == "CSV") {
      exportToXlsx();
    }
  };

  const retrieveReport = async () => {
    if (
      !academyYear?.value &&
      !exercise?.value &&
      !admNo?.value &&
      !awardType?.code &&
      !awardCode?.value &&
      !startDate &&
      !endDate &&
      !startDateForm &&
      !endDateForm
    ) {
      return false;
    }

    setShowRetrieve(true);
    getRecommendedApplicant(
      academyYear?.value,
      exercise?.value,
      admNo?.value,
      awardType?.code,
      awardCode?.value,
      startDate ? moment(startDate).format("yyyy-MM-DD") : null,
      endDate ? moment(endDate).format("yyyy-MM-DD") : null,
      startDateForm ? moment(startDateForm).format("yyyy-MM-DD") : null,
      endDateForm ? moment(endDateForm).format("yyyy-MM-DD") : null,
      orderBy
    ).then(async (resp) => {
      if (resp?.response?.data?.message) {
        toast.error(resp?.response?.data?.message);
      } else if (resp?.response?.status == 404) {
        toast.error("Data not found");
      } else {
        console.log(resp);
        if (resp.status == 200) {
          setDataApplications(resp?.data?.data);
          const studDetail = await getStudentDetail(admNo?.value);
          setStudentDetail(
            studDetail?.data?.data?.length > 0 ? studDetail?.data?.data[0] : []
          );
        }
      }
    });
  };

  const handleGetListAcademyYear = async () => {
    try {
      setAcademyYear(null);

      const response = await getAcademyYear();

      setListAcademyYear(
        response?.data?.data?.map((item) => ({
          label: item?.ACAD_YR,
          value: item?.ACAD_YR,
        }))
      );
      // handleGetListExercise();
    } catch (error) {
      console.log(error?.message);
    }
  };
  const handleSearch = (searchFunction = null, value = "") => {
    clearTimeout(refTimeoutSearch.current);

    if (value) {
      refTimeoutSearch.current = setTimeout(() => {
        searchFunction(value);
      }, 500);
    }
  };

  const handleGetListExercise = async () => {
    getListExercise(academyYear?.value).then((resp) => {
      if (resp?.response?.data?.message) {
        if (
          resp?.response?.data?.message?.length > 0 &&
          Array.isArray(resp?.response?.data?.message)
        ) {
          resp?.response?.data?.message?.map((contentErr) => {
            toast.error(contentErr?.msg);
          });
        } else {
          toast.error(resp?.response?.data?.message);
        }
      } else if (resp?.response?.status == 404) {
        toast.error("Data not found");
      } else {
        if (resp.status == 200) {
          let listContent = [];
          resp?.data?.data?.map((item) => {
            listContent.push({ value: item?.EXERCISE, label: item?.EXERCISE });
          });
          setListExercise(listContent);
        }
      }
    });
  };

  const handleGetListAdminNo = async (search = "") => {
    try {
      setAdmNo(null);
      setListAdmNo([]);

      const params = {
        student_id: "",
        studstatus: "W",
        transfer_tag: "N",
        page: 1,
        limit: 20,
        search: search,
        sort: "id_no",
      };

      // const response = await getStudentList(params);
      const response = await GetApplications(
        academyYear?.value,
        exercise?.value
      );

      setListAdmNo(
        response?.data?.data?.map((item) => ({
          label: item?.ADM_NO,
          value: item?.ADM_NO,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleGetListAward = async (codeType = "") => {
    try {
      setAwardCode(null);

      const response = await getCodeList(codeType);

      setListAwardCode(
        response?.data?.data?.map((item) => ({
          label: item?.description,
          value: item?.code,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleGetListAwardType = async (codeType = "AWARDTYPECODE") => {
    try {
      setAwardType(null);

      const response = await getCodeList(codeType);

      setListAwardType(
        response?.data?.data?.map((item) => ({
          label: item?.description,
          value: item?.codetype,
          code: item?.code,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  useEffect(() => {
    handleGetListAcademyYear();
    // handleGetListAdminNo();
    handleGetListAwardType();
  }, []);

  useEffect(() => {
    if (academyYear?.value) handleGetListExercise();
  }, [academyYear]);
  useEffect(() => {
    if (exercise?.value) handleGetListAdminNo();
  }, [exercise]);

  useEffect(() => {
    console.log(awardType);
    if (awardType?.code) handleGetListAward(awardType?.code);
  }, [awardType]);

  return (
    <Layout currentNav={"reports"}>
      <Breadcrumb
        listProps={{
          className: "bg-light",
          foo: "bar",
        }}
      >
        <Breadcrumb.Item>Reports</Breadcrumb.Item>
        <Breadcrumb.Item active>Recommended Applicants</Breadcrumb.Item>
      </Breadcrumb>

      <sgds-content-header-top>
        <h2>Recommended Applicants</h2>
      </sgds-content-header-top>
      <sgds-content-body>
        {" "}
        <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
          <div className="row">
            <div className="col">
              <h3>Data Retrieval</h3>
            </div>
          </div>

          <Form
            className={` d-flex flex-column gap-3 collapse-animation`}
            onSubmit={handleSubmit()}
          >
            <Row>
              <Col xs={4}>
                <Form.Group>
                  <Form.Label>Academic Year</Form.Label>
                  <Select
                    onChange={(value) => setAcademyYear(value)}
                    options={listAcademyYear}
                    placeholder={`Academic Year`}
                    value={academyYear}
                  />
                </Form.Group>
              </Col>

              <Col xs={4}>
                <Form.Group>
                  <Form.Label>Exercise</Form.Label>
                  <Select
                    isDisabled={!academyYear?.value}
                    onChange={(value) => setExercise(value)}
                    options={listExercise}
                    placeholder={`Exercise`}
                    value={exercise}
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group>
                  <Form.Label>Adm. No.</Form.Label>
                  <Select
                    onChange={(value) => setAdmNo(value)}
                    options={listAdmNo}
                    placeholder={`Adm. No`}
                    value={admNo}
                    onInputChange={(value) =>
                      handleSearch(handleGetListAdminNo, value)
                    }
                  />
                </Form.Group>
              </Col>

              <Col xs={6} className="mt-3">
                <Form.Group>
                  <Form.Label>Award Type</Form.Label>
                  <Select
                    onChange={(value) => setAwardType(value)}
                    options={listAwardType}
                    placeholder={`Award Type`}
                    value={awardType}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} className="mt-3">
                <Form.Group>
                  <Form.Label>Award</Form.Label>
                  <Select
                    isDisabled={!awardType?.value}
                    onChange={(value) => setAwardCode(value)}
                    options={listAwardCode}
                    placeholder={`Award Code`}
                    value={awardCode}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} className="mt-3">
                <Form.Group>
                  <Form.Label>Date Recommended (Range)</Form.Label>
                  <div className="datepicker-faas">
                    <DatePicker
                      className="form-control w-100"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        if (update == null) {
                          setDateRange(null);
                        } else {
                          setDateRange(update);
                        }
                      }}
                      isClearable={true}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col xs={6} className="mt-3">
                <Form.Group>
                  <Form.Label>Date Form Printed (Range)</Form.Label>
                  <div className="datepicker-faas">
                    <DatePicker
                      className="form-control"
                      selectsRange={true}
                      startDate={startDateForm}
                      endDate={endDateForm}
                      onChange={(update) => {
                        if (update == null) {
                          setDateRangeForm(null);
                        } else {
                          setDateRangeForm(update);
                        }
                      }}
                      isClearable={true}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col xs={4} className="mt-3">
                <Form.Group>
                  <Form.Label>Order by</Form.Label>
                  <Select options={orderByList} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => retrieveReport()}
                >
                  Retrieve
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        {showRetrieve && (
          <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
            <Row>
              <Col xs={12}>
                <div className="text-end">
                  <Button
                    type="button"
                    className="btn btn-primary ms-auto"
                    onClick={() => setShowModalExport(true)}
                  >
                    Export
                  </Button>
                </div>
              </Col>
            </Row>
            <div>
              <Row id="header-pdf" ref={headerRef}>
                <Col xs={12}>
                  <h3 className="text-center mb-6">
                    Recommended Applicant Detail Report
                  </h3>
                </Col>
                <Col xs={4}>
                  <h5>
                    Academic Year: <span>{academyYear?.value}</span>
                  </h5>
                  <h5>
                    Recommended Award Type: <span>{awardType?.label}</span>
                  </h5>
                  <h5>
                    Date Recommended:{" "}
                    <span>
                      {moment(startDate).format("DD/MM/yyyy")} -{" "}
                      {moment(endDate).format("DD/MM/yyyy")}
                    </span>
                  </h5>
                  <h5>
                    No. Recommended: <span>{1}</span>
                  </h5>
                </Col>
                <Col xs={4}>
                  <h5>
                    Exercise: <span>{exercise?.label}</span>
                  </h5>
                  <h5>
                    Recommended Award: <span>{awardCode?.label}</span>
                  </h5>
                  <h5>
                    Date Form Printed:{" "}
                    <span>
                      {moment(startDateForm).format("DD/MM/yyyy")} -{" "}
                      {moment(endDateForm).format("DD/MM/yyyy")}
                    </span>
                  </h5>
                  <h5>
                    No. Form Printed: <span>{1}</span>
                  </h5>
                </Col>
              </Row>
              <Table responsive ref={tbl} id="my-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Adm No.
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      ID No.
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Name
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Course Code
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Stud Status
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Study Stage
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Recommended Date
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Form Printed Date
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Term Address 1
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Term Address 2
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Term Address 3
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Term State
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Term Country
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "150px" }}>
                      Term Postal Code
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.DataCell>{studentDetail?.admNo}</Table.DataCell>
                    <Table.DataCell>{studentDetail?.idNo}</Table.DataCell>
                    <Table.DataCell>{studentDetail?.name}</Table.DataCell>
                    <Table.DataCell>
                      {studentDetail?.courseList?.length > 0
                        ? studentDetail?.courseList[0]?.courseName
                        : "-"}
                    </Table.DataCell>
                    <Table.DataCell>
                      {studentDetail?.courseList?.length > 0
                        ? studentDetail?.courseList[0]?.studStatus
                        : "-"}
                    </Table.DataCell>
                    <Table.DataCell>
                      {studentDetail?.courseList?.length > 0
                        ? studentDetail?.courseList[0]?.studyStage
                        : "-"}
                    </Table.DataCell>
                    <Table.DataCell>
                      {moment(startDate).format("DD/MM/yyyy")} -{" "}
                      {moment(endDate).format("DD/MM/yyyy")}
                    </Table.DataCell>
                    <Table.DataCell>
                      {moment(startDateForm).format("DD/MM/yyyy")} -{" "}
                      {moment(endDateForm).format("DD/MM/yyyy")}
                    </Table.DataCell>
                    <Table.DataCell>{studentDetail?.termAddr1}</Table.DataCell>
                    <Table.DataCell>{studentDetail?.termAddr2}</Table.DataCell>
                    <Table.DataCell>{studentDetail?.termAddr3}</Table.DataCell>
                    <Table.DataCell>{studentDetail?.termState}</Table.DataCell>
                    <Table.DataCell>
                      {studentDetail?.termCountry}
                    </Table.DataCell>
                    <Table.DataCell>
                      {studentDetail?.termPostCode}
                    </Table.DataCell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
        )}
      </sgds-content-body>
      <CustomModal
        show={showModalExport}
        title={`Export`}
        handleClose={() => setShowModalExport(false)}
        modalSize={"md"}
        withIcon={false}
        description={
          <div>
            <div>
              <b>Export as</b>
            </div>
            <div>
              <FormCheck
                type="radio"
                name="export"
                label="PDF"
                value="pdf"
                id="pdf"
                ref={refPDF}
              />
              <FormCheck
                ref={refCSV}
                type="radio"
                name="export"
                label="CSV"
                value="csv"
                id="csv"
              />
            </div>
          </div>
        }
        submitButtonText={`Export`}
        action={handleExport}
      />
    </Layout>
  );
}

export default PrintRecommendedApplicants;
