import React, {useEffect, useState} from "react";

import {Col, Form, Row} from "@govtechsg/sgds-react";
import Select from "react-select";
import {ApplicationStatus, DataFlagStatus, VerificationStatus,} from "@/apis/app-records/maintenance/status";
import {getCodeTypeSasList} from "@/apis/sims/api";
import {toast} from "react-toastify";
import {getRemarkPA} from "@/apis/preparation/setup-remarks/api";
import moment from "moment";

const options = [
    {value: "chocolate", label: "Chocolate"},
    {value: "strawberry", label: "Strawberry"},
    {value: "vanilla", label: "Vanilla"},
];

function ApplicationDetail({
                               data,
                               studentDetail,
                               isDisabled,
                               selectedVerificationStatus,
                               setSelectedVerificationStatus,
                               selectedApplicationStatus,
                               setSelectedApplicationStatus,
                               selectedDataFlag,
                               setSelectedDataFlag,
                               selectedAwardType,
                               setSelectedAwardType,
                               selectedAwardCode,
                               setSelectedAwardCode,
                               textReasonForChanges,
                               setTextReasonForChanges,
                               textRemarkS1,
                               setTextRemarkS1,
                               textApprovalRemarks,
                               setTextApprovalRemarks,
                               textApprovalStatusSpecialRemarks,
                               setTextApprovalStatusSpecialRemarks,
                               textSpecialDetailRemarks,
                               setTextSpecialDetailRemarks,
                               PARemarks,
                               NYPRemarks,
                               setNypRemark,
                               setPaRemark,
                               textDSpecialDetailRemarks,
                               handleApplicationChange
                           }) {
    const [academicYear, setAcademicYear] = useState(null);

    // define variable

    // define list
    const [awardTypeList, setAwardTypeList] = useState([]);
    const [awardCodeList, setAwardCodeList] = useState([]);
    const [PAOptions, setPAOptions] = useState([]);
    const [NYPOptions, setNYPOptions] = useState([]);
    const [selectedPAOption, setSelectedPAOption] = useState(null);
    const [selectedNYPOption, setSelectedNYPOption] = useState(null);
    const [remapVerifOptions, setRemapVerifOptions] = useState([]);
    const [remapApplOptions, setRemapApplOptions] = useState([]);
    const [selectedReasonForChanges, setSelectedReasonForChanges] = useState(null)
    const [reasonOptions, setReasonOptions] = useState([]);

    const roleList = JSON.parse(sessionStorage.getItem("roles")) || [];

    const handleClickEdit = () => {
        setIsDisabled(!isDisabled);
    };

    useEffect(() => {
        setSelectedVerificationStatus({
            label:
                data?.VERIFY_STATUS ?
                VerificationStatus()
                .find(option => option.value === data?.VERIFY_STATUS)
                ? `${data?.VERIFY_STATUS} (${VerificationStatus().find(option => option.value === data?.VERIFY_STATUS).label})`
                : '' : '',
            value: data?.VERIFY_STATUS,
        });

        setSelectedApplicationStatus({
            label: data?.APPLN_STATUS,
            value: data?.APPLN_STATUS,
        });

        setSelectedDataFlag({
            label: data?.DATA_FLAG,
            value: data?.DATA_FLAG,
        });

        setSelectedAwardType({
            label: "",
            value: data?.REC_AWARD_TYPE,
        });

        setSelectedAwardCode({
            label: "",
            value: data?.REC_AWARD_CODE,
        });

        // Approval status remarks (NYP)
        setSelectedNYPOption({
            label: NYPRemarks?.map((content) => {
                if (content?.CODE == data?.REMARK) {
                    return content?.CODE + " - " + content?.REMARK;
                }
            }),
            value: data?.REMARK,
        });

        // Approval status remarks (PA)
        setSelectedPAOption({
            label: data?.APPROVAL_STATUS_REASON,
            value: data?.APPROVAL_STATUS_REASON,
        });

        // Special Note == Approval Status Special Remarks
        setTextApprovalStatusSpecialRemarks(data?.SPECIAL_NOTE);
        // Special details == Special Details Remark
        setTextSpecialDetailRemarks(data?.SPECIAL_DETAILS);
        setTextReasonForChanges(data?.REASON_FOR_CHANGES);
        // setTextApprovalRemarks(data?.REMARKS1);

        // setTextRemarkS1(data?.REMARKS1);

        getCodeTypeSasList("AWARDTYPECODE").then((resp) => {
            let contentArray = [];
            resp?.data?.data?.map((content) => {
                contentArray.push({
                    label: content.description,
                    value: content.codetype,
                    code: content.code,
                });

                if (data?.REC_AWARD_TYPE == content.codetype) {
                    setSelectedAwardType({
                        label: content.description,
                        value: content.codetype,
                        code: content.code,
                    });
                }
            });
            setAwardTypeList(contentArray);
        });

        getCodeTypeSasList(selectedAwardType.code).then((resp) => {
            let contentArray = [];
            resp?.data?.data?.map((content) => {
                contentArray.push({
                    label: content.description,
                    value: content.code,
                });
                if (data?.REC_AWARD_CODE == content.code) {
                    setSelectedAwardCode({
                        label: content.description,
                        value: content.code,
                    });
                }
            });
            setAwardCodeList(contentArray);
        });
    }, [
        data?.VERIFY_STATUS,
        data?.APPLN_STATUS,
        data?.DATA_FLAG,
        data?.REC_AWARD_TYPE,
        data?.REC_AWARD_CODE,
        data?.REASON_FOR_CHANGES,
        data?.REMARKS1,
        data?.REMARK,
        data?.APPROVAL_STATUS_REASON,
        data?.SPECIAL_NOTE,
    ]);

    useEffect(() => {
        getCodeTypeSasList(selectedAwardType.code).then((resp) => {
            let content = [];
            resp?.data?.data?.map((data) => {
                content.push({
                    label: data.description,
                    value: data.code,
                });
            });
            setAwardCodeList(content);
        });
    }, [selectedAwardType]);

    useEffect(() => {
        const newNYPOptions = NYPRemarks?.map((remark) => ({
            label: `${remark.CODE} - ${remark.REMARK}`,
            value: remark.CODE,
        }));

        setNYPOptions(newNYPOptions);

        const newPAOptions = PARemarks?.map((remark) => ({
            label: remark.STS_REASON,
            value: remark.STS_REASON,
        }));

        setPAOptions(newPAOptions);
    }, [PARemarks, NYPRemarks]);

    useEffect(() => {
        if (data) {
            const matchingOption = NYPOptions?.find(
                (option) => option.value === data.REASON_FOR_CHANGES
            );
            if (matchingOption) {
                setSelectedNYPOption(matchingOption);
            }

            const PAmatchingOption = PAOptions?.find(
                (option) => option.value === data.APPROVAL_STATUS_REASON
            );
            if (PAmatchingOption) {
                setSelectedPAOption(PAmatchingOption);
            }
        }
    }, [data, NYPOptions, PAOptions]);

    useEffect(() => {
        const updatedApplOptions = ApplicationStatus().map((status) => {
            console.log("status update application status")
            console.log(status)
            // let isDisabled = true;
            let isDisabled = false;
            /*
            //Application status can only be set to 'N' if the verification status = Exclude
            if (data?.VERIFY_STATUS === "E" && status.value === "N") {
                isDisabled = false;
            }

            //Allow changing the application status to 'P' or 'C' if the last application status is ‘A’ or ‘N’.
            if (
                (data?.APPLN_STATUS === "A" || data?.APPLN_STATUS === "N") &&
                (status.value === "P" || status.value === "C")
            ) {
                isDisabled = false;
            }

            */
            return {...status, isDisabled};
        });

        setRemapApplOptions(updatedApplOptions);
    }, [data?.VERIFY_STATUS, data?.APPLN_STATUS]);

    useEffect(() => {
        getRemarkPA()
            .then((response) => {
                console.log(response)
                const statusReason = response?.data?.data?.map((option) => ({
                    label: option.STS_REASON,
                    value: option.STS_REASON,
                }))

                setReasonOptions(statusReason)
            })
    }, []);

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>Application Date</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.APPLN_DATE ? moment(data?.APPLN_DATE).format('YYYY-MM-DD HH:mm:ss') : ''}
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>Verification Status</Form.Label>
                            <Select
                                options={VerificationStatus()}
                                isDisabled={roleList.includes("FAAS_ADM") ||roleList.includes("FAAS_TEMP")  || roleList.includes("FAAS_TEMPA")  ? isDisabled : true }
                                value={selectedVerificationStatus}
                                onChange={(selectedOption) => {
                                    setSelectedVerificationStatus({
                                        label: `${selectedOption.value} (${selectedOption.label})`,
                                        value: selectedOption.value,
                                    });
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>Application Status</Form.Label>
                            <Select
                                options={remapApplOptions}
                                isDisabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                value={selectedApplicationStatus}
                                onChange={(selectedOption) => {
                                    if (
                                        (data?.APPLN_STATUS === "P" &&
                                            selectedOption.value === "R") ||
                                        (data?.APPLN_STATUS === "C" && selectedOption.value === "R")
                                    ) {
                                        toast.warning("Cannot change status from P/C");
                                        return false;
                                    } else if (
                                        selectedOption.value == "R" &&
                                        studentDetail?.studStatus !== "E"
                                    ) {
                                        toast.warning("Student status is not ENROLLED");
                                    } else {
                                        setSelectedApplicationStatus({
                                            label: selectedOption.label,
                                            value: selectedOption.value,
                                        });
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>First Verified By / Date</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.VERIFY_STATUS_BY}
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label></Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.VERIFY_STATUS_DATE}
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>Latest File Upload Date / Time</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={"-"}
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={8} className="">
                        <Form.Group>
                            <Form.Label>Reason for changes</Form.Label>
                            <Select
                                options={reasonOptions}
                                isDisabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                value={selectedReasonForChanges}
                                onChange={(selectedOption) => {
                                    setSelectedReasonForChanges({
                                        label: `${selectedOption.value} (${selectedOption.label})`,
                                        value: selectedOption.value,
                                    });
                                }}
                            />
                            {/*<Form.Control*/}
                            {/*    type={`text`}*/}
                            {/*    disabled={roleList.includes("FAAS_ADM") ? isDisabled : true}*/}
                            {/*    value={textReasonForChanges}*/}
                            {/*    onChange={(e) => {*/}
                            {/*        setTextReasonForChanges(e.target.value);*/}
                            {/*    }}*/}
                            {/*></Form.Control>*/}
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>Data Flag</Form.Label>
                            <Select
                                options={DataFlagStatus()}
                                isDisabled={
                                    roleList.includes("FAAS_ADM")
                                        ? isDisabled ||
                                        (data?.APPLN_STATUS !== "P" && data?.APPLN_STATUS !== "C")
                                        : true
                                }
                                value={selectedDataFlag}
                                onChange={(selectedOption) => {
                                    setSelectedDataFlag({
                                        label: selectedOption.label,
                                        value: selectedOption.value,
                                    });
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Approval Status Remarks (PA)</Form.Label>
                            <Select
                                options={PAOptions}
                                value={selectedPAOption}
                                onChange={(option) => {
                                    setSelectedPAOption(option);
                                    setPaRemark(option.value);
                                }}
                                isDisabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group className={`d-flex flex-column`}>
                            <Form.Label>Approval Status Remarks (NYP)</Form.Label>
                            <Select
                                options={NYPOptions}
                                value={selectedNYPOption}
                                onChange={(option) => {
                                    setSelectedNYPOption(option);
                                    setNypRemark(option.value);
                                }}
                                isDisabled={
                                    roleList.includes("FAAS_ADM") ||
                                    roleList.includes("FAAS_TEMPA")
                                        ? isDisabled
                                        : true
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Application Remarks</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={isDisabled}
                                value={data?.REMARKS1}
                                onChange={(e) => {
                                    handleApplicationChange('REMARKS1', e.target.value)
                                    setTextApprovalRemarks(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Approval Status Special Remarks</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={isDisabled}
                                value={textApprovalStatusSpecialRemarks}
                                onChange={(e) => {
                                    setTextApprovalStatusSpecialRemarks(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Special Detail</Form.Label>
                            <Form.Control
                                as="textarea"
                                className="mb-1"
                                type={`text`}
                                disabled={true}
                                value={textDSpecialDetailRemarks}
                            ></Form.Control>

                            <Form.Control
                                type={`text`}
                                disabled={isDisabled}
                                value={textSpecialDetailRemarks}
                                onChange={(e) => {
                                    setTextSpecialDetailRemarks(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>
                                Recommend Award Type{" "}
                                {data?.APPLN_STATUS === "R" || data?.APPLN_STATUS === "A" ? (
                                    <span className={`text-danger`}> *</span>
                                ) : (
                                    ""
                                )}
                            </Form.Label>
                            <Select
                                required={
                                    data?.APPLN_STATUS === "R" || data?.APPLN_STATUS === "A"
                                }
                                options={awardTypeList}
                                isDisabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                value={selectedAwardType}
                                onChange={(selectedOption) => {
                                    console.log(selectedOption);
                                    setSelectedAwardType({
                                        label: selectedOption.label,
                                        value: selectedOption.value,
                                        code: selectedOption.code,
                                    });
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>
                                Recommend Award <span className={`text-danger`}> *</span>
                            </Form.Label>
                            <Select
                                required={
                                    data?.APPLN_STATUS === "R" || data?.APPLN_STATUS == "A"
                                }
                                options={awardCodeList}
                                isDisabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                value={selectedAwardCode}
                                onChange={(selectedOption) => {
                                    setSelectedAwardCode({
                                        label: selectedOption.label,
                                        value: selectedOption.value,
                                    });
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default ApplicationDetail;
