import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {Button, Col, Form, Row, Tab, Table, Tabs,} from "@govtechsg/sgds-react";
import Select from "react-select";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {Accordion} from "@govtechsg/sgds-react/Accordion";
import FormulaModal from "@/components/FormulaModal";
import CustomModal from "@/components/CustomModal";
import {toast} from "react-toastify";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import * as XLSX from "xlsx";
import {getOccupationList} from "@/apis/preparation/setup-occupation/api";
import moment from "moment";

import {
  deleteScholarship,
  editApplicationDetail,
  getApplicationDetail,
  getApplicationList,
  getApplicationParentLog,
  getApplicationSiblingLog,
  getDetailApplicationLog,
  getScholarship,
  transferApplicationExercise,
  upsertScholarship,
} from "@/apis/app-records/maintenance/api";
import {getListAcadYears, getListExercise,} from "@/apis/preparation/app-timeline/api";

import {getStudentDetail} from "@/apis/sims/api";

import {getParents, updateParents} from "@/apis/app-records/parents/api";
import {getSiblings, updateSiblings} from "@/apis/app-records/siblings/api";

import PersonalData from "@/components/AppRecords/Maintenance/PersonalParticulars/PersonalData";
import ApplicationDetail from "@/components/AppRecords/Maintenance/PersonalParticulars/ApplicationDetail";
import Awarded from "@/components/AppRecords/Maintenance/PersonalParticulars/Awarded";

import Father from "@/components/AppRecords/Maintenance/FamilyDetails/Father";
import Mother from "@/components/AppRecords/Maintenance/FamilyDetails/Mother";
import Sibling from "@/components/AppRecords/Maintenance/FamilyDetails/Sibling";
import Applicant from "@/components/AppRecords/Maintenance/FamilyDetails/Applicant";
import OtherScholarshipBeneficiary from "@/components/AppRecords/Maintenance/FamilyDetails/OtherScholarshipBeneficiary";

import ResultDetails from "@/components/AppRecords/Maintenance/ResultDetails/ResultDetails";
import OtherDetails from "@/components/AppRecords/Maintenance/OtherDetails/OtherDetails";
import OriginalDetails from "@/components/AppRecords/Maintenance/OriginalDetails/OriginalDetails";
import {getRemarkNYP, getRemarkPA,} from "@/apis/preparation/setup-remarks/api";

const options = [
    {value: "chocolate", label: "Chocolate"},
    {value: "strawberry", label: "Strawberry"},
    {value: "vanilla", label: "Vanilla"},
];

function AppEnquiry() {
    const TEMP_EXERCISE = 7;

    const [isOpen, setIsOpen] = useState(true);
    const contentRef = useRef(null);

    // variable selected
    const [academicYear, setAcademicYear] = useState();
    const [selectedListExercise, setSelectedListExercise] = useState(null);
    const [selectedListAdmNo, setSelectedListAdmNo] = useState(null);

    // variable list
    const [listAcadYears, setListAcadYears] = useState([]);
    const [listExercise, setListExercise] = useState([]);
    const [listAdmNo, setListAdmNo] = useState([]);
    const [listParent, setListParent] = useState([]);
    const [listSibling, setListSibling] = useState([]);
    const [listScholarship, setListScholarship] = useState([]);

    const [isDisabled, setIsDisabled] = useState(true);
    const [showFormulaModal, setShowFormulaModal] = useState(false);
    const [showCCAModal, setShowCCAModal] = useState(false);

    const [applicationDetail, setApplicationDetail] = useState({});
    const [studentDetail, setStudentDetail] = useState({});
    const [
        showTransferToAnotherExerciseModal,
        setShowTransferToAnotherExerciseModal,
    ] = useState(false);

    const [originalDetail, setOriginalDetail] = useState({});
    const [parentLog, setParentLog] = useState([]);
    const [siblingLog, setSiblingLog] = useState([]);

    const [PARemarks, setPARemarks] = useState([]);
    const [NYPRemarks, setNYPRemarks] = useState([]);

    // variable to submit
    // personal particulars
    const [maritalStatus, setMaritalStatus] = useState({label: "", value: ""});
    const [dialectGroup, setDialectGroup] = useState({label: "", value: ""});
    const [housingType, setHousingType] = useState({label: "", value: ""});
    const [viewCCAList, setViewCCAList] = useState([]);
    const [transferToAnotherExerciseValue, setTransferToAnotherExerciseValue] =
        useState(0);

    // application details
    const [selectedVerificationStatus, setSelectedVerificationStatus] = useState({
        label: "",
        value: "",
    });
    const [selectedApplicationStatus, setSelectedApplicationStatus] = useState({
        label: "",
        value: "",
    });
    const [selectedDataFlag, setSelectedDataFlag] = useState({
        label: "",
        value: "",
    });

    const [selectedAwardType, setSelectedAwardType] = useState({
        label: "",
        value: "",
        code: "",
    });
    const [selectedAwardCode, setSelectedAwardCode] = useState({
        label: "",
        value: "",
    });

    const [textReasonForChanges, setTextReasonForChanges] = useState("");
    const [textRemarkS1, setTextRemarkS1] = useState("");
    const [textApprovalRemarks, setTextApprovalRemarks] = useState("");
    const [
        textApprovalStatusSpecialRemarks,
        setTextApprovalStatusSpecialRemarks,
    ] = useState("");
    const [textSpecialDetailRemarks, setTextSpecialDetailRemarks] = useState("");

    const [tobeDeletedScholarship, setTobeDeletedScholarship] = useState([]);

    const [statusOccupationList, setStatusOccupationList] = useState([]);

    const [paRemark, setPaRemark] = useState("");
    const [nypRemark, setNypRemark] = useState("");

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const handleClickEdit = () => {
        setIsDisabled(!isDisabled);
    };

    const getDetailofApplicationMaintenance = () => {
        getApplicationDetail(
            academicYear,
            selectedListExercise,
            // TEMP_EXERCISE,
            selectedListAdmNo
            // "240657P"
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setApplicationDetail(resp?.data?.data);
                    setViewCCAList(resp?.data?.data?.CCAList);
                    setTextReasonForChanges(resp?.data?.data?.REASON_FOR_CHANGES);
                }
            }

            // setApplicationDetail(content);
        });
    };

    const getListOfAcadYears = () => {
        getListAcadYears().then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    let listYears = [];
                    resp?.data?.data?.map((item) => {
                        listYears.push({value: item?.ACAD_YR, label: item?.ACAD_YR});
                    });
                    setListAcadYears(listYears);
                }
            }
        });
    };

    const getListOfExercise = (year) => {
        getListExercise(year).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    let listContent = [];
                    resp?.data?.data?.map((item) => {
                        listContent.push({value: item?.EXERCISE, label: item?.EXERCISE});
                    });
                    setListExercise(listContent);
                }
            }
        });
    };

    const getListOfApplicationList = (params) => {
        getApplicationList({ACAD_YR: academicYear, EXERCISE: params}).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    let listContent = [];
                    resp?.data?.data?.map((item) => {
                        listContent.push({value: item?.ADM_NO, label: item?.ADM_NO});
                    });
                    setListAdmNo(listContent);
                }
            }
        });
    };

    const getListOfParents = () => {
        getParents(
            academicYear,
            // TEMP_EXERCISE,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data?.length > 0) {
                        setListParent(resp?.data?.data);
                    } else {
                        toast.warning("Parent Data is empty");
                        setListParent([
                            {
                                RELATIONSHIP: "F",
                                ID: "",
                                NAME: "",
                                STATUS: "",
                                ID_NO: "",
                                MTHLY_INCOME_CONTRIB: "",
                                REMARK: "",
                                CHILD_SUPPORT: "",
                                BIRTHDATE: "",
                                OTHER_INCOME: null,
                                FAAS_OCCUPATIONS: "",
                            },
                            {
                                RELATIONSHIP: "M",
                                ID: "",
                                NAME: "",
                                STATUS: "",
                                ID_NO: "",
                                MTHLY_INCOME_CONTRIB: "",
                                REMARK: "",
                                CHILD_SUPPORT: "",
                                BIRTHDATE: "",
                                OTHER_INCOME: null,
                                FAAS_OCCUPATIONS: "",
                            },
                        ]);
                    }
                }
            }
        });
    };

    const getListOfSiblings = () => {
        getSiblings(
            academicYear,
            //   TEMP_EXERCISE,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    let contentSibling = resp?.data?.data;

                    if (contentSibling?.length > 0) {
                        contentSibling?.map((item, index) => {
                            statusOccupationList.map((content) => {
                                if (
                                    contentSibling[index]?.FAAS_OCCUPATIONS?.hasOwnProperty("ID")
                                ) {
                                    if (
                                        contentSibling[index]?.FAAS_OCCUPATIONS?.ID ===
                                        content?.value
                                    ) {
                                        contentSibling[index].OCCUPATIONS = {
                                            label: content.label,
                                            value: content.value,
                                            isIncomeContribute: content.is_income_contribute,
                                            isRemarkRequired: content.is_remark_required,
                                        };
                                    }
                                } else {
                                    if (
                                        contentSibling[index]?.FAAS_OCCUPATIONS == content?.value
                                    ) {
                                        contentSibling[index].OCCUPATIONS = {
                                            label: content.label,
                                            value: content.value,
                                            isIncomeContribute: content.is_income_contribute,
                                            isRemarkRequired: content.is_remark_required,
                                        };
                                    }
                                }
                            });
                        });
                    }
                    setListSibling(contentSibling);
                }
            }
        });
    };

    const handleAcademicYearChange = (e) => {
        const newValue = e.value;
        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
            setAcademicYear(newValue);
            getListOfExercise(newValue);
        }
    };

    const handleListExerciseChange = (e) => {
        const newValue = e.value;
        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
            setSelectedListExercise(newValue);
        }
        getListOfApplicationList(newValue)
    };

    const handleListAdmNoChange = (e) => {
        const newValue = e.value;
        setSelectedListAdmNo(newValue);
    };

    const getDetailofStudentInformation = () => {
        getStudentDetail(selectedListAdmNo, academicYear).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setStudentDetail(resp?.data?.data[0]);
                    }
                    else{
                      toast.warning("No Record Found")
                    }
                }
            }
        });
    };

    const getLogs = () => {
        getDetailApplicationLog(
            academicYear,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    console.log(resp?.data?.data);
                    setOriginalDetail(resp?.data?.data);
                }
            }
        });

        getApplicationParentLog(
            academicYear,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setParentLog(resp?.data?.data);
                }
            }
        });

        getApplicationSiblingLog(
            academicYear,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setSiblingLog(resp?.data?.data);
                }
            }
        });
    };

    const getScholarships = () => {
        getScholarship(academicYear, selectedListExercise, selectedListAdmNo).then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        setListScholarship(resp?.data?.data);
                    }
                }
            }
        );
    };

    const handleSubmit = () => {
        getDetailofApplicationMaintenance();
        getDetailofStudentInformation();
        getListOfParents();
        getListOfSiblings();
        getLogs();
        getScholarships();
    };

    const submitToApplicationDetail = () => {
        //If the application status is ‘R’, the Recommended Award Type & Award fields are mandatory.
        if (
            applicationDetail?.APPLN_STATUS === "R" &&
            !selectedAwardType.value &&
            !selectedAwardCode.value
        ) {
            toast.warning("Recommended Award Type and Award must be filled");
            return false;
        }

        //Approval Status Remark (PA)’ or ‘Approval Status Remark (NYP)’ field is mandatory if the application status is ‘N’.
        if (
            applicationDetail?.APPLN_STATUS === "N" &&
            !textApprovalStatusSpecialRemarks &&
            nypRemark
        ) {
            toast.warning(
                "Approval Status Remark NYP and Approval Status Remark PA must be filled"
            );
            return false;
        }

        let variableToBeSubmitted = {
            DATA: [
                {
                    ID: applicationDetail?.ID,
                    ACAD_YR: academicYear,
                    EXERCISE: selectedListExercise,
                    //   EXERCISE: TEMP_EXERCISE,
                    ADM_NO: selectedListAdmNo,
                    // DEPENDENT: "1",
                    INDEPEND_MTHLY_CONTRIB: applicationDetail?.INDEPEND_MTHLY_CONTRIB,
                    // CCC_INCOME: "100.00",
                    GROSS_INCOME: applicationDetail?.GROSS_INCOME,
                    // FPCI: "300.00",
                    // PHYSICAL_STATUS: null,
                    // CHARGE_IN_COURT: "Y",
                    // PS_CIC_DETAILS: "Y",
                    VERIFY_STATUS: selectedVerificationStatus.value,
                    APPLN_STATUS: selectedApplicationStatus.value,
                    DATA_FLAG: selectedDataFlag.value,

                    REASON_FOR_CHANGES: textReasonForChanges,
                    // Approval status remarks (NYP)
                    // REMARK_DESC: nypRemark,
                    // Approval status remarks (PA)
                    APPROVAL_STATUS_REASON: paRemark,
                    // Special details == Special Details Remark
                    SPECIAL_DETAILS: textSpecialDetailRemarks,
                    // Special Note == Approval Status Special Remarks
                    SPECIAL_NOTE: textApprovalStatusSpecialRemarks,
                    // Application REMARK
                    REMARKS1: textApprovalRemarks,

                    // REMARK_DESC: nypRemark,
                    // Application Detail Remark
                    REMARK: applicationDetail?.REMARK,

                    REC_AWARD_TYPE: selectedAwardType.value,
                    REC_AWARD_CODE: selectedAwardCode.value,

                    COMP_SPONSOR_TAG: applicationDetail?.COMP_SPONSOR_TAG,
                    CHARGE_IN_COURT: applicationDetail?.CHARGE_IN_COURT,
                    MOE_SUBSIDISED: applicationDetail?.MOE_SUBSIDISED,
                    INTACT_FAMILY_TAG: applicationDetail?.INTACT_FAMILY_TAG,
                    CPF_APPROVED_TAG: applicationDetail?.CPF_APPROVED_TAG,

                    // PRINT_APPLN_FORM: null,
                    // DOWNLOAD_DATE: null,
                    LAST_UPD_ID: sessionStorage.getItem("staffId")
                        ? sessionStorage.getItem("staffId")
                        : process.env.REACT_APP_LAST_UPD_ID,
                    // MEDICAL_HISTORY: null,
                    // INTACT_FAMILY_TAG: null,
                    // CPF_APPROVED_TAG: null,
                    // RECOMMENDED_DATE: null,
                    // FORM_PRINTED_DATE: null,
                    // MPCI: "250.00",
                    // FAMILY_MEMBERS: "4",
                    // SCHOLAR_SPONSOR_TAG: "Y",
                    // MTHLY_INCOME: "150.00",
                    MARITAL_STATUS: maritalStatus.value,
                    // REMARKS1: textRemarkS1,
                    DIALECT_GRP: dialectGroup.value,
                    HOMEHOUSETYPE: housingType.value,
                    // COMP_SPONSOR_TAG: "Y",
                    // MOE_SUBSIDISED: "Y",
                    OTHER_INCOME: applicationDetail?.OTHER_INCOME,
                    // SPECIAL_CIRCUMSTANCES: null,
                    VERIFY_STATUS_BY: sessionStorage.getItem("username"),
                    VERIFY_STATUS_DATE: moment(new Date()).format("yyyy-MM-DD"),
                    // COMCARE: null,
                    // MTC_APPEAL_REMARK: null,
                    // SPECIAL_CASE: null,
                    // GOVBUR_TAG: null,
                },
            ],
        };

        editApplicationDetail(variableToBeSubmitted).then((resp) => {
            handleClickEdit();
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success("Application has been saved");
                }
            }
        });
    };

    const submitToParentList = () => {
        listParent.forEach(function (v) {
            delete v.OCCUPATION;
        });

        updateParents({DATA: listParent}).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success("Parent data has been saved");
                }
            }
        });
    };

    const submitToSiblingList = () => {
        listSibling.forEach(function (v) {
            delete v.OCCUPATIONS;
            delete v.OCCUPATION;
        });

        updateSiblings({DATA: listSibling}).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success("Sibling data has been saved");
                }
            }
        });
    };

    const submitToScholarship = () => {
        let otherParams = {
            ACAD_YR: academicYear,
            ADM_NO: selectedListAdmNo,
            EXERCISE: selectedListExercise,
        };

        upsertScholarship({
            DATA: listScholarship.map((scholarship) => ({
                ...scholarship,
                ...otherParams,
            })),
        }).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success("Scholarship data has been saved");
                }
            }
        });

        if (tobeDeletedScholarship.length > 0) {
            tobeDeletedScholarship.forEach((id) => {
                deleteScholarship(id).then(() => {
                    console.log("Deleted");
                });
            });
        }
    };

    const handleSubmitApplicationForm = () => {
        submitToApplicationDetail();
        submitToParentList();
        submitToSiblingList();
        submitToScholarship();
    };

    const submitTransferToAnotherExercise = () => {
        transferApplicationExercise({
            ACAD_YR: academicYear,
            ADM_NO: selectedListAdmNo,
            EXERCISE: selectedListExercise,
            NEW_EXERCISE: transferToAnotherExerciseValue,
            STAFF_ID: sessionStorage.getItem("role")
                ? sessionStorage.getItem("role")
                : process.env.REACT_APP_LAST_UPD_ID,
        }).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success("Success transfer to another exercise");
                }
            }
        });
    };

    const handleParentChange = (field, value, index) => {
        setListParent((prevState) => {
            return prevState.map((item, i) => {
                return i === index ? {...item, [field]: value} : item;
            });
        });
    };
    const handleSiblingChange = (field, value, index) => {
        setListSibling((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[index] = {...updatedItems[index], [field]: value};
            return updatedItems;
        });
    };

    const handleApplicationChange = (field, value) => {
        setApplicationDetail((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleChangeListScholarship = (field, value, index) => {
        setListScholarship((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[index] = {...updatedItems[index], [field]: value};
            return updatedItems;
        });
    };

    const handleDataChange = (updatedData) => {
        setApplicationDetail(updatedData);
    };

    const handleExportCCA = () => {
        const wsData = [
            [
                "S/N",
                "CCA Point",
                "Course Code",
                "Classification",
                "CCA Activity",
                "Involve Date",
                "Endorse Status",
            ],
            ...viewCCAList.map((data, index) => [
                index + 1,
                data.ccaPoint,
                data.courseCode,
                data.classification,
                data.ccaActivity,
                data.involveDate,
                data.endorseStatus,
            ]),
        ];

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "CCA Data");

        XLSX.writeFile(wb, "cca_data.xlsx");
    };

    const getListOfRemarks = () => {
        getRemarkNYP().then((response) => {
            setNYPRemarks(response?.data?.data);
        });

        getRemarkPA().then((response) => {
            setPARemarks(response?.data?.data);
        });
    };

    const getTotalSumIncome = () => {
        const sumIncomeSibling = listSibling?.reduce(
            (accumulator, item) => accumulator + Number(item?.GROSS_MTHLY_INCOME),
            0
        );
        const sumIncomeParent = listParent?.reduce(
            (accumulator, item) => accumulator + Number(item?.MTHLY_INCOME_CONTRIB),
            0
        );

        handleApplicationChange("MTHLY_INCOME", sumIncomeSibling + sumIncomeParent);
    };

    useEffect(() => {
        // getDetailofApplicationMaintenance();
        // getListOfExercise();
        // getListOfParents();
        getListOfAcadYears();
        // getListOfApplicationList();
        getListOfRemarks();
    }, []);

    useEffect(() => {
        getOccupationList().then((resp) => {
            if (resp?.status == 200) {
                let contentArray = [];
                resp?.data?.data?.map((content) => {
                    if (content?.OBSOLETE_FLAG != "Y") {
                        contentArray.push({
                            label: content.OCCUPATION,
                            value: content.ID,
                            is_income_contribute: content.INCOME_CONTRIBUTION,
                            is_remark_required: content.REMARK,
                        });
                    }
                });
                setStatusOccupationList(contentArray);
            }
        });
    }, []);

    return (
        <Layout currentNav={"app-records"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Administer Application Records</Breadcrumb.Item>
                <Breadcrumb.Item active>Application Enquiry</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Application Enquiry</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <div className="row">
                        <div className="col">
                            <h3>Data Retrieval</h3>
                        </div>
                    </div>

                    <Form
                        ref={contentRef}
                        className={` d-flex flex-column gap-3 collapse-animation`}
                        style={{
                            overflow: isOpen ? "unset" : "hidden",
                            maxHeight: isOpen
                                ? `${
                                    contentRef?.current?.scrollHeight == null
                                        ? "100%"
                                        : contentRef?.current?.scrollHeight
                                }px`
                                : "0px",
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        <Row>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Academic Year</Form.Label>
                                    <Select
                                        value={{label: academicYear, value: academicYear}}
                                        options={listAcadYears}
                                        onChange={(e) => {
                                            handleAcademicYearChange(e);
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Exercise</Form.Label>
                                    <Select
                                        options={listExercise}
                                        onChange={(e) => handleListExerciseChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Adm. No</Form.Label>
                                    <Select
                                        options={listAdmNo}
                                        onChange={(e) => handleListAdmNoChange(e)}
                                    />
                                </Form.Group>
                            </Col>

                            {/**
                             *
                             <Col xs={3}>
                             <Form.Group>
                             <Form.Label>ID No.</Form.Label>
                             <Select options={options} />
                             </Form.Group>
                             </Col>
                             <Col xs={12} className="mt-3">
                             <Form.Group>
                             <Form.Label>Name</Form.Label>
                             <Form.Control type={`text`} disabled={false}></Form.Control>
                             </Form.Group>
                             </Col>
                             *
                             *
                             */}
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <Button type="submit" className="btn btn-primary">
                                    Retrieve
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    <button
                        onClick={toggleCollapse}
                        className="btn-clear text-blue ps-0 mt-3"
                    >
                        {isOpen ? "Hide " : "Expand "}{" "}
                        {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                    </button>
                </div>
                <div>
                    <h3>{studentDetail?.name}</h3>
                </div>
                <div>
                    <Tabs
                        defaultActiveKey="personal_particulars"
                        id="uncontrolled-tab-example2"
                        variant="tabs-basic-toggle"
                        className="nav-fill"
                    >
                        <Tab eventKey="personal_particulars" title="Personal Particulars">
                            <div className="mt-4">
                                <Accordion defaultActiveKey="1" className="">
                                    <Accordion.Item eventKey="0" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Personal Data
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <PersonalData
                                                data={applicationDetail}
                                                studentDetail={studentDetail}
                                                setShowCCAModal={setShowCCAModal}
                                                isDisabled={isDisabled}
                                                maritalStatus={maritalStatus}
                                                setMaritalStatus={setMaritalStatus}
                                                dialectGroup={dialectGroup}
                                                setDialectGroup={setDialectGroup}
                                                housingType={housingType}
                                                setHousingType={setHousingType}
                                                setShowTransferToAnotherExerciseModal={
                                                    setShowTransferToAnotherExerciseModal
                                                }
                                                isReadOnly={true}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="0" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Application Details
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ApplicationDetail
                                                data={applicationDetail}
                                                studentDetail={studentDetail}
                                                isDisabled={isDisabled}
                                                selectedVerificationStatus={selectedVerificationStatus}
                                                setSelectedVerificationStatus={
                                                    setSelectedVerificationStatus
                                                }
                                                selectedApplicationStatus={selectedApplicationStatus}
                                                setSelectedApplicationStatus={
                                                    setSelectedApplicationStatus
                                                }
                                                selectedDataFlag={selectedDataFlag}
                                                setSelectedDataFlag={setSelectedDataFlag}
                                                selectedAwardType={selectedAwardType}
                                                setSelectedAwardType={setSelectedAwardType}
                                                selectedAwardCode={selectedAwardCode}
                                                setSelectedAwardCode={setSelectedAwardCode}
                                                textReasonForChanges={textReasonForChanges}
                                                setTextReasonForChanges={setTextReasonForChanges}
                                                textRemarkS1={textRemarkS1}
                                                setTextRemarkS1={setTextRemarkS1}
                                                textApprovalRemarks={textApprovalRemarks}
                                                setTextApprovalRemarks={setTextApprovalRemarks}
                                                textApprovalStatusSpecialRemarks={
                                                    textApprovalStatusSpecialRemarks
                                                }
                                                setTextApprovalStatusSpecialRemarks={
                                                    setTextApprovalStatusSpecialRemarks
                                                }
                                                textSpecialDetailRemarks={textSpecialDetailRemarks}
                                                setTextSpecialDetailRemarks={
                                                    setTextSpecialDetailRemarks
                                                }
                                                isReadOnly={true}
                                                PARemarks={PARemarks}
                                                NYPRemarks={NYPRemarks}
                                                setPaRemark={setPaRemark}
                                                setNypRemark={setNypRemark}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="0" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Awarded
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Awarded
                                                data={applicationDetail}
                                                studentDetail={studentDetail}
                                                isDisabled={isDisabled}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Tab>
                        <Tab eventKey="family_details" title="Family Details">
                            <div className="mt-4">
                                <Accordion defaultActiveKey="1" className="">
                                    <Accordion.Item eventKey="0" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Father
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Father
                                                handleParentChange={handleParentChange}
                                                listParent={listParent}
                                                setListParent={setListParent}
                                                data={applicationDetail}
                                                studentDetail={setShowFormulaModal}
                                                setShowFormulaModal={setShowFormulaModal}
                                                isDisabled={isDisabled}
                                                handleApplicationChange={handleApplicationChange}
                                                getTotalSumIncome={getTotalSumIncome}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="0" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Mother
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Mother
                                                handleParentChange={handleParentChange}
                                                listParent={listParent}
                                                setListParent={setListParent}
                                                data={applicationDetail}
                                                studentDetail={setShowFormulaModal}
                                                setShowFormulaModal={setShowFormulaModal}
                                                isDisabled={isDisabled}
                                                getTotalSumIncome={getTotalSumIncome}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="0" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Siblings / Relatives
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Sibling
                                                handleSiblingChange={handleSiblingChange}
                                                listSibling={listSibling}
                                                setListSibling={setListSibling}
                                                getTotalSumIncome={getTotalSumIncome}
                                                data={applicationDetail}
                                                studentDetail={setShowFormulaModal}
                                                setShowFormulaModal={setShowFormulaModal}
                                                isDisabled={isDisabled}
                                                statusOccupationList={statusOccupationList}
                                                isReadOnly={true}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="0" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Applicant
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Applicant
                                                data={applicationDetail}
                                                studentDetail={setShowFormulaModal}
                                                setShowFormulaModal={setShowFormulaModal}
                                                isDisabled={isDisabled}
                                                handleApplicationChange={handleApplicationChange}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Tab>
                        <Tab eventKey="result_details" title="Result Details">
                            <ResultDetails
                                data={applicationDetail}
                                studentDetail={studentDetail}
                                isDisabled={isDisabled}
                            />
                        </Tab>
                        <Tab eventKey="other_details" title="Other Details">

                            <OtherDetails
                                isDisabled={isDisabled}
                                details={applicationDetail}
                                studentDetail={studentDetail}
                                onDataChange={handleDataChange}
                                handleApplicationChange={handleApplicationChange}
                            />

                            <div className="mt-4">
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="0" className="shadow border-0">
                                        <Accordion.Header className="header-text text-blue">
                                            Scholarship / Sponsorship
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <OtherScholarshipBeneficiary
                                                scholarshipDetail={listScholarship}
                                                setListScholarship={setListScholarship}
                                                handleChangeListScholarship={
                                                    handleChangeListScholarship
                                                }
                                                tobeDeletedScholar={tobeDeletedScholarship}
                                                handleChangeTobeDeleted={(ids) =>
                                                    setTobeDeletedScholarship(ids)
                                                }
                                                data={applicationDetail}
                                                studentDetail={setShowFormulaModal}
                                                setShowFormulaModal={setShowFormulaModal}
                                                isDisabled={isDisabled}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Tab>
                        <Tab eventKey="original_details" title="Original Details">
                            <OriginalDetails
                                isDisabled={isDisabled}
                                details={originalDetail}
                                parentLog={parentLog}
                                siblingLog={siblingLog}
                                studentDetail={studentDetail}
                                handleShowFormulaModal={() => setShowFormulaModal(true)}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </sgds-content-body>
            <FormulaModal
                show={showFormulaModal}
                handleClose={() => setShowFormulaModal(!showFormulaModal)}
            />
            <CustomModal
                show={showCCAModal}
                handleClose={() => setShowCCAModal(!showCCAModal)}
                title="View CCA"
                description={
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>S/N</Table.HeaderCell>
                                <Table.HeaderCell>CCA Point</Table.HeaderCell>
                                <Table.HeaderCell>Course Code</Table.HeaderCell>
                                <Table.HeaderCell>Classification</Table.HeaderCell>
                                <Table.HeaderCell>CCA Activity</Table.HeaderCell>
                                <Table.HeaderCell>Involve Date</Table.HeaderCell>
                                <Table.HeaderCell>Endorse Status</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {viewCCAList?.map((data, index) => {
                                return (
                                    <Table.Row>
                                        <Table.DataCell>{index + 1}</Table.DataCell>
                                        <Table.DataCell>{data.ccaPoint}</Table.DataCell>
                                        <Table.DataCell>{data.courseCode}</Table.DataCell>
                                        <Table.DataCell>{data.classification}</Table.DataCell>
                                        <Table.DataCell>{data.ccaActivity}</Table.DataCell>
                                        <Table.DataCell>{data.involveDate}</Table.DataCell>
                                        <Table.DataCell>{data.endorseStatus}</Table.DataCell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                }
                action={handleExportCCA}
                submitButtonText={"Export to Excel"}
                // hideSubmitButton={true}
            />
            <CustomModal
                show={showTransferToAnotherExerciseModal}
                handleClose={() =>
                    setShowTransferToAnotherExerciseModal(
                        !showTransferToAnotherExerciseModal
                    )
                }
                title="Transfer To Another Exercise"
                description={
                    <>
                        <div>
                            <p>Current Info:</p>
                            <div>
                                Academy Year: {academicYear}
                                <br/>
                                Exercise: {selectedListExercise}
                                <br/>
                                Adm. No.: {selectedListAdmNo}
                            </div>
                        </div>
                        <Form.Group>
                            <Form.Label>Transfer to Exercise</Form.Label>
                            <Form.Control
                                className=""
                                type={`text`}
                                // disabled={true}
                                value={transferToAnotherExerciseValue}
                                onChange={(e) =>
                                    setTransferToAnotherExerciseValue(e.target.value)
                                }
                            ></Form.Control>
                        </Form.Group>
                    </>
                }
                action={() => submitTransferToAnotherExercise()}
                submitButtonText={"Submit"}
            />
        </Layout>
    );
}

export default AppEnquiry;
