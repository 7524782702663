import {Button, Col, Row, Table} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import Layout from "@/components/Layout";
import {getCodeTypeSasList, saveAwards} from "@/apis/sims/api";
import Select from "react-select";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {toast} from "react-toastify";

export default function Add() {
    const [awardList, setAwardList] = useState([]);
    const [selectedAwardType, setSelectedAwardType] = useState(null);
    const [awardTypeList, setAwardTypeList] = useState([]);

    const handleChange = (id, field, value) => {
        setAwardList((prev) =>
            prev.map((item, index) => {
                if (index === id) {
                    return {...item, [field]: value};
                }
                return item;
            })
        );
    };

    const handleActionAwards = (item, action) => {
        saveAwards([
            {
                award_type: item.codetype,
                award: item.code,
                award_desc: item.description,
                last_upd_id: sessionStorage.getItem('staffId') ? sessionStorage.getItem('staffId') : process.env.REACT_APP_LAST_UPD_ID,
                action,
            },
        ]).then((res) => {
            if (res?.response?.data?.message) {
                if (
                    res?.response?.data?.message?.length > 0 &&
                    Array.isArray(res?.response?.data?.message)
                ) {
                    res?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(res?.response?.data?.message);
                }
            } else if (res?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                if (res?.data?.status == true) {
                    toast.success("Record updated successfully")
                    handleGetListAward(selectedAwardType.value);

                } else {
                    toast.error("Failed to save award")

                }
            }
        });
    };

    const handleSave = (item) => {
        handleActionAwards(item, item?.is_new ? "I" : "U");
    };

    const handleDelete = (item) => {
        if (confirm(`Are you sure want to delete ${item.code}!`) === true) {
            handleActionAwards(item, "D");
        }
    };

    const handleEdit = (id) => {
        setAwardList((prev) =>
            prev.map((item, index) => {
                if (index === id) {
                    return {...item, editable: !item.editable};
                }
                return item;
            })
        );
    };

    const handleAddRow = () => {
        setAwardList((prevArr) => {
            const newArr = [...prevArr];

            const newObj = {
                codetype: selectedAwardType.value,
                code: "",
                description: "",
                editable: true,
                is_new: true,
            };

            newArr.unshift(newObj);

            return newArr;
        });
    };

    const handleGetListAwardType = () => {
        getCodeTypeSasList("AWARDTYPECODE").then((res) => {
            if (res?.response?.data?.message) {
                if (
                    res?.response?.data?.message?.length > 0 &&
                    Array.isArray(res?.response?.data?.message)
                ) {
                    res?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(res?.response?.data?.message);
                }
            } else if (res?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                let data = res?.data?.data || [];
                setAwardTypeList(
                    data.map((item) => {
                        return {label: item.description, value: item.code};
                    })
                );
            }
        });
    };

    const handleGetListAward = (code, index) => {
        getCodeTypeSasList(code).then((res) => {
            if (res?.response?.data?.message) {
                if (
                    res?.response?.data?.message?.length > 0 &&
                    Array.isArray(res?.response?.data?.message)
                ) {
                    res?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(res?.response?.data?.message);
                }
            } else if (res?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                let data = res?.data?.data || [];
                setAwardList(
                    data.map((item) => {
                        return {...item, editable: false};
                    })
                );
            }
        });
    };

    useEffect(function () {
        handleGetListAwardType();
    }, []);

    return (
        <Layout currentNav={"utilities"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Utilities</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Update Student Scholarship Detail
                </Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Update Student Scholarship Detail</h2>
            </sgds-content-header-top>

            <Row>
                <Col>
                    <Select
                        placeholder="Select Award Type"
                        options={awardTypeList}
                        value={selectedAwardType}
                        onChange={(e) => {
                            setSelectedAwardType(e);
                            setAwardList([]);
                        }}
                    />
                </Col>

                <Col xs={1}>
                    <Button
                        type={"button"}
                        variant={"primary"}
                        disabled={selectedAwardType == null ? true : false}
                        onClick={() => {
                            handleGetListAward(selectedAwardType.value);
                        }}
                    >
                        Retrieve
                    </Button>
                </Col>
            </Row>

            <sgds-content-body>
                <Row className={`justify-content-end`}>
                    {selectedAwardType !== null && (
                        <Col md={12}>
                            <Button
                                variant={`outline-dark`}
                                onClick={handleAddRow}
                                className={`d-flex gap-3`}
                            >
                                <i className="bi bi-plus-circle"></i>
                                Add 
                            </Button>
                        </Col>
                    )}

                    <Col xs={12}>
                        <Table responsive>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>No</Table.HeaderCell>
                                    <Table.HeaderCell>Award Code</Table.HeaderCell>
                                    <Table.HeaderCell>Award Decription</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {awardList.map((item, index) => (
                                    <Table.Row key={index}>
                                        <Table.DataCell>{index + 1}</Table.DataCell>
                                        <Table.DataCell>
                                            {item.editable ? (
                                                <input
                                                    type="text"
                                                    value={item.code}
                                                    onChange={(e) =>
                                                        handleChange(index, "code", e.target.value)
                                                    }
                                                />
                                            ) : (
                                                item.code
                                            )}
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            {item.editable ? (
                                                <input
                                                    type="text"
                                                    value={item.description}
                                                    onChange={(e) =>
                                                        handleChange(index, "description", e.target.value)
                                                    }
                                                />
                                            ) : (
                                                item.description
                                            )}
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            <div className={`d-flex gap-1`}>
                                                {item.editable ? (
                                                    <button
                                                        className={`btn-clear text-green-500`}
                                                        onClick={() => handleSave(item)}
                                                    >
                                                        <i className={`bi bi-check-lg`}></i> Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        className={`btn-clear`}
                                                        onClick={() => handleEdit(index)}
                                                    >
                                                        <i className={`bi bi-pencil`}></i>
                                                    </button>
                                                )}
                                                <button
                                                    className={`btn-clear`}
                                                    onClick={() => {
                                                        handleDelete(item);
                                                    }}
                                                >
                                                    <i className={`bi bi-trash`}></i>
                                                </button>
                                            </div>
                                        </Table.DataCell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Col>
                </Row>
            </sgds-content-body>
        </Layout>
    );
}
