import React, { useEffect, useState } from "react";

import { Col, Form, Row } from "@govtechsg/sgds-react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";

import { getOccupationList } from "@/apis/preparation/setup-occupation/api";

const statusOptions = [
  {
    label: "Living in the same household",
    value: "L",
  },
  {
    label: "Not living in the same household",
    value: "NL",
  },
  {
    label: "Deceased",
    value: "DE",
  },
  {
    label: "Divorced. Living with applicant",
    value: "DL",
  },
  {
    label: "Divorced. Not living with applicant",
    value: "DN",
  },
  {
    label: "In-Jail",
    value: "IJ",
  },
];

function Mother({
  data,
  listParent,
  setListParent,
  studentDetail,
  isDisabled,
  handleParentChange,
  getTotalSumIncome,
}) {
  const [statusOccupationList, setStatusOccupationList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: "",
  });
  const [selectedOccupation, setSelectedOccupation] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    if (listParent?.length > 0) {
      listParent?.map((item, index) => {
        if (item?.RELATIONSHIP == "M") {
          setCurrentIndex(index);
          statusOptions.map((content) => {
            if (item?.STATUS == content.value) {
              setSelectedStatus({
                label: content.label,
                value: content.value,
              });
            }
          });

          statusOccupationList.map((content) => {
            if (
              listParent[currentIndex]?.FAAS_OCCUPATIONS?.hasOwnProperty("ID")
            ) {
              if (
                listParent[currentIndex]?.FAAS_OCCUPATIONS?.ID ===
                content?.value
              ) {
                setSelectedOccupation({
                  label: content.label,
                  value: content.value,
                  isIncomeContribute: content.is_income_contribute,
                  isRemarkRequired: content.is_remark_required,
                });
              }
            } else {
              if (
                listParent[currentIndex]?.FAAS_OCCUPATIONS == content?.value
              ) {
                setSelectedOccupation({
                  label: content.label,
                  value: content.value,
                  isIncomeContribute: content.is_income_contribute,
                  isRemarkRequired: content.is_remark_required,
                });
              }
            }
          });
        }
      });
    }
  }, [listParent]);

  useEffect(() => {
    getOccupationList().then((resp) => {
      if (resp?.status == 200) {
        let contentArray = [];
        resp?.data?.data?.map((content) => {
          if (content.OBSOLETE_FLAG != "Y") {
            contentArray.push({
              label: content.OCCUPATION,
              value: content.ID,
              is_income_contribute: content.INCOME_CONTRIBUTION,
              is_remark_required: content?.REMARK,
            });
          }
        });
        setStatusOccupationList(contentArray);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col xs={12} className="">
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type={`text`}
                disabled={isDisabled || listParent?.length === 0}
                value={listParent[currentIndex]?.NAME}
                onChange={(e) => {
                  handleParentChange("NAME", e.target.value, currentIndex);
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6} className="">
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Select
                options={statusOptions}
                isDisabled={isDisabled || listParent?.length === 0}
                value={selectedStatus}
                onChange={(selectedOption) => {
                  setSelectedStatus({
                    label: selectedOption.label,
                    value: selectedOption.value,
                  });
                  handleParentChange(
                    "STATUS",
                    selectedOption.value,
                    currentIndex
                  );
                }}
              />
            </Form.Group>
          </Col>
          <Col xs={6} className="">
            <Form.Group>
              <Form.Label>NRIC / Passport No</Form.Label>
              <Form.Control
                type={`text`}
                disabled={isDisabled || listParent?.length === 0}
                required={selectedStatus?.value == "DL" ? true : false}
                value={listParent[currentIndex]?.ID_NO}
                onChange={(e) => {
                  handleParentChange("ID_NO", e.target.value, currentIndex)
                }
                }
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6} className="">
            <Form.Group className="d-flex flex-column">
              <Form.Label>Birth date</Form.Label>
              <DatePicker
                className={`form-control`}
                maxDate={new Date()}
                selected={listParent[currentIndex]?.BIRTHDATE}
                onChange={(date) => {
                  if (date == null) {
                    handleParentChange("BIRTHDATE", "", currentIndex);
                    handleParentChange("AGE", 0, currentIndex);
                  } else {
                    const choosenDate = moment(date).format(
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    handleParentChange("BIRTHDATE", choosenDate, currentIndex);
                    handleParentChange(
                      "AGE",
                      moment().diff(choosenDate, "years"),
                      currentIndex
                    );
                  }
                }}
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                disabled={
                  isDisabled ||
                  listParent?.length === 0 ||
                  selectedStatus?.value == "DE"
                }
              />
            </Form.Group>
          </Col>
          <Col xs={6} className="">
            <Form.Group>
              <Form.Label>Age</Form.Label>
              <Form.Control
                type={`text`}
                disabled={true}
                value={listParent[currentIndex]?.AGE}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6} className="">
            <Form.Group>
              <Form.Label>Occupation</Form.Label>
              <Select
                options={statusOccupationList}
                isDisabled={
                  isDisabled ||
                  listParent?.length === 0 ||
                  selectedStatus?.value == "DE" ||
                  selectedStatus?.value == "IJ"
                }
                value={selectedOccupation}
                onChange={(selectedOption) => {
                  handleParentChange(
                    "FAAS_OCCUPATIONS",
                    selectedOption.value,
                    currentIndex
                  );

                  setSelectedOccupation({
                    label: selectedOption.label,
                    value: selectedOption.value,
                    isIncomeContribute: selectedOption.is_income_contribute,
                    isRemarkRequired: selectedOption.is_remark_required,
                  });
                  if (selectedOption.is_income_contribute != "Y") {
                    handleParentChange("MTHLY_INCOME_CONTRIB", 0, currentIndex);
                  }
                }}
              />
            </Form.Group>
          </Col>
          <Col xs={6} className="">
            <Form.Group>
              <Form.Label>Other Income</Form.Label>
              <Form.Control
                type={`text`}
                disabled={isDisabled || listParent?.length === 0}
                value={listParent[currentIndex]?.OTHER_INCOME}
                onChange={(e) => {
                  handleParentChange(
                    "OTHER_INCOME",
                    e.target.value,
                    currentIndex
                  );
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={12} className="">
            <Form.Group>
              <Form.Label>Gross Monthly Income</Form.Label>
              <Form.Control
                type={`text`}
                disabled={
                  isDisabled ||
                  listParent?.length === 0 ||
                  selectedOccupation.isIncomeContribute != "Y" ||
                  selectedStatus?.value == "DE" ||
                  selectedStatus?.value == "DN" ||
                  selectedStatus?.value == "IJ"
                }
                value={listParent[currentIndex]?.MTHLY_INCOME_CONTRIB}
                onChange={(e) => {
                  handleParentChange(
                    "MTHLY_INCOME_CONTRIB",
                    e.target.value,
                    currentIndex
                  );
                  getTotalSumIncome();
                }}
              ></Form.Control>
            </Form.Group>
          </Col>

          <Col xs={12} className="">
            <Form.Group>
              <Form.Label>Remark</Form.Label>
              <Form.Control
                type={`text`}
                disabled={
                  isDisabled ||
                  listParent?.length === 0 ||
                  selectedOccupation.isRemarkRequired != "Y" ||
                  selectedOccupation.isRemarkRequired != "Y" ||
                  selectedStatus?.value !== "NL"
                }
                required={selectedStatus?.value == "NL" ? true : false}
                value={listParent[currentIndex]?.REMARK}
                onChange={(e) => {
                  handleParentChange("REMARK", e.target.value, currentIndex);
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default Mother;
