import {Button, Col, Form, Row,} from "@govtechsg/sgds-react";
import Layout from "@/components/Layout";
import React, {useEffect, useState} from "react";
import UploadModal from "@/components/UploadModal";
import {toast} from "react-toastify";
import CustomModal from "@/components/CustomModal";
import * as XLSX from "xlsx";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
    deleteComcare,
    getComcareList,
    postAppendComcare,
    postComcare,
    postOverwriteComcare,
    putComcare,
} from "@/apis/preparation/maintain-comcare/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {isStartDateLaterThanEndDate} from "@/components/utils/utils";
import SortableTable from "@/components/SortableTable";

export default function ComcareDetail() {
    const [adminNo, setAdminNo] = useState("");
    const [schemeName, setSchemeName] = useState("");

    const [data, setData] = useState([
        {
            ADM_NO: "",
            SCHEMES_NAME: "",
            SCHEMES_STARTDATE: "",
            SCHEMES_ENDDATE: "",
        },
    ]);

    const [header, setHeader] = useState([
        {key: 'index', label: '#', required: false},
        {key: 'SCHEMES_NAME', label: 'Scheme Name', required: false},
        {key: 'SCHEMES_STARTDATE', label: 'Start Date', required: true},
        {key: 'SCHEMES_ENDDATE', label: 'End Date', required: true},
        {key: 'action', label: '', required: false},
    ]);

    const [editArr, setEditArr] = useState([true]);

    const [show, setShow] = useState(false);

    const [showUpload, setShowUpload] = useState(false);

    const [selectedId, setSelectedId] = useState("");

    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [selectedFile, setSelectedFile] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isNextPage, setIsNextPage] = useState(true);
    const itemsPerPage = 20;

    const handleFileSelect = (file) => {
        setSelectedFile(file);
    };

    const [isEditing, setIsEditing] = useState(false);

    function excelDateToJSDate(excelDate) {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const dateOffset = (excelDate - 25569) * millisecondsPerDay;
        const jsDate = new Date(dateOffset);
        return jsDate.toISOString().split("T")[0];
    }

    const postOverwrite = () => {
        if (!selectedFile) {
            toast.error(`Select file`);

            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet);

            let canProceed = true;
            jsonData.map((row) => {
                if (
                    isStartDateLaterThanEndDate(
                        row?.SCHEMES_STARTDATE,
                        row?.SCHEMES_ENDDATE
                    )
                ) {
                    toast.error("End date cannot be earlier than start date.");
                    canProceed = false;
                }
            });

            if (canProceed) {
                const modifiedData = jsonData.map((row) => {
                    const dateColumns = ["SCHEMES_STARTDATE", "SCHEMES_ENDDATE"];

                    dateColumns.forEach((column) => {
                        if (row[column]) {
                            const formattedDate = excelDateToJSDate(row[column]);
                            row[column] = formattedDate;
                        }
                    });

                    return row;
                });
                postOverwriteComcare(modifiedData)
                    .then((response) => {
                        if (response?.response?.data?.message) {
                            if (
                                response?.response?.data?.message?.length > 0 &&
                                Array.isArray(response?.response?.data?.message)
                            ) {
                                response?.response?.data?.message?.map((contentErr) => {
                                    toast.error(contentErr?.msg);
                                });
                            } else {
                                toast.error(response?.response?.data?.message);
                            }
                        } else if (response?.response?.status == 404) {
                            toast.error("Data not found");
                        } else {
                            toast.success(`Record updated successfully `);
                            getCodes();
                            setShow(false);
                        }
                    })
                    .catch((error) => {
                        toast.error(error.response?.data.message);
                    });
            }
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const postAppend = () => {
        if (!selectedFile) {
            toast.error(`Select file`);

            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet);

            let canProceed = true;
            jsonData.map((row) => {
                if (
                    isStartDateLaterThanEndDate(
                        row?.SCHEMES_STARTDATE,
                        row?.SCHEMES_ENDDATE
                    )
                ) {
                    toast.error("End date cannot be earlier than start date.");
                    canProceed = false;
                }
            });

            if (canProceed) {
                const modifiedData = jsonData.map((row) => {
                    const dateColumns = ["SCHEMES_STARTDATE", "SCHEMES_ENDDATE"];

                    dateColumns.forEach((column) => {
                        if (row[column]) {
                            const formattedDate = excelDateToJSDate(row[column]);
                            row[column] = formattedDate;
                        }
                    });

                    return row;
                });

                postAppendComcare(modifiedData)
                    .then((response) => {
                        if (response?.response?.data?.message) {
                            if (
                                response?.response?.data?.message?.length > 0 &&
                                Array.isArray(response?.response?.data?.message)
                            ) {
                                response?.response?.data?.message?.map((contentErr) => {
                                    toast.error(contentErr?.msg);
                                });
                            } else {
                                toast.error(response?.response?.data?.message);
                            }
                        } else if (response?.response?.status == 404) {
                            toast.error("Data not found");
                        } else {
                            toast.success(`Record updated successfully `);
                            getCodes();
                            setShow(false);
                        }
                    })
                    .catch((error) => {
                        toast.error(error.response?.data.message);
                    });
            }
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const handleEdit = (index) => {
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = false;
            return newArr;
        });

        setIsEditing(true);
    };

    const handleSubmitEdit = (index, CodeID) => {
        if (
            !data[index].ADM_NO ||
            !data[index].SCHEMES_NAME ||
            !data[index].SCHEMES_STARTDATE
        ) {
            toast.error("Please fill out the required fields");
            return false;
        }

        const startDate = moment(data[index].SCHEMES_STARTDATE);
        const endDate = moment(data[index].SCHEMES_ENDDATE);

        if (endDate.isBefore(startDate)) {
            toast.error("End date cannot be earlier than start date.");
            return
        } else if (startDate.isAfter(endDate)) {
            toast.error("Start date cannot be later than end date.");
            return
        }

        const CodeData = {
            ADM_NO: data[index].ADM_NO,
            SCHEMES_NAME: data[index].SCHEMES_NAME,
            SCHEMES_STARTDATE: moment(data[index].SCHEMES_STARTDATE).format(
                "YYYY-MM-DD"
            ),
            SCHEMES_ENDDATE: moment(data[index].SCHEMES_ENDDATE).format("YYYY-MM-DD"),
        };

        //submit data
        if (CodeID) {
            //update
            putComcare(CodeID, CodeData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getCodes();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });
                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            //post
            postComcare(CodeData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getCodes();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });

                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        }
    };

    const handleDeleteRow = () => {
        if (selectedId) {
            deleteComcare(selectedId)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success("Record deleted successfully");
                        getCodes();
                        setShow(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            const updatedData = [...data];

            updatedData.splice(selectedIndex, 1);

            setData(updatedData);

            setShow(false);
            setIsEditing(false);

            const updatedEditArray = [...editArr];
            updatedEditArray.splice(selectedIndex, 1);
            setEditArr(updatedEditArray);
        }
    };

    const handleChange = (index, field, value) => {
        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            [field]: value,
        };
        setData(updatedData);
    };

    const getCodes = () => {
        getComcareList(adminNo, schemeName, itemsPerPage, currentPage)
            .then((response) => {
                if (response?.data?.data?.length > 0) {
                    setData(response?.data.data);
                    const newArr = Array.from({
                        length: response?.data.data.length,
                    }).fill(true);

                    setEditArr(newArr);
                    setTotalPages(response?.data?.totalPage);
                    setIsNextPage(response?.data?.nextPage);
                } else {
                    toast.warning("Data not found");
                }
            })
            .catch((error) => {
                toast.error(error.response?.data.message);
            });
    };

    const handleCancelEdit = (index) => {
        if (data[index].new_data === true) {
            const updatedData = data.filter(item => !item.new_data);
            setData(updatedData);
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = true;
            return newArr;
        });

        setIsEditing(false);
    }

    const renderActionButtons = (row, rowIndex) => (
        <div className={`d-flex gap-1`}>
            {!editArr[rowIndex] ? (
                <div className={`d-flex gap-1`}>
                    <button
                        className={`btn-clear text-green-500`}
                        onClick={() => handleSubmitEdit(rowIndex, row.ID)}
                    >
                        <i className={`bi bi-check-lg`}></i>
                        Save
                    </button>

                    <button
                        className={`btn-clear text-red-500`}
                        onClick={() => handleCancelEdit(rowIndex)}
                    >
                        <i className={`bi bi-x-circle`}></i>
                        {` `}
                        Cancel
                    </button>
                </div>
            ) : (
                <button
                    className={`btn-clear`}
                    disabled={isEditing}
                    onClick={() => handleEdit(rowIndex)}
                >
                    <i className={`bi bi-pencil`}></i>
                    {` `}
                    Edit
                </button>
            )}

            <button
                className={`btn-clear`}
                disabled={rowIndex == 0 ? row?.ID ? isEditing : false : isEditing}
                onClick={() => {
                    setShow(true);
                    setSelectedId(row.ID ?? "");
                    setSelectedIndex(rowIndex);
                }}
            >
                <i className={`bi bi-trash`}></i>
            </button>
        </div>
    );

    const renderCellContent = (row, rowIndex, key) => (
        editArr[rowIndex] ? (
            key === "SCHEMES_STARTDATE" || key === "SCHEMES_ENDDATE" ? moment(row[key]).format("DD/MM/YYYY") : row[key]
        ) : (

            key !== "SCHEMES_NAME" ?
                <DatePicker
                    selected={row[key]}
                    onChange={(date) => {
                        if (date === null) {
                            handleChange(rowIndex, key, "");
                        } else {
                            handleChange(rowIndex, key, date);
                        }
                    }}
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                />
                : row[key]
        )
    );

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handler to go to the previous page
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        getCodes();
    }, [currentPage]);
    return (
        <Layout currentNav={"preparation"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Setup Preparation</Breadcrumb.Item>
                <Breadcrumb.Item active>Maintain and Upload Comcare</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Maintain and Upload Comcare</h2>
            </sgds-content-header-top>
            <sgds-content-area-bottom>
                <Row className={`align-items-end`}>
                    <Col xs={3}>
                        <Form.Group>
                            <Form.Label>Adm no.</Form.Label>
                            <Form.Control
                                type={"text"}
                                onChange={(e) => setAdminNo(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </Col>

                    <Col xs={2}>
                        <Form.Group>
                            <Form.Label>Scheme Name</Form.Label>
                            <Form.Control
                                type={"text"}
                                onChange={(e) => setSchemeName(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Button className={`btn-primary`} onClick={() => getCodes()}>
                            Retrieve
                        </Button>
                    </Col>
                </Row>
            </sgds-content-area-bottom>
            <div>
                <Button
                    variant={"outline-dark"}
                    className={`d-flex gap-2`}
                    onClick={() => {
                        setShowUpload(true);
                    }}
                >
                    <i className={`bi bi-upload`}></i>
                    Upload File
                </Button>
            </div>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm`}>
                    <SortableTable headers={header} data={data} renderActionButtons={renderActionButtons}
                                   renderCellContent={renderCellContent} setData={setData}/>

                    <div className="text-center">
                        <button
                            className={`btn-clear`}
                            onClick={prevPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
              {" "}
                            Page {currentPage} of {totalPages}{" "}
            </span>
                        <button
                            className={`btn-clear`}
                            onClick={nextPage}
                            disabled={!isNextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
                {/*<Table responsive>*/}
                {/*    <TableHeader>*/}
                {/*        <TableRow>*/}
                {/*            <TableHeaderCell width={"5%"}>#</TableHeaderCell>*/}
                {/*            <TableHeaderCell>*/}
                {/*                Adm No.*/}
                {/*                <span className={`text-danger`}> *</span>*/}
                {/*            </TableHeaderCell>*/}
                {/*            <TableHeaderCell>*/}
                {/*                Scheme Name*/}
                {/*                <span className={`text-danger`}> *</span>*/}
                {/*            </TableHeaderCell>*/}
                {/*            <TableHeaderCell>*/}
                {/*                Start Date*/}
                {/*                <span className={`text-danger`}> *</span>*/}
                {/*            </TableHeaderCell>*/}
                {/*            <TableHeaderCell>End Date</TableHeaderCell>*/}
                {/*            <TableHeaderCell width={"10%"}></TableHeaderCell>*/}
                {/*        </TableRow>*/}
                {/*    </TableHeader>*/}

                {/*    <TableBody>*/}
                {/*        {data?.map((row, index) => (*/}
                {/*            <TableRow key={index}>*/}
                {/*                <TableDataCell>{index + 1}</TableDataCell>*/}
                {/*                <TableDataCell>{row.ADM_NO}</TableDataCell>*/}
                {/*                <TableDataCell>{row.SCHEMES_NAME}</TableDataCell>*/}
                {/*                <TableDataCell>*/}
                {/*                    {!editArr[index] ? (*/}
                {/*                        <DatePicker*/}
                {/*                            selected={row.SCHEMES_STARTDATE}*/}
                {/*                            onChange={(date) => {*/}
                {/*                                if (date == null) {*/}
                {/*                                    handleChange(index, "SCHEMES_STARTDATE", "");*/}
                {/*                                } else {*/}
                {/*                                    handleChange(index, "SCHEMES_STARTDATE", date);*/}
                {/*                                }*/}
                {/*                            }}*/}
                {/*                            dateFormat="dd/MM/yyyy"*/}
                {/*                            showMonthDropdown*/}
                {/*                            showYearDropdown*/}
                {/*                        />*/}
                {/*                    ) : (*/}
                {/*                        moment(row.SCHEMES_STARTDATE).format("DD/MM/YYYY")*/}
                {/*                    )}*/}
                {/*                </TableDataCell>*/}
                {/*                <TableDataCell>*/}
                {/*                    {!editArr[index] ? (*/}
                {/*                        <DatePicker*/}
                {/*                            selected={row.SCHEMES_ENDDATE}*/}
                {/*                            onChange={(date) => {*/}
                {/*                                if (date == null) {*/}
                {/*                                    handleChange(index, "SCHEMES_ENDDATE", "");*/}
                {/*                                } else {*/}
                {/*                                    handleChange(index, "SCHEMES_ENDDATE", date);*/}
                {/*                                }*/}
                {/*                            }}*/}
                {/*                            dateFormat="dd/MM/yyyy"*/}
                {/*                            showMonthDropdown*/}
                {/*                            showYearDropdown*/}
                {/*                        />*/}
                {/*                    ) : (*/}
                {/*                        moment(row.SCHEMES_ENDDATE).format("DD/MM/YYYY")*/}
                {/*                    )}*/}
                {/*                </TableDataCell>*/}
                {/*                <TableDataCell>*/}
                {/*                    <div className={`d-flex gap-1`}>*/}
                {/*                        {!editArr[index] ? (*/}
                {/*                            <button*/}
                {/*                                className={`btn-clear text-green-500`}*/}
                {/*                                onClick={() => handleSubmitEdit(index, row.ID)}*/}
                {/*                            >*/}
                {/*                                <i className={`bi bi-check-lg`}></i>*/}
                {/*                                {` `}*/}
                {/*                                Save*/}
                {/*                            </button>*/}
                {/*                        ) : (*/}
                {/*                            <button*/}
                {/*                                className={`btn-clear`}*/}
                {/*                                disabled={isEditing}*/}
                {/*                                onClick={() => handleEdit(index)}*/}
                {/*                            >*/}
                {/*                                <i className={`bi bi-pencil`}></i> Edit*/}
                {/*                            </button>*/}
                {/*                        )}*/}

                {/*                        <button*/}
                {/*                            className={`btn-clear`}*/}
                {/*                            disabled={isEditing}*/}
                {/*                            onClick={() => {*/}
                {/*                                setShow(true);*/}
                {/*                                setSelectedId(row.ID ?? "");*/}
                {/*                                setSelectedIndex(index);*/}
                {/*                            }}*/}
                {/*                        >*/}
                {/*                            <i className={`bi bi-trash`}></i>*/}
                {/*                        </button>*/}
                {/*                    </div>*/}
                {/*                </TableDataCell>*/}
                {/*            </TableRow>*/}
                {/*        ))}*/}
                {/*    </TableBody>*/}
                {/*</Table>*/}

            </sgds-content-body>

            <UploadModal
                show={showUpload}
                handleClose={() => setShowUpload(false)}
                title={`Upload Comcare`}
                onFileSelect={handleFileSelect}
                actionOverwrite={postOverwrite}
                actionAppend={postAppend}
                templateURL={`https://docs.google.com/spreadsheets/d/1Vp2ZULwVC8lVbLcL_43YCQEW0OYLRgQ9q3cxfEhgwqo/edit?gid=0#gid=0`}
            />

            <CustomModal
                show={show}
                title={`Delete record?`}
                handleClose={() => setShow(false)}
                action={handleDeleteRow}
                description={`The selected record will be remove from the list. Proceed to delete?`}
            />
        </Layout>
    );
}
