import {Accordion} from "@govtechsg/sgds-react/Accordion";
import {Col, Form, Row} from "@govtechsg/sgds-react";
import React from "react";
import moment from "moment/moment";

export default function OriginalDetails({
                                            isDisabled,
                                            applicationDetail,
                                            listMTCRequestResult,
                                            listMTCDetails,
                                            listMTIncomes,
                                            listMTConsents,
                                            listMTCases,
                                            listMTCaseNotes,
                                            listMTCDetailsOriginal,
                                            listOriginalDetails,
                                        }) {
    return (
        <>
            <div className="">
                <Accordion defaultActiveKey="1" className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Family Members
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                {
                                    listMTCDetailsOriginal?.map((detail, index) => (
                                        <Row key={index}>
                                            <Col xs={12}>
                                                <Form.Label>Family #{index + 1}</Form.Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group>
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled={true}
                                                        value={detail?.HH_NAME}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group>
                                                    <Form.Label>NRIC/Fin/Passport No. (if foreigner)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled={true}
                                                        value={detail?.HH_UIN}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>Date of Birth</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled={true}
                                                        value={moment(detail?.DOB).format('YYYY-MM-DD')}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>Age</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled={true}

                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>Gender </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled={true}
                                                        value={detail?.GENDER}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>Relationship Status</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled={true}
                                                        value={detail?.RELATIONSHIP}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>Residential Status</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled={true}
                                                        value={detail?.RES_STATUS}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group>
                                                    <Form.Label>Marital Status</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        disabled={true}
                                                        value={detail?.MARITAL_STATUS}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="">
                <Accordion defaultActiveKey="1" className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Income Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Occupation</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listMTCDetailsOriginal?.OCCUPATION}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Other income</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listMTCDetailsOriginal?.OTHER_INCOME}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Text>
                                                Reason if occupation status is unemployed
                                            </Form.Text>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Special Means Status</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listMTCDetailsOriginal?.SPECIAL_MEANS_STATUS}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="">
                <Accordion defaultActiveKey="1" className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Consent Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Reusable Consent</Form.Label>
                                            <Form.Text>If no, please specify Consent NRIC and Name)</Form.Text>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listMTCDetailsOriginal?.REUSABLE_CONSENTSCOPE}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>Reusable Consent ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listMTCDetailsOriginal?.REUSABLE_CONSENTID}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>Reusable Consent Scope</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listMTCDetailsOriginal?.REUSABLE_CONSENTSCOPE}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>Is No Valid Auth</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listMTCDetailsOriginal?.ISNOVALIDAUTH}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Consent Type</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listOriginalDetails?.CONSENT_TYPE}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Consent Scope</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listOriginalDetails?.CONSENT_SCOPE}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Legal Capacity</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listOriginalDetails?.LEGAL_CAPACITY}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Manual Consent</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listOriginalDetails?.NYP_MANUAL_CONSENT}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Consent NRIC</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listOriginalDetails?.NYP_CONSENT_ID}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Consent Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listOriginalDetails?.NYP_CONSENT_NAME}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Consent Status</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listOriginalDetails?.NYP_CONSENT_STATUS}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Consent Date</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listOriginalDetails?.NYP_CONSENT_DATE}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={listMTCDetailsOriginal?.REMARKS}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    )
}
