import React, { useCallback, useEffect, useRef, useState } from "react";
import Layout from "@/components/Layout";
import {
  Button,
  Col,
  Form,
  FormCheck,
  Row,
  Table,
} from "@govtechsg/sgds-react";
import Select from "react-select";
import { Breadcrumb } from "@govtechsg/sgds-react/Breadcrumb";
import { getAcademyYear, getExercise } from "@/apis/timeline/api";
import { getCodeList, getStudentDetail } from "@/apis/sims/api";
import CustomModal from "@/components/CustomModal";
import { GetShortlistApplicants } from "@/apis/reports/api";

import { getApplicationList } from "@/apis/app-records/maintenance/api";

import jsPDF from "jspdf";
import { utils, writeFileXLSX } from "xlsx";
import autoTable from "jspdf-autotable";
import { toast } from "react-toastify";

const options = [{ value: "CREATED_AT", label: "CREATED_AT" }];

function PrintShortlistedApplicants() {
  const [showRetrieve, setShowRetrieve] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  // choice
  const [academyYear, setAcademyYear] = useState({ label: new Date().getFullYear(), value: new Date().getFullYear() });
  const [exercise, setExercise] = useState({ label: "", value: "" });
  const [award, setAward] = useState({ label: "", value: "" });
  const [awardType, setAwardType] = useState({ label: "", value: "" });
  const [orderByField, setOrderByField] = useState("CREATED_AT");
  const [shortlistedPrint, setShortlistedPrint] = useState({});
  const [applicationDetail, setApplicationDetail] = useState({});

  // list choice
  const [dataAcademyYear, setDataAcademyYear] = useState([]);
  const [dataExercise, setDataExercise] = useState([]);
  const [dataAward, setDataAward] = useState([]);
  const [dataAwardType, setDataAwardType] = useState([]);

  const [loadingAcademyYear, setLoadingAcademyYear] = useState(true);
  const [loadingListAward, setLoadingListAward] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [dataApplications, setDataApplications] = useState(null);

  const refPDF = useRef();
  const refCSV = useRef();
  const headerRef = useRef();
  const tbl = useRef(null);
  const courseTableRef = useRef(null);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  const handleSubmit = async () => {
    console.log(awardType.value);

    try {
      // handle get application duluan
      const data = await getApplicationList({
        ACAD_YR: academyYear?.value,
        EXERCISE: exercise?.value,
        REC_AWARD_TYPE: awardType?.value,
        REC_AWARD_CODE: award?.value,
        ORDER_BY_FIELD: orderByField,
      });

      console.log("data");
      console.log(data?.data?.data);
      setShowRetrieve(true);

      if (data?.data?.data?.length > 0) {
        setApplicationDetail(data?.data?.data[0]);
        const responseFromApplicationList = data?.data?.data;
        responseFromApplicationList.map(async (content, index) => {
          const studentResponse = await getStudentDetail(
            // content?.ADM_NO,
            "141561Y",
            content?.ACAD_YR
          );

          if (studentResponse?.data?.data?.length > 0) {
            setShortlistedPrint(studentResponse?.data?.data[0]);
          }
        });
      }

      // then get student list based on application list
    } catch (error) {
      console.log(error);
      toast.error("Unable to get data");
    }
  };

  const exportToXlsx = useCallback(() => {
    const headerElement = headerRef.current;
    const mainTable = tbl.current;
    const courseTable = courseTableRef.current;

    const extractTextContent = (element) => {
      if (!element) return [];
      const rows = [];
      const headingElements = element.querySelectorAll("h3, h5, h6");
      headingElements.forEach((headingElement) => {
        rows.push([headingElement.textContent.trim()]);
      });
      return rows;
    };

    const headerRows = extractTextContent(headerElement);

    const headerSheet = utils.aoa_to_sheet(headerRows);

    const tableSheet = utils.table_to_sheet(mainTable);

    const combinedSheet = utils.sheet_add_aoa(
      headerSheet,
      utils.sheet_to_json(tableSheet, { header: 1, skipHeader: true }),
      { origin: -1 }
    );

    const wb = utils.book_new();

    utils.book_append_sheet(wb, combinedSheet, "Applicant Data");

    const wsCourse = utils.table_to_sheet(courseTable);
    utils.book_append_sheet(wb, wsCourse, "Course Data");

    writeFileXLSX(wb, "shorlisted-applicants.xlsx");
  }, [tbl]);

  const handleExport = () => {
    let optionChecked = "";

    let pdfChecked = refPDF.current.checked;
    let csvChecked = refCSV.current.checked;
    if (pdfChecked) {
      optionChecked = "PDF";
    } else if (csvChecked) {
      optionChecked = "CSV";
    }

    if (optionChecked == "PDF") {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a3",
        margin: [10, 10, 10, 10],
      });

      //header
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      doc.text("Shortlisted Applicants Report", 105, 20, { align: "center" });

      doc.setFontSize(14);
      doc.text(
        `Shortlisted Students for CDC/CCC-POLY BURSARY TIER 1 for ${
          academyYear?.label ?? "-"
        }`,
        105,
        30,
        { align: "center" }
      );

      doc.setFontSize(12);
      doc.text(
        `Quantum: ${
          shortlistedPrint?.studFinAssistList?.length > 0
            ? shortlistedPrint?.studFinAssistList[
                shortlistedPrint?.studFinAssistList?.length - 1
              ]?.amtAwarded
            : "-"
        }`,
        20,
        50
      );

      doc.text(`Eligibility Criteria: ${awardType?.label ?? "-"}`, 20, 60);

      doc.setFontSize(12);
      doc.text("Notes", 20, 80);
      doc.text(
        "MPCI: Monthly Per Capital Income (Gross Mthly Income / No of Dependents in Household)",
        20,
        90
      );
      doc.text(
        "FPCI: Family Per Capital Income (Gross Mthly Income * 12 / No of Dependents in Household)",
        20,
        100
      );
      doc.text("A*: Distinction", 20, 110);

      autoTable(doc, {
        startY: 120,
        html: "#my-table",
        headStyles: { fillColor: [9, 230, 42], textColor: [255, 255, 255] },
        columnStyles: {
          0: { cellWidth: 60 },
          1: { cellWidth: 80 },
          2: { cellWidth: 80 },
        },
        styles: {
          fontSize: 10,
          cellPadding: 4,
          valign: "middle",
        },
        margin: { top: 20 },
      });

      const lastY = doc.autoTable.previous.finalY;

      autoTable(doc, {
        startY: lastY + 30,
        html: "#course-table",
        headStyles: { fillColor: [9, 230, 42], textColor: [255, 255, 255] },
        columnStyles: {
          0: { cellWidth: 60 },
          1: { cellWidth: 40 },
          2: { cellWidth: 40 },
        },
        styles: {
          fontSize: 10,
          cellPadding: 4,
          valign: "middle",
          overflow: "linebreak",
        },
        margin: { top: 20 },
      });

      doc.save("shortlisted-applicants.pdf");
    } else if (optionChecked == "CSV") {
      exportToXlsx();
    }
  };

  const handleRetrieve = () => {
    setShowRetrieve(true);
    GetShortlistApplicants(
      academyYear?.value,
      exercise?.value,
      awardType?.value,
      award?.value
    )
      .then((res) => {
        console.log(res.data);
        setDataApplications(res.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const handleGetListAcademyYear = async () => {
    setLoadingAcademyYear(true);

    try {
      setAcademyYear(null);

      const response = await getAcademyYear();

      setDataAcademyYear(
        response?.data?.data?.map((item) => ({
          label: item?.ACAD_YR,
          value: item?.ACAD_YR,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoadingAcademyYear(false);
    }
  };

  const handleGetListAward = async (codeType = "") => {
    setLoadingListAward(true);

    try {
      setAward(null);

      const response = await getCodeList(codeType);

      setDataAward(
        response?.data?.data?.map((item) => ({
          label: item?.description,
          value: item?.code,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoadingListAward(false);
    }
  };

  const handleGetListAwardType = async (codeType = "AWARDTYPECODE") => {
    try {
      setAwardType({ label: "", value: "" });

      const response = await getCodeList(codeType);

      setDataAwardType(
        response?.data?.data?.map((item) => ({
          label: item?.description,
          value: item?.code,
          codetype: item?.codetype,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleGetListExercise = async () => {
    const params = {
      ACAD_YR: academyYear?.value,
    };

    try {
      setExercise(null);

      const response = await getExercise(params);

      setDataExercise(
        response?.data?.data?.map((item) => ({
          label: item?.EXERCISE,
          value: item?.EXERCISE,
        }))
      );
    } catch (error) {
      console.log(error?.message);
    }
  };
  useEffect(() => {
    handleGetListAcademyYear();
    handleGetListAwardType();
  }, []);

  useEffect(() => {
    if (academyYear?.value) handleGetListExercise();
  }, [academyYear]);

  useEffect(() => {
    console.log("award type kena");
    if (awardType?.value) handleGetListAward(awardType?.value);
  }, [awardType]);
  return (
    <Layout currentNav={"reports"}>
      <Breadcrumb
        listProps={{
          className: "bg-light",
          foo: "bar",
        }}
      >
        <Breadcrumb.Item>Reports</Breadcrumb.Item>
        <Breadcrumb.Item active>Shortlisted Applicants</Breadcrumb.Item>
      </Breadcrumb>

      <sgds-content-header-top>
        <h2>Shortlisted Applicants</h2>
      </sgds-content-header-top>
      <sgds-content-body>
        {" "}
        <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
          <div className="row">
            <div className="col">
              <h3>Data Retrieval</h3>
            </div>
          </div>

          <Form
            className={` d-flex flex-column gap-3 collapse-animation`}
            // onSubmit={}
          >
            <Row>
              <Col xs={6}>
                <Form.Group>
                  <Form.Label>Academic Year</Form.Label>
                  <Select
                    onChange={(value) => setAcademyYear(value)}
                    options={dataAcademyYear}
                    placeholder={`Academic Year`}
                    value={academyYear}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group>
                  <Form.Label>Exercise</Form.Label>
                  <Select
                    isDisabled={!academyYear?.value}
                    onChange={(value) => setExercise(value)}
                    options={dataExercise}
                    value={exercise}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} className="mt-3">
                <Form.Group>
                  <Form.Label>Award Type</Form.Label>
                  <Select
                    onChange={(value) => setAwardType(value)}
                    options={dataAwardType}
                    value={awardType}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} className="mt-3">
                <Form.Group>
                  <Form.Label>Award</Form.Label>
                  <Select
                    isDisabled={!awardType?.value}
                    onChange={(value) => setAward(value)}
                    options={dataAward}
                    value={award}
                  />
                </Form.Group>
              </Col>
              <Col xs={4} className="mt-3">
                <Form.Group>
                  <Form.Label>Order by</Form.Label>
                  <Select
                    options={options}
                    onChange={(selected) => setOrderByField(selected.value)}
                  />
                </Form.Group>
              </Col>{" "}
            </Row>
            <Row>
              <Col xs={3}>
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Retrieve
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        {showRetrieve && (
          <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
            <Row>
              <Col xs={12}>
                <div className="text-end">
                  <Button
                    type="button"
                    className="btn btn-primary ms-auto"
                    onClick={() => setShowModalExport(true)}
                  >
                    Export
                  </Button>
                </div>
              </Col>
            </Row>
            <div>
              <Row id="header-pdf" ref={headerRef}>
                <Col xs={12}>
                  <h3 className="text-center mb-6">
                    Shortlisted Applicants Report
                  </h3>
                </Col>
                <Col xs={12}>
                  <h5>
                    <u>
                      Shortlisted Students for CDC/CCC-POLY BURSARY TIER 1 for
                      {academyYear?.label}
                    </u>
                  </h5>
                </Col>
                <Col xs={4}>
                  <h5>
                    Quantum:{" "}
                    {shortlistedPrint?.studFinAssistList?.length > 0
                      ? shortlistedPrint?.studFinAssistList[
                          shortlistedPrint?.studFinAssistList?.length - 1
                        ]?.amtAwarded
                      : "-"}
                  </h5>
                </Col>
                <Col xs={4}>
                  <h5>
                    Eligibility Criteria: <span>{awardType?.label}</span>
                  </h5>
                </Col>
                <Col xs={12}>
                  <h6>Notes</h6>
                  <h6>
                    MPCI: Monthly Per Capital Income (Gross Mthly Income / No of
                    Dependents in Household)
                  </h6>
                  <h6>
                    FPCI: Family Per Capital Income (Gross Mthly Income * 12 /
                    No of Dependents in Household)
                  </h6>
                  <h6>A*: Distinction</h6>
                </Col>
              </Row>
              <Table responsive ref={tbl} id="my-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>PARTICULARS</Table.HeaderCell>
                    <Table.HeaderCell>FINANCIAL SITUATION</Table.HeaderCell>
                    <Table.HeaderCell>ACADEMIC PERFORMANCE</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row key={1}>
                    <Table.DataCell>
                      <div className={`d-flex gap-3`}>
                        <div>Name:</div>
                        <div>{shortlistedPrint?.name ?? "-"}</div>
                      </div>
                      <br />
                      <div className={`d-flex gap-3`}>
                        <div>Course Code:</div>
                        <div>
                          {shortlistedPrint?.courseList?.length > 0
                            ? shortlistedPrint?.courseList[0]?.courseCode
                            : "-"}
                        </div>
                      </div>
                      <br />
                      <div className={`d-flex gap-3`}>
                        <div>Study Stage:</div>
                        <div>
                          {shortlistedPrint?.courseList?.length > 0
                            ? shortlistedPrint?.courseList[0]?.studyStage
                            : "-"}
                        </div>
                      </div>
                      <br />
                      <div className={``}>
                        <div>Address:</div>
                        <br />
                        <div className={`d-flex gap-3 flex-column ms-2`}>
                          <div>TERM 1 :{shortlistedPrint?.termAddr1}</div>
                          <br />
                          <div>TERM 2 :{shortlistedPrint?.termAddr2}</div>
                          <br />
                          <div>TERM 3 :{shortlistedPrint?.termAddr3}</div>
                        </div>
                      </div>
                      <br />
                      <div className={``}>
                        <div>Tel:</div>
                        <br />
                        <div className={`d-flex flex-column ms-2`}>
                          <div>TEL 1 :{shortlistedPrint?.termTel}</div>
                          <br />
                          <div>TEL 2 :{shortlistedPrint?.termTel2}</div>
                        </div>
                      </div>
                      <br />
                      <div className={`d-flex gap-3`}>
                        <div>Pager:</div>
                        <div>Pager</div>
                      </div>
                      <br />
                      <div className={`d-flex gap-3`}>
                        <div>HP:</div>
                        <div>{shortlistedPrint?.handphone}</div>
                      </div>
                    </Table.DataCell>
                    <Table.DataCell>
                      <div className={`d-flex gap-3`}>
                        <div>Gross Mthly Income:</div>
                        <div>${applicationDetail?.GROSS_INCOME}</div>
                      </div>
                      <br />
                      <div className={`d-flex gap-3`}>
                        <div>Gross Household Income (CCC Income):</div>
                        <div>${applicationDetail?.CCC_INCOME}</div>
                      </div>
                      <br />
                      <div className={`d-flex gap-3`}>
                        <div>No. of Dependents make up of (age):</div>
                        <div>{applicationDetail?.DEPENDENT}</div>
                      </div>
                      <br />
                      <br />
                      <div className={``}>
                        <div>MPCI: ${applicationDetail?.MPCI}</div>
                        <br />
                        <div>FPCI: ${applicationDetail?.FPCI}</div>
                      </div>
                      <br />
                      <div className={`d-flex gap-3`}>
                        <div>Special Details:</div>
                        <div>{applicationDetail?.SPECIAL_DETAILS}</div>
                      </div>
                    </Table.DataCell>
                    <Table.DataCell>
                      <div className={`d-flex gap-3`}>
                        <div>Entry Qual:</div>
                        <div>
                          {shortlistedPrint?.courseList?.length > 0
                            ? shortlistedPrint?.courseList[0]?.entryQual
                            : "-"}
                        </div>
                      </div>
                      <br />
                      <div className={`d-flex flex-column`}>
                        <div>GCEO Level Aggregate: -</div>
                        <br />
                        <div>CCA Grade (Points): -</div>
                      </div>
                      <br />
                    </Table.DataCell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>

            <Row>
              <Col xs={12} className="">
                <Table size={"sm"} id={`course-table`} ref={courseTableRef}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Subject</Table.HeaderCell>
                      <Table.HeaderCell>Year</Table.HeaderCell>
                      <Table.HeaderCell>Grade</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {shortlistedPrint?.courseList?.length > 0
                      ? shortlistedPrint?.courseList[0]?.studyPgmsList?.map(
                          (module, indexModule) => {
                            return (
                              <Table.Row key={indexModule}>
                                <Table.DataCell>
                                  {module?.moduleDesc}
                                </Table.DataCell>
                                <Table.DataCell>
                                  {module?.acadYr}
                                </Table.DataCell>
                                <Table.DataCell>
                                  {module?.moduleScore}
                                </Table.DataCell>
                              </Table.Row>
                            );
                          }
                        )
                      : "-"}
                  </Table.Body>
                </Table>
              </Col>
            </Row>
          </div>
        )}
        <CustomModal
          show={showModalExport}
          title={`Export`}
          handleClose={() => setShowModalExport(false)}
          modalSize={"md"}
          withIcon={false}
          description={
            <div>
              <div>
                <b>Export as</b>
              </div>
              <div>
                <FormCheck
                  type="radio"
                  name="export"
                  label="PDF"
                  value="pdf"
                  id="pdf"
                  ref={refPDF}
                />
                <FormCheck
                  ref={refCSV}
                  type="radio"
                  name="export"
                  label="CSV"
                  value="csv"
                  id="csv"
                />
              </div>
            </div>
          }
          submitButtonText={`Export`}
          action={handleExport}
        />
      </sgds-content-body>
    </Layout>
  );
}

export default PrintShortlistedApplicants;
