import axios from "axios";

export function postUploadErrorLogs(data) {
  return axios
    .post(
      process.env.REACT_APP_API_URL +
        "/api/v1/applications/logs/uploads/errors",
      data
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}
