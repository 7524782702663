import React from "react";
import axios from "axios";

export async function getListAcadYears() {
  return axios
    .get(
      process.env.REACT_APP_API_URL +
        "/api/v1/applications/timelines/distincts/acadyears"
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}

export async function getListExercise(year) {
  let url =
    process.env.REACT_APP_API_URL +
    "/api/v1/applications/timelines/distincts/exercises?ACAD_YR=" +
    year;

  if (year == null) {
    url =
      process.env.REACT_APP_API_URL +
      "/api/v1/applications/timelines/distincts/exercises";
  }

  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
      return err;
    });
}
