import { apexAxiosCall } from "@/utils/apiCall";
import axios from "axios";

export async function getStaffList(search = "") {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/getStaffList",
    {
      dummy_account: "Y",
      empm_type: "F",
      system: "FAAS",
      page: "0",
      limit: "0",
      search: search,
      sort: "staff_id",
    }
  );
}

export async function getRoleStaffList(search = "", page = 1, limit = 50) {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/getStaffRoleList",
    {
      dummy_account: "Y",
      empm_type: "F",
      system: "FAAS",
      page: page,
      limit: limit,
      search: search,
      sort: "staff_id",
    }
  );
}

export function getStaffDetail(userId, system = "FAAS", role_tag = "Y") {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/getStaffInfo",
    {
      userId: userId,
      system: system,
      role_tag: role_tag,
    }
  );
}

export function getRolesList() {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/getSysRoles",
    {
      application_sys: "FAAS",
    }
  );
}

export const updateRoleSingleEmployee = (
  staff_id = "",
  application_sys = "",
  assigned_by = "",
  last_upd_id = "",
  role = "",
  org_role = ""
) => {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/saveStaffRoles",
    {
      data: [
        {
          staff_id,
          application_sys,
          assigned_by,
          last_upd_id,
          role,
          org_role,
          action: "U",
        },
      ],
    }
  );
};

export const updateRoleMultipleEmployee = (data) => {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/saveStaffRoles",
    {
      data,
    }
  );
};

export const deleteRole = (
  staff_id = "",
  application_sys = "",
  assigned_by = "",
  last_upd_id = "",
  role = "",
  org_role = ""
) => {
  return apexAxiosCall(
    process.env.REACT_APP_NYP_STAFF_API_URL + "/resources/sp/saveStaffRoles",
    {
      data: [
        {
          staff_id,
          application_sys,
          assigned_by,
          last_upd_id,
          role,
          org_role,
          action: "D",
        },
      ],
    }
  );
};

export function getApplications() {
  return axios
    .get(process.env.REACT_APP_API_URL + "/api/v1/applications")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.error(err?.message);
    });
}
